import { styled } from '@mui/material/styles';
import { Dispatch, FocusEventHandler, MouseEventHandler, useCallback, useState } from 'react';
import { useRefreshAsync } from '../../../atoms/project-details';
import { maxLength } from '../../../services/max-length';
import { RButton } from '../../base/button/button';
import { RTextArea } from '../../base/text-area/text-area';
import { RValidationMessages } from '../../core/validation-message/validation-messages';
import { useElementID } from '../../hooks/element-id';
import { useSave } from '../../hooks/save';
import { ComponentPropsNormarized, ValidationErrors } from '../../types';
import { CategorySelect } from './category-select';

type Props = {
  projectID: number;
} & ComponentPropsNormarized<'form'>;

function Component({ projectID, className }: Props): JSX.Element {
  const formID = useElementID();

  const [comment, setComment] = useState('');
  const [inputting, setInputting] = useState(false);
  const onBlur = useOnBlur(formID, setInputting);

  const [category, setCategory] = useState('');
  const [importance, setImportance] = useState('');

  const [validationErrors, onOk] = useOnOk(
    projectID,
    category === '' ? undefined : Number(category),
    importance === '' ? undefined : Number(importance),
    comment,
    setCategory,
    setImportance,
    setComment,
  );

  return (
    <form id={formID} className={className} action="" onBlur={onBlur}>
      <RTextArea
        sx={{
          width: 328,
          height: 100,
          backgroundColor: '#fff',
          borderRadius: '4px',
          border: '1px solid #c6c6c6',
          color: '#9d9d9d',
        }}
        placeholder="フリーコメントを入力"
        value={comment}
        setValue={setComment}
        maxLength={maxLength.project.comment}
        onFocus={(): void => setInputting(true)}
      />
      <RValidationMessages messages={validationErrors.Body} />

      {(inputting || comment !== '') && (
        <>
          <CategorySelect kind="category" category={category} setCategory={setCategory} sx={categorySelectCSS} />
          <CategorySelect kind="importance" category={importance} setCategory={setImportance} sx={categorySelectCSS} />
        </>
      )}

      <div>
        <RButton
          sx={{
            marginTop: '8px',
            marginLeft: 'auto',
          }}
          onClick={onOk}>
          送信
        </RButton>
      </div>
    </form>
  );
}

const useOnBlur = (formID: string, setInputting: Dispatch<boolean>): FocusEventHandler<HTMLElement> => {
  const onBlur: FocusEventHandler<HTMLElement> = useCallback(
    (e) => {
      const newElement = e.relatedTarget;
      if (newElement !== null) {
        if (newElement.closest(`#${formID}`) !== null) {
          return;
        }
      }
      setInputting(false);
    },
    [formID, setInputting],
  );

  return onBlur;
};

const useOnOk = (
  projectID: number,
  categoryID: number | undefined,
  importanceID: number | undefined,
  body: string,
  setCategory: Dispatch<string>,
  setImportance: Dispatch<string>,
  setComment: Dispatch<string>,
): [ValidationErrors, MouseEventHandler<HTMLElement>] => {
  const [save, validationErrors, clearValidationErrors] = useSave('/v1/ReviewComment/Create');
  const refreshAsync = useRefreshAsync();

  const onOk: MouseEventHandler<HTMLElement> = useCallback(
    async (e) => {
      clearValidationErrors();

      const response = await save(e, {
        projectID: projectID,
        categoryID: categoryID,
        importanceID: importanceID,
        body: body,
      });

      if (response.errorCode !== null) {
        return;
      }

      await refreshAsync();

      setCategory('');
      setImportance('');
      setComment('');
    },
    [body, categoryID, clearValidationErrors, importanceID, projectID, refreshAsync, save, setCategory, setComment, setImportance],
  );

  return [validationErrors, onOk];
};

const categorySelectCSS = {
  marginTop: '4px',
};

export const FreeComment = styled(Component)({
  paddingRight: 12,
});
