import I_close from '../../../Images/navigation/close.svg';

import { styled } from '@mui/material/styles';
import { RImage } from '../../base/image/image';
import { RTextArea } from '../../base/text-area/text-area';
import { ComponentPropsNormarized, ValidationErrors } from '../../types';
import { MouseEventHandler, useCallback, useState } from 'react';
import { RModal } from '../modal/modal';
import { RButton } from '../../base/button/button';
import { RValidationMessages } from '../validation-message/validation-messages';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import { reportAtom, reportCategory } from '../../../atoms/report';
import { maxLength } from '../../../services/max-length';
import { useSave } from '../../hooks/save';

type Props = Omit<ComponentPropsNormarized<typeof RModal>, 'close'>;

function Component({ className, ...others }: Props): JSX.Element {
  const [description, setDescription] = useState('');
  const onCloseClick = useClose();
  const [submit, validationErrors] = useSubmit(description);

  return (
    <RModal close={onCloseClick} className={className} {...others}>
      <section className={classes.wrapper}>
        <form action="">
          <div className={classes.titleWrapper}>
            <h2 className={classes.title}>通報</h2>
            <p className={classes.close} onClick={onCloseClick}>
              <RImage src={I_close} />
            </p>
          </div>
          <RTextArea
            rows={11}
            cols={30}
            placeholder="通報する内容を入力(通報対象は自動的に送信されます)"
            required
            maxLength={maxLength.report}
            value={description}
            setValue={setDescription}
            sx={{
              width: '420px',
              height: '158px',
            }}
          />
          <RValidationMessages messages={validationErrors.Description} />
          <ul className={classes.buttons}>
            <li>
              <RButton kind="CancelNoFrame" onClick={onCloseClick}>
                キャンセル
              </RButton>
            </li>
            <li className={classes.submit}>
              <RButton kind="Primary" onClick={submit}>
                送信
              </RButton>
            </li>
          </ul>
        </form>
      </section>
    </RModal>
  );
}

const useClose = (): (() => void) => {
  const resetReport = useResetRecoilState(reportAtom);

  const onCloseClick = useCallback(() => {
    resetReport();
  }, [resetReport]);

  return onCloseClick;
};

const useSubmit = (description: string): [MouseEventHandler<HTMLElement>, ValidationErrors] => {
  const report = useRecoilValue(reportAtom);

  const [save, validationErrors] = useSave('/v1/Report/Create', '通報しました。');
  const close = useClose();

  const onOk: MouseEventHandler<HTMLElement> = useCallback(
    async (e) => {
      const response = await save(e, {
        category: reportCategory[report.category],
        targetID: report.targetID,
        description: description,
      });

      if (response.errorCode === null) {
        close();
      }
    },
    [close, description, report.category, report.targetID, save],
  );

  return [onOk, validationErrors];
};

const PREFIX = 'RReport';
const classes = {
  wrapper: `${PREFIX}-wrapper`,
  titleWrapper: `${PREFIX}-titleWrapper`,
  title: `${PREFIX}-title`,
  close: `${PREFIX}-close`,
  buttons: `${PREFIX}-buttons`,
  submit: `${PREFIX}-submit`,
};

export const RReport = styled(Component, {
  shouldForwardProp: (prop) => prop !== 'sx',
})({
  [`& .${classes.wrapper}`]: {
    width: '468px',
    padding: '24px',
  },
  [`& .${classes.titleWrapper}`]: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '24px',
  },
  [`& .${classes.title}`]: {
    fontSize: '2rem',
    lineHeight: '1.5',
    fontWeight: 500,
    color: '#000',
  },
  [`& .${classes.close}`]: {
    cursor: 'pointer',
  },
  [`& .${classes.buttons}`]: {
    marginTop: 24,
    marginLeft: 'auto',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  [`& .${classes.submit}`]: {
    marginLeft: 8,
  },
});
