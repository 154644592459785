import { styled } from '@mui/material/styles';
import { useTitle } from '../../hooks/title';
import { Summary } from './summary';
import { FileList } from './file-list';
import { Reviews } from './reviews';
import { FileContents } from './file-contents';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import { useEffect } from 'react';
import { projectDetailsShowCommentButtonAtom } from '../../../atoms/project-details-show-comment-button';
import { projectDetailsShowHoverMenuAtom } from '../../../atoms/project-details-show-hover-menu';
import { projectDetailsShowClosedReviewAtom } from '../../../atoms/project-details-show-closed-review';
import { NewReviewDialog } from './new-review-dialog';
import { projectDetailsNewReviewTargetAtom } from '../../../atoms/project-details-new-review-target';
import { projectDetailsAtom, ProjectdetailsAtom, Review, useRefreshAsync } from '../../../atoms/project-details';
import { RInformation } from '../../core/information/information';
import { logInUserAtom } from '../../../atoms/log-in-user';
import { useIsMine } from './is-mine';
import { RLink } from '../../base/link/link';
import { useIDFromParam } from '../../hooks/id-from-param';
import { RLoadingPlaceholder } from '../../core/loading-placeholder/loading-placeholder';
import { RContainer } from '../../layout/Container';
import { ComponentPropsNormarized } from '../../types';

type Props = ComponentPropsNormarized<typeof RContainer>;

function Component({ className }: Props): JSX.Element {
  useIDFromParam();

  const data = useData();
  useTitle(data?.name ?? undefined);

  useResetCommentUI();

  const projectDetailsNewReviewTarget = useRecoilValue(projectDetailsNewReviewTargetAtom);

  if (data === null) {
    return <RLoadingPlaceholder />;
  }

  return (
    <RContainer className={className}>
      <Message ownerID={data.ownerID} reviews={data.reviews} />

      <Summary projectData={data} />

      <FileList projectID={data.id} ownerID={data.ownerID} files={data.files} />

      <section className={classes.fileContent}>
        <FileContents projectID={data.id} ownerID={data.ownerID} files={data.files} reviews={data.reviews} />
        <Reviews projectData={data} />
      </section>

      {projectDetailsNewReviewTarget.line !== null && <NewReviewDialog projectID={data.id} />}
    </RContainer>
  );
}

type MessageProps = {
  ownerID: number;
  reviews: Review[];
};

function Message({ ownerID, reviews }: MessageProps): JSX.Element {
  const logInUser = useRecoilValue(logInUserAtom);
  const isMine = useIsMine(ownerID);

  if (logInUser.id === null) {
    return (
      <RInformation>
        <RLink kind="Primary" href="/User/LogIn">
          ログイン
        </RLink>
        して、レビューコメントを投稿してみませんか？
      </RInformation>
    );
  }

  if (isMine && reviews.length === 0) {
    return (
      <RInformation>
        このプロジェクトにはまだコメントがないようです。
        <RLink kind="Primary" href="/UserSearch/List">
          だれかにレビューをお願いする
        </RLink>
        こともできます！
      </RInformation>
    );
  }

  return <></>;
}

const useResetCommentUI = (): void => {
  const resetProjectDetailsShowCommentButton = useResetRecoilState(projectDetailsShowCommentButtonAtom);
  useEffect(() => {
    resetProjectDetailsShowCommentButton();
  }, [resetProjectDetailsShowCommentButton]);

  const resetProjectDetailsShowHoverMenu = useResetRecoilState(projectDetailsShowHoverMenuAtom);
  useEffect(() => {
    resetProjectDetailsShowHoverMenu();
  }, [resetProjectDetailsShowHoverMenu]);

  const resetShouldShowClosedReview = useResetRecoilState(projectDetailsShowClosedReviewAtom);
  useEffect(() => {
    resetShouldShowClosedReview();
  }, [resetShouldShowClosedReview]);

  const resetProjectDetailsNewReviewTarget = useResetRecoilState(projectDetailsNewReviewTargetAtom);
  useEffect(() => {
    resetProjectDetailsNewReviewTarget();
  }, [resetProjectDetailsNewReviewTarget]);
};

const useData = (): ProjectdetailsAtom | null => {
  const logInUser = useRecoilValue(logInUserAtom);

  const data = useRecoilValue(projectDetailsAtom);
  const resetData = useResetRecoilState(projectDetailsAtom);
  const refreshAsync = useRefreshAsync();

  useEffect((): (() => void) => {
    refreshAsync();

    return (): void => {
      resetData();
    };
  }, [
    logInUser.id, // ログアウト時に、それに適した表示に更新する。
    refreshAsync,
    resetData,
  ]);

  return data;
};

const PREFIX = 'ProjectDetails';
const classes = {
  fileContent: `${PREFIX}-fileContent`,
};

export const ProjectDetails = styled(Component)({
  [`& .${classes.fileContent}`]: {
    display: 'flex',
  },
});
