import { styled } from '@mui/material/styles';
import { Twitter } from './twitter';
import { Activities } from './activities';
import { MyProjects } from './my-projects';
import { NewProjects } from './new-projects';
import { useTitle } from '../../hooks/title';
import { RContainer } from '../../layout/Container';

function Component(): JSX.Element {
  useTitle('');

  return (
    <RContainer
      innerStyle={{
        display: 'flex',
      }}>
      <div className={classes.main}>
        <Activities />
        <MyProjects />
        <NewProjects />
      </div>
      <Twitter />
    </RContainer>
  );
}

const PREFIX = 'Top';
const classes = {
  main: `${PREFIX}-main`,
};

export const Top = styled(Component)({
  [`& .${classes.main}`]: {
    width: '100%',
  },
});
