import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Dispatch, SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { RButton } from '../../../base/button/button';
import { RCheckbox } from '../../../base/checkbox/checkbox';
import { RRecordGroup } from '../../../core/record/record-group/record-group';
import { RRecord } from '../../../core/record/record/record';
import { useData } from '../../../hooks/data';
import { useSave } from '../../../hooks/save';
import { useTitle } from '../../../hooks/title';
import { ComponentPropsNormarized } from '../../../types';

type SettingItem = {
  key: string;
  label: string;
};

const settingItems: SettingItem[] = [
  { key: 'comment', label: 'レビューコメントの投稿・返信' },
  { key: 'message', label: 'メッセージが届きました' },
  // { key: 'follow', label: 'フォローされました' },
  // { key: 'comment', label: 'プロジェクトにコメントが届きました' },
  // { key: 'replyToComment', label: 'プロジェクトのコメントに返信がありました' },
  // { key: 'goodToComment', label: 'プロジェクトのコメントに「いいね」がつきました' },
  // { key: 'rate', label: '評価が届きました' },
];

type Props = ComponentPropsNormarized<'div'>;

function Component({ className }: Props): JSX.Element {
  useTitle('通知メール');

  const [getter, setter, onSave] = useSettings();

  return (
    <div className={className}>
      <RRecordGroup>
        <RRecord
          hover="None"
          sx={{
            padding: '31px',
          }}>
          <p className={classes.description}>better-codeから以下の通知メールを受け取ります。</p>

          <Box
            sx={{
              marginTop: '24px',
            }}>
            {settingItems.map((settingItem) => (
              <label key={settingItem.key}>
                <Box
                  sx={{
                    height: 44,
                    padding: 0,
                    borderBottom: 'solid 1px #E3E3E3',

                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}>
                  {settingItem.label}
                  <RCheckbox
                    checked={getter(settingItem.key)}
                    setChecked={setter(settingItem.key)}
                    sx={{
                      marginRight: '29px',
                    }}
                  />
                </Box>
              </label>
            ))}
          </Box>

          <RButton
            sx={{
              marginTop: '16px',
              marginLeft: 'auto',
              width: 130,
            }}
            onClick={onSave}>
            この内容で保存
          </RButton>
        </RRecord>
      </RRecordGroup>
    </div>
  );
}

const useSettings = (): [(key: string) => boolean, (key: string) => Dispatch<boolean>, (e: SyntheticEvent) => void] => {
  const [settingsComment, setSettingsComment] = useState(false);
  const [settingsMessage, setSettingsMessage] = useState(false);
  const [settingsFollow, setSettingsFollow] = useState(false);
  const [settingsReplyToComment, setSettingsReplyToComment] = useState(false);
  const [settingsGoodToComment, setSettingsGoodToComment] = useState(false);
  const [settingsRate, setSettingsRate] = useState(false);

  const getter = useCallback(
    (key: string): boolean => {
      switch (key) {
        case 'comment':
          return settingsComment;
        case 'message':
          return settingsMessage;
        case 'follow':
          return settingsFollow;
        case 'replyToComment':
          return settingsReplyToComment;
        case 'goodToComment':
          return settingsGoodToComment;
        case 'rate':
          return settingsRate;
      }
      throw new Error();
    },
    [settingsComment, settingsFollow, settingsGoodToComment, settingsMessage, settingsRate, settingsReplyToComment],
  );

  const setter = useCallback((key: string): Dispatch<boolean> => {
    switch (key) {
      case 'comment':
        return setSettingsComment;
      case 'message':
        return setSettingsMessage;
      case 'follow':
        return setSettingsFollow;
      case 'replyToComment':
        return setSettingsReplyToComment;
      case 'goodToComment':
        return setSettingsGoodToComment;
      case 'rate':
        return setSettingsRate;
    }
    throw new Error();
  }, []);

  type PayloadItem = {
    key: string;
    on: boolean;
  };
  type Payload = {
    data: PayloadItem[];
  };

  const [payload] = useData<Payload>('/v1/MyPage/NotificationMail');
  useEffect(() => {
    if (payload !== undefined) {
      for (const setting of payload.data) {
        setter(setting.key)(setting.on);
      }
    }
  }, [payload, setter]);

  const [save] = useSave('/v1/MyPage/UpdateNotificationMail', '通知設定を保存しました。');

  const onSave = useCallback(
    async (e: SyntheticEvent) => {
      await save(e, {
        reviewComment: settingsComment,
        message: settingsMessage,
        rate: settingsRate,
        follow: settingsFollow,
      });
    },
    [save, settingsComment, settingsFollow, settingsMessage, settingsRate],
  );

  return [getter, setter, onSave];
};

const PREFIX = 'NotificationMail';
const classes = {
  description: `${PREFIX}-description`,
};

export const NotificationMail = styled(Component, {
  shouldForwardProp: (prop) => prop !== 'sx',
})({
  [`& .${classes.description}`]: {
    fontSize: 16,
    lineHeight: '150%',
  },
});
