import I_close from '../../../../Images/navigation/close_black.png';

import { styled } from '@mui/material/styles';
import { RModal } from '../../../core/modal/modal';
import { RImage } from '../../../base/image/image';
import { useRecoilValue } from 'recoil';
import { logInUserAtom } from '../../../../atoms/log-in-user';
import { ComponentPropsNormarized } from '../../../types';

type Props = {
  close: () => void;
} & ComponentPropsNormarized<typeof RModal>;

function Component({ close, className }: Props): JSX.Element {
  const logInUser = useRecoilValue(logInUserAtom);

  return (
    <RModal close={close} className={className}>
      <section className={classes.wrapper}>
        <p>
          <RImage
            src={I_close}
            sx={{
              cursor: 'pointer',

              position: 'absolute',
              top: 25,
              right: 25,
            }}
            onClick={close}
          />
        </p>
        <h2 className={classes.title}>メールをご確認ください</h2>
        <p>
          {logInUser.mail}にパスワードリセット用のメールをお送りしました。 <br />
          メールを確認して、パスワードリセット用のURLにアクセスしてください。
        </p>
      </section>
    </RModal>
  );
}

const PREFIX = 'ResetPassword';
const classes = {
  wrapper: `${PREFIX}-wrapper`,
  title: `${PREFIX}-title`,
};

export const ResetPassword = styled(Component, {
  shouldForwardProp: (prop) => prop !== 'sx',
})({
  [`& .${classes.wrapper}`]: {
    width: 466,
    padding: 48,
    position: 'relative',

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  [`& .${classes.title}`]: {
    fontWeight: 500,
    textAlign: 'center',
    width: '100%',
    fontSize: '2rem',
    marginBottom: 20,
    lineHeight: 1.5,
  },
});
