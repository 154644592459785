import I_close from '../../../Images/navigation/close_24px.png';

import { styled } from '@mui/material/styles';
import { RImage } from '../../base/image/image';
import { RTextArea } from '../../base/text-area/text-area';
import { maxLength } from '../../../services/max-length';
import { Dispatch, MouseEventHandler, RefObject, useCallback, useEffect, useRef, useState } from 'react';
import { CategorySelect } from './category-select';
import { RButton } from '../../base/button/button';
import { useSetFocus } from '../../hooks/set-fucus';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import { projectDetailsNewReviewTargetAtom } from '../../../atoms/project-details-new-review-target';
import { useOnEsc } from '../../hooks/on-esc';
import Draggable from '../../functions/draggable';
import { RValidationMessages } from '../../core/validation-message/validation-messages';
import { useRefreshAsync } from '../../../atoms/project-details';
import { ComponentPropsNormarized, ValidationErrors } from '../../types';
import { clearHighlight, hoverHighlight, scrollToComment } from './code-prettify';
import { useSave } from '../../hooks/save';

type Props = {
  projectID: number;
} & ComponentPropsNormarized<'section'>;

function Component({ projectID, className }: Props): JSX.Element {
  const [newComment, setNewComment] = useState('');
  const [category, setCategory] = useState('');
  const [importance, setImportance] = useState('');

  const ref = useSetPosition();

  const focusTarget = useSetFocus<HTMLTextAreaElement>();

  const onCloseClick = useOnCloseClick();
  useOnEsc(onCloseClick);

  const [validationErrors, onOk] = useOnOk(
    projectID,
    category === '' ? undefined : Number(category),
    importance === '' ? undefined : Number(importance),
    newComment,
    setCategory,
    setImportance,
    setNewComment,
    onCloseClick,
  );

  const onMouseDown = useDrag(ref, focusTarget);

  return (
    <section ref={ref} className={className} onMouseDown={(e): void => onMouseDown(e.nativeEvent)}>
      <p className={classes.close}>
        <RImage
          src={I_close}
          sx={{
            cursor: 'pointer',
            height: 20,
          }}
          data-undraggable
          onClick={onCloseClick}
        />
      </p>
      <form action="">
        <RTextArea
          ref={focusTarget}
          value={newComment}
          setValue={setNewComment}
          data-undraggable
          placeholder={'コードの改善案を伝えましょう。\nなるべく理由も伝えましょう。'}
          maxLength={maxLength.project.comment}
          sx={{
            width: 547,
            height: 151,
            paddingLeft: '8px',
            borderRadius: '4px',
            border: '1px solid #C6C6C6',
            color: '#404040',
            marginBottom: 0,
            lineHeight: '1.8rem',
          }}
        />

        <RValidationMessages messages={validationErrors.Body} />

        <CategorySelect kind="category" category={category} setCategory={setCategory} sx={categorySelectCSS} />
        <CategorySelect kind="importance" category={importance} setCategory={setImportance} sx={categorySelectCSS} />

        <RButton
          sx={{
            position: 'absolute',
            right: 20,
            bottom: 20,
          }}
          data-undraggable
          onClick={onOk}>
          送信
        </RButton>
      </form>
    </section>
  );
}

const useSetPosition = (): RefObject<HTMLElement> => {
  const ref = useRef<HTMLElement>(null);
  const projectDetailsNewReviewTarget = useRecoilValue(projectDetailsNewReviewTargetAtom);

  useEffect(() => {
    if (ref.current === null) {
      return;
    }
    if (projectDetailsNewReviewTarget.event === null) {
      return;
    }

    ref.current.style.top = `${projectDetailsNewReviewTarget.event.pageY + 20}px`; // クリック位置に表示するとその部分のソースコードが見えなくなるので、少し下に表示する。
    ref.current.style.left = `${projectDetailsNewReviewTarget.event.pageX}px`;
  }, [projectDetailsNewReviewTarget.event]);

  return ref;
};

export const useOnCloseClick = (): (() => void) => {
  const resetProjectDetailsNewReviewTarget = useResetRecoilState(projectDetailsNewReviewTargetAtom);

  return useCallback(() => {
    resetProjectDetailsNewReviewTarget();

    clearHighlight();
    hoverHighlight(true);
  }, [resetProjectDetailsNewReviewTarget]);
};

const useDrag = (ref: RefObject<HTMLElement>, focusTarget: RefObject<HTMLTextAreaElement>): ((e: MouseEvent) => void) =>
  useCallback(
    (e: MouseEvent) => {
      if (ref.current === null) {
        return;
      }

      new Draggable(ref.current, () => {
        if (focusTarget.current === null) {
          return;
        }
        focusTarget.current.focus();
      }).start(e);
    },
    [focusTarget, ref],
  );

const useOnOk = (
  projectID: number,
  categoryID: number | undefined,
  importanceID: number | undefined,
  body: string,
  setCategory: Dispatch<string>,
  setImportance: Dispatch<string>,
  setComment: Dispatch<string>,
  onCloseClick: () => void,
): [ValidationErrors, MouseEventHandler<HTMLElement>] => {
  const projectDetailsNewReviewTarget = useRecoilValue(projectDetailsNewReviewTargetAtom);

  const [save, validationErrors, clearValidationErrors] = useSave('/v1/ReviewComment/Create');
  const refreshAsync = useRefreshAsync();

  const onOk: MouseEventHandler<HTMLElement> = useCallback(
    async (e) => {
      clearValidationErrors();

      const response = await save(e, {
        projectID: projectID,
        fileID: projectDetailsNewReviewTarget.fileID,
        line: projectDetailsNewReviewTarget.line,
        categoryID: categoryID,
        importanceID: importanceID,
        body: body,
      });

      if (response.errorCode !== null) {
        return;
      }

      type Payload = {
        reviewID: number;
      };
      const payload = response.payload as Payload;

      await refreshAsync();

      setCategory('');
      setImportance('');
      setComment('');
      onCloseClick();

      scrollToComment(payload.reviewID);
    },
    [
      body,
      categoryID,
      clearValidationErrors,
      importanceID,
      onCloseClick,
      projectDetailsNewReviewTarget.fileID,
      projectDetailsNewReviewTarget.line,
      projectID,
      refreshAsync,
      save,
      setCategory,
      setComment,
      setImportance,
    ],
  );

  return [validationErrors, onOk];
};

const PREFIX = 'NewReviewDialog';
const classes = {
  close: `${PREFIX}-close`,
};

const categorySelectCSS = {
  marginTop: '8px',
};

export const NewReviewDialog = styled(Component)({
  zIndex: 2,
  position: 'absolute',

  // 画面外からスタートして、表示時にマウス座標のそばに移動する。
  top: -1000,
  left: -1000,

  padding: 20,
  width: 587,

  background: '#FFFFFF',
  boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.25)',
  borderRadius: 8,

  cursor: 'move',

  [`& .${classes.close}`]: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '0 0 12px 0',
  },
});
