import { styled } from '@mui/material/styles';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { RButton } from '../../../base/button/button';
import { RLink } from '../../../base/link/link';
import { classNames } from '../../../classNames';
import { RAvatar } from '../../../core/avatar/avatar';
import { RRecordGroup } from '../../../core/record/record-group/record-group';
import { RRecord } from '../../../core/record/record/record';
import { RUserName } from '../../../core/user-name/user-name';
import { useData } from '../../../hooks/data';
import { useTitle } from '../../../hooks/title';
import { ComponentPropsNormarized } from '../../../types';

type Item = {
  partnerID: number;
  partnerDeleted: boolean;
  userName: string;
  avatarImagePath: string;
  body: string;
  opened: boolean;
  createDate: string;
};

type Data = {
  items: Item[];
};

type Props = ComponentPropsNormarized<'div'>;

function Component({ className }: Props): JSX.Element {
  useTitle('メッセージ');

  const onClick = useOnClick();

  const [data] = useData<Data>('/v1/MyPage/Message');
  if (data === undefined) {
    return <></>;
  }

  return (
    <div className={className}>
      <RRecordGroup>
        {data.items.map((message, i) => (
          <RRecord key={i} unreadMessage={!message.opened} onClick={(): void => onClick(message.partnerID)}>
            <article className={classes.article}>
              <RAvatar
                size="DM"
                src={message.avatarImagePath}
                userID={message.partnerDeleted ? undefined : message.partnerID}
                sx={{
                  width: 56,
                  height: 56,
                }}
              />
              <div className={classes.messageContent}>
                <div className={classes.messageHeader}>
                  <RUserName
                    userId={message.partnerID}
                    deletedUser={message.partnerDeleted}
                    sx={{
                      fontWeight: 'bold',
                      fontSize: 16,
                      lineHeight: '150%',
                      letterSpacing: 0.1,
                    }}>
                    {message.userName}
                  </RUserName>
                  <p className={classes.createDate}>{message.createDate}</p>
                </div>
                <div className={classes.messageText}>
                  <p className={classNames(classes.body, message.opened ? undefined : classes.bodyUnread)}>{message.body}</p>
                </div>
              </div>
            </article>
          </RRecord>
        ))}
      </RRecordGroup>

      {data.items.length === 0 && (
        <RRecord
          hover="None"
          sx={{
            padding: '55px 0',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}>
          <h2 className={classes.noMessageTitle}>まだメッセージがありません</h2>
          {/* <p>レビュー依頼や、レビュー契約の提案をしませんか？</p> */}
          <p className={classes.noMessageDescription}>レビュー依頼をしませんか？</p>

          <RLink
            kind="GrayOnWhite"
            href="/UserSearch/List"
            sx={{
              marginTop: '24px',
            }}>
            <RButton width={172}>ユーザーを探しに行く</RButton>
          </RLink>
        </RRecord>
      )}
    </div>
  );
}

const useOnClick = (): ((partnerID: number) => void) => {
  const navigate = useNavigate();

  const onClick = useCallback(
    (partnerID: number) => {
      navigate(`/MyPage/MessageDetail/${partnerID}`);
    },
    [navigate],
  );

  return onClick;
};

const PREFIX = 'Message';
const classes = {
  article: `${PREFIX}-article`,
  messageContent: `${PREFIX}-messageContent`,
  messageHeader: `${PREFIX}-messageHeader`,
  createDate: `${PREFIX}-createDate`,
  messageText: `${PREFIX}-messageText`,
  body: `${PREFIX}-body`,
  bodyUnread: `${PREFIX}-bodyUnread`,

  noMessageTitle: `${PREFIX}-noMessageTitle`,
  noMessageDescription: `${PREFIX}-noMessageDescription`,
};

export const Message = styled(Component, {
  shouldForwardProp: (prop) => prop !== 'sx',
})({
  [`& .${classes.article}`]: {
    display: 'flex',
  },
  [`& .${classes.messageContent}`]: {
    width: '100%',
    marginLeft: 8,
  },
  [`& .${classes.messageHeader}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  [`& .${classes.createDate}`]: {
    fontSize: 12,
    color: '#757575',
  },
  [`& .${classes.messageText}`]: {
    overflow: 'hidden',
    width: '100%',
  },
  [`& .${classes.body}`]: {
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 1,
  },
  [`& .${classes.bodyUnread}`]: {
    fontWeight: 'bold',
  },

  [`& .${classes.noMessageTitle}`]: {
    fontWeight: 'bold',
    fontSize: 16,
    lineHeight: '21px',
  },
  [`& .${classes.noMessageDescription}`]: {
    marginTop: 12,

    lineHeight: '21px',
    color: '#757575',
  },
});
