import I_upload from '../../../Images/action/upload.png';
import I_uploadHover from '../../../Images/action/upload_hover.png';

import { styled } from '@mui/material/styles';
import { FileListContent } from './file-list-content';
import { File } from '../../../atoms/project-details';
import { useIsMine } from './is-mine';
import { useElementID } from '../../hooks/element-id';
import { useRef } from 'react';
import { useUploadFile } from '../../hooks/upload-file';
import { RValidationMessages } from '../../core/validation-message/validation-messages';
import { ComponentPropsNormarized } from '../../types';

type Props = {
  projectID: number;
  ownerID: number;
  files: File[];
} & ComponentPropsNormarized<'div'>;

function Component({ projectID, ownerID, files, className }: Props): JSX.Element {
  const isMine = useIsMine(ownerID);
  const inputElementID = useElementID();

  const fileRef = useRef<HTMLInputElement>(null);
  const [validationErrors, uploadFile] = useUploadFile(projectID, fileRef);

  return (
    <div className={className}>
      <section>
        <ul className={classes.header}>
          <li className={classes.title}>ファイル一覧</li>
          {isMine && (
            <li>
              <label htmlFor={inputElementID} className={classes.uploadFiles}>
                アップロード
              </label>
              <form action="">
                <input id={inputElementID} ref={fileRef} type="file" onChange={uploadFile} className={classes.file} />
              </form>
              <RValidationMessages messages={validationErrors['']} />
            </li>
          )}
        </ul>
      </section>

      <FileListContent projectID={projectID} ownerID={ownerID} files={files} />
    </div>
  );
}

const PREFIX = 'FileList';
const classes = {
  header: `${PREFIX}-header`,
  title: `${PREFIX}-title`,
  uploadFiles: `${PREFIX}-uploadFiles`,
  file: `${PREFIX}-file`,
};

export const FileList = styled(Component)({
  [`& .${classes.header}`]: {
    margin: '24px 0 12px 0',
    display: 'flex',
  },
  [`& .${classes.title}`]: {
    fontSize: 16,
  },
  [`& .${classes.uploadFiles}`]: {
    position: 'relative',
    top: 2,
    paddingLeft: 40,
    background: `url(${I_upload}) no-repeat calc(50% - 36px) center/9.5%`,

    opacity: 1,
    color: '#404040',
    cursor: 'pointer',
    textDecoration: 'none',

    [`&:hover`]: {
      color: '#0080FF',
      backgroundImage: `url(${I_uploadHover})`,
    },
  },
  [`& .${classes.file}`]: {
    display: 'none',
  },
});
