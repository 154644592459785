import 'code-prettify-google/src/prettify.css';
import 'code-prettify-google/styles/sons-of-obsidian.css';

import I_commentIcon from '../../../Images/action/comment_outline.png';
import I_commentIconHover from '../../../Images/action/comment.png';

import { styled } from '@mui/material/styles';
import { classNames } from '../../classNames';
import { File, Review } from '../../../atoms/project-details';
import { usePrettify, scrollToComment, useOnCodeClicked } from './code-prettify';
import { FileContentHeader } from './file-content-header';
import { useCallback } from 'react';
import { ComponentPropsNormarized } from '../../types';

type Props = {
  projectID: number;
  ownerID: number;
  files: File[];
  reviews: Review[];
} & ComponentPropsNormarized<'div'>;

function Component({ projectID, ownerID, files, reviews, className }: Props): JSX.Element {
  usePrettify(files, reviews);
  const onCodeClicked = useOnCodeClicked();
  const onLineNumberClicked = useOnLineNumberClicked();

  // ファイルごとの行数。
  const fileLinesMap = new Map<File, string[]>();
  files
    .filter((x) => !x.deleted)
    .forEach((file) => {
      fileLinesMap.set(file, file.content.split('\r'));
    });

  // 最後が空行なら、それを除外。
  fileLinesMap.forEach((lines, file) => {
    if (lines[lines.length - 1]?.replace('\n', '').length === 0) {
      lines.pop();
      fileLinesMap.set(file, lines);
    }
    // 空ファイルの場合は、空行を1行表示する。(そこにコメントを付けられるようにする)
    if (lines.length === 0) {
      lines.push('');
      fileLinesMap.set(file, lines);
    }
  });

  return (
    <div className={className}>
      <div>
        {files
          .filter((x) => !x.deleted)
          .map((file) => (
            <div
              key={file.id}
              id={`file-content-wrap-${file.id}`} // TOP画面の活動一覧からのリンク先。
              className={classes.fileContentWrap}>
              <FileContentHeader projectID={projectID} file={file} ownerID={ownerID} />

              <section
                id={`file-section-${file.id}`} // コメント一覧からのリンク先。
                className={classes.code}>
                <div className={classes.codeWrapper}>
                  <ul className={classes.lineNumber}>
                    {Array(fileLinesMap.get(file)!.length)
                      .fill(0)
                      .map((_, i) => (
                        <li key={i} onClick={(e): void => onLineNumberClicked(e)}>
                          {i + 1}.
                        </li>
                      ))}
                  </ul>

                  <pre className={classNames('prettyprint', 'linenums', classes.pre)} data-file-id={file.id} onClick={(e): void => onCodeClicked(e)}>
                    {file.content}
                  </pre>
                </div>
              </section>
            </div>
          ))}
      </div>
    </div>
  );
}

export const useOnLineNumberClicked = (): ((event: React.MouseEvent<HTMLElement, MouseEvent>) => void) => {
  const onLineNumberClicked = useCallback((event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    const target = event.target as Element;
    const reviewID = target.getAttribute('data-review-id');
    if (reviewID) {
      scrollToComment(parseInt(reviewID));
    }
  }, []);

  return onLineNumberClicked;
};

const PREFIX = 'FileContents';
const classes = {
  wrapper: `${PREFIX}-wrapper`,
  fileContentWrap: `${PREFIX}-fileContentWrap`,
  code: `${PREFIX}-code`,
  codeWrapper: `${PREFIX}-codeWrapper`,
  lineNumber: `${PREFIX}-lineNumber`,
  pre: `${PREFIX}-pre`,
};

export const FileContents = styled(Component)({
  maxWidth: 1000,
  width: '100%',
  maxHeight: 1060,
  marginTop: 24,
  marginRight: 8,
  overflowY: 'auto',

  [`& .${classes.fileContentWrap}`]: {
    marginBottom: 24,
    backgroundColor: '#fff',
    borderRadius: 8,
    border: '1px solid #E3E3E3',
    marginRight: 16,
    position: 'relative',
  },

  [`& .${classes.code}`]: {
    overflowY: 'auto',

    background: '#292e32',
    borderRadius: '0 0 8px 8px/0 0 8px 8px',
    marginTop: 8,
    maxHeight: 483,
  },
  [`& .${classes.codeWrapper}`]: {
    display: 'flex',
    width: 'fit-content',
  },
  [`& .${classes.lineNumber}`]: {
    position: 'sticky',
    left: 0,
    background: '#292e32',

    fontSize: '1.2rem',
    padding: '12px 12px 0 32px',
    color: '#555',
    overflowY: 'visible',

    [`& li`]: {
      lineHeight: '23.63px',
      textAlign: 'right',
    },
  },

  [`& .${classes.pre}`]: {
    width: '100%',
    border: '0 solid #888',
    background: '#292e32',
    fontFamily: '"SF Mono","Monaco","Andale Mono","Lucida Console","Bitstream Vera Sans Mono","Courier New",Courier,monospac',
    letterSpacing: 0.5,
    fontSize: 12,
  },

  // code-prettifyが動的に適用するスタイルを上書き。
  '& pre.prettyprint': {
    marginTop: 0,
    marginBottom: 0,
    paddingTop: 14,
    paddingBottom: 14,

    overflowX: 'auto',
    overflowY: 'visible',
  },

  // 以下、code-prettifyが動的に生成・適用するクラス用。
  '& pre.prettyprint ol': {
    paddingLeft: 0,
  },

  '& pre.prettyprint ol.linenums li': {
    background: '#292e32',
    pointerEvents: 'none',
    transition: 'none',

    // クリックイベントなどを行単位で処理するためにliは生成させるが、行番号スクロール時に左端に固定されるように自前で表示するので、自動生成される行番号は批評時にする。
    listStyleType: 'none',
  },

  '& pre.prettyprint ol.linenums li span.generated': {
    pointerEvents: 'auto',
    display: 'inline-block',
    width: '100%',
  },

  '& pre.prettyprint ol.linenums li.normal:hover, pre.prettyprint ol.linenums li.clicked': {
    backgroundColor: '#454545',
  },

  [`& .${classes.lineNumber} li.active::before`]: {
    content: '""',
    display: 'inline-block',
    background: `url(${I_commentIcon})`,
    backgroundSize: 'contain',

    width: 13,
    height: 13,

    position: 'absolute',
    marginTop: 8,
    left: 8,

    pointerEvents: 'auto',
    cursor: 'pointer',
  },

  [`& .${classes.lineNumber} li.active:hover::before`]: {
    background: `url(${I_commentIconHover})`,
  },
});
