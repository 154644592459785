import { styled } from '@mui/material/styles';
import { Dispatch, MouseEventHandler, RefObject, useCallback, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import { projectDetailsShowCommentButtonAtom } from '../../../atoms/project-details-show-comment-button';
import { maxLength } from '../../../services/max-length';
import { RButton } from '../../base/button/button';
import { RTextArea } from '../../base/text-area/text-area';
import { Review, useRefreshAsync } from '../../../atoms/project-details';
import { RValidationMessages } from '../../core/validation-message/validation-messages';
import { ApiResponse } from '../../../services/api';
import { ValidationErrors } from '../../types';
import { useSave } from '../../hooks/save';

type Props = {
  projectID: number;
  review: Review;
};

function Component({ projectID, review }: Props): JSX.Element {
  const [comment, setComment] = useState('');

  const [validationErrors, textArea, onOk] = useOnOk(projectID, review.id, comment, setComment);

  const [projectDetailsShowCommentButton, setProjectDetailsShowCommentButton] = useRecoilState(projectDetailsShowCommentButtonAtom);

  return (
    <form action="">
      <RTextArea
        ref={textArea}
        sx={{
          width: '100%',
          marginTop: '8px',
          letterSpacing: '1px',
        }}
        placeholder="返信を入力"
        maxLength={maxLength.project.comment}
        value={comment}
        setValue={setComment}
        onFocus={(): void => {
          setProjectDetailsShowCommentButton(review.id);
        }}
      />
      <RValidationMessages messages={validationErrors.Body} />

      {projectDetailsShowCommentButton === review.id && (
        <RButton
          sx={{
            marginTop: '8px',
            marginLeft: 'auto',
          }}
          onClick={onOk}>
          送信
        </RButton>
      )}
    </form>
  );
}

const useOnOk = (
  projectID: number,
  reviewID: number,
  body: string,
  setComment: Dispatch<string>,
): [ValidationErrors, RefObject<HTMLTextAreaElement>, MouseEventHandler<HTMLElement>] => {
  const [save, validationErrors, clearValidationErrors] = useSave('/v1/ReviewComment/Reply');
  const refreshAsync = useRefreshAsync();

  const textArea = useRef<HTMLTextAreaElement>(null);

  const onOk: MouseEventHandler<HTMLElement> = useCallback(
    async (e) => {
      clearValidationErrors();

      const response = (await save(e, {
        projectID: projectID,
        reviewID: reviewID,
        body: body,
      })) as ApiResponse;

      if (response.errorCode !== null) {
        return;
      }

      await refreshAsync();

      setComment('');

      // デフォルトの高さに戻す。
      if (textArea.current === null) {
        return;
      }
      textArea.current.style.height = '';
    },
    [body, clearValidationErrors, projectID, refreshAsync, reviewID, save, setComment],
  );

  return [validationErrors, textArea, onOk];
};

export const CommentNewResponse = styled(Component)({});
