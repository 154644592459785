import { useState, useEffect, ReactNode, useCallback } from 'react';
import { ApiResponse, useApi } from '../../services/api';
import { RLink } from '../base/link/link';

export type Activity = {
  id: number;
  type: number;
  creatorID: number;
  creatorName: string;
  avatarImagePath: string;
  targetID: number;
  projectID: number;
  projectName: string;
  userName: string;
  userDeleted: boolean;
  createDate: string;
};

type ActivityData = {
  activities: Activity[];
  hasMore: boolean;
};

export const useActivityData = (count: number, userID?: number): [ActivityData, () => Promise<void>] => {
  const [activityData, setActivityData] = useState({
    activities: [],
    hasMore: false,
  } as ActivityData);

  const api = useApi();

  useEffect(() => {
    const f = async (): Promise<void> => {
      const response = (await api.get('/v1/Activity/List', {
        params: {
          count: count,
          id: userID,
        },
      })) as ApiResponse;

      setActivityData(response.payload as ActivityData);
    };
    f();
  }, [api, count, userID]);

  const onMoreClick = useCallback(async () => {
    const response = (await api.get('/v1/Activity/List', {
      params: {
        count: activityData.activities.length + 10,
        id: userID,
      },
    })) as ApiResponse;

    setActivityData(response.payload as ActivityData);
  }, [activityData.activities.length, api, userID]);

  return [activityData, onMoreClick];
};

export const buildActivityDescription: (activity: Activity, className: string) => ReactNode = (activity, className) => {
  switch (activity.type) {
    case 1:
      return 'ユーザー登録しました。';
    case 2:
      return 'プロフィールを更新しました。';
    case 3:
      return (
        <>
          <span className={className}>{activity.projectName}</span>プロジェクトに
          <RLink href={`/Project/Details/${activity.projectID}#file-content-wrap-${activity.targetID}`}>ファイル</RLink>をアップロードしました。
        </>
      );
    case 4:
      return (
        <>
          <span className={className}>{activity.projectName}</span>プロジェクトに
          <RLink href={`/Project/Details/${activity.projectID}#comment-list-${activity.targetID}`}>コメント</RLink>しました。
        </>
      );
    case 6:
      if (activity.userDeleted) {
        return (
          <>
            <span className={className}>{activity.userName}</span>さんをフォローしました。
          </>
        );
      }
      return (
        <>
          <RLink href={`/Profile/Main/${activity.targetID}`}>
            <span className={className}>{activity.userName}</span>
          </RLink>
          さんをフォローしました。
        </>
      );
  }
  throw new Error('Unknown activity.type');
};
