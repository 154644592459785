import { SerializedStyles } from '@emotion/react';
import { css, CSSInterpolation, styled } from '@mui/material/styles';
import { MouseEventHandler, ReactNode, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { ComponentPropsNormarized } from '../../types';

type Props = {
  kind?:
    | 'Primary'
    | 'GrayOnWhite'
    | 'GrayOnGray'
    | 'Danger'
    | 'WithAvatar'
    | 'HeaderLeftMenuItem'
    | 'AvatarMenuItem'
    | 'headerRightMenuItemLogIn'
    | 'headerRightMenuItemRegistration'
    | 'Report';
  href: string;
  children?: ReactNode;
} & Omit<ComponentPropsNormarized<typeof Link>, 'to' | 'href'>;

function Component({ href, children, className, ...others }: Props): JSX.Element {
  const onClick: MouseEventHandler<HTMLElement> = useCallback((e) => {
    e.stopPropagation();
  }, []);

  if (href === '') {
    return <>{children}</>;
  }

  if (href.includes('://')) {
    return (
      <a href={href} className={className} {...others}>
        {children}
      </a>
    );
  }

  return (
    <HashLink to={href} onClick={onClick} className={className} {...others}>
      {children}
    </HashLink>
  );
}

const getCSS = ({ kind }: Props): SerializedStyles => {
  const base = css({
    cursor: 'pointer',
    textDecoration: 'none',
  });

  const headerRight = css({
    display: 'block',
    width: '129px',
    height: '63px',
    textAlign: 'center',
    letterSpacing: '0.15px',
    lineHeight: '64px',
  });

  const primary = css({
    color: '#3399FF',
    '&:hover': {
      textDecorationLine: 'underline',
    },
  });
  const grayOnWhite = css({
    color: '#9D9D9D',
    '&:hover': {
      color: '#0080FF',
    },
  });
  const grayOnGray = css({
    color: '#8A8A8A',
    '&:hover': {
      color: '#575757',
    },
  });
  const danger = css({
    color: '#8A8A8A',
    '&:hover': {
      color: '#FC5F5F',
    },
  });
  const withAvatar = css({
    color: '#404040',
    '&:hover': {
      textDecorationLine: 'underline',
    },
  });
  const avatarMenuItem = css({
    letterSpacing: '0.25px',
    padding: '13px 18px 13px 50px',
    width: '100%',
    height: '100%',
    display: 'block',
    color: '#404040',
    '&:hover': {
      color: '#404040',
    },
  });
  const headerLeftMenuItem = css({
    letterSpacing: '0.25px',
    padding: '0px 20px',
    height: '100%',
    display: 'block',
    color: '#404040',
    '&:hover': {
      background: '#f2f2f2',
      color: '#404040',
    },
  });
  const headerRightMenuItemLogIn = css([
    headerRight,
    {
      color: '#3399FF',
      '&:hover': {
        backgroundColor: '#F0F7FF',
        color: '#0080FF',
      },
    },
  ]);
  const headerRightMenuItemRegistration = css([
    headerRight,
    {
      backgroundColor: '#3399FF',
      color: '#FFFFFF',
      '&:hover': {
        backgroundColor: '#0066CC',
      },
    },
  ]);
  const report = css({
    color: '#C6C6C6',
    '&:hover': {
      color: '#FC5F5F',
    },
  });

  let kindCSS: CSSInterpolation = css({});
  switch (kind) {
    case 'Primary':
      kindCSS = primary;
      break;
    case 'GrayOnWhite':
      kindCSS = grayOnWhite;
      break;
    case 'GrayOnGray':
      kindCSS = grayOnGray;
      break;
    case 'Danger':
      kindCSS = danger;
      break;
    case 'WithAvatar':
      kindCSS = withAvatar;
      break;
    case 'HeaderLeftMenuItem':
      kindCSS = headerLeftMenuItem;
      break;
    case 'AvatarMenuItem':
      kindCSS = avatarMenuItem;
      break;
    case 'headerRightMenuItemLogIn':
      kindCSS = headerRightMenuItemLogIn;
      break;
    case 'headerRightMenuItemRegistration':
      kindCSS = headerRightMenuItemRegistration;
      break;
    case 'Report':
      kindCSS = report;
      break;
    default:
      kindCSS = primary;
  }

  return css([base, kindCSS]);
};

export const RLink = styled(Component, {
  shouldForwardProp: (prop) => prop !== 'sx',
})(getCSS);
