import I_favorite from '../../../../Images/action/favorite.png';
import I_outline from '../../../../Images/action/outline.png';

import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { RImage } from '../../../base/image/image';
import { RAvatar } from '../../avatar/avatar';
import { RRecordGroup } from '../record-group/record-group';
import { RRecord } from '../record/record';
import { SyntheticEvent, useCallback, useState } from 'react';
import { useSave } from '../../../hooks/save';
import { classNames } from '../../../classNames';
import { Rating } from '@mui/material';
import { ComponentPropsNormarized, Language } from '../../../types';

type User = {
  id: number;
  name: string;
  avatarImagePath: string;
  following: boolean;
  languages: Language[];
  description: string;
  lastAccessDateFromNow?: string;
  ratingPoint?: number;
  ratingCount?: number;
};

export type Users = {
  userRecords: User[];
  hasMore: boolean;
};

type Props = {
  data: Users;
  onMoreClick: () => Promise<void>;
  showFollowStatus: boolean;
  enableFollow: boolean;
} & Omit<ComponentPropsNormarized<typeof RRecordGroup>, 'children'>;

function Component({ data: { userRecords, hasMore }, onMoreClick, showFollowStatus, enableFollow, className }: Props): JSX.Element {
  const [onClickUpdate, deletedIDs] = useUpdateFollow();

  const navigate = useNavigate();

  return (
    <RRecordGroup className={className}>
      {userRecords.map((user) => (
        <RRecord key={user.id} onClick={(): void => navigate(`/Profile/Main/${user.id}`)}>
          <div className={classes.header}>
            <div className={classes.headerMain}>
              <RAvatar src={user.avatarImagePath} size="Header" />

              <div className={classes.headerDetails}>
                <h2 className={classes.userName}>{user.name}</h2>

                <div className={classes.userAttributes}>
                  <ul className={classes.userLanguages}>
                    {user.languages.map((language) => (
                      <li key={language.id} className={classes.userLanguage}>
                        {language.name}
                      </li>
                    ))}
                  </ul>

                  {/* <ul className="user-search-result-item-tags">
                        <li className="user-search-result-item-tag">#タグ</li>
                        <li className="user-search-result-item-tag">#タグ</li>
                      </ul> */}
                </div>
              </div>
            </div>

            {showFollowStatus && (
              <div className={classNames(classes.toggleIcon, enableFollow ? classes.toggleIconHover : undefined)}>
                <RImage
                  src={user.following && !deletedIDs.has(user.id) ? I_favorite : I_outline}
                  onClick={enableFollow ? (e): Promise<void> => onClickUpdate(e, user.id, deletedIDs.has(user.id)) : undefined}
                />
              </div>
            )}
          </div>

          <p className={classes.userDescription}>{user.description}</p>

          {user.lastAccessDateFromNow !== undefined && (
            <div className={classes.footer}>
              <div className={classes.loginTime}>最終ログイン：{user.lastAccessDateFromNow}</div>

              {(user.ratingCount ?? 0) > 0 && (
                <div className={classes.rating}>
                  <span className={classes.ratingPoint}>{user.ratingPoint?.toFixed(1)}</span>
                  <Rating
                    readOnly
                    precision={0.5}
                    sx={{
                      color: '#3399FF',
                      marginLeft: '4px',
                    }}
                    size="large"
                    value={user.ratingPoint}
                  />
                  <span className={classes.ratingCount}>({user.ratingCount})</span>
                </div>
              )}
            </div>
          )}
        </RRecord>
      ))}

      {hasMore && <RRecord next10 onClick={onMoreClick} />}
    </RRecordGroup>
  );
}

const useUpdateFollow = (): [(e: React.MouseEvent<HTMLElement, MouseEvent>, userID: number, follow: boolean) => Promise<void>, Set<number>] => {
  const [deletedIDs, setDeletedIDs] = useState(new Set<number>());
  const [save] = useSave('/v1/MyPage/FollowUnfollow');

  const onClickUpdate = useCallback(
    async (e: SyntheticEvent, userID: number, follow: boolean) => {
      e.stopPropagation();

      await save(e, {
        userID: userID,
        follow: follow,
      });

      const a = new Set(deletedIDs);
      if (follow) {
        a.delete(userID);
      } else {
        a.add(userID);
      }
      setDeletedIDs(a);
    },
    [deletedIDs, save],
  );

  return [onClickUpdate, deletedIDs];
};

const PREFIX = 'RUserRecords';
const classes = {
  header: `${PREFIX}-header`,
  headerMain: `${PREFIX}-headerMain`,
  headerDetails: `${PREFIX}-headerDetails`,
  userName: `${PREFIX}-userName`,
  userAttributes: `${PREFIX}-userAttributes`,
  userLanguages: `${PREFIX}-userLanguages`,
  userLanguage: `${PREFIX}-userLanguage`,
  toggleIcon: `${PREFIX}-toggleIcon`,
  toggleIconHover: `${PREFIX}-toggleIconHover`,
  userDescription: `${PREFIX}-userDescription`,

  footer: `${PREFIX}-footer`,
  loginTime: `${PREFIX}-loginTime`,
  rating: `${PREFIX}-rating`,
  ratingPoint: `${PREFIX}-ratingPoint`,
  ratingCount: `${PREFIX}-ratingCount`,
};

export const RUserRecords = styled(Component)({
  [`& .${classes.header}`]: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  [`& .${classes.headerMain}`]: {
    display: 'flex',
  },
  [`& .${classes.headerDetails}`]: {
    marginLeft: 16,
  },
  [`& .${classes.userName}`]: {
    fontWeight: 'bold',
    fontSize: 16,
    lineHeight: '150%',
  },
  [`& .${classes.userAttributes}`]: {
    marginTop: '8px',
    display: 'flex',
    flexWrap: 'wrap',
  },
  [`& .${classes.userLanguages}`]: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  [`& .${classes.userLanguage}`]: {
    marginLeft: 4,
    padding: '2px 8px',
    background: '#F2F2F2',
    borderRadius: 4,
    fontSize: 10,
    lineHeight: '12px',
    textAlign: 'center',

    [`&:nth-of-type(1)`]: {
      marginLeft: 0,
    },
  },
  [`& .${classes.toggleIcon}`]: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '32px',
    height: '32px',
    borderRadius: '16px',
  },
  [`& .${classes.toggleIconHover}`]: {
    [`&:hover`]: {
      background: 'rgba(252, 95, 114, 0.1)',
    },
  },

  [`& .${classes.userDescription}`]: {
    display: '-webkit-box',
    marginTop: 8,
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 3,
    overflow: 'hidden',
  },

  [`& .${classes.footer}`]: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 8,
  },
  [`& .${classes.loginTime}`]: {
    fontSize: 12,
    lineHeight: '15px',
    color: '#757575',
  },
  [`& .${classes.rating}`]: {
    marginLeft: 12,
  },
  [`& .${classes.ratingPoint}`]: {
    fontSize: 12,
    lineHeight: '150%',
  },
  [`& .${classes.ratingCount}`]: {
    marginLeft: 4,
    fontSize: 12,
    lineHeight: '150%',
    color: '#757575',
  },
});
