import I_edit from '../../../../Images/icon/edit.png';
import I_editHover from '../../../../Images/icon/edit-hover.png';

import { styled } from '@mui/material/styles';
import { RAvatar } from '../../../core/avatar/avatar';
import { useRecoilValue } from 'recoil';
import { logInUserAtom } from '../../../../atoms/log-in-user';
import { AvatarEditorDialog } from './avatar-editor-dialog';
import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { ComponentPropsNormarized, ValidationErrors } from '../../../types';
import { RValidationMessages } from '../../../core/validation-message/validation-messages';

type Props = ComponentPropsNormarized<'div'>;

function Component({ className }: Props): JSX.Element {
  const [showOpenDialog, setShowOpenDialog] = useState(false);
  const [file, setFile] = useState(undefined as File | undefined);
  const [validationErrors, onOpenDialogClick] = useOnOpenDialogClick(setShowOpenDialog);

  const logInUser = useRecoilValue(logInUserAtom);
  if (logInUser.id === null || logInUser.avatarUrl === null) {
    return <></>;
  }

  return (
    <div className={className}>
      <div className={classes.avatarEditorWrapper}>
        <RAvatar
          size="Preview"
          src={logInUser.avatarUrl}
          sx={{
            position: 'relative',
          }}
        />

        <label className={classes.avatarEditorButton}>
          <input
            type="file"
            accept="image/*"
            className={classes.avatarInput}
            onChange={(e): void => {
              const f = onOpenDialogClick(e.nativeEvent);
              setFile(f);
            }}
          />
        </label>
      </div>
      <div className={classes.footer}>
        <RValidationMessages
          messages={validationErrors.avatar}
          sx={{
            position: 'absolute',
            width: 448,
            left: -150,
            top: 8,
          }}
        />
        <p className={classes.avatarRecommendedSize}>推奨サイズ&nbsp;最小140×140px</p>
      </div>

      {showOpenDialog && <AvatarEditorDialog file={file!} close={(): void => setShowOpenDialog(false)} />}
    </div>
  );
}

const useOnOpenDialogClick = (setShowOpenDialog: Dispatch<SetStateAction<boolean>>): [ValidationErrors, (e: Event) => File | undefined] => {
  const [validationErrors, setValidationErrors] = useState({} as ValidationErrors);

  const onOpenDialogClick = useCallback(
    (e: Event): File | undefined => {
      setValidationErrors({});

      const target = e.target as HTMLInputElement;
      const fileObject = (target.files as FileList)[0];
      if (typeof fileObject === 'undefined') {
        return undefined;
      }

      // 同一ファイルを再選択された場合でもchangeイベントを発生させるため。
      target.value = '';

      if (fileObject.size > 100 * 1024) {
        setValidationErrors({ avatar: ['アップロードするファイルは100KBまででお願いします。'] });
        return undefined;
      }
      if (fileObject.type.match(/^image\//) === null) {
        setValidationErrors({ avatar: ['アップロードするファイルの形式は、PNG, JPEGのどちらかでお願いします。'] });
        return undefined;
      }

      setShowOpenDialog(true);

      return fileObject;
    },
    [setShowOpenDialog],
  );

  return [validationErrors, onOpenDialogClick];
};

const PREFIX = 'AvatarEditor';
const classes = {
  avatarEditorWrapper: `${PREFIX}-avatarEditorWrapper`,
  avatarEditorButton: `${PREFIX}-avatarEditorButton`,
  avatarInput: `${PREFIX}-avatarInput`,
  footer: `${PREFIX}-footer`,
  avatarRecommendedSize: `${PREFIX}-avatarRecommendedSize`,
};

export const AvatarEditor = styled(Component, {
  shouldForwardProp: (prop) => prop !== 'sx',
})({
  [`& .${classes.avatarEditorWrapper}`]: {
    position: 'relative',
  },
  [`& .${classes.avatarEditorButton}`]: {
    width: 40,
    height: 40,
    borderRadius: 20,

    position: 'absolute',
    bottom: 0,
    right: 0,

    cursor: 'pointer',

    backgroundImage: `url(${I_edit})`,
    backgroundSize: 40,
    '&:hover': {
      backgroundImage: `url(${I_editHover})`,
    },
  },
  [`& .${classes.avatarInput}`]: {
    display: 'none',
  },
  [`& .${classes.footer}`]: {
    position: 'absolute',
  },
  [`& .${classes.avatarRecommendedSize}`]: {
    position: 'absolute',
    width: 180,
    top: 24,
    left: -25,
    textAlign: 'center',
    color: '#9D9D9D',
  },
});
