import { styled } from '@mui/material/styles';
import { useState, useCallback, ReactNode } from 'react';
import { ComponentPropsNormarized } from '../../types';

type Props = {
  line: number;
  children: ReactNode;
} & ComponentPropsNormarized<'div'>;

function Component({ line, children, className }: Props): JSX.Element {
  const [init, onClick, descriptionOmitted] = useSetDescriptionHeight();

  return (
    <div className={className}>
      <div className={descriptionOmitted ? classes.omission : undefined} ref={init}>
        <p className={descriptionOmitted ? classes.omissionText : undefined} style={{ WebkitLineClamp: line }}>
          {children}
        </p>
      </div>
      {descriptionOmitted && (
        <div className={classes.more} onClick={onClick}>
          <p className={classes.moreTitle}>もっと見る</p>
        </div>
      )}
    </div>
  );
}

const useSetDescriptionHeight: () => [(elem: HTMLDivElement) => void, () => void, boolean] = () => {
  const [descriptionOmitted, setDescriptionOmitted] = useState(true);

  const init = useCallback((elem: HTMLDivElement | null) => {
    if (elem === null) {
      return;
    }

    setDescriptionOmitted(elem.offsetHeight < elem.scrollHeight);
  }, []);

  const onClick = useCallback(() => {
    setDescriptionOmitted(false);
  }, []);

  return [init, onClick, descriptionOmitted];
};

const PREFIX = 'RShowMore';
const classes = {
  omission: `${PREFIX}-omission`,
  omissionText: `${PREFIX}-omissionText`,
  more: `${PREFIX}-more`,
  moreTitle: `${PREFIX}-moreTitle`,
};

export const RShowMore = styled(Component, {
  shouldForwardProp: (prop) => prop !== 'sx',
})({
  [`& .${classes.omission}`]: {
    overflow: 'hidden',
  },
  [`& .${classes.omissionText}`]: {
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
  },
  [`& .${classes.more}`]: {
    '&:after': {
      content: '""',
      display: 'block',
      clear: 'both',
    },
  },
  [`& .${classes.moreTitle}`]: {
    color: '#9D9D9D',
    marginTop: '8px',
    float: 'right',
    width: '80px',
    cursor: 'pointer',
    '&:hover': {
      color: '#0080FF',
    },
  },
});
