import { Dispatch, SetStateAction, useState, useEffect } from 'react';
import { useElementID } from './element-id';
import { useOnEsc } from './on-esc';

/**
 * ESCやメニュー外クリックでホバーメニューを閉じる。
 * @returns [メニューを表示すべきか, メニュー表示更新, メニュー表示ボタンの親要素に設定すべきid]
 */
export const useMenuControl = (): [boolean, Dispatch<SetStateAction<boolean>>, string] => {
  const menuElementID = useElementID();
  const [menuVisible, setMenuVisible] = useState(false);

  useOnEsc(() => setMenuVisible(false));

  useEffect((): (() => void) => {
    const onClick = (e: MouseEvent): void => {
      const target = e.target as HTMLElement | null;
      if (target === null) {
        return;
      }
      if (target.closest(`#${menuElementID}`) !== null) {
        return;
      }
      setMenuVisible(false);
    };

    document.body.addEventListener('click', onClick);

    return (): void => {
      document.body.removeEventListener('click', onClick);
    };
  }, [menuElementID]);

  return [menuVisible, setMenuVisible, menuElementID];
};
