import React, { ReactNode } from 'react';

export const withBR: (text: string | null) => ReactNode = (text) => {
  if (text === null) {
    return <></>;
  }

  const texts = text.split('\n');
  return texts.map((item, index) => (
    <React.Fragment key={index}>
      {item}
      {index < texts.length - 1 && <br />}
    </React.Fragment>
  ));
};
