import { styled } from '@mui/material/styles';
import { Dispatch, SetStateAction, useRef, useState } from 'react';
import { maxLength } from '../../../../services/max-length';
import { RButton } from '../../../base/button/button';
import { RLink } from '../../../base/link/link';
import { RTextArea } from '../../../base/text-area/text-area';
import { RAvatar } from '../../../core/avatar/avatar';
import { RRecordGroup } from '../../../core/record/record-group/record-group';
import { RRecord } from '../../../core/record/record/record';
import { RUserName } from '../../../core/user-name/user-name';
import { RValidationMessages } from '../../../core/validation-message/validation-messages';
import { useData } from '../../../hooks/data';
import { useSave } from '../../../hooks/save';
import { ComponentPropsNormarized, ValidationErrors } from '../../../types';

type Props = {
  userID: number;
  refresh: () => void;
} & ComponentPropsNormarized<'section'>;

type Data = {
  partnerName: string;
  avatarURL: string;
  partnerDeleted: boolean;
};

type SaveData = {
  partnerID: number;
  body: string;
};

function Component({ userID, refresh, className }: Props): JSX.Element {
  const [message, setMessage] = useState('');
  const [onSave, validationErrors] = useOnSave(setMessage, refresh);

  const params = useRef({ id: userID });
  const [data] = useData<Data>('/v1/MyPage/MessageDetailHeader', params.current);
  if (data === undefined) {
    return <></>;
  }

  return (
    <section className={className}>
      <RRecordGroup>
        <RRecord hover="None">
          <div className={classes.editAreaNameWrapper}>
            <RAvatar size="M" src={data.avatarURL} userID={data.partnerDeleted ? undefined : userID} />
            <h2>
              <RUserName
                userId={userID}
                deletedUser={data.partnerDeleted}
                sx={{
                  fontSize: '1.6rem',
                  marginLeft: '8px',
                  fontWeight: 'bold',
                }}>
                {data.partnerName}
              </RUserName>
            </h2>
          </div>

          {data.partnerDeleted ? (
            <p className={classes.deleted}>このユーザーは退会済みです。</p>
          ) : (
            <>
              <RTextArea
                value={message}
                setValue={setMessage}
                placeholder="メッセージを入力してください。"
                maxLength={maxLength.message}
                sx={{
                  marginTop: '8px',
                  width: '100%',
                  height: 178,
                }}
              />
              <RValidationMessages messages={validationErrors.Body} />

              <p className={classes.notes}>
                ※本サイト以外での直接契約や本サイトの趣旨に関係ないやりとりは、アカウント停止・削除の対象となる可能性があります。 <br />
                詳しくは
                <RLink href="/Legal/TermsOfUse" target="_blank">
                  利用規約
                </RLink>
                をご覧ください。
              </p>

              <RButton
                onClick={(e): Promise<void> =>
                  onSave(e, {
                    partnerID: userID,
                    body: message,
                  })
                }
                sx={{
                  marginTop: '12px',
                  width: 144,
                }}>
                メッセージを送信
              </RButton>
            </>
          )}
        </RRecord>
      </RRecordGroup>
    </section>
  );
}

const useOnSave = (
  setMessage: Dispatch<SetStateAction<string>>,
  refresh: () => void,
): [(event: React.SyntheticEvent, data: SaveData) => Promise<void>, ValidationErrors] => {
  const [save, validationErrors] = useSave('/v1/MyPage/PostMessage', 'メッセージを送信しました。');

  const onSave = async (event: React.SyntheticEvent, data: SaveData): Promise<void> => {
    await save(event, data);
    setMessage('');
    refresh();
  };

  return [onSave, validationErrors];
};

const PREFIX = 'Header';
const classes = {
  editAreaNameWrapper: `${PREFIX}-editAreaNameWrapper`,
  deleted: `${PREFIX}-deleted`,
  notes: `${PREFIX}-notes`,
};

export const Header = styled(Component, {
  shouldForwardProp: (prop) => prop !== 'sx',
})({
  [`& .${classes.editAreaNameWrapper}`]: {
    display: 'flex',
    alignItems: 'center',
  },
  [`& .${classes.deleted}`]: {
    marginTop: 7,
    padding: 11,

    background: '#DFDFDF',

    border: '1px solid #C6C6C6',
    borderRadius: 4,

    lineHeight: '21px',
    letterSpacing: 0.25,
    color: '#757575',
  },
  [`& .${classes.notes}`]: {
    marginTop: 4,
    fontSize: 12,
    lineHeight: '15px',
    color: '#9D9D9D',
  },
});
