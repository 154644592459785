import { styled } from '@mui/material/styles';
import { Introduction } from './introduction';
import { Skills } from './skills';
import { useData } from '../../../hooks/data';
import { useIDFromParam } from '../../../hooks/id-from-param';
import { ComponentPropsNormarized } from '../../../types';

export type Skill = {
  id: number;
  languageID: number;
  name: string;
  level: number;
  description: string | null;
};

type Data = {
  description: string | null;
  skills: Skill[];
};

type Props = ComponentPropsNormarized<'div'>;

function Component({ className }: Props): JSX.Element {
  const id = useIDFromParam();

  const [data] = useData<Data>(`/v1/Profile/Main/${id}`);

  if (data === undefined) {
    return <></>;
  }

  return (
    <div className={className}>
      <Introduction description={data.description} />
      <Skills skills={data.skills} />
      {/* <ReviewComments /> */}
    </div>
  );
}

export const Main = styled(Component)({});
