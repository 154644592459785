import I_logo from '../../../Images/icon/logo_color.svg';

import { classNames } from '../../classNames';
import { RLink } from '../../base/link/link';
import { LoggedIn } from './logged-in';
import { LoggedOut } from './logged-out';
import { RImage } from '../../base/image/image';
import { styled } from '@mui/material/styles';
import { useRecoilValue } from 'recoil';
import { logInUserAtom } from '../../../atoms/log-in-user';
import { ComponentPropsNormarized } from '../../types';
import { RContainer } from '../Container';

type Props = ComponentPropsNormarized<'header'>;

function Component({ className }: Props): JSX.Element {
  const logInUser = useRecoilValue(logInUserAtom);

  const list = [
    { label: 'プロジェクト', href: '/Home/Index' },
    { label: 'ユーザー', href: '/UserSearch/List' },
    { label: 'コラム', href: '/Column' },
    // { label: 'FAQ', href: '#' },
  ];

  return (
    <header className={className}>
      <RContainer
        sx={{
          flexGrow: 'initial',
          padding: '0 24px',
        }}>
        <nav
          style={{
            display: 'flex',
            position: 'relative',
          }}>
          <h1>
            <RLink href="/">
              <RImage
                src={I_logo}
                sx={{
                  width: '195px',
                  height: '35px',
                  marginTop: '15px',
                }}
              />
            </RLink>
          </h1>
          <ul className={classNames(classes.ul)}>
            {list.map((item) => (
              <li key={item.href} className={classes.menuItem}>
                <RLink kind="HeaderLeftMenuItem" href={item.href}>
                  {item.label}
                </RLink>
              </li>
            ))}
          </ul>
          {logInUser.id === null ? <LoggedOut /> : <LoggedIn />}
        </nav>
      </RContainer>
    </header>
  );
}

const PREFIX = 'Header';
const classes = {
  inner: `${PREFIX}-inner`,
  logo: `${PREFIX}-logo`,
  menuItem: `${PREFIX}-menuItem`,
  ul: `${PREFIX}-ul`,
};

export const Header = styled(Component, {
  shouldForwardProp: (prop) => prop !== 'sx',
})({
  height: '64px',
  boxShadow: '0px 2px 8px rgba(51, 51, 51, 0.06)',
  background: '#fff',

  [`& .${classes.menuItem}`]: {
    '&:first-of-type': {
      marginLeft: '36px',
    },
  },

  [`& .${classes.ul}`]: {
    display: 'flex',
    lineHeight: '64px',
  },
});
