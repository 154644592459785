import { styled } from '@mui/material/styles';
import { Timeline } from 'react-twitter-widgets';
import { useCallback } from 'react';
import { ComponentPropsNormarized } from '../../types';

type Props = ComponentPropsNormarized<'div'>;

function Component({ className }: Props): JSX.Element {
  const setStyle = useSetStyle();

  return (
    <div className={className}>
      <section className={classes.news}>
        <h3 className={classes.title}>運営からのお知らせ</h3>
        <div className={classes.newsWrapper}>
          <Timeline
            dataSource={{ sourceType: 'profile', screenName: 'bettercode1' }}
            options={{ lang: 'ja', height: '592', chrome: 'noheader' }}
            onLoad={setStyle}
          />
        </div>
      </section>
    </div>
  );
}

const useSetStyle = (): (() => void) => {
  // Twitter APIが動的に生成するHTMLに対してスタイルを適用する。
  const setStyle = useCallback((): void => {
    const iframe = document.querySelector('.twitter-timeline.twitter-timeline-rendered');
    if (iframe === null) {
      return;
    }
    const iframeElem = iframe as HTMLIFrameElement;

    const elem = iframeElem.contentDocument?.querySelector('.timeline-Body');
    if (elem === null || elem === undefined) {
      return;
    }
    const timelineBodyElem = elem as HTMLElement;

    timelineBodyElem.style.borderTop = 'none';
  }, []);

  return setStyle;
};

const PREFIX = 'Twitter';
const classes = {
  news: `${PREFIX}-news`,
  newsWrapper: `${PREFIX}-newsWrapper`,
  title: `${PREFIX}-title`,
};

export const Twitter = styled(Component)({
  [`& .${classes.news}`]: {
    marginLeft: '24px',
  },
  [`& .${classes.newsWrapper}`]: {
    width: '250px',
    backgroundColor: '#FFF',
    border: '1px solid #E3E3E3',
    borderRadius: '10px',
    marginTop: '12px',
    overflow: 'auto',
  },
  [`& .${classes.title}`]: {
    fontSize: '1.6rem',
    fontWeight: 'bold',
    color: '#757575',
  },
});
