import { styled } from '@mui/material/styles';
import { ChangeEventHandler, Dispatch, forwardRef, MutableRefObject, useCallback, useEffect } from 'react';
import { useForwardedRef } from '../../hooks/forwarded-ref';
import { ComponentPropsNormarized } from '../../types';

type Props = {
  value: string;
  setValue: Dispatch<string>;
} & Omit<ComponentPropsNormarized<'textarea'>, 'value'>;

const Component = forwardRef<HTMLTextAreaElement, Props>(({ value, setValue, className, ...others }, ref) => {
  const fowardedRef = useForwardedRef(ref);
  useExtendHeight(fowardedRef);

  const onChange: ChangeEventHandler<HTMLTextAreaElement> = useCallback(
    (e) => {
      setValue(e.target.value);
      extendHeight(e.target);
    },
    [setValue],
  );

  return <textarea ref={fowardedRef} value={value ?? undefined} onChange={onChange} className={className} {...others} />;
});

const useExtendHeight = (ref: MutableRefObject<HTMLTextAreaElement | null>): void => {
  useEffect(() => {
    if (ref?.current === null) {
      return;
    }
    extendHeight(ref.current);
  }, [ref]);
};

const extendHeight = (elem: HTMLTextAreaElement): void => {
  let newHeight = elem.scrollHeight;
  if (elem.clientHeight < newHeight) {
    if (newHeight > window.innerHeight / 2) {
      newHeight = window.innerHeight / 2;
    }
    elem.style.height = `${newHeight}px`;
  }
};

export const RTextArea = styled(Component, {
  shouldForwardProp: (prop) => prop !== 'sx',
})({
  // これを適用しないと、なぜか下部にマージンができる。
  display: 'block',

  padding: '7px 11px 11px 11px',
  color: '#404040',
  background: '#FFFFFF',

  fontSize: 16,
  lineHeight: '23px',

  border: '1px solid #C6C6C6',
  borderRadius: 4,

  '&::placeholder': {
    color: '#9D9D9D',
  },
  '&:focus': {
    borderColor: '#3399FF',
    outline: 'none',
  },
});
