import { RImage } from '../../base/image/image';
import { css, styled } from '@mui/material/styles';
import { useRedirect } from '../../hooks/redirect';
import { ComponentPropsNormarized, Styles } from '../../types';
import { SerializedStyles } from '@emotion/react';
import { CSSProperties } from 'react';

type Props = {
  userID?: number;
  size: 'Header' | 'L' | 'M' | 'S' | 'Preview' | 'DM';
  src: string;
} & ComponentPropsNormarized<typeof RImage>;

function Component({ userID, size, src, className, ...others }: Props): JSX.Element {
  const [onClick] = useRedirect(userID, `/Profile/Main/${userID}`);

  return (
    <span onClick={userID === undefined || size === 'Header' ? undefined : onClick} className={className}>
      <RImage src={src} sx={getImageStyle(size)} {...others} />
    </span>
  );
}

const getImageStyle = (size: Props['size']): CSSProperties => {
  const avatarImg: CSSProperties = {
    display: 'block',
    borderRadius: '50%',
  };

  type StyleKeys = Props['size'];
  const styles: Styles<StyleKeys> = {
    Header: {
      width: 48,
      height: 48,
    },
    L: {
      width: 140,
      height: 140,
    },
    M: {
      width: 40,
      height: 40,
    },
    S: {
      width: 28,
      height: 28,
    },
    Preview: {
      width: 128,
      height: 128,
    },
    DM: {
      width: 56,
      height: 56,
    },
  };

  return { ...avatarImg, ...styles[size] };
};

const getCSS = ({ userID, size }: Props): SerializedStyles => {
  const base = css({
    display: 'block',
    borderRadius: '50%',
  });
  const avatarLinkHover = css({
    '&:hover': {
      opacity: '0.7',
      cursor: 'pointer',
    },
  });

  const sizeCSS = {
    Header: css({
      width: 48,
      height: 48,
    }),
    L: css({
      width: 140,
      height: 140,
    }),
    M: css({
      width: 40,
      height: 40,
    }),
    S: css({
      width: 28,
      height: 28,
    }),
    Preview: css({
      width: 128,
      height: 128,
    }),
    DM: css({
      width: 56,
      height: 56,
    }),
  };

  return css([base, userID !== undefined && avatarLinkHover, sizeCSS[size]]);
};

export const RAvatar = styled(Component, {
  shouldForwardProp: (prop) => prop !== 'sx',
})(getCSS);
