import { styled } from '@mui/material/styles';
import { useTitle } from '../../../hooks/title';
import { ComponentPropsNormarized } from '../../../types';
import { Delete } from './delete';
import { Main } from './main';

type Props = ComponentPropsNormarized<'div'>;

function Component({ className }: Props): JSX.Element {
  useTitle('アカウント');

  return (
    <div className={className}>
      <Main />
      <Delete />
    </div>
  );
}

export const Account = styled(Component, {
  shouldForwardProp: (prop) => prop !== 'sx',
})({
  padding: 31,
  position: 'relative',

  backgroundColor: '#fff',
  borderRadius: 8,
  border: '1px solid #E3E3E3',

  [`& h2`]: {
    [`& span`]: {
      fontWeight: 'bold',
    },
  },
});
