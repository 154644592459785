import I_close from '../../../Images/navigation/close.svg';

import { styled } from '@mui/material/styles';
import { RModal } from '../modal/modal';
import { RImage } from '../../base/image/image';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import { logInSuggestionAtom } from '../../../atoms/log-in-suggestion';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { RButton } from '../../base/button/button';
import { ComponentPropsNormarized } from '../../types';

type Props = ComponentPropsNormarized<typeof RModal>;

function Component({ close, className }: Props): JSX.Element {
  const logInSuggestion = useRecoilValue(logInSuggestionAtom);

  const onCancelClick = useOnCancelClick();
  const onLogInClick = useOnButtonClick('/User/LogIn');
  const onCreateUserClick = useOnButtonClick('/User/Create');

  return (
    <RModal
      style={{
        maxWidth: 'initial',
      }}
      close={close}
      className={className}>
      <div className={classes.header}>
        <p onClick={onCancelClick}>
          <RImage
            sx={{
              cursor: 'pointer',
            }}
            src={I_close}
          />
        </p>
      </div>

      <div className={classes.contents}>
        <h2 className={classes.description}>{logInSuggestion.description}</h2>

        <div className={classes.operations}>
          <RButton
            sx={{
              width: '256px',
              height: '48px',
            }}
            kind="WithFrame"
            onClick={onLogInClick}>
            ログイン
          </RButton>
          <p className={classes.or}>または</p>
          <RButton
            sx={{
              width: '256px',
              height: '48px',
            }}
            onClick={onCreateUserClick}>
            ユーザー登録
          </RButton>
        </div>
      </div>
    </RModal>
  );
}

const useOnButtonClick = (to: string): (() => void) => {
  const navigate = useNavigate();
  const resetLogInSuggest = useResetRecoilState(logInSuggestionAtom);

  const onClick = useCallback(() => {
    navigate(to);
    resetLogInSuggest();
  }, [navigate, to, resetLogInSuggest]);

  return onClick;
};

const useOnCancelClick = (): (() => void) => {
  const resetLogInSuggestion = useResetRecoilState(logInSuggestionAtom);

  const onCancel = useCallback(() => {
    resetLogInSuggestion();
  }, [resetLogInSuggestion]);

  return onCancel;
};

const PREFIX = 'RLogInSuggest';
const classes = {
  header: `${PREFIX}-header`,
  contents: `${PREFIX}-contents`,
  description: `${PREFIX}-description`,
  operations: `${PREFIX}-operations`,
  or: `${PREFIX}-or`,
};

export const RLogInSuggestion = styled(Component, {
  shouldForwardProp: (prop) => prop !== 'sx',
})({
  [`& .${classes.header}`]: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '16px 16px 0 0',
  },
  [`& .${classes.contents}`]: {
    padding: '8px 88px 48px 88px',
  },
  [`& .${classes.description}`]: {
    lineHeight: '21px',
  },
  [`& .${classes.operations}`]: {
    marginTop: '24px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  [`& .${classes.or}`]: {
    margin: '8px 0',
  },
});
