import I_alert from '../../../Images/alert/Vector.png';

import { styled } from '@mui/material/styles';
import { reportCategory } from '../../../atoms/report';
import { RLink } from '../../base/link/link';
import { useLogInSuggestion } from '../../hooks/log-in-suggestion';
import { useReportDialog } from '../../hooks/report-dialog';
import { ComponentPropsNormarized } from '../../types';

type Props = { category: keyof typeof reportCategory; targetID: number } & ComponentPropsNormarized<'div'>;

function Component({ category, targetID, className, ...others }: Props): JSX.Element {
  const onClick = useReportDialog(category, targetID);
  const logInSuggestion = useLogInSuggestion('ログインしてから通報しましょう。', onClick);

  return (
    <div {...others} className={className}>
      <RLink
        kind="Report"
        sx={{
          paddingLeft: '16px',
          background: `url(${I_alert}) no-repeat left center`,
        }}
        href="#"
        onClick={logInSuggestion}>
        通報
      </RLink>
    </div>
  );
}

export const RReportLink = styled(Component, {
  shouldForwardProp: (prop) => prop !== 'sx',
})({
  display: 'flex',
  justifyContent: 'flex-end',
  textAlign: 'right',
});
