import I_trashBox from '../../../Images/action/trashbox_14px_18px.png';
import I_flag from '../../../Images/action/flag_15px_17px.png';

import { styled } from '@mui/material/styles';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import { logInUserAtom } from '../../../atoms/log-in-user';
import { RImage } from '../../base/image/image';
import { classNames } from '../../classNames';
import { MouseEventHandler, useCallback, useState } from 'react';
import { RConfirmDeleteDialog } from '../../core/confirm-delete-dialog/confirm-delete-dialog';
import { useReportDialog } from '../../hooks/report-dialog';
import { ReviewComment, useRefreshAsync } from '../../../atoms/project-details';
import { projectDetailsShowHoverMenuAtom } from '../../../atoms/project-details-show-hover-menu';
import { useSave } from '../../hooks/save';
import { ComponentPropsNormarized } from '../../types';

type Props = {
  comment: ReviewComment;
  projectOwnerID: number;
} & ComponentPropsNormarized<'div'>;

function Component({ comment, projectOwnerID, className }: Props): JSX.Element {
  const logInUser = useRecoilValue(logInUserAtom);
  const [deleting, setDeleting] = useState(false);

  const onDeleteCommentClicked = useOnDeleteCommentClicked(comment.id);

  const showReportDialog = useReportDialog('comment', comment.id);

  return (
    <div className={className}>
      {(projectOwnerID === logInUser.id || comment.createUserID === logInUser.id) && (
        <div
          className={classNames(classes.menuItem, classes.menuItemDelete)}
          onClick={(): void => {
            setDeleting(true);
          }}>
          <RImage src={I_trashBox} />
          <div className={classNames(classes.label, classes.labelDelete)}>削除する</div>
        </div>
      )}
      {deleting && (
        <RConfirmDeleteDialog close={(): void => setDeleting(false)} onOK={onDeleteCommentClicked}>
          コメントを削除します。よろしいですか？
        </RConfirmDeleteDialog>
      )}

      <div className={classNames(classes.menuItem, classes.menuItemReport)} onClick={showReportDialog}>
        <RImage src={I_flag} />
        <div className={classNames(classes.label, classes.labelReport)}>通報する</div>
      </div>
    </div>
  );
}

const useOnDeleteCommentClicked = (commentID: number): MouseEventHandler<HTMLElement> => {
  const [save] = useSave('/v1/ReviewComment/Delete');
  const refreshAsync = useRefreshAsync();
  const resetProjectDetailsShowHoverMenu = useResetRecoilState(projectDetailsShowHoverMenuAtom);

  const onDeleteCommentClicked: MouseEventHandler<HTMLElement> = useCallback(
    async (e) => {
      const response = await save(e, {
        commentID: commentID,
      });

      if (response.errorCode !== null) {
        return;
      }

      await refreshAsync();

      resetProjectDetailsShowHoverMenu();
    },
    [commentID, refreshAsync, resetProjectDetailsShowHoverMenu, save],
  );

  return onDeleteCommentClicked;
};

const PREFIX = 'CommentHoverMenuDetail';
const classes = {
  menuItem: `${PREFIX}-menuItem`,
  menuItemDelete: `${PREFIX}-menuItemDelete`,
  menuItemReport: `${PREFIX}-menuItemReport`,
  label: `${PREFIX}-label`,
  labelDelete: `${PREFIX}-labelDelete`,
  labelReport: `${PREFIX}-labelReport`,
};

export const CommentHoverMenuDetail = styled(Component)({
  position: 'absolute',
  width: 180,
  padding: '4px 0px',
  right: 0,
  top: 48,
  borderRadius: 4,

  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',

  background: '#FFFFFF',
  boxShadow: '0px 0px 2px rgba(56, 56, 56, 0.2), 0px 2px 2px rgba(56, 56, 56, 0.24)',

  [`& .${classes.menuItem}`]: {
    width: 180,
    height: 36,
    borderRadius: 0,
    padding: '6px 20px',

    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    cursor: 'pointer',
  },
  [`& .${classes.menuItemDelete}`]: {
    '&:hover': {
      backgroundColor: '#FFEBEB',
    },
  },
  [`& .${classes.menuItemReport}`]: {
    '&:hover': {
      backgroundColor: '#f2f2f2',
    },
  },

  [`& .${classes.label}`]: {
    marginLeft: 10,
  },
  [`& .${classes.labelDelete}`]: {
    color: '#FB4646',
  },
  [`& .${classes.labelReport}`]: {
    color: '#404040',
  },
});
