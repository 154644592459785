import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { styled } from '@mui/material/styles';
import Slick from 'react-slick';
import { classNames } from '../../classNames';
import { RAvatar } from '../../core/avatar/avatar';
import { RUserName } from '../../core/user-name/user-name';
import { buildActivityDescription, useActivityData } from '../../hooks/activity';
import { ComponentPropsNormarized } from '../../types';

type Props = ComponentPropsNormarized<'section'>;

function Component({ className }: Props): JSX.Element {
  const [activityData] = useActivityData(20);

  const slickSettings = {
    vertical: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 500,
  };

  return (
    <section className={className}>
      <Slick {...slickSettings}>
        {activityData.activities.map((activity) => (
          <div key={activity.id}>
            <div className={classes.content}>
              <div className={classes.avatarImage}>
                <RAvatar size="S" src={activity.avatarImagePath} userID={activity.creatorID} />
              </div>
              <span className={classNames(classes.description, classes.p)}>
                <span className={classes.maximum10}>
                  <RUserName userId={activity.creatorID}>{activity.creatorName}</RUserName>
                </span>
                さんが{buildActivityDescription(activity, classes.maximum10)}
              </span>
              <p className={classNames(classes.time, classes.p)}>{activity.createDate}</p>
            </div>
          </div>
        ))}
      </Slick>
    </section>
  );
}

const PREFIX = 'Activities';
const classes = {
  wrapper: `${PREFIX}-wrapper`,
  content: `${PREFIX}-content`,
  avatar: `${PREFIX}-avatar`,
  p: `${PREFIX}-p`,
  avatarImage: `${PREFIX}-avatarImage`,
  description: `${PREFIX}-description`,
  maximum10: `${PREFIX}-maximum10`,
  time: `${PREFIX}-time`,
};

export const Activities = styled(Component)({
  height: '48px',
  maxWidth: '1086px',
  width: '100%',

  backgroundColor: '#F0F7FF',
  border: '1px solid #99CCFF',
  borderRadius: 8,

  padding: '9px 19px',
  overflow: 'hidden',

  [`& .slick-slide`]: {
    // これを明示的に指定しないと、なぜかカルーセルの時間経過に伴ってずれていく。(最初のスライドに戻ったタイミングでズレがリセットされる)
    // コンテンツの実際の高さは28pxだが、それだと上部に前スライドの下部が微妙に見えた状態になる。
    // 29(以上)を指定すると問題なく表示されるので、そうしておく。
    height: 29,
  },

  [`& .${classes.content}`]: {
    display: 'flex',
    alignItems: 'center',
  },
  [`& .${classes.p}`]: {
    lineHeight: '28px',
    float: 'left',
    letterSpacing: '0.25px',
  },
  [`& .${classes.avatarImage}`]: {
    marginRight: '12px',
    float: 'left',
  },
  [`& .${classes.description}`]: {
    display: 'flex',
  },
  [`& .${classes.maximum10}`]: {
    display: 'block',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    maxWidth: '18ch',
  },
  [`& .${classes.time}`]: {
    color: '#757575',
  },
});
