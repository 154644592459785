import { CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ComponentPropsNormarized } from '../../types';

type Props = ComponentPropsNormarized<'div'>;

function Component({ className }: Props): JSX.Element {
  return (
    <div className={className}>
      <CircularProgress />
    </div>
  );
}

export const RLoadingPlaceholder = styled(Component, {
  shouldForwardProp: (prop) => prop !== 'sx',
})({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexGrow: 1,

  [`& .MuiCircularProgress-svg`]: {
    color: '#3399FF',
  },
});
