// import I_star from '../../../Images/toggle/star.png';
// import I_starBorder from '../../../Images/toggle/star_border.png';
import I_heart from '../../../Images/content/heart.png';
import I_heartOff from '../../../Images/content/heart_off.png';
import I_heartGray from '../../../Images/content/heart_gray.png';

import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { RAvatar } from '../../core/avatar/avatar';
import { useRecoilValue } from 'recoil';
import { logInUserAtom } from '../../../atoms/log-in-user';
import { RButton } from '../../base/button/button';
import { RReportLink } from '../../core/report-link/report-link';
import { useLogInSuggestion } from '../../hooks/log-in-suggestion';
import { Dispatch, MouseEventHandler, useCallback, useState } from 'react';
import { useSave } from '../../hooks/save';
import { ComponentPropsNormarized } from '../../types';

type Props = {
  userID: number;
  name: string;
  avatarURL: string;
  followerCount: number;
  lastAccessDate: string;
  following: boolean;
} & ComponentPropsNormarized<'div'>;

function Component({ userID, name, avatarURL, followerCount, lastAccessDate, following: followingInitail, className }: Props): JSX.Element {
  const [following, setFollowing] = useState(followingInitail);

  const logInUser = useRecoilValue(logInUserAtom);
  const navigate = useNavigate();
  const follow = useFollow(userID, true, setFollowing);
  const unfollow = useFollow(userID, false, setFollowing);
  const logInSuggestionForFollow = useLogInSuggestion(`ログインして${name}さんをフォローしませんか？`, follow);
  const logInSuggestionForDM = useLogInSuggestion(`ログインして${name}さんにメッセージを送信しませんか？`, `/MyPage/MessageDetail/${userID}`);

  const shouldShowFollow = logInUser.id === null || (!following && userID !== logInUser.id);
  const shouldShowUnfollow = logInUser.id !== null && following;

  return (
    <div className={className}>
      <RAvatar
        sx={{
          margin: '0 auto 20px auto',
        }}
        size="L"
        src={avatarURL}
      />
      <p className={classes.userNumber}>No. {userID}</p>
      <h2 className={classes.userName}>{name}</h2>

      {/* <ul className={classes.rating}>
        <li className={classes.starCount}>4.0</li>
        <li className={classes.starsL}>
          {Array(4)
            .fill(0)
            .map((_, i) => (
              <RImage key={i} src={I_star} sx={startImageCSS} />
            ))}
          {Array(5 - 4)
            .fill(0)
            .map((_, i) => (
              <RImage key={i} src={I_starBorder} sx={startImageCSS} />
            ))}
        </li>
        <li className={classes.reviewCount}>(13)</li>
      </ul> */}

      <p className={classes.followerNumber}>{followerCount}フォロワー</p>
      <p className={classes.logInTime} title="">
        最終ログイン：{lastAccessDate}
      </p>

      {shouldShowUnfollow && (
        <RButton onClick={unfollow} kind="Unfollow" size="Follow">
          <div className={classes.followButton}>
            <div className={classes.unfollowIcon} />
            <div className={classes.followLabel}>フォロー中</div>
          </div>
        </RButton>
      )}
      {shouldShowFollow && (
        <RButton onClick={logInSuggestionForFollow} kind="Follow" size="Follow">
          <div className={classes.followButton}>
            <div className={classes.followIcon} />
            <div>フォローする</div>
          </div>
        </RButton>
      )}

      {userID === logInUser.id && (
        <div>
          <RButton
            size="L"
            kind="WithFrame"
            sx={{
              width: '232px',
              margin: '0 auto',
            }}
            onClick={(): void => {
              navigate('/MyPage/Edit');
            }}>
            編集する
          </RButton>
        </div>
      )}
      {/* <hr className={classes.border} />

      <ul className={classes.countArea}>
        <li className={classes.concluded}>成立した契約</li>
        <li className={classes.concludedItem}>依頼した数：4</li>
        <li className={classes.concludedItem}>依頼を受けた数：5</li>
      </ul> */}

      {userID !== logInUser.id && (
        <>
          <hr className={classes.border} />
          <div className={classes.button} onClick={logInSuggestionForDM}>
            <RButton
              size="L"
              sx={{
                margin: '0 auto',
                width: '232px',
              }}>
              メッセージを送る
            </RButton>
          </div>
          <div className={classes.requestByMessage}>
            <p>メッセージでレビュー依頼やレビュー提案ができます。</p>
          </div>

          <RReportLink
            category="user"
            targetID={userID}
            sx={{
              margin: '28px 30px 0 0',
            }}
          />
        </>
      )}
    </div>
  );
}

const useFollow = (targetUserID: number, follow: boolean, setFollowing: Dispatch<boolean>): MouseEventHandler<HTMLElement> => {
  const [save] = useSave('/v1/Follow/Follow');

  const callback: MouseEventHandler<HTMLElement> = useCallback(
    async (e) => {
      await save(e, {
        targetUserID: targetUserID,
        follow: follow,
      });

      setFollowing(follow);
    },
    [save, targetUserID, follow, setFollowing],
  );

  return callback;
};

const PREFIX = 'Summary';
const classes = {
  userNumber: `${PREFIX}-userNumber`,
  userName: `${PREFIX}-userName`,
  rating: `${PREFIX}-rating`,
  starCount: `${PREFIX}-starCount`,
  starsL: `${PREFIX}-starsL`,
  reviewCount: `${PREFIX}-reviewCount`,
  followerNumber: `${PREFIX}-followerNumber`,
  logInTime: `${PREFIX}-logInTime`,

  followButton: `${PREFIX}-followButton`,
  followIcon: `${PREFIX}-followIcon`,
  unfollowIcon: `${PREFIX}-unfollowIcon`,
  followLabel: `${PREFIX}-followLabel`,

  border: `${PREFIX}-border`,
  countArea: `${PREFIX}-countArea`,
  concluded: `${PREFIX}-concluded`,
  concludedItem: `${PREFIX}-concludedItem`,
  button: `${PREFIX}-button`,
  requestByMessage: `${PREFIX}-requestByMessage`,
};

// const startImageCSS = {
//   paddingTop: '3px',
//   width: '22px',
//   height: '22px',
// };

export const Summary = styled(Component)({
  width: '292px',
  backgroundColor: '#fff',
  border: '1px solid #E3E3E3',
  borderRadius: '8px',
  margin: '0px 0 24px 0',
  padding: '29px 0px',

  [`& .${classes.userNumber}`]: {
    color: '#757575',
    fontSize: '1.2rem',
    textAlign: 'center',
    marginBottom: '4px',
  },
  [`& .${classes.userName}`]: {
    fontSize: '2.4rem',
    fontWeight: 700,
    textAlign: 'center',
    margin: '0 auto 16px auto',
    width: '266px',
  },
  [`& .${classes.rating}`]: {
    display: 'flex',
    justifyContent: 'center',
  },
  [`& .${classes.starCount}`]: {
    marginRight: '4px',
    fontSize: '1.8rem',
    lineHeight: 1.5,
  },
  [`& .${classes.starsL}`]: {
    marginRight: '4px',
  },
  [`& .${classes.reviewCount}`]: {
    marginRight: '4px',
    fontSize: '1.4rem',
    paddingTop: '2px',
  },
  [`& .${classes.followerNumber}`]: {
    color: '#757575',
    fontSize: '1.2rem',
    textAlign: 'center',
    marginBottom: '8px',
  },
  [`& .${classes.logInTime}`]: {
    color: '#757575',
    fontSize: '1.2rem',
    textAlign: 'center',
    marginBottom: '20px',
  },

  [`& .${classes.followButton}`]: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',

    [`&:hover .${classes.followIcon}`]: {
      backgroundImage: `url(${I_heart})`,
    },
    [`&:hover .${classes.unfollowIcon}`]: {
      backgroundImage: `url(${I_heartGray})`,
    },
  },
  [`& .${classes.followIcon}`]: {
    width: 20,
    height: 20,
    backgroundPosition: 'left 0px top 2px',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${I_heartOff})`,
  },
  [`& .${classes.unfollowIcon}`]: {
    width: 20,
    height: 20,
    backgroundPosition: 'left 0px top 2px',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${I_heartOff})`,
  },
  [`& .${classes.followLabel}`]: {
    marginLeft: 4,
  },

  [`& .${classes.border}`]: {
    marginTop: '16px',
    marginBottom: '14px',
    border: 'none',
    height: 1,
    width: '232px',
    backgroundColor: '#E3E3E3',
  },
  [`& .${classes.countArea}`]: {
    marginLeft: '30px',
  },
  [`& .${classes.concluded}`]: {
    fontWeight: 'bold',
    color: '#404040',
    lineHeight: '21px',
  },
  [`& .${classes.concludedItem}`]: {
    lineHeight: '21px',
  },
  [`& .${classes.button}`]: {
    marginTop: '14px',
  },
  [`& .${classes.requestByMessage}`]: {
    width: '232px',
    height: '30px',
    fontSize: '1.2rem',
    margin: '8px auto',
    color: '#757575',
  },
});
