import { styled } from '@mui/material/styles';
import { RPassword } from '../../base/password/password';
import { RInput } from '../../base/input/input';
import { classNames } from '../../classNames';
import { RLink } from '../../base/link/link';
import { RButton } from '../../base/button/button';
import { MouseEvent, MouseEventHandler, useCallback, useState } from 'react';
import { ApiResponse, useApi } from '../../../services/api';
import { useSetRecoilState } from 'recoil';
import { RValidationMessages } from '../../core/validation-message/validation-messages';
import { ComponentPropsNormarized, ValidationErrors } from '../../types';
import { maxLength } from '../../../services/max-length';
import { useNavigate } from 'react-router-dom';
import { useTitle } from '../../hooks/title';
import { createUserMailAddressAtom } from '../../../atoms/create-user-mail-address';
import { useOpenSnackbar } from '../../../atoms/snackbar';
import { RContainer } from '../../layout/Container';

type Props = ComponentPropsNormarized<typeof RContainer>;

function Component({ className }: Props): JSX.Element {
  useTitle('ログイン');

  const [mail, setMail] = useState('');
  const [password, setPassword] = useState('');

  const [validationErrors, onLogInClick] = useOnLogInClick(mail, password);

  return (
    <RContainer className={className}>
      <div className={classes.wrapper}>
        <h1 className={classes.h1}>メールアドレスでログイン</h1>

        <form method="post" action="/User/LogIn" className={classes.form}>
          <RInput
            type="email"
            acceptEnterKey
            placeholder="メールアドレス"
            value={mail}
            setValue={setMail}
            sx={{
              marginTop: '24px',
              width: '100%',
            }}
            maxLength={maxLength.user.mail}
          />
          <RValidationMessages messages={validationErrors.Mail} />

          <RPassword
            acceptEnterKey
            value={password}
            setValue={setPassword}
            sx={{
              marginTop: '24px',
              width: '100%',
            }}
          />
          <RValidationMessages messages={validationErrors.Password} />

          <RButton
            kind="Primary"
            sx={{
              marginTop: '30px',
              width: '100%',
              height: '48px',
            }}
            onClick={onLogInClick}>
            ログイン
          </RButton>

          <p className={classes.p}>
            <RLink kind="GrayOnWhite" href="/PasswordReset/Start">
              パスワードを忘れた方はこちら
            </RLink>
          </p>
          <h2 className={classes.h2}>アカウントをお持ちでない方</h2>
          <p className={classNames(classes.p, classes.link)}>
            <RLink kind="GrayOnWhite" href="/User/Create">
              <RButton
                kind="WithFrame"
                sx={{
                  width: '100%',
                  height: '48px',
                }}>
                新規登録はこちら
              </RButton>
            </RLink>
          </p>
        </form>
      </div>
    </RContainer>
  );
}

const useOnLogInClick = (mail: string, password: string): [ValidationErrors, MouseEventHandler<HTMLElement>] => {
  const [validationErrors, setValidationErrors] = useState({} as ValidationErrors);
  const navigate = useNavigate();
  const openSnackbar = useOpenSnackbar();
  const setCreateUserMailAddress = useSetRecoilState(createUserMailAddressAtom);

  const api = useApi();

  const onLogInClick = useCallback(
    async (e: MouseEvent<HTMLElement>) => {
      e.preventDefault();

      const response = (await api.post('/v1/User/LogIn', {
        Mail: mail,
        Password: password,
      })) as ApiResponse;

      if (response.errorCode === 'validationError') {
        setValidationErrors(response.payload as ValidationErrors);
        return;
      }
      if (response.errorCode === 'invalidPassword') {
        setValidationErrors({ Password: ['メールアドレスまたはパスワードが違います。'] });
        return;
      }
      if (response.errorCode === 'notConfirmed') {
        setCreateUserMailAddress(mail);
        navigate('/User/LogInNotConfirmed');
        return;
      }

      type Payload = {
        id: string;
        name: string;
        avatarUrl: string;
      };

      const payload = response.payload as Payload;

      openSnackbar(`${payload.name}さん、おかえりなさい！`);
      navigate('/');
    },
    [api, mail, navigate, openSnackbar, password, setCreateUserMailAddress],
  );

  return [validationErrors, onLogInClick];
};

const PREFIX = 'LogIn';
const classes = {
  wrapper: `${PREFIX}-wrapper`,
  h1: `${PREFIX}-h1`,
  form: `${PREFIX}-form`,
  p: `${PREFIX}-p`,
  input: `${PREFIX}-input`,
  h2: `${PREFIX}-h2`,
  link: `${PREFIX}-link`,
  register: `${PREFIX}-register`,
  logIn: `${PREFIX}-logIn`,
};

export const LogIn = styled(Component, {
  shouldForwardProp: (prop) => prop !== 'sx',
})({
  [`& .${classes.wrapper}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  [`& .${classes.h1}`]: {
    fontWeight: 500,
    fontSize: '28px',
    marginBottom: '30px',
  },

  [`& .${classes.form}`]: {
    width: 510,
  },
  [`& .${classes.p}`]: {
    marginTop: 25,
    lineHeight: '21px',
    textAlign: 'center',
  },

  [`& .${classes.h2}`]: {
    borderTop: '1px solid #C6C6C6',
    color: '#757575',
    paddingTop: '20px',
    margin: '25px auto 0 auto',
    textAlign: 'center',
  },

  [`& .${classes.link}`]: {
    marginTop: '12px',
  },
});
