import axios, { AxiosError, AxiosInstance, AxiosRequestTransformer } from 'axios';
import Cookies from 'js-cookie';
import { useMemo } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';

export type ApiResponse = {
  errorCode: string | null;
  payload: unknown;
};

const transformRequests = [] as AxiosRequestTransformer[];
transformRequests.push((data, headers) => {
  if (headers !== undefined) {
    const token = Cookies.get('csrfToken') ?? '';
    headers['csrf-token'] = token;
  }

  return data;
});
if (Array.isArray(axios.defaults.transformRequest)) {
  transformRequests.push(...(axios.defaults.transformRequest as AxiosRequestTransformer[]));
} else {
  transformRequests.push(axios.defaults.transformRequest as AxiosRequestTransformer);
}

const createApi: (navigate: NavigateFunction) => AxiosInstance = (navigate) => {
  const api = axios.create({
    transformRequest: transformRequests,
    paramsSerializer: { indexes: null },
  });

  api.interceptors.request.use((request) => request);

  api.interceptors.response.use(
    (response) => {
      const { data } = response;

      // サーバーエラー。
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if (data.errorCode === undefined) {
        if (!window.location.href.endsWith('/Error')) {
          navigate('/Error');
        }
        // 後続処理を強制的に中断する。
        throw new Error('500');
      }

      if (data.errorCode === 'notLoggedIn') {
        navigate('/User/LogIn');

        // 後続処理を強制的に中断する。
        throw new Error('notLoggedIn');
      }

      if (data.errorCode === 'notFound') {
        navigate('/NotFound');

        // 後続処理を強制的に中断する。
        throw new Error('notFound');
      }

      return data;
    },
    (error: AxiosError) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if (error.response === undefined || (error.response.data as any).errorCode === undefined) {
        if (!window.location.href.endsWith('/Error')) {
          navigate('/Error');
        }
        // 後続処理を強制的に中断する。
        throw new Error('500');
      }

      // CSRF違反。
      if (error.response.status === 403) {
        navigate('/User/LogIn');

        // 後続処理を強制的に中断する。
        throw new Error('403');
      }

      return error.response;
    },
  );

  return api;
};

export const useApi: () => AxiosInstance = () => {
  const navigate = useNavigate();

  const api = useMemo(() => createApi(navigate), [navigate]);
  return api;
};
