import I_attachment from '../../../../Images/content/attachment.png';
import I_comment from '../../../../Images/action/comment_outline.png';
import I_resolvedComment from '../../../../Images/action/comment_resolved.png';

import { styled } from '@mui/material/styles';
import { RAvatar } from '../../avatar/avatar';
import { RRecord } from '../record/record';
import { RUserName } from '../../user-name/user-name';
import { classNames } from '../../../classNames';
import { useRedirect } from '../../../hooks/redirect';
import { withBR } from '../../../functions/withBR';
import { RImage } from '../../../base/image/image';
import { ComponentPropsNormarized, Project } from '../../../types';
import { RProjectStatus } from '../../project-status/project-status';
import { RLanguages } from './languages/languages';

type Props = {
  project: Project;
  kind?: 'Top' | 'Profile';
} & ComponentPropsNormarized<typeof RRecord>;

function Component({ project, kind = 'Top', className }: Props): JSX.Element {
  const [onClick] = useRedirect(project.id, `/Project/Details/${project.id}`);

  return (
    <RRecord onClick={onClick} className={className}>
      <div className={classes.header}>
        <RProjectStatus status={project.status} />
        <RLanguages languages={project.languages} />
      </div>

      <p className={classes.title}>{project.name}</p>
      <div className={classes.description}>
        <p>{withBR(project.description)}</p>
      </div>

      {/* <RTags /> */}

      <div className={classes.footer}>
        <div className={classes.owner}>
          {kind === 'Top' && (
            <p className={classes.avatar}>
              <RAvatar size="M" src={project.createUserAvatorUrl} userID={project.creatorID} />
            </p>
          )}

          <div>
            {kind === 'Top' && (
              <RUserName
                userId={project.creatorID}
                sx={{
                  fontSize: '1.2rem',
                }}
                color="#757575">
                {project.creatorName}
              </RUserName>
            )}

            <p className={classes.memo}>{project.createDateFormatted}</p>
          </div>
        </div>

        <ul className={classes.counts}>
          <li className={classNames(classes.memo, classes.iconWrapper)} title="ファイル数">
            <RImage src={I_attachment} sx={iconCSS} />
            {project.fileCount}
          </li>
          <li className={classNames(classes.memo, classes.iconWrapper)} title="コメント数">
            <RImage src={I_comment} sx={iconCSS} />
            {project.commentCount}
          </li>
          <li className={classNames(classes.memo, classes.iconWrapper)} title="解決済みコメント数">
            <RImage src={I_resolvedComment} sx={iconCSS} />
            {project.resolvedCommentCount}
          </li>
        </ul>
      </div>
    </RRecord>
  );
}

const iconCSS = {
  verticalAlign: 'middle',
  marginRight: '5px',
};

const PREFIX = 'RProjectRecord';
const classes = {
  header: `${PREFIX}-header`,
  title: `${PREFIX}-title`,
  description: `${PREFIX}-description`,
  footer: `${PREFIX}-footer`,
  owner: `${PREFIX}-owner`,
  avatar: `${PREFIX}-avatar`,
  counts: `${PREFIX}-counts`,
  memo: `${PREFIX}-memo`,
  iconWrapper: `${PREFIX}-iconWrapper`,
};

export const RProjectRecord = styled(Component, {
  shouldForwardProp: (prop) => prop !== 'sx',
})({
  [`& .${classes.header}`]: {
    display: 'flex',
    height: 20,
  },

  [`& .${classes.title}`]: {
    fontWeight: 'bold',
    fontSize: '1.4rem',
    lineHeight: 1.5,
    marginBottom: '4px',
  },

  [`& .${classes.description}`]: {
    overflow: 'hidden',

    p: {
      display: '-webkit-box',
      WebkitBoxOrient: 'vertical',
      WebkitLineClamp: 3,
      color: '#757575',
    },
  },

  [`& .${classes.footer}`]: {
    marginTop: '8px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },

  [`& .${classes.owner}`]: {
    display: 'flex',
  },

  [`& .${classes.avatar}`]: {
    marginRight: '8px',
  },

  [`& .${classes.counts}`]: {
    display: 'flex',
  },

  [`& .${classes.memo}`]: {
    fontSize: '1.2rem',
    color: '#757575',
  },

  [`& .${classes.iconWrapper}`]: {
    marginRight: '9.5px',
  },
});
