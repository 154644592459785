import { ForwardedRef, MutableRefObject, useRef, useEffect } from 'react';

/**
 * ForwardedRef<T>からref(＝MutableRefObject<T | null>)を取り出す。
 * @param ref forwardRefの第二引数。
 * @returns 取り出し結果。
 */
export const useForwardedRef = <T extends HTMLElement>(ref: ForwardedRef<T>): MutableRefObject<T | null> => {
  const innerRef = useRef<T | null>(null);

  useEffect(() => {
    if (ref === null) {
      return;
    }

    if (typeof ref === 'function') {
      ref(innerRef.current);
    } else {
      ref.current = innerRef.current;
    }
  });

  return innerRef;
};
