import { styled } from '@mui/material/styles';
import { withBR } from '../../../functions/withBR';
import { RRecord } from '../../../core/record/record/record';
import { RShowMore } from '../../../core/show-more/show-more';
import { ComponentPropsNormarized } from '../../../types';

type Props = {
  description: string | null;
} & ComponentPropsNormarized<'section'>;

function Component({ description, className }: Props): JSX.Element {
  return (
    <section className={className}>
      <h3 className={classes.sectionTitle}>自己紹介</h3>
      {description === null ? (
        <RRecord
          hover="None"
          sx={{
            padding: '39px 0',
            color: '#8A8A8A',
            textAlign: 'center',
          }}>
          まだ自己紹介がありません。
        </RRecord>
      ) : (
        <RRecord hover="None">
          <RShowMore line={10}>{withBR(description)}</RShowMore>
          {/* <ul className={classes.tags}>
              <li>#タグ</li>
              <li>#タグ</li>
              <li>#タグ</li>
              <li>#タグ</li>
              <li>#タグ</li>
            </ul> */}
        </RRecord>
      )}
    </section>
  );
}

const PREFIX = 'Introduction';
const classes = {
  sectionTitle: `${PREFIX}-sectionTitle`,
  tags: `${PREFIX}-tags`,
};

export const Introduction = styled(Component)({
  marginTop: '24px',

  [`& .${classes.sectionTitle}`]: {
    fontSize: '1.6rem',
    fontWeight: 'bold',
    color: '#404040',
    marginBottom: '8px',
  },
  [`& .${classes.tags}`]: {
    display: 'flex',
  },
});
