import Cookies from 'js-cookie';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { logInUserAtom } from '../../atoms/log-in-user';

export const useNavigateIfNotLoggedIn = (): boolean => {
  const logInUser = useRecoilValue(logInUserAtom);
  const navigate = useNavigate();

  useEffect((): void => {
    if (Cookies.get('.ASPXAUTH') === undefined) {
      navigate('/User/LogIn');
    }
  }, [logInUser.id, navigate]);

  return logInUser.id !== null;
};
