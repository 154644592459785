export const maxLength = {
  user: {
    name: 35,
    mail: 260,
    description: 4000,
    skill: {
      description: 4000,
    },
  },
  project: {
    name: 40,
    description: 4000,
    comment: 4000,
    language: 5,
  },
  report: 4000,
  message: 4000,
} as const;

export const minLength = {
  user: {
    password: 8,
  },
} as const;
