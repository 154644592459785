import { useEffect } from 'react';

export const useTitle = (title: string | undefined): void => {
  useEffect(() => {
    let newTitle = '';
    if (title === undefined || title === '') {
      newTitle = 'better-code';
    } else {
      newTitle = `${title} | better-code`;
    }

    document.title = newTitle;

    return (): void => {
      document.title = 'better-code';
    };
  }, [title]);
};
