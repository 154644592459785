import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { logInUserAtom } from '../../../atoms/log-in-user';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCallback, useEffect, useRef } from 'react';
import { appInsights } from '../../../services/AppInsights';
import Cookies from 'js-cookie';
import { useApi, ApiResponse } from '../../../services/api';
import { ProjectDetailsCategoriesAtom, projectDetailsCategoriesAtom } from '../../../atoms/project-details-categories';
import { projectDetailsImportanceAtom, ProjectDetailsImportanceAtom } from '../../../atoms/project-details-importance';
import { useScrollToHash } from '../../functions/scroll-to-hash';
import { LanguagesAtom, languagesAtom } from '../../../atoms/languages';
import { avatarMenuStateAtom } from '../../../atoms/avatar-menu-state';
import { useOpenSnackbar } from '../../../atoms/snackbar';

export function RManager(): JSX.Element {
  useInitialize();

  useScrollToHash();

  useErrorHandler();

  useApplicationInsights();

  useMasterData();

  useSnackbar();

  usePageTransition();

  return <></>;
}

const useInitialize = (): void => {
  const [logInUser, setLogInUser] = useRecoilState(logInUserAtom);
  const api = useApi();

  useEffect(() => {
    const f = async (): Promise<void> => {
      if (logInUser.id !== null) {
        return;
      }

      if (Cookies.get('.ASPXAUTH') === undefined) {
        return;
      }

      const response = (await api.get('/v1/User/LogInInfo')) as ApiResponse;

      type Payload = {
        id: number;
        name: string;
        mail: string;
        avatarUrl: string;
      };
      const payload = response.payload as Payload;

      setLogInUser({
        id: payload.id,
        name: payload.name,
        mail: payload.mail,
        avatarUrl: payload.avatarUrl,
      });

      appInsights.setAuthenticatedUserContext(payload.id.toString(), '', true);
    };
    f();
  }, [api, setLogInUser, logInUser.id]);
};

const useSnackbar = (): void => {
  const openSnackbar = useOpenSnackbar();

  useEffect(() => {
    const snack = Cookies.get('snack');
    if (snack === undefined) {
      return;
    }
    Cookies.remove('snack');

    openSnackbar(snack);
  }, [openSnackbar]);
};

const usePageTransition = (): void => {
  const location = useLocation();
  const prevPath = useRef<string>('');
  const keys = useRef<string[]>([]);

  const resetAvatarMenuState = useResetRecoilState(avatarMenuStateAtom);

  if (location.pathname === prevPath.current) {
    // ページ遷移していない。
    return;
  }
  prevPath.current = location.pathname;

  setTimeout(() => {
    resetAvatarMenuState();
  });

  if (keys.current.includes(location.key)) {
    // ブラウザの戻る/進むによってページ遷移した。
    return;
  }
  keys.current.push(location.key);

  if (location.hash === '') {
    window.scrollTo(0, 0);
  }
};

const useErrorHandler = (): void => {
  const navigate = useNavigate();

  const handler = useCallback(
    (e: ErrorEvent | PromiseRejectionEvent) => {
      if (e.type === 'unhandledrejection') {
        appInsights.trackException({ exception: new Error((e as PromiseRejectionEvent).reason) });
      } else {
        appInsights.trackException({ exception: (e as ErrorEvent).error });
      }

      // 明示的にthrow new Error('XXX');しているもの達はそこでハンドル済みのため、ここではno-opとする。
      if (e.type === 'unhandledrejection') {
        const { message } = (e as PromiseRejectionEvent).reason;
        if (['500', 'notLoggedIn', 'notFound', '403'].includes(message)) {
          e.preventDefault();
          return;
        }
      }
      navigate('/Error');
    },
    [navigate],
  );

  useEffect(() => {
    window.addEventListener('error', handler);
    return (): void => {
      window.removeEventListener('error', handler);
    };
  }, [handler]);

  useEffect(() => {
    window.addEventListener('unhandledrejection', handler);
    return (): void => {
      window.removeEventListener('unhandledrejection', handler);
    };
  }, [handler]);
};

const useApplicationInsights = (): void => {
  const logInUser = useRecoilValue(logInUserAtom);
  const locationRef = useRef<string>('');
  const location = useLocation();

  if (location.pathname !== locationRef.current) {
    appInsights.trackPageView({
      isLoggedIn: logInUser.id !== null,
      refUri: locationRef.current,
    });
    locationRef.current = location.pathname;
  }
};

const useMasterData = (): void => {
  const api = useApi();
  const setProjectDetailsCategories = useSetRecoilState(projectDetailsCategoriesAtom);
  const setProjectDetailsImportances = useSetRecoilState(projectDetailsImportanceAtom);
  const setLanguages = useSetRecoilState(languagesAtom);

  type Payload = {
    projectDetailsCategories: ProjectDetailsCategoriesAtom;
    projectDetailsImportances: ProjectDetailsImportanceAtom;
    languages: LanguagesAtom;
  };

  useEffect(() => {
    const f = async (): Promise<void> => {
      const response = (await api.get('/v1/Masters/List')) as ApiResponse;
      const payload = response.payload as Payload;

      setProjectDetailsCategories(payload.projectDetailsCategories);
      setProjectDetailsImportances(payload.projectDetailsImportances);
      setLanguages(payload.languages);
    };
    f();

    // 初期ロード時に一度だけ取得しておけば十分。
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
