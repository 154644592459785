import { useCallback } from 'react';
import { atom, useSetRecoilState } from 'recoil';
import { useIDFromParam } from '../components/hooks/id-from-param';
import { Language } from '../components/types';
import { ApiResponse, useApi } from '../services/api';

export type ReviewComment = {
  id: number;
  body: string;
  createDateFormatted: string;
  createUserID: number;
  userDeleted: boolean;
  createUserName: string;
  createUserAvatorUrl: string;
  good: number;
  votedToGood: boolean;
  bad: number;
  votedToBad: boolean;
  bookmarked: boolean;
};

export type Review = {
  id: number;
  createUserName: string;
  closed: boolean;
  categoryName: string | null;
  categoryDescription: string;
  importanceID: number | null;
  fileID: number | null;
  fileDeleted: boolean;
  fileName: string | null;
  line: number | null;
  comments: ReviewComment[];
};

export type File = {
  id: number;
  name: string;
  content: string;
  uploadDateFormatted: string;
  deleted: boolean;
};

export type ProjectdetailsAtom = {
  id: number;
  ownerID: number;
  ownerName: string;
  ownerAvatorUrl: string;
  name: string;
  createDateFormatted: string;
  description: string;
  files: File[];
  reviews: Review[];
  languages: Language[];
};

export const projectDetailsAtom = atom({
  key: 'projectDetailsAtom',
  default: null as ProjectdetailsAtom | null,
});

export const useRefreshAsync = (): (() => Promise<ProjectdetailsAtom>) => {
  const api = useApi();
  const setData = useSetRecoilState(projectDetailsAtom);

  const id = useIDFromParam();

  const refreshAsync = useCallback(async (): Promise<ProjectdetailsAtom> => {
    const response = (await api.get(`/v1/Project/Details/${id}`)) as ApiResponse;
    const data = response.payload as ProjectdetailsAtom;
    setData(data);
    return data;
  }, [api, id, setData]);

  if (isNaN(id)) {
    return (): Promise<ProjectdetailsAtom> =>
      new Promise<ProjectdetailsAtom>(() => {
        // no-op
      });
  }

  return refreshAsync;
};
