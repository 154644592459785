import { atom } from 'recoil';

export type ProjectDetailsNewReviewTargetAtom = {
  fileID: number | null;
  line: number | null;
  event: MouseEvent | null;
};

export const projectDetailsNewReviewTargetAtom = atom({
  key: 'projectDetailsNewReviewTargetAtom',
  default: {
    fileID: null,
    line: null,
    event: null,
  } as ProjectDetailsNewReviewTargetAtom,
});
