import I_profile from '../../../Images/icon/user.svg';
import I_profileActive from '../../../Images/icon/user-active.svg';
import I_account from '../../../Images/icon/settings.svg';
import I_accountActive from '../../../Images/icon/settings-active.svg';
import I_follow from '../../../Images/icon/follow.svg';
import I_followActive from '../../../Images/icon/follow-active.svg';
import I_bookmark from '../../../Images/icon/bookmark.svg';
import I_bookmarkActive from '../../../Images/icon/bookmark-active.svg';
import I_message from '../../../Images/icon/message.svg';
import I_messageActive from '../../../Images/icon/message-active.svg';
import I_notice from '../../../Images/icon/mail.svg';
import I_noticeActive from '../../../Images/icon/mail-active.svg';

import { styled } from '@mui/material/styles';
import { Outlet, useLocation } from 'react-router-dom';
import { RLink } from '../../base/link/link';
import { RImage } from '../../base/image/image';
import { classNames } from '../../classNames';
import { useNavigateIfNotLoggedIn } from '../../hooks/navigate-if-not-logged-in';
import { RContainer } from '../../layout/Container';
import { ComponentPropsNormarized } from '../../types';

type MenuItem = {
  label: string;
  urls: string[];
  icon: string;
  iconActive: string;
};

type MenuItems = MenuItem[];

const menuItems: MenuItems = [
  {
    label: 'プロフィール',
    urls: ['/MyPage/Edit'],
    icon: I_profile,
    iconActive: I_profileActive,
  },
  {
    label: 'アカウント',
    urls: ['/MyPage/Account'],
    icon: I_account,
    iconActive: I_accountActive,
  },
  {
    label: 'フォロー',
    urls: ['/MyPage/Follow', '/MyPage/Follower'],
    icon: I_follow,
    iconActive: I_followActive,
  },
  {
    label: 'ブックマーク',
    urls: ['/MyPage/Bookmark'],
    icon: I_bookmark,
    iconActive: I_bookmarkActive,
  },
  {
    label: 'メッセージ',
    urls: ['/MyPage/Message', '/MyPage/MessageDetail'],
    icon: I_message,
    iconActive: I_messageActive,
  },
  {
    label: '通知メール',
    urls: ['/MyPage/NotificationMail'],
    icon: I_notice,
    iconActive: I_noticeActive,
  },
];

type Props = ComponentPropsNormarized<typeof RContainer>;

function Component({ className }: Props): JSX.Element {
  const activeMenuItem = useActiveMenuItem();

  const loggedIn = useNavigateIfNotLoggedIn();
  if (!loggedIn) {
    return <></>;
  }

  return (
    <RContainer className={className}>
      <h1 className={classes.topicPath}>
        マイページ&nbsp;&#47;&nbsp;
        {activeMenuItem.label}
      </h1>

      <div className={classes.splitContent}>
        {/* メニュー */}
        <div>
          <nav className={classes.sideNav}>
            <ul>
              {menuItems.map((menuItem) => (
                <li key={menuItem.label} className={classNames(classes.li, menuItem === activeMenuItem ? classes.activeLi : undefined)}>
                  <RLink
                    kind="GrayOnWhite"
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '100%',
                      height: '100%',
                    }}
                    href={menuItem.urls[0]!}>
                    <RImage
                      src={menuItem === activeMenuItem ? menuItem.iconActive : menuItem.icon}
                      sx={[
                        {
                          marginLeft: '12px',
                          width: 28,
                          height: 28,
                        },
                      ]}
                    />
                    <div className={classNames(classes.iconText, menuItem === activeMenuItem ? classes.activeIconText : undefined)}>{menuItem.label}</div>
                  </RLink>
                </li>
              ))}
            </ul>
          </nav>
        </div>
        {/* コンテンツ */}
        <Outlet />
      </div>
    </RContainer>
  );
}

const useActiveMenuItem = (): MenuItem => {
  const location = useLocation();

  const menuItem = menuItems.find((x) => x.urls.includes(location.pathname.split('/', 3).join('/')));
  if (menuItem === undefined) {
    throw new Error();
  }

  return menuItem;
};

const PREFIX = 'MyPage';
const classes = {
  topicPath: `${PREFIX}-topicPath`,
  splitContent: `${PREFIX}-splitContent`,
  sideNav: `${PREFIX}-sideNav`,
  li: `${PREFIX}-li`,
  activeLi: `${PREFIX}-activeLi`,
  iconText: `${PREFIX}-iconText`,
  activeIconText: `${PREFIX}-activeIconText`,
};

export const MyPage = styled(Component, {
  shouldForwardProp: (prop) => prop !== 'sx',
})({
  [`& .${classes.topicPath}`]: {
    fontSize: 20,
    fontWeight: 700,
    marginBottom: 20,
  },
  [`& .${classes.splitContent}`]: {
    display: 'grid',
    gridGap: '0 20px',
    gridTemplateColumns: '180px 1fr',
  },

  [`& .${classes.sideNav}`]: {
    padding: '3px 0',
    backgroundColor: '#fff',
    borderRadius: 8,
    border: '1px solid #E3E3E3',
  },
  [`& .${classes.li}`]: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: 48,
    [`&:hover`]: {
      backgroundColor: '#f2f2f2',
    },
  },
  [`& .${classes.activeLi}`]: {
    borderRight: '4px solid #3399ff',
  },
  [`& .${classes.iconText}`]: {
    marginLeft: 12,
    lineHeight: '1.8px',
    color: '#757575',
  },
  [`& .${classes.activeIconText}`]: {
    color: '#3399ff',
    fontWeight: 'bold',
  },
});
