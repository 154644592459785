import { styled } from '@mui/material/styles';
import { RRecordGroup } from '../../core/record/record-group/record-group';
import { RRecord } from '../../core/record/record/record';
import { buildActivityDescription, useActivityData } from '../../hooks/activity';
import { ComponentPropsNormarized } from '../../types';

type Props = {
  userID: number;
} & ComponentPropsNormarized<'div'>;

function Component({ userID, className }: Props): JSX.Element {
  const [activityData, onMoreClick] = useActivityData(10, userID);

  return (
    <div className={className}>
      <h3 className={classes.title}>最近の活動</h3>
      <div className={classes.leftWorks}>
        <RRecordGroup>
          {activityData.activities.map((activity) => (
            <RRecord
              key={activity.id}
              hover="None"
              sx={{
                padding: '7px 15px',
              }}>
              <p className={classes.leftDate}>{activity.createDate}</p>
              <p className={classes.leftWorkContent}>{buildActivityDescription(activity, classes.maximum10)}</p>
            </RRecord>
          ))}
          {activityData.hasMore && <RRecord next10 onClick={onMoreClick} />}
        </RRecordGroup>
      </div>
    </div>
  );
}

const PREFIX = 'Activities';
const classes = {
  title: `${PREFIX}-title`,
  leftWorks: `${PREFIX}-leftWorks`,
  leftDate: `${PREFIX}-leftDate`,
  leftWorkContent: `${PREFIX}-leftWorkContent`,
  maximum10: `${PREFIX}-maximum10`,
};

export const Activities = styled(Component)({
  [`& .${classes.title}`]: {
    fontSize: '1.6rem',
    fontWeight: 'bold',
    marginBottom: '8px',
  },
  [`& .${classes.leftWorks}`]: {
    width: '292px',
  },
  [`& .${classes.leftDate}`]: {
    fontSize: '1.2rem',
    color: '#404040',
    marginBottom: '8px',
    height: '15px',
    lineHeight: '15px',
  },
  [`& .${classes.leftWorkContent}`]: {
    lineHeight: '21px',
  },
  [`& .${classes.maximum10}`]: {
    display: '-webkit-inline-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    verticalAlign: 'bottom',
  },
});
