// import I_bell from '../../../Images/icon/bell.png';

import { RAvatar } from '../../core/avatar/avatar';
import { styled } from '@mui/material/styles';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { logInUserAtom } from '../../../atoms/log-in-user';
import { avatarMenuStateAtom } from '../../../atoms/avatar-menu-state';
import { useCallback, MouseEvent } from 'react';
import { ComponentPropsNormarized } from '../../types';

type Props = ComponentPropsNormarized<'ul'>;

function Component({ className }: Props): JSX.Element {
  const onAvatarClick = useOnAvatarClick();
  const logInUser = useRecoilValue(logInUserAtom);

  return (
    <ul className={className}>
      {/* <li className={classes.bell}>
        <RLink href="#">
          <RImage src={I_bell} />
        </RLink>
      </li> */}
      <li className={classes.avatar} onClick={onAvatarClick}>
        <RAvatar size="Header" src={logInUser.avatarUrl as string} userID={logInUser.id ?? undefined} />
      </li>
    </ul>
  );
}

const useOnAvatarClick = (): ((e: MouseEvent<HTMLElement>) => void) => {
  const setAavatarMenuState = useSetRecoilState(avatarMenuStateAtom);
  const onAvatarClick = useCallback(
    (e: MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      setAavatarMenuState((state) => !state);
    },
    [setAavatarMenuState],
  );
  return onAvatarClick;
};

const PREFIX = 'LoggedInIcons';
const classes = {
  bell: `${PREFIX}-bell`,
  bellImage: `${PREFIX}-bellImage`,
  avatar: `${PREFIX}-avatar`,
};

export const LoggedInIcons = styled(Component, {
  shouldForwardProp: (prop) => prop !== 'sx',
})({
  display: 'flex',
  marginLeft: 'auto',

  [`& .${classes.bell}`]: {
    marginTop: '10px',
    width: '48px',
    height: '48px',
    borderRadius: '100%',
    textAlign: 'center',
    paddingTop: '6px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#F2F2F2',
    },
  },

  [`& .${classes.bellImage}`]: {
    marginTop: '4px',
  },

  [`& .${classes.avatar}`]: {
    marginTop: '8px',
    marginLeft: '28px',
  },
});
