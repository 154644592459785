import { atom } from 'recoil';

export type LogInUserAtom = {
  id: number | null;
  name: string | null;
  mail: string | null;
  avatarUrl: string | null;
};

export const logInUserAtom = atom({
  key: 'logInUserAtom',
  default: {
    id: null,
    name: null,
    mail: null,
    avatarUrl: null,
  } as LogInUserAtom,
});
