import { useEffect } from 'react';

export const useOnEsc = (f: () => void): void => {
  useEffect((): (() => void) => {
    const onEsc = (event: KeyboardEvent): void => {
      if (event.key !== 'Escape') {
        return;
      }

      f();

      document.body.removeEventListener('keydown', onEsc);
    };

    document.body.addEventListener('keydown', onEsc);

    return (): void => {
      document.body.removeEventListener('keydown', onEsc);
    };
  }, [f]);
};
