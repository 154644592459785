import { styled } from '@mui/material/styles';
import { ReactNode } from 'react';
import { ComponentPropsNormarized } from '../../../types';

type Props = {
  children: ReactNode;
} & ComponentPropsNormarized<'div'>;

function Component({ children, className, ...others }: Props): JSX.Element {
  return (
    <div className={className} {...others}>
      {children}
    </div>
  );
}

export const RRecordGroup = styled(Component, {
  shouldForwardProp: (prop) => prop !== 'sx',
})({});
