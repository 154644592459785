import './reset.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Header } from './components/layout/header/header';
import { Footer } from './components/layout/footer/footer';
import { Error404 } from './components/screens/error404/error404';
import { Error500 } from './components/screens/error500/error500';
import { LogIn } from './components/screens/log-in/log-in';
import { Top } from './components/screens/top/top';
import { Profile } from './components/screens/profile/profile';
import { Main } from './components/screens/profile/main/main';
import { RReport } from './components/core/report/report';
import { RManager } from './components/core/manager/manager';
import { RLogInSuggestion } from './components/core/log-in-suggestion/log-in-suggestion';
import { Projects } from './components/screens/profile/project/projects';
import { CreateUser } from './components/screens/create-user/create-user';
import { CreatedUser } from './components/screens/created-user/created-user';
import { LogInNotConfirmed } from './components/screens/log-in-not-confirmed/log-in-not-confirmed';
import { PasswordResetStart } from './components/screens/password-reset-start/password-reset-start';
import { PasswordResetRequested } from './components/screens/password-reset-requested/password-reset-requested';
import { PasswordResetReset } from './components/screens/password-reset-reset/password-reset-reset';
import { ProjectDetails } from './components/screens/project-details/project-details';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import { reportAtom } from './atoms/report';
import { logInSuggestionAtom } from './atoms/log-in-suggestion';
import { logInUserAtom } from './atoms/log-in-user';
import { MyPage } from './components/screens/my-page/my-page';
import { Edit } from './components/screens/my-page/edit/edit';
import { Account } from './components/screens/my-page/account/account';
import { Bookmark } from './components/screens/my-page/bookmark/bookmark';
import { Message } from './components/screens/my-page/message/message';
import { NotificationMail } from './components/screens/my-page/notification-mail/notification-mail';
import { Follow } from './components/screens/my-page/follow/follow';
import { RSnackbar } from './components/core/snackbar/snackbar';
import { MessageDetail } from './components/screens/my-page/message-detail/message-detail';
import { UserSearchList } from './components/screens/user-search-list/user-search-list';
import { LegalTokusho } from './components/screens/legal-tokusho/legal-tokusho';
import { LegalTermsOfUse } from './components/screens/legal-terms-of-use/legal-terms-of-use';
import { LegalPrivacyPolicy } from './components/screens/legal-privacy-policy/legal-privacy-policy';
import { styled } from '@mui/material';
import { ComponentPropsNormarized } from './components/types';
import { Column } from './components/screens/column/column';

type Props = ComponentPropsNormarized<'div'>;

function Component({ className }: Props): JSX.Element {
  const logInUser = useRecoilValue(logInUserAtom);
  const logInSuggestion = useRecoilValue(logInSuggestionAtom);
  const resetLogInSuggestion = useResetRecoilState(logInSuggestionAtom);

  const report = useRecoilValue(reportAtom);

  return (
    <div className={className}>
      <BrowserRouter>
        <RManager />
        <Header />
        <RSnackbar />
        <Routes>
          <Route path="/" element={<Top />} />
          <Route path="/Home/Index" element={<Top />} />
          <Route path="/User/Create" element={<CreateUser />} />
          <Route path="/User/Created" element={<CreatedUser />} />
          <Route path="/User/LogIn" element={<LogIn />} />
          <Route path="/User/LogInNotConfirmed" element={<LogInNotConfirmed />} />
          <Route path="/PasswordReset/Start" element={<PasswordResetStart />} />
          <Route path="/PasswordReset/Requested" element={<PasswordResetRequested />} />
          <Route path="/PasswordReset/Reset" element={<PasswordResetReset />} />
          <Route path="/Project/Details/:id" element={<ProjectDetails />} />
          <Route path="/UserSearch/List/" element={<UserSearchList />} />
          <Route path="/Profile" element={<Profile />}>
            <Route path="Main/:id" element={<Main />} />
            <Route path="Project/:id" element={<Projects />} />
          </Route>
          <Route path="/MyPage" element={<MyPage />}>
            <Route path="Edit" element={<Edit />} />
            <Route path="Account" element={<Account />} />
            <Route path="Follow" element={<Follow />} />
            <Route path="Follower" element={<Follow />} />
            <Route path="Bookmark" element={<Bookmark />} />
            <Route path="Message" element={<Message />} />
            <Route path="MessageDetail/:id" element={<MessageDetail />} />
            <Route path="NotificationMail" element={<NotificationMail />} />
          </Route>
          <Route path="/Legal/TermsOfUse" element={<LegalTermsOfUse />} />
          <Route path="/Legal/PrivacyPolicy" element={<LegalPrivacyPolicy />} />
          <Route path="/Legal/Tokusho" element={<LegalTokusho />} />
          <Route path="/Column" element={<Column />} />

          <Route path="/Error" element={<Error500 />} />
          <Route path="*" element={<Error404 />} />
        </Routes>
        <Footer />
        {report.visible && <RReport />}
        {logInUser.id === null && logInSuggestion.visible && <RLogInSuggestion close={resetLogInSuggestion} />}
      </BrowserRouter>
    </div>
  );
}

const App = styled(Component)({
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
});

export default App;
