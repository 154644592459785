import { styled } from '@mui/material/styles';
import { useState } from 'react';
import { RButton } from '../../../base/button/button';
import { useTitle } from '../../../hooks/title';
import { ComponentPropsNormarized } from '../../../types';
import { Circle } from '../circle';
import { ConfirmDelete } from './confirm-delete';

type Props = ComponentPropsNormarized<'div'>;

function Component({ className }: Props): JSX.Element {
  useTitle('アカウント');

  const [showConfirm, setShowConfirm] = useState(false);

  return (
    <div className={className}>
      <h2>
        <Circle color="#FC5F5F" />
        <span>アカウント削除</span>
      </h2>
      <p className={classes.description}>アカウントを完全に削除します。</p>
      <div className={classes.notice}>
        <ul>
          <li>※自分で作成したプロジェクトなどのデータは削除されます。</li>
          <li>※他のユーザーへのコメント・評価などは削除されません。</li>
          {/* <li>※未出金の売上がある場合、出金できなくなります。出金申請済みの場合でも出金されません。</li> */}
        </ul>
      </div>

      <RButton kind="Danger" onClick={(): void => setShowConfirm(true)} width={144}>
        アカウントを削除
      </RButton>
      {showConfirm && <ConfirmDelete close={(): void => setShowConfirm(false)} />}
    </div>
  );
}

const PREFIX = 'Delete';
const classes = {
  description: `${PREFIX}-description`,
  notice: `${PREFIX}-notice`,
};

export const Delete = styled(Component, {
  shouldForwardProp: (prop) => prop !== 'sx',
})({
  marginTop: 40,

  [`& .${classes.description}`]: {
    marginTop: 8,
  },
  [`& .${classes.notice}`]: {
    marginTop: 8,
    marginBottom: 12,

    [`& li`]: {
      marginTop: 4,
      fontSize: 12,
      lineHeight: '15px',
      color: '#757575',
    },
  },
});
