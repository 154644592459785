import { LoggedInIcons } from './logged-in-icons';
import { AvatarMenu } from './avatar-menu';
import { useRecoilValue } from 'recoil';
import { avatarMenuStateAtom } from '../../../atoms/avatar-menu-state';

export function LoggedIn(): JSX.Element {
  const avatarMenuState = useRecoilValue(avatarMenuStateAtom);

  return (
    <>
      <LoggedInIcons />
      {avatarMenuState && <AvatarMenu />}
    </>
  );
}
