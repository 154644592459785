import { MouseEventHandler, useCallback } from 'react';
import { useSetRecoilState } from 'recoil';
import { reportCategory, reportAtom } from '../../atoms/report';
import { useLogInSuggestion } from './log-in-suggestion';

export const useReportDialog = (category: keyof typeof reportCategory, targetID: number): MouseEventHandler<HTMLElement> => {
  const setReport = useSetRecoilState(reportAtom);
  const showReportDialog = useCallback(() => {
    setReport({ visible: true, category: category, targetID: targetID });
  }, [setReport, category, targetID]);

  const onClick = useLogInSuggestion('ログインしてから通報しましょう。', showReportDialog);

  return onClick;
};
