import I_close from '../../../../Images/navigation/close_black.png';
import 'croppie/croppie.css';

import { styled } from '@mui/material/styles';
import Croppie from 'croppie';
import { MouseEventHandler, RefObject, useCallback, useEffect, useRef } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { logInUserAtom } from '../../../../atoms/log-in-user';
import { RModal } from '../../../core/modal/modal';
import { RImage } from '../../../base/image/image';
import { RButton } from '../../../base/button/button';
import { ComponentPropsNormarized, ValidationErrors } from '../../../types';
import { RValidationMessages } from '../../../core/validation-message/validation-messages';
import { useSave } from '../../../hooks/save';
import { ApiResponse } from '../../../../services/api';

type Props = {
  file: File;
  close: () => void;
} & ComponentPropsNormarized<typeof RModal>;

function Component({ file, close, className }: Props): JSX.Element {
  const [croppieElementRef, save, validationErrors] = useCroppie(file, close);

  const logInUser = useRecoilValue(logInUserAtom);
  if (logInUser.id === null || logInUser.avatarUrl === null) {
    return <></>;
  }

  return (
    <RModal close={close} className={className}>
      <section className={classes.wrapper}>
        <div className={classes.title}>
          <h2>プロフィール画像を編集</h2>
          <p>
            <RImage
              src={I_close}
              sx={{
                cursor: 'pointer',
              }}
              onClick={close}
            />
          </p>
        </div>

        {/* 画像部分とスライダー部分 */}
        <div ref={croppieElementRef} />
        <RValidationMessages messages={validationErrors.File} />

        <ul className={classes.buttons}>
          <li>
            <RButton kind="CancelNoFrame" onClick={close}>
              キャンセル
            </RButton>
          </li>
          <li className={classes.saveButton}>
            <RButton onClick={save} width={186}>
              プロフィール画像に設定
            </RButton>
          </li>
        </ul>
      </section>
    </RModal>
  );
}

const useCroppie = (file: File, close: () => void): [RefObject<HTMLDivElement>, MouseEventHandler<HTMLButtonElement>, ValidationErrors] => {
  const croppieElementRef = useRef<HTMLDivElement>(null);
  const croppieRef = useRef<Croppie | null>(null);

  const [doSave, validationErrors, clearValidationErrors] = useSave('/v1/MyPage/UpdateAvatar', 'プロフィール画像を変更しました。');

  const [logInUser, setLogInUser] = useRecoilState(logInUserAtom);

  useEffect(() => {
    if (croppieRef.current !== null) {
      return;
    }
    if (croppieElementRef.current === null) {
      return;
    }

    croppieRef.current = new Croppie(croppieElementRef.current, {
      viewport: {
        width: 140,
        height: 140,
        type: 'circle',
      },
      boundary: {
        width: 400,
        height: 400,
      },
    });
  }, [croppieElementRef]);

  useEffect(() => {
    const reader = new FileReader();
    reader.onload = (event): void => {
      if (croppieRef.current === null) {
        return;
      }

      croppieRef.current
        .bind({
          url: (event.target as FileReader).result as string,
        })
        .then(() => {
          if (croppieRef.current === null) {
            return;
          }

          const slider = document.querySelector('.cr-slider');
          if (slider !== null) {
            slider.setAttribute('min', '0.25');
            slider.setAttribute('max', '4');
          }
          croppieRef.current.setZoom(1);
        });
    };
    reader.readAsDataURL(file);
  }, [file]);

  const save: MouseEventHandler<HTMLButtonElement> = useCallback(
    async (e) => {
      if (croppieRef.current === null) {
        return;
      }

      const f = await croppieRef.current.result({ type: 'blob' });

      const params = new FormData();
      params.append('file', f);

      clearValidationErrors();
      const response = (await doSave(e, params, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })) as ApiResponse;

      if (response.errorCode !== null) {
        return;
      }

      type Payload = { avatarUrl: string };
      const payload = response.payload as Payload;

      // キャッシュをクリア。
      await fetch(payload.avatarUrl, { cache: 'reload' });

      // 画像設定済みだった場合は同じURLのままで返される画像が変わるため、リロードのためにいったんnullにする。
      setLogInUser({ ...logInUser, avatarUrl: null });
      setTimeout(() => {
        setLogInUser({ ...logInUser, avatarUrl: payload.avatarUrl });
      });

      close();
    },
    [clearValidationErrors, close, doSave, logInUser, setLogInUser],
  );

  return [croppieElementRef, save, validationErrors];
};

const PREFIX = 'AvatarEditorDialog';
const classes = {
  wrapper: `${PREFIX}-wrapper`,
  title: `${PREFIX}-title`,
  buttons: `${PREFIX}-buttons`,
  saveButton: `${PREFIX}-saveButton`,
};

export const AvatarEditorDialog = styled(Component, {
  shouldForwardProp: (prop) => prop !== 'sx',
})({
  [`& .${classes.wrapper}`]: {
    position: 'relative',
    padding: 24,
    width: 480,
    minHeight: 600,
  },
  [`& .${classes.title}`]: {
    marginBottom: 24,
    display: 'flex',
    justifyContent: 'space-between',

    [`& h2`]: {
      fontSize: '2rem',
      lineHeight: '1.5',
      fontWeight: 500,
      color: '#000',
    },
  },

  [`& .croppie-container`]: {
    width: 'initial',
    height: 'initial',

    [`& .cr-slider`]: {
      width: '100%',
      border: 'none',
    },
  },

  [`& .${classes.buttons}`]: {
    marginTop: 24,

    display: 'flex',
    justifyContent: 'flex-end',
  },
  [`& .${classes.saveButton}`]: {
    marginLeft: 12,
  },
});
