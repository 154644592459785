import I_500 from '../../../Images/error/error.png';
import I_blank from '../../../Images/navigation/blank.png';

import { styled } from '@mui/material/styles';
import { RLink } from '../../base/link/link';
import { RImage } from '../../base/image/image';
import { useTitle } from '../../hooks/title';
import { RContainer } from '../../layout/Container';
import { ComponentPropsNormarized } from '../../types';

type Props = ComponentPropsNormarized<typeof RContainer>;

function Component({ className }: Props): JSX.Element {
  useTitle('');

  return (
    <RContainer className={className} innerStyle={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <h1 className={classes.h1}>エラーが発生しました</h1>
      <p>
        <RImage src={I_500} width="191" height="198" />
      </p>
      <p className={classes.text}>
        申し訳ありません。何らかの理由により、処理に失敗しました。
        <br />
        再試行の前に、システムトラブルの可能性がないか、
        <RLink href="https://twitter.com/bettercode1" target="_blank" rel="noreferrer">
          better-codeからのお知らせ
          <RImage src={I_blank} />
        </RLink>
        をご確認ください。
      </p>
    </RContainer>
  );
}

const PREFIX = 'Error500';
const classes = {
  h1: `${PREFIX}-h1`,
  text: `${PREFIX}-text`,
};

export const Error500 = styled(Component, {
  shouldForwardProp: (prop) => prop !== 'sx',
})({
  [`& .${classes.h1}`]: {
    fontSize: '34px',
    lineHeight: '51px',
    color: '#3399ff',
    letterSpacing: '0.25px',
    marginBottom: '24px',
  },

  [`& .${classes.text}`]: {
    display: 'inline-block',
    textAlign: 'left',
    margin: '40px 0 0 0',
    lineHeight: '21px',
  },
});
