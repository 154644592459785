import { RefObject, useRef, useEffect } from 'react';

export const useSetFocus = <T extends HTMLElement>(): RefObject<T> => {
  const focusTarget = useRef<T>(null);

  useEffect(() => {
    if (focusTarget.current === null) {
      return;
    }
    focusTarget.current.focus();
  }, []);

  return focusTarget;
};
