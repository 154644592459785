import { styled } from '@mui/material/styles';
import { useTitle } from '../../hooks/title';
import { RLink } from '../../base/link/link';
import React, { ReactNode } from 'react';
import { RRecord } from '../../core/record/record/record';
import { RContainer } from '../../layout/Container';
import { ComponentPropsNormarized } from '../../types';

type ListItem = {
  dt: string;
  dd: ReactNode;
};

const list: ListItem[] = [
  {
    dt: '事業者名',
    dd: <>氏名・住所・電話番号はページ下部のお問い合わせフォームからご請求ください。</>,
  },
  {
    dt: 'メールアドレス',
    dd: (
      <>
        better.code.system@gmail.com <br />
        ※システムからの自動送信用です。 <br />
        お問い合わせは、ページ下部のお問い合わせフォームをご利用ください。
      </>
    ),
  },
  {
    dt: '販売価格（役務の対価）',
    dd: (
      <>
        レビュアーとレビュイーとで締結する契約の価格の20%を申し受けます。 <br />
        当サービスに対して100%をお支払いいただきますが、当サービスが申し受ける上記の金額を差し引いた額は当サービスからレビュアーに支払われます。
      </>
    ),
  },
  {
    dt: '代金の支払い',
    dd: (
      <>
        本サービス上でレビュアーとレビュイーとの契約を締結する際にお支払いいただきます。 <br />
        クレジットカードなど本サービスで受け付け可能な方法によってお支払いいただきます。
      </>
    ),
  },
  {
    dt: '商品の引渡時期',
    dd: <>本サービス上でレビュアーとレビュイーとの契約が締結された際に紹介が完了となります。</>,
  },
  {
    dt: 'キャンセル',
    dd: <>紹介完了後はキャンセルできません。</>,
  },
  {
    dt: '契約内容の履行',
    dd: <>紹介後、レビュアーからレビュイーに対して役務が提供されない場合でも、本サービスでは責任を負いません。</>,
  },
  {
    dt: '継続的な契約',
    dd: <>レビュアーとレビュイーとで継続的に契約を締結する場合は、その都度手数料を申し受けます。</>,
  },
];

type Props = ComponentPropsNormarized<typeof RContainer>;

function Component({ className }: Props): JSX.Element {
  useTitle('特定商取引法に基づく表記');

  return (
    <RContainer className={className}>
      <RRecord
        hover="None"
        sx={{
          padding: '28px 40px',
        }}>
        <h2 className={classes.title}>特定商取引法に基づく表記</h2>

        <p className={classes.description}>
          本サービスでレビューを受けることを希望する方(以下「レビュイー」という)に対して、本サービスがレビューする方(以下「レビュアー」という)を紹介する際に、本サービスが申し受ける手数料については以下の通りです。
        </p>

        <dl className={classes.dl}>
          {list.map((item) => (
            <React.Fragment key={item.dt}>
              <dt className={classes.dt}>{item.dt}</dt>
              <dd className={classes.dd}>{item.dd}</dd>
            </React.Fragment>
          ))}
        </dl>

        <p className={classes.footer}>
          特定商取引法に定められた記載事項のうち本画面に記載のないものについては、下記から請求していただければ遅滞なく提供します。 <br />
          その際、手数料として1万円(税別)を申し受けます。
        </p>

        <h3 className={classes.formTitle}>お問い合わせフォーム</h3>
        <p className={classes.formURL}>
          <RLink href="https://docs.google.com/forms/d/e/1FAIpQLSftphkg3eHG7fuDbxgqqyNVE5qht1ELuLzQCzSJDj2wYBTgbA/viewform" target="_blank" rel="noreferrer">
            https://docs.google.com/forms/d/e/1FAIpQLSftphkg3eHG7fuDbxgqqyNVE5qht1ELuLzQCzSJDj2wYBTgbA/viewform
          </RLink>
        </p>
      </RRecord>
    </RContainer>
  );
}

const PREFIX = 'LegalTokusho';
const classes = {
  title: `${PREFIX}-title`,
  description: `${PREFIX}-description`,
  dl: `${PREFIX}-dl`,
  dt: `${PREFIX}-dt`,
  dd: `${PREFIX}-dd`,
  footer: `${PREFIX}-footer`,
  formTitle: `${PREFIX}-formTitle`,
  formURL: `${PREFIX}-formURL`,
};

export const LegalTokusho = styled(Component, {
  shouldForwardProp: (prop) => prop !== 'sx',
})({
  [`& .${classes.title}`]: {
    fontSize: '2rem',
    lineHeight: '3rem',
    fontWeight: 500,
  },
  [`& .${classes.description}`]: {
    marginTop: 32,
  },
  [`& .${classes.dl}`]: {
    marginTop: 32,
    display: 'flex',
    flexWrap: 'wrap',
  },
  [`& .${classes.dt}`]: {
    flex: '0 0 22.3%',
    padding: '28px 40px 28px 0',
    borderBottom: 'solid 1px #E3E3E3',
  },
  [`& .${classes.dd}`]: {
    flex: '0 0 77.7%',
    padding: '28px 0 28px 20px',
    borderBottom: 'solid 1px #E3E3E3',
  },
  [`& .${classes.footer}`]: {
    marginTop: 52,
  },
  [`& .${classes.formTitle}`]: {
    marginTop: 32,
    fontWeight: 'bold',
    fontSize: '1.6rem',
    lineHeight: '100%',
  },
  [`& .${classes.formURL}`]: {
    marginTop: 8,
  },
});
