import { css, styled } from '@mui/material/styles';
import { ProjectStatusValues, ProjectStatus, ComponentPropsNormarized } from '../../types';
import { ComponentProps } from 'react';
import { SerializedStyles } from '@emotion/react';

type Props = {
  status: ProjectStatusValues;
  style?: ComponentProps<'p'>['style'];
} & ComponentPropsNormarized<'p'>;

function Component({ status, style, className }: Props): JSX.Element {
  let text = '';

  switch (status) {
    case ProjectStatus.open:
      text = '全員にレビュー依頼';
      break;
    case ProjectStatus.onlyPartner:
      text = '契約者に依頼';
      break;
    case ProjectStatus.nobody:
      text = 'プロジェクト終了';
      break;
    default:
      throw new Error('unknown status.');
  }

  return (
    <p className={className} style={style}>
      {text}
    </p>
  );
}

const getCSS = ({ status }: Props): SerializedStyles => {
  const base: SerializedStyles = css({
    padding: '3px 15px',
    border: '1px solid #37BE91',
    color: '#37BE91',
    fontSize: 10,
    opacity: '0.87',
    borderRadius: '100px',
    textAlign: 'center',
    marginRight: '12px',
    lineHeight: 'calc(20px - 4px * 2)',
  });

  const onlyPartner: SerializedStyles = css({
    border: '1px solid #FCA903',
    color: '#FCA903',
  });
  const nobody: SerializedStyles = css({
    border: '1px solid #8A8A8A',
    color: '#8A8A8A',
  });

  return css([base, status === ProjectStatus.onlyPartner && onlyPartner, status === ProjectStatus.nobody && nobody]);
};

export const RProjectStatus = styled(Component, {
  shouldForwardProp: (prop) => prop !== 'sx',
})(getCSS);
