import { ComponentProps, CSSProperties, JSXElementConstructor } from 'react';

// styleではなくsxで指定させる。
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ComponentPropsNormarized<T extends keyof JSX.IntrinsicElements | JSXElementConstructor<any>> = Omit<ComponentProps<T>, 'style'>;

/**
 * styleプロパティに渡すためのスタイル一覧。
 */
export type Styles<T extends string> = { [k in T]: CSSProperties };

export type ValidationErrors = { [index: string]: string[] };

export const projectTypes = {
  all: 0x0000,
  mine: 0x0001,
  commented: 0x0002,
} as const;

export const ProjectStatus = {
  open: 1,
  onlyPartner: 2,
  nobody: 3,
} as const;

export type ProjectStatusValues = (typeof ProjectStatus)[keyof typeof ProjectStatus];

export type Language = {
  id: number;
  name: string;
};

export type Project = {
  id: number;
  name: string;
  description: string;
  creatorID: number;
  creatorName: string;
  createUserAvatorUrl: string;
  createDateFormatted: string;
  fileCount: number;
  commentCount: number;
  resolvedCommentCount: number;
  status: ProjectStatusValues;
  languages: Language[];
};

export type Payload = {
  projects: Project[];
  hasMore: boolean;
};
