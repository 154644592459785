import { styled } from '@mui/material/styles';
import { useCallback, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { ApiResponse, useApi } from '../../../../services/api';
import { RButton } from '../../../base/button/button';
import { RLink } from '../../../base/link/link';
import { RRecord } from '../../../core/record/record/record';
import { MenuItems, RTabBar } from '../../../core/tab-bar/tab-bar';
import { useData } from '../../../hooks/data';
import { useTitle } from '../../../hooks/title';
import { Users, RUserRecords } from '../../../core/record/user-records/user-records';
import { ComponentPropsNormarized } from '../../../types';

const menuItems: MenuItems = [
  {
    key: 'following',
    label: 'フォロー中',
    url: '/MyPage/Follow',
  },
  {
    key: 'follower',
    label: 'フォロワー',
    url: '/MyPage/Follower',
  },
];

type Props = ComponentPropsNormarized<'div'>;

function Component({ className }: Props): JSX.Element {
  useTitle('フォロー');

  const location = useLocation();

  const [data, onMoreClick] = useFollowData();
  if (data === undefined) {
    return <></>;
  }

  return (
    <div className={className}>
      <RTabBar menuItems={menuItems} idIsOptional />

      {data.userRecords.length > 0 ? (
        <RUserRecords
          data={data}
          onMoreClick={onMoreClick}
          showFollowStatus={location.pathname === '/MyPage/Follow'}
          enableFollow={true}
          sx={{
            marginTop: '24px',
          }}
        />
      ) : location.pathname === '/MyPage/Follow' ? (
        <RRecord hover="None" sx={noneCSS}>
          <h2 className={classes.noneTitle}>まだ誰もフォローしていません</h2>
          <p className={classes.noneDescription}>レビューをお願いしたい人や、提案したい人をフォローしてみませんか？</p>
          <RLink kind="GrayOnWhite" href="/UserSearch/List" sx={noneLinkCSS}>
            <RButton width={172}>ユーザーを探しに行く</RButton>
          </RLink>
        </RRecord>
      ) : (
        <RRecord hover="None" sx={noneCSS}>
          <h2 className={classes.noneTitle}>まだフォロワーはいません</h2>
          <p className={classes.noneDescription}>プロフィールを詳しく書くと、他の人に興味を持ってもらえるかもしれません。</p>
          <RLink kind="GrayOnWhite" href="/MyPage/Edit" sx={noneLinkCSS}>
            <RButton width={186}>プロフィールを編集する</RButton>
          </RLink>
        </RRecord>
      )}
    </div>
  );
}

const useFollowData = (): [Users | undefined, () => Promise<void>] => {
  const location = useLocation();
  const api = useApi();

  // タブ切り替え時のみリロードするため、refオブジェクトを使い回す。
  const params = useRef({ follow: location.pathname === '/MyPage/Follow', resultShowCount: 10 });
  if (params.current.follow && location.pathname !== '/MyPage/Follow') {
    params.current = { follow: false, resultShowCount: 10 };
  }
  if (!params.current.follow && location.pathname === '/MyPage/Follow') {
    params.current = { follow: true, resultShowCount: 10 };
  }
  const [data, setData] = useData<Users>('/v1/MyPage/FollowListSearch', params.current);

  const onMoreClick = useCallback(async () => {
    const response = (await api.get('/v1/MyPage/FollowListSearch', {
      params: { ...params.current, resultShowCount: data!.userRecords.length + 10 },
    })) as ApiResponse;

    setData(response.payload as Users);
  }, [api, data, setData]);

  return [data, onMoreClick];
};

const PREFIX = 'Follow';
const classes = {
  noneTitle: `${PREFIX}-noneTitle`,
  noneDescription: `${PREFIX}-noneDescription`,
};

const noneCSS = {
  marginTop: '24px',
  padding: '55px 151px',

  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

const noneLinkCSS = {
  marginTop: '24px',
};

export const Follow = styled(Component, {
  shouldForwardProp: (prop) => prop !== 'sx',
})({
  [`& .${classes.noneTitle}`]: {
    fontWeight: 'bold',
    fontSize: 16,
    lineHeight: '21px',
  },
  [`& .${classes.noneDescription}`]: {
    marginTop: 12,
    lineHeight: '21px',
    color: '#757575',
  },
});
