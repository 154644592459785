import { styled } from '@mui/material/styles';
import { ComponentPropsNormarized } from '../../types';

type Props = Omit<ComponentPropsNormarized<'img'>, 'alt'>;

function Component({ className, ...others }: Props): JSX.Element {
  return <img alt="" {...others} className={className} />;
}

export const RImage = styled(Component, {
  shouldForwardProp: (prop) => prop !== 'sx',
})({});
