import { styled } from '@mui/material/styles';
import { ComponentPropsWithoutRef, MutableRefObject, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { zIndex } from '../../../services/z-index';
import { classNames } from '../../classNames';
import { useElementID } from '../../hooks/element-id';
import { useOnEsc } from '../../hooks/on-esc';
import { ComponentPropsNormarized } from '../../types';

type Props = {
  close: () => void;
  style?: ComponentPropsWithoutRef<'div'>['style'];
} & ComponentPropsNormarized<'div'>;

function Component({ close, style, children, className, ...others }: Props): JSX.Element {
  const ref = useRef<HTMLDivElement | null>(null);
  const initialized = useMoveToCenter(ref);
  useCloseEventListener(ref, close);
  const id = useElementID();

  const classNameList = [classes.base];

  if (!initialized) {
    classNameList.push(classes.initializing);
  }

  return (
    <div className={className} {...others}>
      <div id={id} ref={ref} className={classNames(...classNameList)} style={style}>
        {children}
      </div>
    </div>
  );
}

const useMoveToCenter = (ref: MutableRefObject<HTMLDivElement | null>): boolean => {
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (ref.current !== null) {
      const width = ref.current.clientWidth;
      ref.current.style.left = `calc(50% - ${width}px/2)`;

      const height = ref.current.clientHeight;
      ref.current.style.top = `calc(50% - ${height}px/2)`;

      setInitialized(true);
    }
  }, [ref]);

  return initialized;
};

const useCloseEventListener = (ref: MutableRefObject<HTMLDivElement | null>, close: () => void): void => {
  useOnEsc(close);

  // エラー発生時にクローズ。
  const location = useLocation();
  useEffect(() => {
    if (location.pathname === '/Error') {
      close();
    }
  }, [location.pathname, close]);

  // 画面遷移時にクローズ。
  const locationRef = useRef<string>(location.pathname);
  useEffect(() => {
    if (location.pathname !== locationRef.current) {
      close();
    }
    locationRef.current = location.pathname;
  }, [location.pathname, close]);
};

const PREFIX = 'RModal';
const classes = {
  base: `${PREFIX}-base`,
  initializing: `${PREFIX}-initializing`,
};

export const RModal = styled(Component, {
  // ルート要素は背景だが、外からスタイルを与えたいのはそこでは無く表示するポップアップ部分なので、sxで受け取ってルート要素に適用しても意味が無い。
  // styleで渡してもらう。
  skipSx: true,
})({
  position: 'fixed',
  top: '0px',
  left: '0px',
  width: '100%',
  height: '100vh',
  backgroundColor: 'rgba(87, 87, 87, 0.7)',
  zIndex: zIndex.modalBackGround,
  cursor: 'initial',

  [`& .${classes.base}`]: {
    backgroundColor: '#fff',
    borderRadius: '10px',
    position: 'absolute',
    boxShadow: '0px 15px 12px rgb(56 56 56 / 22%), 0px 19px 38px rgb(56 56 56 / 30%)',
    color: '#404040',
  },

  [`& .${classes.initializing}`]: {
    opacity: 0,
  },
});
