import { atom } from 'recoil';

export type LanguagesAtom = {
  id: number;
  name: string;
}[];

export const languagesAtom = atom({
  key: 'languagesAtom',
  default: [] as LanguagesAtom,
});
