import { styled } from '@mui/material/styles';
import { RCheckbox } from '../../base/checkbox/checkbox';
import { CommentsDescription } from './comments-description';
import { FreeComment } from './free-comment';
import { getReviewsToShow } from './review-util';
import { ReviewBox } from './review-box';
import { useRecoilState, useRecoilValue } from 'recoil';
import { logInUserAtom } from '../../../atoms/log-in-user';
import { projectDetailsShowClosedReviewAtom } from '../../../atoms/project-details-show-closed-review';
import { ProjectdetailsAtom } from '../../../atoms/project-details';
import { ComponentPropsNormarized } from '../../types';

type Props = {
  projectData: ProjectdetailsAtom;
} & ComponentPropsNormarized<'div'>;

function Component({ projectData, className }: Props): JSX.Element {
  const logInUser = useRecoilValue(logInUserAtom);

  const { id, ownerID, reviews } = projectData;
  const [shouldShowClosedReview, setShouldShowClosedReview] = useRecoilState(projectDetailsShowClosedReviewAtom);

  return (
    <div className={className}>
      <div className={classes.header}>
        <h3 className={classes.headerTitle}>コメント</h3>
        <RCheckbox checked={shouldShowClosedReview} setChecked={setShouldShowClosedReview}>
          解決したコメントを表示
        </RCheckbox>
      </div>

      <div className={classes.commentScroll}>
        {logInUser.id !== null && <FreeComment projectID={id} />}
        <CommentsDescription ownerID={ownerID} reviews={reviews} shouldShowClosedReview={shouldShowClosedReview} />

        {getReviewsToShow(reviews, shouldShowClosedReview).map((review) => (
          <ReviewBox key={review.id} projectID={id} projectOwnerID={ownerID} review={review} />
        ))}
      </div>
    </div>
  );
}

const PREFIX = 'Reviews';
const classes = {
  header: `${PREFIX}-header`,
  headerTitle: `${PREFIX}-headerTitle`,
  commentScroll: `${PREFIX}-commentScroll`,
};

export const Reviews = styled(Component)({
  width: 450,
  marginTop: 24,
  marginLeft: 10,

  [`& .${classes.header}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: '1px solid #e3e3e3',
    paddingBottom: 8,
  },
  [`& .${classes.headerTitle}`]: {
    fontSize: '1.6rem',
    fontWeight: 'bold',
    lineHeight: 1,
    color: '#757575',
  },
  [`& .${classes.commentScroll}`]: {
    maxHeight: 1024,
    overflowY: 'scroll',
    marginTop: 8,
  },
});
