import I_moreVert from '../../../Images/navigation/more_vert.svg';
import I_edit from '../../../Images/content/edit.png';
import I_editHover from '../../../Images/content/edit_hover.png';
import I_delete from '../../../Images/action/delete_border_red_24px.png';
// import I_message from '../../../Images/action/message.svg';

import { RImage } from '../../base/image/image';
import { styled } from '@mui/material/styles';
import { RRecordGroup } from '../../core/record/record-group/record-group';
import { RRecord } from '../../core/record/record/record';
import { RAvatar } from '../../core/avatar/avatar';
import { RUserName } from '../../core/user-name/user-name';
import { useIsMine } from './is-mine';
import { MouseEventHandler, useCallback, useState } from 'react';
import { classNames } from '../../classNames';
import { RProjectEditor } from '../../core/project-editor/project-editor';
import { RConfirmDeleteDialog } from '../../core/confirm-delete-dialog/confirm-delete-dialog';
import { ProjectdetailsAtom, useRefreshAsync } from '../../../atoms/project-details';
import { useNavigate } from 'react-router-dom';
import { useSave } from '../../hooks/save';
import { useMenuControl } from '../../hooks/menu-control';
import { RReportLink } from '../../core/report-link/report-link';
import { withBR } from '../../functions/withBR';
import { RLanguages } from '../../core/record/project-record/languages/languages';
import { ComponentPropsNormarized } from '../../types';

type Props = {
  projectData: ProjectdetailsAtom;
} & ComponentPropsNormarized<'section'>;

function Component({ projectData, className }: Props): JSX.Element {
  const { id, description, ownerID, ownerName, ownerAvatorUrl, createDateFormatted, name } = projectData;
  const isMine = useIsMine(ownerID);

  const [menuVisible, setMenuVisible, menuElementID] = useMenuControl();
  const [editing, setEditing] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const refreshAsync = useRefreshAsync();

  const onDeleteProjectClicked = useOnDeleteProjectClicked(id);

  return (
    <section className={className}>
      <RRecordGroup>
        <RRecord
          hover="None"
          sx={{
            padding: '23px 31px 16px 31px',
          }}>
          <div className={classes.header1}>
            <div className={classes.header1left}>
              {/* <RProjectStatus
              status={1}
              sx={{
                height: '28px',
                lineHeight: '20px',
                fontSize: '14px',
              }}
            /> */}
              <RAvatar size="S" userID={ownerID} src={ownerAvatorUrl} />
              <RUserName
                userId={ownerID}
                sx={{
                  marginLeft: '8px',
                }}>
                {ownerName}
              </RUserName>
            </div>

            <div className={classes.header1right}>
              <p className={classes.createDate}>{createDateFormatted}</p>
              {isMine && (
                <div id={menuElementID} className={classes.hamburgerMenu}>
                  <RImage src={I_moreVert} onClick={(): void => setMenuVisible((v) => !v)} />

                  {menuVisible && (
                    <div className={classes.dropdown}>
                      <div
                        className={classNames(classes.menuItem, classes.menuItemEdit)}
                        onClick={(): void => {
                          setEditing(true);
                          setMenuVisible(false);
                        }}>
                        <RImage src={I_edit} />
                        <p className={classes.menuItemLabel}>編集する</p>
                      </div>

                      <div
                        className={classNames(classes.menuItem, classes.menuItemDelete)}
                        onClick={(): void => {
                          setDeleting(true);
                          setMenuVisible(false);
                        }}>
                        <RImage src={I_delete} />
                        <p className={classes.menuItemLabel}>削除する</p>
                      </div>
                    </div>
                  )}
                  {editing && (
                    <RProjectEditor
                      projectID={id}
                      name={name}
                      description={description}
                      languages={projectData.languages.map((x) => x.id)}
                      closeProjectEidtor={(): void => setEditing(false)}
                      postURL="/v1/Project/Edit"
                      onEnd={(_id): void => {
                        setEditing(false);
                        refreshAsync();
                      }}
                    />
                  )}
                  {deleting && (
                    <RConfirmDeleteDialog close={(): void => setDeleting(false)} onOK={onDeleteProjectClicked}>
                      プロジェクトを削除すると元に戻せません。 <br />
                      本当に削除してもよろしいですか？
                    </RConfirmDeleteDialog>
                  )}
                </div>
              )}

              {/* <p className="suggest">
                レビュー契約を提案
                <RImage src={I_message} />
              </p> */}
            </div>
          </div>

          <h2 className={classes.name}>{name}</h2>
        </RRecord>

        <Description projectData={projectData} />
      </RRecordGroup>
    </section>
  );
}

type DescriptionProps = {
  projectData: ProjectdetailsAtom;
};

function Description({ projectData }: DescriptionProps): JSX.Element {
  const { ownerID, id, name, description } = projectData;
  const isMine = useIsMine(ownerID);

  const [editing, setEditing] = useState(false);
  const refreshAsync = useRefreshAsync();

  // TODO 説明だけでなくタグ・言語も設定されていない場合に限る。
  if (description === null) {
    if (isMine) {
      return (
        <>
          <RRecord
            hover="Blue"
            sx={{
              height: 144,
              padding: 0,
              textAlign: 'center',
            }}
            onClick={(): void => setEditing(true)}>
            <div className={classes.noDescriptionWrapper}>
              <p>プロジェクト情報を入力しましょう！</p>
            </div>
          </RRecord>
          {editing && (
            <RProjectEditor
              projectID={id}
              name={name}
              description={description}
              languages={projectData.languages.map((x) => x.id)}
              closeProjectEidtor={(): void => setEditing(false)}
              postURL="/v1/Project/Edit"
              onEnd={(_id): void => {
                setEditing(false);
                refreshAsync();
              }}
            />
          )}
        </>
      );
    }

    return (
      <RRecord
        hover="None"
        sx={{
          padding: '59px 31px 23px 31px',
          textAlign: 'center',

          color: '#757575',
        }}>
        <p>まだプロジェクト情報がありません。</p>
        <RReportLink category="project" targetID={id} />
      </RRecord>
    );
  }

  return (
    <RRecord
      hover="None"
      sx={{
        padding: '15px 31px 23px 31px',
      }}>
      <p>{withBR(description)}</p>
      <RLanguages
        size="M"
        languages={projectData.languages}
        sx={{
          marginTop: '16px',
        }}
      />
      {/* <div className="tag">
              <ul className="tag-name">
                <li>#Tag</li>
                <li>#Tag</li>
              </ul>
            </div> */}
      {!isMine && <RReportLink category="project" targetID={id} />}
    </RRecord>
  );
}

const useOnDeleteProjectClicked = (projectID: number): MouseEventHandler<HTMLButtonElement> => {
  const [save] = useSave('/v1/Project/Delete', 'プロジェクトを削除しました。');

  const navigate = useNavigate();

  const onDeleteProjectClicked: MouseEventHandler<HTMLButtonElement> = useCallback(
    async (e) => {
      await save(e, {
        id: projectID,
      });
      navigate('/');
    },
    [navigate, projectID, save],
  );

  return onDeleteProjectClicked;
};

const PREFIX = 'Summary';
const classes = {
  header1: `${PREFIX}-header1`,
  header1left: `${PREFIX}-header1left`,
  header1right: `${PREFIX}-header1right`,
  createDate: `${PREFIX}-createDate`,
  hamburgerMenu: `${PREFIX}-hamburgerMenu`,
  dropdown: `${PREFIX}-dropdown`,
  menuItem: `${PREFIX}-menuItem`,
  menuItemEdit: `${PREFIX}-menuItemEdit`,
  menuItemDelete: `${PREFIX}-menuItemDelete`,
  menuItemLabel: `${PREFIX}-menuItemLabel`,
  name: `${PREFIX}-name`,
  noDescriptionWrapper: `${PREFIX}-noDescriptionWrapper`,
};

export const Summary = styled(Component)({
  [`& .${classes.header1}`]: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  [`& .${classes.header1left}`]: {
    display: 'flex',
    alignItems: 'center',
  },
  [`& .${classes.header1right}`]: {
    display: 'flex',
  },
  [`& .${classes.createDate}`]: {
    color: '#757575',
    marginRight: 8,
  },

  [`& .${classes.hamburgerMenu}`]: {
    height: 24,
    borderRadius: '50%',
    cursor: 'pointer',
    position: 'relative',
    [`&:hover`]: {
      background: '#f2f2f2',
    },
  },
  [`& .${classes.dropdown}`]: {
    width: 'auto',
    height: 'auto',
    position: 'absolute',
    top: 30,
    right: 10,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '4px 0',
    boxShadow: '0px 0px 2px rgba(56, 56, 56, 0.2), 0px 2px 2px rgba(56, 56, 56, 0.24)',
    borderRadius: 4,
    backgroundColor: '#fff',
  },
  [`& .${classes.menuItem}`]: {
    width: 180,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '6px 20px',
  },
  [`& .${classes.menuItemEdit}`]: {
    [`&:hover`]: {
      backgroundColor: '#F0F7FF',
    },
  },
  [`& .${classes.menuItemDelete}`]: {
    color: '#FC5F5F',
    [`&:hover`]: {
      backgroundColor: '#FFEBEB',
    },
  },
  [`& .${classes.menuItemLabel}`]: {
    marginLeft: 16,
  },

  [`& .${classes.name}`]: {
    marginTop: '8px',
    fontWeight: 'bold',
    fontSize: '20px',
    lineHeight: '30px',

    letterSpacing: '0.15px',

    color: '#404040',
  },

  [`& .${classes.noDescriptionWrapper}`]: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',

    background: `url(${I_edit}) no-repeat calc(50% - 130px) center`,
    cursor: 'pointer',
    borderRadius: 8,
    [`&:hover`]: {
      backgroundImage: `url(${I_editHover})`,
    },
  },
});
