import { styled } from '@mui/material/styles';
import { RButton } from '../../base/button/button';
import { MouseEventHandler, ReactNode } from 'react';
import { RModal } from '../modal/modal';
import { ComponentPropsNormarized } from '../../types';

type Props = {
  close: () => void;
  onOK: MouseEventHandler<HTMLButtonElement>;
  children?: ReactNode;
} & ComponentPropsNormarized<typeof RModal>;

function Component({ close, onOK, children, className }: Props): JSX.Element {
  return (
    <RModal
      close={close}
      style={{
        padding: '48px 80px',
        textAlign: 'center',
      }}
      className={className}>
      <p>{children}</p>
      <div className={classes.buttons}>
        <RButton kind="WithFrame" size="InModalS" onClick={close}>
          キャンセル
        </RButton>
        <RButton
          kind="Danger"
          size="InModalS"
          onClick={onOK}
          sx={{
            marginLeft: '16px',
          }}>
          削除
        </RButton>
      </div>
    </RModal>
  );
}

const PREFIX = 'RConfirmDeleteDialog';
const classes = {
  buttons: `${PREFIX}-buttons`,
};

export const RConfirmDeleteDialog = styled(Component, {
  shouldForwardProp: (prop) => prop !== 'sx',
})({
  [`& .${classes.buttons}`]: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 28,
    width: 272,
    display: 'flex',
    justifyContent: 'space-between',
  },
});
