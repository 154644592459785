import I_logo2 from '../../../Images/icon/logo_gray.svg';
import I_twitter from '../../../Images/social/twitter.png';

import { styled } from '@mui/material/styles';
import { RImage } from '../../base/image/image';
import { ComponentPropsNormarized } from '../../types';
import { MenuItem } from './menu-item';
import { RContainer } from '../Container';

type Props = ComponentPropsNormarized<'footer'>;

function Component({ className }: Props): JSX.Element {
  const leftList = [
    { label: 'プロジェクト', href: '/Home/Index' },
    { label: 'ユーザー', href: '/UserSearch/List' },
    { label: 'コラム', href: '/Column' },
    // { label: 'FAQ', href: '#' },
  ];

  const rightList = [
    { label: '利用規約', href: '/Legal/TermsOfUse' },
    { label: 'プライバシーポリシー', href: '/Legal/PrivacyPolicy' },
    { label: '特定商取引に基づく表記', href: '/Legal/Tokusho' },
    {
      label: 'お問い合わせ',
      href: 'https://docs.google.com/forms/d/e/1FAIpQLSftphkg3eHG7fuDbxgqqyNVE5qht1ELuLzQCzSJDj2wYBTgbA/viewform?usp=sf_link',
      target: '_blank',
      rel: 'noreferrer',
    },
  ];

  return (
    <footer className={className}>
      <RContainer
        sx={{
          flexGrow: 'initial',
          padding: '0 24px',
        }}>
        <div>
          <ul
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-end',
            }}>
            <MenuItem href="/">
              <RImage
                src={I_logo2}
                sx={{
                  width: 224,
                  height: 40,
                }}
              />
            </MenuItem>
            <MenuItem
              sx={{
                width: '32px',
                height: '32px',
              }}
              href="https://twitter.com/bettercode1"
              target="_blank"
              rel="noreferrer">
              <RImage src={I_twitter} />
            </MenuItem>
          </ul>
        </div>
        <div>
          <ul
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: 8,
            }}>
            <li className={classes.left}>
              <ul className={classes.flex}>
                {leftList.map((item) => (
                  <MenuItem key={item.href} href={item.href}>
                    {item.label}
                  </MenuItem>
                ))}
              </ul>
            </li>

            <li className={classes.right}>
              <ul className={classes.flex}>
                {rightList.map((item) => (
                  <MenuItem key={item.href} href={item.href} target={item.target} rel={item.rel}>
                    {item.label}
                  </MenuItem>
                ))}
              </ul>
            </li>
          </ul>
        </div>
      </RContainer>
    </footer>
  );
}

const PREFIX = 'Footer';
const classes = {
  flex: `${PREFIX}-flex`,
  menuItems: `${PREFIX}-menuItems`,
  left: `${PREFIX}-left`,
  right: `${PREFIX}-right`,
  twitter: `${PREFIX}-twitter`,
};

export const Footer = styled(Component, {
  shouldForwardProp: (prop) => prop !== 'sx',
})({
  backgroundColor: '#F2F2F2',
  borderTop: '1px solid #C6C6C6',
  padding: '27px 0px 32px',

  [`& .${classes.flex}`]: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  [`& .${classes.left}`]: {
    display: 'flex',
  },
  [`& .${classes.right}`]: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
});
