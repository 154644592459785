import I_dropDown from '../../../Images/navigation/arrow_drop_down_24px.png';
import I_dropDownBlue from '../../../Images/navigation/arrow_drop_down_blue.png';
import I_dropUp from '../../../Images/navigation/arrow_drop_up_24px.png';
import I_dropUpBlue from '../../../Images/navigation/arrow_drop_up_blue.png';

import { styled } from '@mui/material/styles';
import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { RCheckbox } from '../../base/checkbox/checkbox';
import { DEFAULT_COUNT, ResultCountByItem } from './user-search-list';
import { ComponentPropsNormarized } from '../../types';

export type Item = {
  id: number;
  name: string;
};

type Props = {
  title: string;
  data: Item[];
  initialItemCountToShow: number;
  deltaItemCountToShow?: number;
  resultCountsByItem: ResultCountByItem[];
  selectedItems: Set<number>;
  setSelectedItems: Dispatch<SetStateAction<Set<number>>>;
  setShowCount: Dispatch<SetStateAction<number>>;
} & ComponentPropsNormarized<'div'>;

function Component({
  title,
  data,
  initialItemCountToShow,
  deltaItemCountToShow,
  resultCountsByItem,
  selectedItems,
  setSelectedItems,
  setShowCount,
  className,
}: Props): JSX.Element {
  const [expanded, setExpanded] = useState(true);
  const [itemCountToShow, setItemCountToShow] = useState(initialItemCountToShow);

  const onChecked = useOnChecked(selectedItems, setSelectedItems, setShowCount);

  return (
    <div className={className}>
      <div className={classes.searchFilterCategoryHeader} onClick={(): void => setExpanded((v) => !v)}>
        <h4 className={classes.searchFilterCategoryTitle}>{title}</h4>
        <div className={expanded ? classes.arrowDropDown : classes.arrowDropUp} />
      </div>

      {expanded && (
        <>
          <ul className={classes.searchFilterOptions}>
            {data.slice(0, itemCountToShow).map((item) => (
              <li key={item.id} className={classes.searchFilterOption}>
                <label className={classes.searchFilterOptionLabel}>
                  <RCheckbox checked={selectedItems.has(item.id)} setChecked={async (v): Promise<void> => onChecked(item, v)} />
                  <span>
                    {item.name}
                    <span className={classes.searchFilterOptionCount}>({resultCountsByItem.find((x) => x.id === item.id)?.count ?? 0})</span>
                  </span>
                </label>
              </li>
            ))}
          </ul>

          <div className={classes.searchFilterFooter}>
            <div
              className={classes.clickable}
              onClick={(): void => setItemCountToShow((v) => (deltaItemCountToShow === undefined ? data.length : v + deltaItemCountToShow))}>
              {itemCountToShow < data.length && 'もっと見る'}
            </div>
            <div
              className={classes.clickable}
              onClick={async (): Promise<void> => {
                setSelectedItems(new Set<number>());
                setShowCount(DEFAULT_COUNT);
              }}>
              選択をクリア
            </div>
          </div>
        </>
      )}
    </div>
  );
}

const useOnChecked = (
  selectedItems: Set<number>,
  setSelectedItems: Dispatch<SetStateAction<Set<number>>>,
  setShowCount: Dispatch<SetStateAction<number>>,
): ((item: Item, v: boolean) => void) => {
  const onChecked = useCallback(
    async (item: Item, v: boolean) => {
      const a = new Set(selectedItems);
      if (v) {
        a.add(item.id);
      } else {
        a.delete(item.id);
      }
      setSelectedItems(a);
      setShowCount(DEFAULT_COUNT);
    },
    [selectedItems, setSelectedItems, setShowCount],
  );

  return onChecked;
};

const PREFIX = 'SearchCategoryCheckbox';
const classes = {
  searchFilterCategoryHeader: `${PREFIX}-searchFilterCategoryHeader`,
  searchFilterCategoryTitle: `${PREFIX}-searchFilterCategoryTitle`,
  arrowDropDown: `${PREFIX}-arrowDropDown`,
  arrowDropUp: `${PREFIX}-arrowDropUp`,
  searchFilterOptions: `${PREFIX}-searchFilterOptions`,
  searchFilterOption: `${PREFIX}-searchFilterOption`,
  searchFilterOptionLabel: `${PREFIX}-searchFilterOptionLabel`,
  searchFilterOptionCount: `${PREFIX}-searchFilterOptionCount`,
  searchFilterFooter: `${PREFIX}-searchFilterFooter`,

  clickable: `${PREFIX}-clickable`,
};

export const SearchCategoryCheckbox = styled(Component)({
  [`& .${classes.searchFilterCategoryHeader}`]: {
    display: 'flex',
    justifyContent: 'space-between',
    cursor: 'pointer',
    [`&:hover`]: {
      color: '#0080FF',

      [`& .${classes.arrowDropDown}`]: {
        backgroundImage: `url(${I_dropDownBlue})`,
      },
      [`& .${classes.arrowDropUp}`]: {
        backgroundImage: `url(${I_dropUpBlue})`,
      },
    },
  },
  [`& .${classes.searchFilterCategoryTitle}`]: {
    fontWeight: 'bold',
    lineHeight: '21px',
  },
  [`& .${classes.arrowDropDown}`]: {
    width: '24px',
    backgroundImage: `url(${I_dropDown})`,
  },
  [`& .${classes.arrowDropUp}`]: {
    width: '24px',
    backgroundImage: `url(${I_dropUp})`,
  },
  [`& .${classes.searchFilterOptions}`]: {
    marginTop: 12,
  },
  [`& .${classes.searchFilterOption}`]: {
    margin: '4px 0px',
  },
  [`& .${classes.searchFilterOptionLabel}`]: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    cursor: 'pointer',
  },
  [`& .${classes.searchFilterOptionCount}`]: {
    marginLeft: 4,
    color: '#757575',
  },
  [`& .${classes.searchFilterFooter}`]: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 8,
    color: '#8A8A8A',
  },

  [`& .${classes.clickable}`]: {
    cursor: 'pointer',
    [`&:hover`]: {
      color: '#0080FF',
    },
  },
});
