import I_message from '../../../Images/alert/message.png';

import { styled } from '@mui/material/styles';
import { ReactNode } from 'react';
import { ComponentPropsNormarized } from '../../types';

type Props = {
  children: ReactNode;
} & ComponentPropsNormarized<'div'>;

function Component({ children, className }: Props): JSX.Element {
  return (
    <div className={className}>
      <div className={classes.messageArea}>{children}</div>
    </div>
  );
}

const PREFIX = 'RInformation';
const classes = {
  messageArea: `${PREFIX}-messageArea`,
};

export const RInformation = styled(Component, {
  shouldForwardProp: (prop) => prop !== 'sx',
})({
  marginBottom: 12,

  [`& .${classes.messageArea}`]: {
    border: '1px solid #FDCB68',
    borderRadius: 8,
    color: '#757575',
    lineHeight: '56px',
    paddingLeft: 54,
    background: `url(${I_message}) no-repeat 22px center`,
    backgroundColor: '#FFF8EB',
  },
});
