export default class Draggable {
  private target: HTMLElement;
  private baseX = 0;
  private baseY = 0;

  private onEnd: () => void;

  public constructor(target: HTMLElement, onEnd: () => void) {
    this.target = target;
    this.onEnd = onEnd;
  }

  public start(event: MouseEvent): void {
    if ((event.target as HTMLElement).getAttribute('data-undraggable') !== null) {
      return;
    }
    if (event.button !== 0) {
      return;
    }

    this.baseX = event.pageX - this.target.offsetLeft;
    this.baseY = event.pageY - this.target.offsetTop;

    document.body.addEventListener('mousemove', this.onMouseMove);
    this.target.addEventListener('mouseup', this.onLeave);
    document.body.addEventListener('mouseleave', this.onLeave);
  }

  private onMouseMove = function (this: Draggable, event: MouseEvent): void {
    if (!this.target) {
      throw new Error();
    }

    // マウスポインタの位置に要素を移動。
    this.target.style.top = `${event.pageY - this.baseY}px`;
    this.target.style.left = `${event.pageX - this.baseX}px`;
  }.bind(this); // eslint-disable-line no-invalid-this

  private onLeave = function (this: Draggable, _event: MouseEvent): void {
    if (!this.target) {
      throw new Error();
    }
    this.onEnd();

    document.body.removeEventListener('mousemove', this.onMouseMove);
    this.target.removeEventListener('mouseup', this.onLeave);
    document.body.removeEventListener('mouseleave', this.onLeave);
  }.bind(this); // eslint-disable-line no-invalid-this
}
