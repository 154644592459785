import I_closedReview from '../../../Images/icon/closed-review.svg';

import { styled } from '@mui/material/styles';
import { RImage } from '../../base/image/image';
import { withBR } from '../../functions/withBR';
import { Review, ReviewComment } from '../../../atoms/project-details';
import { CommentHoverMenu } from './comment-hover-menu';
import { CommentFooter } from './comment-footer';
import { RAvatar } from '../../core/avatar/avatar';
import { RReviewCategory } from '../../core/review-category/review-category';
import { ImportanceIDs, RReviewImportance } from '../../core/review-importance/review-importance';
import { projectDetailsShowHoverMenuAtom } from '../../../atoms/project-details-show-hover-menu';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { useCallback } from 'react';
import { scrollToCommentIcon } from './code-prettify';
import { ComponentPropsNormarized } from '../../types';

type Props = {
  projectID: number;
  projectOwnerID: number;
  review: Review;
  comment: ReviewComment;
  root?: boolean;
} & ComponentPropsNormarized<'div'>;

function Component({ projectID, projectOwnerID, review, comment, root = false, className }: Props): JSX.Element {
  const setProjectDetailsShowHoverMenu = useSetRecoilState(projectDetailsShowHoverMenuAtom);
  const resetProjectDetailsShowHoverMenu = useResetRecoilState(projectDetailsShowHoverMenuAtom);
  const projectDetailsShowHoverMenu = useRecoilValue(projectDetailsShowHoverMenuAtom);
  const onReviewClicked = useOnReviewClicked();

  return (
    <div
      id={`comment-id-${comment.id}`} // ブックマーク一覧からのリンク先。
      className={className}
      onMouseEnter={(): void => setProjectDetailsShowHoverMenu(comment.id)}
      onMouseLeave={(): void => resetProjectDetailsShowHoverMenu()}>
      <div className={classes.reviewHeader}>
        <div
          className={classes.reviewHeaderInner}
          id={`comment-list-${review.id}`} // TOP画面の活動一覧からのリンク先。
        >
          <p>
            <RAvatar
              size="S"
              userID={comment.userDeleted ? undefined : comment.createUserID}
              src={comment.createUserAvatorUrl}
              title={comment.createUserName}
            />
          </p>

          {
            // 削除済みファイルに紐付くコメント
            root && review.fileDeleted && <p className={classes.fileInfoDeleted}>このファイルは削除されました。</p>
          }

          {
            // フリーコメント以外のコメント
            root && !review.fileDeleted && review.fileID !== null && (
              <p className={classes.fileInfo} onClick={(e): void => onReviewClicked(e.currentTarget, review.fileID ?? 0, review.line ?? 0)}>
                <span className={classes.fileName}>{withBR(review.fileName)}</span>
                &nbsp;
                {review.line}行目
              </p>
            )
          }

          {root && review.closed && (
            <div className={classes.closedIcon}>
              <RImage src={I_closedReview} title="解決済み" />
            </div>
          )}
        </div>

        {projectDetailsShowHoverMenu === comment.id && <CommentHoverMenu root={root} projectOwnerID={projectOwnerID} review={review} comment={comment} />}
      </div>

      {
        // カテゴリー・重要度
        root && (
          <div className={classes.reviewHeader2}>
            {review.categoryName !== null && <RReviewCategory title={review.categoryDescription}>{review.categoryName}</RReviewCategory>}
            {review.importanceID !== null && (
              <RReviewImportance
                importance={review.importanceID as ImportanceIDs}
                sx={{
                  marginLeft: '8px',
                }}
              />
            )}
          </div>
        )
      }

      {/* コメント本体 */}
      <div className={classes.body}>
        <p>{comment.body === '' ? 'このコメントは削除されました。' : withBR(comment.body)}</p>
      </div>

      <CommentFooter projectID={projectID} comment={comment} />
    </div>
  );
}

const PREFIX = 'Comment';
const classes = {
  reviewHeader: `${PREFIX}-reviewHeader`,
  reviewHeaderInner: `${PREFIX}-reviewHeaderInner`,
  fileInfo: `${PREFIX}-fileInfo`,
  fileName: `${PREFIX}-fileName`,
  fileInfoDeleted: `${PREFIX}-fileInfoDeleted`,
  closedIcon: `${PREFIX}-closedIcon`,
  reviewHeader2: `${PREFIX}-reviewHeader2`,
  body: `${PREFIX}-body`,
};

const useOnReviewClicked = (): ((clicked: HTMLElement, fileID: number, lineNumber: number) => void) =>
  useCallback((clicked: HTMLElement, fileID: number, lineNumber: number) => {
    scrollToCommentIcon(fileID, lineNumber);
  }, []);

export const Comment = styled(Component)({
  position: 'relative',

  [`& .${classes.reviewHeader}`]: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  [`& .${classes.reviewHeaderInner}`]: {
    display: 'flex',
  },
  [`& .${classes.fileInfoDeleted}`]: {
    fontSize: '1.2rem',
    lineHeight: '28px',
    marginLeft: 8,
  },
  [`& .${classes.fileInfo}`]: {
    fontSize: '1.2rem',
    lineHeight: '28px',
    marginLeft: 8,
    cursor: 'pointer',
    wordBreak: 'break-all',
    textDecoration: 'underline',
    display: 'flex',
  },
  [`& .${classes.fileName}`]: {
    display: 'inline-block',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    maxWidth: '22ch',
  },
  [`& .${classes.closedIcon}`]: {
    marginTop: 4,
    marginLeft: 8,
  },
  [`& .${classes.body}`]: {
    marginTop: 12,
    marginBottom: 12,
  },
  [`& .${classes.reviewHeader2}`]: {
    marginTop: 8,
  },
});
