import I_close from '../../../../Images/navigation/close_black.png';

import { styled } from '@mui/material/styles';
import { RModal } from '../../../core/modal/modal';
import { RImage } from '../../../base/image/image';
import { ComponentPropsNormarized } from '../../../types';

type Props = {
  mail: string;
  close: () => void;
} & ComponentPropsNormarized<'div'>;

function Component({ mail, close, className }: Props): JSX.Element {
  return (
    <div className={className}>
      <RModal close={close}>
        <section className={classes.wrapper}>
          <p>
            <RImage
              src={I_close}
              sx={{
                cursor: 'pointer',

                position: 'absolute',
                top: 25,
                right: 25,
              }}
              onClick={close}
            />
          </p>
          <h2 className={classes.title}>あと1ステップで完了です</h2>
          <p>
            {mail}にメールアドレス変更用のメールをお送りしました。 <br />
            メールを確認して、変更確認用のURLにアクセスしてください。
          </p>
        </section>
      </RModal>
    </div>
  );
}

const PREFIX = 'InputMailAddressDone';
const classes = {
  wrapper: `${PREFIX}-wrapper`,
  title: `${PREFIX}-title`,
};
export const InputMailAddressDone = styled(Component, {
  shouldForwardProp: (prop) => prop !== 'sx',
})({
  [`& .${classes.wrapper}`]: {
    width: 466,
    padding: 48,
    position: 'relative',

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  [`& .${classes.title}`]: {
    fontWeight: 500,
    textAlign: 'center',
    width: '100%',
    fontSize: '2rem',
    marginBottom: 20,
    lineHeight: 1.5,
  },
});
