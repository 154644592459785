import { styled } from '@mui/material/styles';
import { ChangeEventHandler, Dispatch, KeyboardEventHandler, useCallback } from 'react';
import { ComponentPropsNormarized } from '../../types';

type Props = {
  value: string;
  setValue: Dispatch<string>;
  acceptEnterKey?: boolean;
} & Omit<ComponentPropsNormarized<'input'>, 'value'>;

function Component({ value, setValue, acceptEnterKey = false, className, ...others }: Props): JSX.Element {
  const onChange: ChangeEventHandler<HTMLInputElement> = useCallback((e) => setValue(e.target.value), [setValue]);

  const onKeyDown: KeyboardEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      if (acceptEnterKey) {
        return;
      }

      if (e.code === 'Enter' || e.code === 'NumpadEnter') {
        e.preventDefault();
      }
    },
    [acceptEnterKey],
  );

  return <input type="text" value={value ?? undefined} onChange={onChange} onKeyDown={onKeyDown} className={className} {...others} />;
}

export const RInput = styled(Component, {
  shouldForwardProp: (prop) => prop !== 'sx',
})({
  display: 'block',
  height: 40,
  padding: '7px 11px',

  fontSize: 16,

  color: '#404040',
  background: '#FFFFFF',

  border: '1px solid #C6C6C6',
  borderRadius: 4,

  '&::placeholder': {
    color: '#9D9D9D',
  },
  '&:focus': {
    borderColor: '#3399FF',
    outline: 'none',
  },
});
