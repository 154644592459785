import { atom } from 'recoil';

export type ProjectDetailsImportanceAtom = {
  id: number;
  label: string;
  description: string;
}[];

export const projectDetailsImportanceAtom = atom({
  key: 'projectDetailsImportanceAtom',
  default: [] as ProjectDetailsImportanceAtom,
});
