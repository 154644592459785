import I_visibility from '../../../Images/action/visibility.png';
import I_visibilityHover from '../../../Images/action/visibility_hover.png';
import I_visibilityOff from '../../../Images/action/visibility_off.png';
import I_visibilityOffHover from '../../../Images/action/visibility_off_hover.png';

import { Dispatch, useCallback, useState } from 'react';
import { RImage } from '../image/image';
import { RInput } from '../input/input';
import { styled } from '@mui/material/styles';
import { ComponentPropsNormarized } from '../../types';

type Props = {
  value: string;
  setValue: Dispatch<string>;
  acceptEnterKey?: boolean;
} & Omit<ComponentPropsNormarized<'input'>, 'type' | 'value'>;

function Component({ value, setValue, acceptEnterKey, className, ...others }: Props): JSX.Element {
  const [visible, setVisible] = useState(false);

  const onClick = useCallback(() => {
    setVisible((v) => !v);
  }, []);

  return (
    <div className={className} {...others}>
      <RInput
        type={visible ? 'text' : 'password'}
        acceptEnterKey={acceptEnterKey}
        placeholder="パスワード"
        value={value}
        setValue={setValue}
        sx={{
          width: '100%',
        }}
      />

      <span onClick={onClick} className={classes.buttonWrapper}>
        <div className={classes.button}>
          <RImage src={visible ? I_visibilityOff : I_visibility} />
        </div>
        <div className={classes.button}>
          <RImage src={visible ? I_visibilityOffHover : I_visibilityHover} />
        </div>
      </span>
    </div>
  );
}

const PREFIX = 'RPassword';
const classes = {
  input: `${PREFIX}-input`,
  buttonWrapper: `${PREFIX}-buttonWrapper`,
  button: `${PREFIX}-button`,
};

export const RPassword = styled(Component, {
  shouldForwardProp: (prop) => prop !== 'sx',
})({
  position: 'relative',

  [`& .${classes.buttonWrapper}`]: {
    position: 'absolute',
    top: '8px',
    right: '12px',
    cursor: 'pointer',

    [`& .${classes.button}:nth-of-type(1)`]: {
      opacity: 1,
    },
    [`& .${classes.button}:nth-of-type(2)`]: {
      opacity: 0,
    },
    '&:hover': {
      [`& .${classes.button}:nth-of-type(1)`]: {
        opacity: 0,
      },
      [`& .${classes.button}:nth-of-type(2)`]: {
        opacity: 1,
      },
    },
  },

  [`& .${classes.button}`]: {
    position: 'absolute',
    marginTop: '1.5px',
    top: 0,
    right: 0,
  },
});
