import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { useNavigate } from 'react-router-dom';
import { ApiResponse, useApi } from '../../services/api';

export const useData = <T>(url: string, params?: unknown): [T | undefined, Dispatch<SetStateAction<T>>] => {
  const [data, setData] = useState(undefined as unknown as T);
  const navigate = useNavigate();
  const api = useApi();

  useEffect(() => {
    const f = async (): Promise<void> => {
      const response = (await api.get(url, { params: params })) as ApiResponse;
      setData(response.payload as T);
    };
    f();
  }, [url, navigate, api, params]);

  return [data, setData];
};
