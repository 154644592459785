import { MouseEventHandler, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

export const useRedirect: (id: number | undefined, to: string) => [MouseEventHandler<HTMLElement>] = (id, to) => {
  const navigate = useNavigate();

  const onClick: MouseEventHandler<HTMLElement> = useCallback(
    (e) => {
      if (id === undefined) {
        return;
      }
      e.stopPropagation();
      navigate(to);
    },
    [id, navigate, to],
  );

  return [onClick];
};
