import I_good from '../../../Images/action/good.svg';
import I_goodActive from '../../../Images/action/good_active.svg';
import I_bad from '../../../Images/action/bad.svg';
import I_badActive from '../../../Images/action/bad_active.svg';

import { styled } from '@mui/material/styles';
import { RImage } from '../../base/image/image';
import { ReviewComment, useRefreshAsync } from '../../../atoms/project-details';
import React, { MouseEventHandler, useCallback, useRef } from 'react';
import { useLogInSuggestion } from '../../hooks/log-in-suggestion';
import { useSave } from '../../hooks/save';
import { ComponentPropsNormarized } from '../../types';

type Props = {
  projectID: number;
  comment: ReviewComment;
} & ComponentPropsNormarized<'div'>;

function Component({ projectID, comment, className }: Props): JSX.Element {
  const onVoteGood = useVote(projectID, comment.id, comment.votedToGood, true);
  const onVoteBad = useVote(projectID, comment.id, comment.votedToBad, false);

  return (
    <div className={className}>
      <div className={classes.icon} onClick={onVoteGood}>
        <RImage src={comment.votedToGood ? I_goodActive : I_good} />
      </div>
      <div className={classes.count}>{comment.good}</div>

      <div className={classes.icon} onClick={onVoteBad}>
        <RImage src={comment.votedToBad ? I_badActive : I_bad} />
      </div>
      <div className={classes.count}>{comment.bad}</div>
    </div>
  );
}

const useVote = (projectID: number, commentID: number, voted: boolean, good: boolean): MouseEventHandler<HTMLElement> => {
  const [deleteVote] = useSave('/v1/ReviewComment/DeleteVote');
  const [doVote] = useSave('/v1/ReviewComment/Vote');
  const refreshAsync = useRefreshAsync();

  const eventRef = useRef<React.MouseEvent<HTMLElement, MouseEvent> | null>(null);

  const vote: MouseEventHandler<HTMLElement> = useCallback(
    async (e) => {
      eventRef.current = e;

      if (voted) {
        await deleteVote(e, {
          projectID: projectID,
          commentID: commentID,
        });
      } else {
        await doVote(e, {
          projectID: projectID,
          commentID: commentID,
          good: good,
        });
      }

      await refreshAsync();
    },
    [commentID, deleteVote, doVote, good, projectID, refreshAsync, voted],
  );

  const onClick = useLogInSuggestion('ログインしてから投票しましょう。', vote);

  return onClick;
};

const PREFIX = 'Votes';
const classes = {
  icon: `${PREFIX}-icon`,
  count: `${PREFIX}-count`,
};

export const Votes = styled(Component)({
  display: 'flex',

  [`& .${classes.icon}`]: {
    width: 18,
    height: 18,
    borderRadius: '50%',
    cursor: 'pointer',
    '&:not(:first-of-type)': {
      marginLeft: 8,
    },
    '&:hover': {
      background: '#F2F2F2',
    },
  },
  [`& .${classes.count}`]: {
    fontSize: 12,
    lineHeight: '15px',
    letterSpacing: 0.4,
    color: '#9D9D9D',
    marginLeft: 2,
  },
});
