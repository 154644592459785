import { AxiosRequestConfig } from 'axios';
import { useState, useRef, useCallback } from 'react';
import { useOpenSnackbar } from '../../atoms/snackbar';
import { useApi, ApiResponse } from '../../services/api';
import { ValidationErrors } from '../types';

export const useSave = <T>(
  url: string,
  message?: string,
): [(event: React.SyntheticEvent, data: T, config?: AxiosRequestConfig<T>) => Promise<ApiResponse>, ValidationErrors, () => void] => {
  const [validationErrors, setValidationErrors] = useState({} as ValidationErrors);
  const processing = useRef(false);
  const api = useApi();
  const openSnackbar = useOpenSnackbar();

  const clearValidationErrors = useCallback((): void => {
    setValidationErrors({});
  }, []);

  const save: (event: React.SyntheticEvent, data: T, config?: AxiosRequestConfig<T>) => Promise<ApiResponse> = useCallback(
    async (e, data, config) => {
      e.preventDefault();

      if (processing.current) {
        return { errorCode: '', payload: '' } as ApiResponse;
      }
      processing.current = true;

      clearValidationErrors();
      const response = (await api.post(url, data, config)) as ApiResponse;

      // eslint-disable-next-line require-atomic-updates
      processing.current = false;

      if (response.errorCode === 'validationError') {
        setValidationErrors(response.payload as ValidationErrors);
        return response;
      }

      if (message !== undefined) {
        openSnackbar(message);
      }

      return response;
    },
    [api, clearValidationErrors, message, openSnackbar, url],
  );

  return [save, validationErrors, clearValidationErrors];
};
