import I_guideIcon from '../../../Images/alert/ques.png';
import I_guideIconHover from '../../../Images/alert/ques_hover.png';

import { styled } from '@mui/material/styles';
import { ComponentPropsWithoutRef, Dispatch, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { projectDetailsCategoriesAtom } from '../../../atoms/project-details-categories';
import { projectDetailsImportanceAtom } from '../../../atoms/project-details-importance';
import { RSelect } from '../../base/select/select';
import { useElementID } from '../../hooks/element-id';
import { ComponentPropsNormarized } from '../../types';
import { CategoryGuide } from './category-guide';

type Props = {
  kind: 'category' | 'importance';
  category: string;
  setCategory: Dispatch<string>;
} & ComponentPropsNormarized<'div'>;

function Component({ kind, category, setCategory, className, ...others }: Props): JSX.Element {
  const elementID = useElementID();

  const categoryOptions = useCategoryOptions();
  const importanceOptions = useImportanceOptions();

  let options: ComponentPropsWithoutRef<typeof RSelect>['data'];
  switch (kind) {
    case 'category':
      options = categoryOptions;
      break;
    case 'importance':
      options = importanceOptions;
      break;
    default:
      throw new Error();
  }

  const [shoudShowCategoryGuide, setShoudShowCategoryGuide] = useState(false);

  return (
    <div id={elementID} className={className} {...others}>
      <RSelect
        kind="Optional"
        data={options}
        value={category}
        setValue={setCategory}
        sx={{
          width: 307,
          marginRight: '8px',
        }}
        data-undraggable
      />
      <span
        className={classes.guideIcon}
        onMouseEnter={(): void => setShoudShowCategoryGuide(true)}
        onMouseLeave={(): void => setShoudShowCategoryGuide(false)}
        data-undraggable
      />
      {shoudShowCategoryGuide && <CategoryGuide kind={kind} baseElementID={elementID} />}
    </div>
  );
}

const useCategoryOptions = (): ComponentPropsWithoutRef<typeof RSelect>['data'] => {
  const projectDetailsCategories = useRecoilValue(projectDetailsCategoriesAtom);
  return [
    { label: 'カテゴリを選択', value: '' },
    ...projectDetailsCategories.map((c) => ({
      group: c.group,
      items: c.items.map((item) => ({ value: item.id.toString(), label: item.label })),
    })),
  ];
};

const useImportanceOptions = (): ComponentPropsWithoutRef<typeof RSelect>['data'] => {
  const projectDetailsImportances = useRecoilValue(projectDetailsImportanceAtom);
  return [{ label: '重要度を選択', value: '' }, ...projectDetailsImportances.map((x) => ({ value: x.id.toString(), label: x.label }))];
};

const PREFIX = 'CategorySelect';
const classes = {
  guideIcon: `${PREFIX}-guideIcon`,
};

export const CategorySelect = styled(Component)({
  display: 'flex',
  alignItems: 'center',

  [`& .${classes.guideIcon}`]: {
    background: `url(${I_guideIcon}) 0 0 no-repeat`,
    display: 'inline-block',
    width: 18,
    height: 18,
    position: 'relative',

    cursor: 'default',

    [`&:hover`]: {
      background: `url(${I_guideIconHover}) 0 0 no-repeat`,
    },
  },
});
