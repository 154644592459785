import I_404 from '../../../Images/error/404.png';
import { classNames } from '../../classNames';
import { RLink } from '../../base/link/link';
import { RImage } from '../../base/image/image';
import { styled } from '@mui/material/styles';
import { useTitle } from '../../hooks/title';
import { RContainer } from '../../layout/Container';
import { ComponentPropsNormarized } from '../../types';

type Props = ComponentPropsNormarized<typeof RContainer>;

function Component({ className }: Props): JSX.Element {
  useTitle('');

  const list = [
    { label: 'プロジェクト', href: '/Home/Index' },
    { label: 'ユーザー', href: '/UserSearch/List' },
    // { label: 'コラム', href: '' },
    // { label: 'FAQ', href: '' },
  ];

  return (
    <RContainer className={className} innerStyle={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <h1 className={classes.h1}>404 Not Found</h1>
      <p>
        <RImage src={I_404} width="281" height="198" />
      </p>
      <p className={classNames(classes.text, classes.description)}>
        アドレスが変更されているか、ページが削除されている可能性があります。
        <br />
        お手数ですが、以下のメニューからページをお探しください。
      </p>
      <ul className={classes.ul}>
        {list.map((item) => (
          <li key={item.href} className={classes.li}>
            <RLink kind="GrayOnGray" href={item.href}>
              {item.label}
            </RLink>
          </li>
        ))}
      </ul>
    </RContainer>
  );
}

const PREFIX = 'Error404';
const classes = {
  h1: `${PREFIX}-h1`,
  text: `${PREFIX}-text`,
  ul: `${PREFIX}-ul`,
  li: `${PREFIX}-li`,
  description: `${PREFIX}-description`,
};

export const Error404 = styled(Component, {
  shouldForwardProp: (prop) => prop !== 'sx',
})({
  [`& .${classes.h1}`]: {
    fontSize: '34px',
    lineHeight: '51px',
    color: '#3399ff',
    letterSpacing: '0.25px',
    marginBottom: '24px',
  },

  [`& .${classes.text}`]: {
    display: 'inline-block',
    textAlign: 'left',
    margin: '40px 0 0 0',
    lineHeight: '21px',
  },

  [`& .${classes.ul}`]: {
    textAlign: 'center',
    marginTop: '49px',
  },

  [`& .${classes.li}`]: {
    display: 'inline',
    marginRight: '35px',
  },

  [`& .${classes.description}`]: {
    textAlign: 'center',
  },
});
