import { ComponentPropsNormarized } from '../../types';
import { RValidationMessage } from './validation-message';

type Props = {
  messages: string[] | undefined;
} & Omit<ComponentPropsNormarized<typeof RValidationMessage>, 'children'>;

export function RValidationMessages({ messages, ...others }: Props): JSX.Element {
  if (messages === undefined) {
    return <></>;
  }

  return (
    <>
      {messages.map((message) => (
        <RValidationMessage key={message} {...others}>
          {message}
        </RValidationMessage>
      ))}
    </>
  );
}
