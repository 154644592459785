import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * メール内リンクやブックマークなどからの直接アクセス時にURL内のハッシュ「#」の位置までスクロールさせる。
 * APIから動的にロードされるのを待って実行するが、正確なタイミングが取れないため、適当な間隔で何度か試みる。
 * 一定時間以内に成功しなかった場合は諦めて処理を終了する。
 *
 *  react-router-hash-linkでは実現できないため、本hookで自力対応。
 */
export const useScrollToHash = (): void => {
  const location = useLocation();

  const MAX_COUNT = 300;
  let count = 0;

  useEffect(() => {
    if (location.hash === '') {
      return (): void => {
        // no-op
      };
    }

    const id = setInterval((): void => {
      if (++count >= MAX_COUNT) {
        clearInterval(id);
        return;
      }

      const elem = document.querySelector(location.hash);
      if (elem === null) {
        return;
      }

      elem.scrollIntoView();

      clearInterval(id);
    }, 100);

    return (): void => {
      clearInterval(id);
    };
  }, [count, location.hash]);
};
