import I_more from '../../../Images/navigation/more_vert.svg';
import I_closed from '../../../Images/action/closed-review.svg';
import I_unclosed from '../../../Images/action/unclosed-review.svg';
import I_bookmarked from '../../../Images/action/bookmarked.svg';
import I_unbookmarked from '../../../Images/action/unbookmarked.svg';

import { styled } from '@mui/material/styles';
import { useRecoilValue } from 'recoil';
import { logInUserAtom } from '../../../atoms/log-in-user';
import { RImage } from '../../base/image/image';
import { CommentHoverMenuDetail } from './comment-hover-menu-detail';
import { MouseEventHandler, useCallback, useState } from 'react';
import { Review, ReviewComment, useRefreshAsync } from '../../../atoms/project-details';
import { useSave } from '../../hooks/save';
import { ComponentPropsNormarized } from '../../types';

type Props = {
  root?: boolean;
  projectOwnerID: number;
  review: Review;
  comment: ReviewComment;
} & ComponentPropsNormarized<'div'>;

function Component({ root = false, projectOwnerID, review, comment, className }: Props): JSX.Element {
  const logInUser = useRecoilValue(logInUserAtom);
  const [shouldShowDetail, setShouldShowDetail] = useState(false);

  const onCommentCloseClicked = useOnCommentCloseClicked(review.id, !review.closed);
  const onBookmarkClicked = useOnBookmarkClicked(comment.id);

  return (
    <div className={className}>
      {/* 解決済み */}
      {root && projectOwnerID === logInUser.id && (
        <div className={classes.menuItem} onClick={onCommentCloseClicked}>
          <RImage src={review.closed ? I_closed : I_unclosed} sx={iconCSS} />
        </div>
      )}

      {/* ブックマーク */}
      {logInUser.id !== null && (
        <div className={classes.menuItem} onClick={(e): Promise<void> => onBookmarkClicked(e, !comment.bookmarked)}>
          <RImage src={comment.bookmarked ? I_bookmarked : I_unbookmarked} sx={iconCSS} />
        </div>
      )}

      {comment.body !== '' && (
        <div
          className={classes.menuItem}
          onClick={(): void => {
            setShouldShowDetail((v) => !v);
          }}>
          <RImage src={I_more} sx={iconCSS} />
        </div>
      )}
      {shouldShowDetail && <CommentHoverMenuDetail comment={comment} projectOwnerID={projectOwnerID} />}
    </div>
  );
}

const useOnCommentCloseClicked = (reviewID: number, close: boolean): MouseEventHandler<HTMLElement> => {
  const [save] = useSave('/v1/Project/CloseComment');
  const refreshAsync = useRefreshAsync();

  const onCommentCloseClicked: MouseEventHandler<HTMLElement> = useCallback(
    async (e) => {
      await save(e, {
        reviewID: reviewID,
        close: close,
      });

      await refreshAsync();
    },
    [close, refreshAsync, reviewID, save],
  );

  return onCommentCloseClicked;
};

const useOnBookmarkClicked = (commentID: number): ((e: React.MouseEvent<HTMLElement, MouseEvent>, bookmark: boolean) => Promise<void>) => {
  const [save] = useSave('/v1/Project/BookmarkComment');
  const refreshAsync = useRefreshAsync();

  const onBookmarkClicked = useCallback(
    async (e: React.MouseEvent<HTMLElement, MouseEvent>, bookmark: boolean) => {
      await save(e, {
        commentID: commentID,
        bookmark: bookmark,
      });

      await refreshAsync();
    },
    [commentID, refreshAsync, save],
  );

  return onBookmarkClicked;
};

const PREFIX = 'CommentHoverMenu';
const classes = {
  menuItem: `${PREFIX}-menuItem`,
};

const iconCSS = {
  maxWidth: '100%',
  height: 'auto',
  margin: 'auto',
};

export const CommentHoverMenu = styled(Component)({
  position: 'absolute',
  right: 0,
  marginTop: '-4px',
  marginRight: '-4px',
  padding: '4px 8px',
  display: 'flex',
  background: '#FFFFFF',
  boxShadow: '0px 0px 2px rgba(56, 56, 56, 0.2), 0px 2px 2px rgba(56, 56, 56, 0.24)',
  borderRadius: 4,

  [`& .${classes.menuItem}`]: {
    display: 'flex',
    borderRadius: 4,

    width: 32,
    height: 32,
    cursor: 'pointer',
    marginLeft: 4,
    '&:first-of-type': {
      marginLeft: 0,
    },
    '&:hover': {
      background: '#F2F2F2',
    },
  },
});
