import { styled } from '@mui/material/styles';
import { RRecordGroup } from '../../../core/record/record-group/record-group';
import { RRecord } from '../../../core/record/record/record';
import { RProjectRecord } from '../../../core/record/project-record/project-record';
import { useCallback, useEffect, useState } from 'react';
import { ApiResponse, useApi } from '../../../../services/api';
import { ComponentPropsNormarized, Payload, Project } from '../../../types';
import { useIDFromParam } from '../../../hooks/id-from-param';

type Props = ComponentPropsNormarized<'div'>;

function Component({ className }: Props): JSX.Element {
  const id = useIDFromParam();

  const [data, onMoreClick] = useData(id);

  return (
    <div className={className}>
      <section>
        <h3 className={classes.title}>作成したプロジェクト</h3>
      </section>

      <RRecordGroup
        sx={{
          marginTop: '8px',
        }}>
        {data.projects.map((project) => (
          <RProjectRecord key={project.id} kind="Profile" project={project} />
        ))}
        {data.hasMore && <RRecord next10 onClick={onMoreClick} />}
      </RRecordGroup>

      {data.projects.length === 0 && (
        <div className={classes.pannel}>
          <p>まだプロジェクトがありません。</p>
        </div>
      )}
    </div>
  );
}

const useData = (userID: number): [Payload, () => void] => {
  const [data, setData] = useState({
    projects: [] as Project[],
    hasMore: false,
  });

  const api = useApi();

  useEffect(() => {
    const f = async (): Promise<void> => {
      const response = (await api.get('/v1/Project/List', {
        params: {
          userID: userID,
          projectType: 0,
          count: 10,
        },
      })) as ApiResponse;

      setData(response.payload as Payload);
    };
    f();
  }, [api, userID]);

  const onMoreClick = useCallback(async () => {
    const response = (await api.get('/v1/Project/List', {
      params: {
        userID: userID,
        projectType: 0,
        count: data.projects.length + 10,
      },
    })) as ApiResponse;

    setData(response.payload as Payload);
  }, [api, userID, data.projects.length]);

  return [data, onMoreClick];
};

const PREFIX = 'Projects';
const classes = {
  title: `${PREFIX}-title`,
  pannel: `${PREFIX}-pannel`,
};

export const Projects = styled(Component)({
  marginTop: '24px',

  [`& .${classes.title}`]: {
    fontSize: '1.6rem',
    marginBottom: '12px',
    color: '#757575',
    fontWeight: 'bold',
  },

  [`& .${classes.pannel}`]: {
    padding: '35px',
    backgroundColor: '#FFF',
    borderRadius: '4px',
    border: '1px solid #E3E3E3',
    textAlign: 'center',
    cursor: 'auto',
  },
});
