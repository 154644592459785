import { MouseEventHandler } from 'react';
import { atom } from 'recoil';

type LogInSuggestionAtom = {
  visible: boolean;
  description: string;
  to: string | MouseEventHandler<HTMLElement>;
};

export const logInSuggestionAtom = atom({
  key: 'logInSuggestionAtom',
  default: {
    visible: false,
    description: '',
    to: '',
  } as LogInSuggestionAtom,
});
