import { styled } from '@mui/material/styles';
import { RLink } from '../../base/link/link';
import { ComponentPropsNormarized } from '../../types';

type Props = {
  userId: number;
  deletedUser?: boolean;
  color?: string;
} & ComponentPropsNormarized<'span'>;

function Component({ children, userId, deletedUser = false, color, className, ...others }: Props): JSX.Element {
  if (deletedUser) {
    return (
      <span className={className} {...others} style={{ color: color }}>
        {children}
      </span>
    );
  }

  return (
    <span className={className} {...others}>
      {/* sxで指定されたcolorをspanに設定してもRLink内で上書きされてしまうため、RLinkにsxで指定する。 */}
      <RLink kind="WithAvatar" href={`/Profile/Main/${userId}`} sx={{ color: color }}>
        {children}
      </RLink>
    </span>
  );
}

export const RUserName = styled(Component, {
  shouldForwardProp: (prop) => prop !== 'sx',
})({
  color: '#404040',
  letterSpacing: '0.25px',
});
