import I_more from '../../../Images/navigation/more.png';
import I_upload from '../../../Images/action/upload.png';
import I_uploadHover from '../../../Images/action/upload_hover.png';

import { styled } from '@mui/material/styles';
import { RRecordGroup } from '../../core/record/record-group/record-group';
import { RRecord } from '../../core/record/record/record';
import { RImage } from '../../base/image/image';
import { File } from '../../../atoms/project-details';
import { useIsMine } from './is-mine';
import { useCallback, useRef } from 'react';
import { RValidationMessages } from '../../core/validation-message/validation-messages';
import { useElementID } from '../../hooks/element-id';
import { useUploadFile } from '../../hooks/upload-file';
import { ComponentPropsNormarized } from '../../types';

type Props = {
  projectID: number;
  ownerID: number;
  files: File[];
} & ComponentPropsNormarized<'section'>;

function Component({ projectID, ownerID, files, className }: Props): JSX.Element {
  const isMine = useIsMine(ownerID);
  const scroll = useScroll();
  const inputElementID = useElementID();

  const fileRef = useRef<HTMLInputElement>(null);
  const [validationErrors, uploadFile] = useUploadFile(projectID, fileRef);

  let element: JSX.Element = <></>;
  if (files.filter((x) => !x.deleted).length === 0) {
    if (isMine) {
      element = (
        <>
          <RRecord hover="Blue" sx={noFilesCSS}>
            <label htmlFor={inputElementID} className={classes.uploadFiles}>
              <p>クリックしてファイルをアップロードしましょう！</p>
            </label>
            <form action="">
              <input id={inputElementID} ref={fileRef} type="file" onChange={uploadFile} className={classes.file} />
            </form>
          </RRecord>
          <RValidationMessages messages={validationErrors['']} />
        </>
      );
    } else {
      element = (
        <RRecord
          hover="None"
          sx={{
            ...noFilesCSS,
            ...{
              color: '#757575',
            },
          }}>
          <p>まだファイルがありません。</p>
        </RRecord>
      );
    }
  } else {
    element = (
      <RRecord
        hover="None"
        sx={{
          padding: '17px 0',
        }}>
        <ul className={classes.files}>
          {files
            .filter((x) => !x.deleted)
            .map((file) => (
              <li key={file.id} onClick={(): void => scroll(file.id)} className={classes.li}>
                <span className={classes.span}>
                  <div className={classes.more}>
                    <RImage src={I_more} />
                  </div>
                  <span className={classes.fileName}>{file.name}</span>
                  <span className={classes.uploadDate}>{file.uploadDateFormatted}</span>
                </span>
              </li>
            ))}
        </ul>
      </RRecord>
    );
  }

  return (
    <section className={className}>
      <RRecordGroup>{element}</RRecordGroup>
    </section>
  );
}

const useScroll = (): ((fileID: number) => void) =>
  useCallback((fileID: number) => {
    const elementID = `file-content-wrap-${fileID}`;
    const element = document.getElementById(elementID);
    if (element === null) {
      return;
    }
    element.scrollIntoView({ behavior: 'smooth' });
  }, []);

const PREFIX = 'FileListContent';
const classes = {
  uploadFiles: `${PREFIX}-uploadFiles`,
  file: `${PREFIX}-file`,
  files: `${PREFIX}-files`,
  li: `${PREFIX}-li`,
  span: `${PREFIX}-span`,
  more: `${PREFIX}-more`,
  fileName: `${PREFIX}-fileName`,
  uploadDate: `${PREFIX}-uploadDate`,
};

const noFilesCSS = {
  padding: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  height: 144,
};

export const FileListContent = styled(Component)({
  [`& .${classes.uploadFiles}`]: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',

    background: `url(${I_upload}) no-repeat calc(50% - 170px) center`,
    cursor: 'pointer',
    borderRadius: 8,
    [`&:hover`]: {
      backgroundImage: `url(${I_uploadHover})`,
    },
  },
  [`& .${classes.file}`]: {
    display: 'none',
  },
  [`& .${classes.files}`]: {
    display: 'inline-block',
    width: '100%',
  },
  [`& .${classes.li}`]: {
    padding: '6px 0 6px 27px',
    width: '100%',
    cursor: 'pointer',
    '&:hover': {
      background: '#F2F2F2',
    },
  },
  [`& .${classes.span}`]: {
    display: 'flex',
  },
  [`& .${classes.more}`]: {
    width: 20,
    height: 20,
  },
  [`& .${classes.fileName}`]: {
    marginLeft: 8,
  },
  [`& .${classes.uploadDate}`]: {
    marginLeft: 8,
    color: '#757575',
  },
});
