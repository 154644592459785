import { atom } from 'recoil';

export type ProjectDetailsCategoriesAtom = {
  group: string;
  items: {
    id: number;
    label: string;
    description: string;
  }[];
}[];

export const projectDetailsCategoriesAtom = atom({
  key: 'projectDetailsCategoriesAtom',
  default: [] as ProjectDetailsCategoriesAtom,
});
