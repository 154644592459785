import { styled } from '@mui/material/styles';
import { Votes } from './votes';
import { ReviewComment } from '../../../atoms/project-details';
import { ComponentPropsNormarized } from '../../types';

type Props = {
  projectID: number;
  comment: ReviewComment;
} & ComponentPropsNormarized<'div'>;

function Component({ projectID, comment, className }: Props): JSX.Element {
  return (
    <div className={className}>
      <p className={classes.date}>{comment.createDateFormatted}</p>

      {comment.body !== '' && <Votes projectID={projectID} comment={comment} />}
    </div>
  );
}

const PREFIX = 'CommentFooter';
const classes = {
  date: `${PREFIX}-date`,
};

export const CommentFooter = styled(Component)({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: 4,

  [`& .${classes.date}`]: {
    fontSize: '1.2rem',
    lineHeight: '15px',
    color: '#757575',
  },
});
