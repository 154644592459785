import { styled } from '@mui/material/styles';
import { ReactNode } from 'react';
import { RLink } from '../../base/link/link';
import { ComponentPropsNormarized } from '../../types';

type Props = {
  children: ReactNode;
} & ComponentPropsNormarized<typeof RLink>;

function Component({ children, className, ...others }: Props): JSX.Element {
  return (
    <li className={className}>
      <RLink kind="GrayOnGray" {...others}>
        {children}
      </RLink>
    </li>
  );
}

export const MenuItem = styled(Component, {
  shouldForwardProp: (prop) => prop !== 'sx',
})({
  marginRight: '28px',
  '&:last-of-type': {
    marginRight: '0px',
  },
});
