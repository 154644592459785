import { styled } from '@mui/material/styles';
import { ComponentPropsNormarized } from '../../types';

type Props = ComponentPropsNormarized<'p'>;

function Component({ children, className, ...others }: Props): JSX.Element {
  return (
    <p className={className} {...others}>
      {children}
    </p>
  );
}

export const RInputGuide = styled(Component, {
  shouldForwardProp: (prop) => prop !== 'sx',
})({
  color: '#757575',
  fontSize: '12px',
  lineHeight: '15px',
  margin: '4px 0',
});
