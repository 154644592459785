import I_close from '../../../../Images/navigation/close_black.png';

import { styled } from '@mui/material/styles';
import { RImage } from '../../../base/image/image';
import { RModal } from '../../../core/modal/modal';
import { RInput } from '../../../base/input/input';
import { Dispatch, SetStateAction } from 'react';
import { maxLength } from '../../../../services/max-length';
import { RButton } from '../../../base/button/button';
import { ComponentPropsNormarized, ValidationErrors } from '../../../types';
import { RValidationMessages } from '../../../core/validation-message/validation-messages';

type Props = {
  close: () => void;
  done: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  mail: string;
  setMail: Dispatch<SetStateAction<string>>;
  validationErrors: ValidationErrors;
} & ComponentPropsNormarized<'div'>;

function Component({ close, done, mail, setMail, validationErrors, className }: Props): JSX.Element {
  return (
    <RModal close={close} className={className}>
      <section className={classes.wrapper}>
        <p>
          <RImage
            src={I_close}
            sx={{
              cursor: 'pointer',

              position: 'absolute',
              top: 25,
              right: 25,
            }}
            onClick={close}
          />
        </p>

        <h2 className={classes.title}>メールアドレス変更</h2>

        <p>
          新しいメールアドレスを入力してください。
          <br />
          確認用のURLをメールでお送りします。
        </p>

        <form action="" className={classes.form}>
          <RInput
            type="email"
            value={mail}
            setValue={setMail}
            placeholder="新しいメールアドレス"
            sx={{
              width: '100%',
            }}
            maxLength={maxLength.user.mail}
          />
          <RValidationMessages messages={validationErrors.MailAddress} />

          <RButton
            size="L"
            sx={{
              width: '100%',
              marginTop: '24px',
            }}
            onClick={(e): void => done(e)}>
            確認用のメールを送る
          </RButton>
        </form>
      </section>
    </RModal>
  );
}

const PREFIX = 'InputMailAddress';
const classes = {
  wrapper: `${PREFIX}-wrapper`,
  title: `${PREFIX}-title`,
  form: `${PREFIX}-form`,
};

export const InputMailAddress = styled(Component, {
  shouldForwardProp: (prop) => prop !== 'sx',
})({
  [`& .${classes.wrapper}`]: {
    minHeight: 192,
    width: 586,
    padding: 48,
    position: 'relative',

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  [`& .${classes.title}`]: {
    fontWeight: 500,
    textAlign: 'center',
    width: '100%',
    marginBottom: 32,
    fontSize: '2rem',
    lineHeight: 1.5,
  },
  [`& .${classes.form}`]: {
    marginTop: 24,
    width: '100%',
  },
});
