import I_box from '../../../Images/alert/box.png';
import I_boxHover from '../../../Images/action/trashbox_14px_18px.png';

import { styled } from '@mui/material/styles';
import { Dispatch, MouseEventHandler, useCallback, useState } from 'react';
import { File, useRefreshAsync } from '../../../atoms/project-details';
import { useIsMine } from './is-mine';
import { RConfirmDeleteDialog } from '../../core/confirm-delete-dialog/confirm-delete-dialog';
import { useResetRecoilState } from 'recoil';
import { projectDetailsShowHoverMenuAtom } from '../../../atoms/project-details-show-hover-menu';
import { useSave } from '../../hooks/save';
import { ComponentPropsNormarized } from '../../types';

type Props = {
  projectID: number;
  file: File;
  ownerID: number;
} & ComponentPropsNormarized<'div'>;

function Component({ projectID, file, ownerID, className }: Props): JSX.Element {
  const isMine = useIsMine(ownerID);

  const [deleting, setDeleting] = useState(0);
  const onDeleteFile = useOnDeleteFileClicked(projectID, file.id, setDeleting);

  return (
    <div className={className}>
      <div className={classes.fileContentHeader}>
        <h3 className={classes.fileContentHeaderFileName}>{file.name}</h3>
        <ul className={classes.fileContentHeaderUl}>
          <li className={classes.fileContentHeaderDate}>{file.uploadDateFormatted}</li>
          {isMine && <li className={classes.fileContentHeaderTrash} onClick={(): void => setDeleting(file.id)} />}
        </ul>
      </div>
      {deleting === file.id && (
        <RConfirmDeleteDialog close={(): void => setDeleting(0)} onOK={onDeleteFile}>
          「{file.name}」を削除します。よろしいですか？
        </RConfirmDeleteDialog>
      )}
    </div>
  );
}

const useOnDeleteFileClicked = (projectID: number, fileID: number, setDeleting: Dispatch<number>): MouseEventHandler<HTMLElement> => {
  const [save] = useSave('/v1/File/Delete');
  const refreshAsync = useRefreshAsync();
  const resetProjectDetailsShowHoverMenu = useResetRecoilState(projectDetailsShowHoverMenuAtom);

  const onDeleteFileClicked: MouseEventHandler<HTMLElement> = useCallback(
    async (e) => {
      await save(e, {
        projectID: projectID,
        fileID: fileID,
      });

      await refreshAsync();

      resetProjectDetailsShowHoverMenu();

      setDeleting(0);
    },
    [fileID, projectID, refreshAsync, resetProjectDetailsShowHoverMenu, save, setDeleting],
  );

  return onDeleteFileClicked;
};

const PREFIX = 'FileContentHeader';
const classes = {
  fileContentHeader: `${PREFIX}-fileContentHeader`,
  fileContentHeaderFileName: `${PREFIX}-fileContentHeaderFileName`,
  fileContentHeaderUl: `${PREFIX}-fileContentHeaderUl`,
  fileContentHeaderDate: `${PREFIX}-fileContentHeaderDate`,
  fileContentHeaderTrash: `${PREFIX}-fileContentHeaderTrash`,
};

export const FileContentHeader = styled(Component)({
  [`& .${classes.fileContentHeader}`]: {
    display: 'flex',
  },
  [`& .${classes.fileContentHeaderFileName}`]: {
    fontSize: '1.6rem',
    marginLeft: 16,
    marginTop: 12,
    wordBreak: 'break-all',
  },
  [`& .${classes.fileContentHeaderUl}`]: {
    display: 'flex',
    marginLeft: 'auto',
  },
  [`& .${classes.fileContentHeaderDate}`]: {
    marginTop: 11,
    color: '#757575',
    marginRight: 20,
  },
  [`& .${classes.fileContentHeaderTrash}`]: {
    marginTop: 3,
    marginRight: 18,
    cursor: 'pointer',
    backgroundImage: `url(${I_box})`,
    backgroundSize: '14px 18px',
    backgroundPosition: 'right 10px',
    backgroundRepeat: 'no-repeat',
    width: 14,
    [`&:hover`]: {
      backgroundImage: `url(${I_boxHover})`,
    },
  },
});
