import { atom } from 'recoil';

export const reportCategory = {
  user: 1, // T_REPORT.TARGET_ID = M_USER.ID
  comment: 2, // T_REPORT.TARGET_ID = T_REVIEW_COMMENT.ID
  message: 3, // T_REPORT.TARGET_ID = T_MESSAGE.ID
  project: 4, // T_REPORT.TARGET_ID = T_PROJECT.ID
};

type ReportAtom = {
  visible: boolean;
  category: keyof typeof reportCategory;
  targetID: number;
};

export const reportAtom = atom({
  key: 'reportAtom',
  default: {
    visible: false,
    category: 'user',
    targetID: -1,
  } as ReportAtom,
});
