import { styled } from '@mui/material/styles';
import { RefObject, useEffect, useRef } from 'react';
import { useRecoilValue } from 'recoil';
import { projectDetailsCategoriesAtom } from '../../../atoms/project-details-categories';
import { projectDetailsImportanceAtom } from '../../../atoms/project-details-importance';
import { ComponentPropsNormarized } from '../../types';

type Guide = {
  name: string;
  description: string;
};

type GuideGroup = {
  name: string;
  guides: Guide[];
};

type Data = GuideGroup[];

type Props = {
  kind: 'category' | 'importance';
  baseElementID: string;
} & ComponentPropsNormarized<'div'>;

function Component({ kind, baseElementID, className }: Props): JSX.Element {
  const ref = useSetPosition<HTMLDivElement>(baseElementID);

  const categoryGuides = useCategoryGuides();
  const importanceGuides = useImportanceGuides();

  let title: string;
  let guides: Data;
  switch (kind) {
    case 'category':
      title = 'カテゴリのつけ方';
      guides = categoryGuides;
      break;
    case 'importance':
      title = '重要度のつけ方';
      guides = importanceGuides;
      break;
    default:
      throw new Error();
  }

  return (
    <div ref={ref} className={className}>
      <h4 className={classes.header}>{title}</h4>

      <table className={classes.table}>
        {guides.map((guide) => (
          <tbody key={guide.name}>
            <tr>
              <th className={classes.groupName}>{guide.name}</th>
            </tr>
            <GuideRows guides={guide.guides} />
          </tbody>
        ))}
      </table>
    </div>
  );
}

function GuideRows({ guides }: Pick<GuideGroup, 'guides'>): JSX.Element {
  return (
    <>
      {guides.map((guide) => (
        <tr key={guide.name}>
          <th className={classes.guideName}>{guide.name}</th>
          <td className={classes.guideDescription}>{guide.description}</td>
        </tr>
      ))}
    </>
  );
}

const useSetPosition = <T extends HTMLElement>(baseElementID: string): RefObject<T> => {
  const ref = useRef<T>(null);

  useEffect(() => {
    if (ref.current === null) {
      return;
    }

    const baseElement = ref.current.closest(`#${baseElementID}`)!;

    ref.current.style.top = `${baseElement.getBoundingClientRect().top - ref.current.getBoundingClientRect().height}px`;
    ref.current.style.left = `${baseElement.getBoundingClientRect().right - ref.current.getBoundingClientRect().width}px`;
  }, [baseElementID]);

  return ref;
};

const useCategoryGuides = (): Data => {
  const projectDetailsCategories = useRecoilValue(projectDetailsCategoriesAtom);
  return [
    ...projectDetailsCategories.map((c) => ({
      name: c.group,
      guides: c.items.map((item) => ({ name: item.label, description: item.description })),
    })),
  ];
};

const useImportanceGuides = (): Data => {
  const projectDetailsImportance = useRecoilValue(projectDetailsImportanceAtom);
  return [
    {
      name: '',
      guides: projectDetailsImportance.map((c) => ({ name: c.label, description: c.description })),
    },
  ];
};

const PREFIX = 'CategoryGuide';
const classes = {
  header: `${PREFIX}-header`,
  table: `${PREFIX}-table`,
  groupName: `${PREFIX}-groupName`,
  guideName: `${PREFIX}-guideName`,
  guideDescription: `${PREFIX}-guideDescription`,
};

export const CategoryGuide = styled(Component)({
  position: 'fixed',
  overflow: 'visible',
  zIndex: 1,

  // 一瞬不正な位置に表示されるのを抑制。
  top: -10000,
  left: -10000,

  background: '#FFFFFF',
  boxShadow: '0px 0px 2px rgba(56, 56, 56, 0.2), 0px 2px 2px rgba(56, 56, 56, 0.24)',
  borderRadius: 4,
  padding: 12,
  fontSize: '1.2rem',
  whiteSpace: 'nowrap',

  [`& .${classes.header}`]: {
    color: '#757575',
    fontWeight: 'bold',
    lineHeight: '15px',
  },
  [`& .${classes.table}`]: {
    color: '#757575',
    textAlign: 'left',
  },
  [`& .${classes.groupName}`]: {
    color: '#757575',
    paddingTop: 8,
  },
  [`& .${classes.guideName}`]: {
    color: '#404040',
  },
  [`& .${classes.guideDescription}`]: {
    paddingLeft: 12,
  },
});
