import { styled } from '@mui/material/styles';
import { RButton } from '../../base/button/button';
import { useTitle } from '../../hooks/title';
import { Dispatch, MouseEventHandler, useCallback, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { createUserMailAddressAtom } from '../../../atoms/create-user-mail-address';
import { useNavigate } from 'react-router-dom';
import { useSave } from '../../hooks/save';
import { RContainer } from '../../layout/Container';
import { ComponentPropsNormarized } from '../../types';

type Props = ComponentPropsNormarized<typeof RContainer>;

function Component({ className }: Props): JSX.Element {
  useTitle('ユーザー登録');

  const navigate = useNavigate();
  const [resent, setResent] = useState(false);

  const createUserMailAddress = useRecoilValue(createUserMailAddressAtom);
  const onResendClick = useOnResendClick(createUserMailAddress, setResent);

  useEffect(() => {
    if (createUserMailAddress === '') {
      navigate('/');
    }
  }, [createUserMailAddress, navigate]);

  if (createUserMailAddress === '') {
    return <></>;
  }

  return (
    <RContainer className={className}>
      <div className={classes.wrapper}>
        <h1 className={classes.h1}>{resent ? 'メールを再送信しました' : 'まだ登録が完了していません'}</h1>

        <div className={classes.form}>
          <p className={classes.p}>登録時に{createUserMailAddress}宛にお送りした、登録確認用メールにて登録を完了させてください。</p>
          <p className={classes.p}>
            ※メールが届いていない場合、迷惑メールに分類されている可能性があります。
            <br />
            下記のボタンから再送信することもできます。
          </p>
          <RButton
            onClick={onResendClick}
            sx={{
              marginTop: '30px',
              width: '100%',
              height: '48px',
              lineHeight: '48px',
            }}>
            登録確認用メールを再送信する
          </RButton>
        </div>
      </div>
    </RContainer>
  );
}

const useOnResendClick = (mail: string, setResent: Dispatch<boolean>): MouseEventHandler<HTMLElement> => {
  const [save] = useSave('/v1/User/SendActivationMail');

  const onResendClick: MouseEventHandler<HTMLElement> = useCallback(
    async (e) => {
      await save(e, {
        mail: mail,
      });

      setResent(true);
    },
    [mail, save, setResent],
  );

  return onResendClick;
};

const PREFIX = 'LogInNotConfirmed';
const classes = {
  wrapper: `${PREFIX}-wrapper`,
  h1: `${PREFIX}-h1`,
  form: `${PREFIX}-form`,
  p: `${PREFIX}-p`,
};

export const LogInNotConfirmed = styled(Component, {
  shouldForwardProp: (prop) => prop !== 'sx',
})({
  [`& .${classes.wrapper}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  [`& .${classes.h1}`]: {
    textAlign: 'center',
    fontWeight: 500,
    fontSize: '28px',
    marginBottom: '30px',
  },

  [`& .${classes.form}`]: {
    width: 510,
  },
  [`& .${classes.p}`]: {
    width: '100%',
    margin: '25px auto',
    lineHeight: '21px',
  },
});
