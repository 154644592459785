import I_more from '../../../../Images/navigation/more_vert-gray.svg';
import I_delete from '../../../../Images/action/delete_border_red_24px.png';
import I_report from '../../../../Images/action/report.svg';

import { styled } from '@mui/material/styles';
import { classNames } from '../../../classNames';
import { withBR } from '../../../functions/withBR';
import { MessageItem } from './messages';
import { RImage } from '../../../base/image/image';
import { useMenuControl } from '../../../hooks/menu-control';
import { Dispatch, MouseEventHandler, useCallback, useState } from 'react';
import { RConfirmDeleteDialog } from '../../../core/confirm-delete-dialog/confirm-delete-dialog';
import { useReportDialog } from '../../../hooks/report-dialog';
import { useSave } from '../../../hooks/save';
import { ComponentPropsNormarized } from '../../../types';

type Props = {
  message: MessageItem;
  isFirst: boolean;
  refresh: () => void;
} & ComponentPropsNormarized<'article'>;

function Component({ message, isFirst, refresh, className }: Props): JSX.Element {
  const [menuVisible, setMenuVisible, menuElementID] = useMenuControl();

  const [shoudShowConfirmDelete, setShoudShowConfirmDelete] = useState(false);
  const onConfirmDeleteClick = useOnConfirmDeleteClick(setMenuVisible, setShoudShowConfirmDelete);
  const onDeleteClick = useOnDeleteClick(setShoudShowConfirmDelete, refresh);

  const showReportDialog = useReportDialog('message', message.id);
  const onOpenReportClick = useOnOpenReportClick(setMenuVisible, showReportDialog);

  return (
    <article
      className={classNames(
        className,
        classes.article,
        message.sent ? classes.sentMessage : classes.receivedMessage,
        message.sent && !isFirst ? classes.marginTop : undefined,
      )}>
      <div className={classes.messageInner}>
        <p className={classes.sendDate}>
          {message.createDate}
          {message.sent && message.opened && <span className={classes.read}>・既読</span>}
        </p>

        {!message.deleted && (
          <div id={menuElementID} className={classes.menuArea}>
            <p
              className={classNames(classes.menu, message.sent ? classes.sentMessageMenu : classes.receivedMessageMenu)}
              onClick={(): void => setMenuVisible((x) => !x)}>
              <RImage src={I_more} />
            </p>

            {menuVisible && (
              <div className={classes.dropdown}>
                <div
                  className={classNames(classes.menuItem, message.sent ? classes.menuItemDelete : classes.menuItemReport)}
                  onClick={message.sent ? onConfirmDeleteClick : onOpenReportClick}>
                  <RImage src={message.sent ? I_delete : I_report} />
                  <p className={classes.menuItemLabel}>{message.sent ? '削除する' : '通報する'} </p>
                </div>
              </div>
            )}
          </div>
        )}
      </div>

      {message.deleted ? (
        <p className={classNames(classes.messageText, classes.messageDeleted)}>このメッセージは削除されました。</p>
      ) : (
        <p className={classes.messageText}>{withBR(message.body)}</p>
      )}

      {shoudShowConfirmDelete && (
        <RConfirmDeleteDialog close={(): void => setShoudShowConfirmDelete(false)} onOK={(e): void => onDeleteClick(e, message.id)}>
          メッセージを削除します。よろしいですか？
        </RConfirmDeleteDialog>
      )}
    </article>
  );
}

const useOnConfirmDeleteClick = (setMenuVisible: Dispatch<boolean>, setShoudShowConfirmDelete: Dispatch<boolean>): (() => void) => {
  const onConfirmDeleteClick = useCallback(() => {
    setMenuVisible(false);
    setShoudShowConfirmDelete(true);
  }, [setMenuVisible, setShoudShowConfirmDelete]);

  return onConfirmDeleteClick;
};

const useOnDeleteClick = (
  setShoudShowConfirmDelete: Dispatch<boolean>,
  refresh: () => void,
): ((e: React.MouseEvent<HTMLButtonElement, MouseEvent>, messageID: number) => void) => {
  const [save] = useSave('/v1/MyPage/DeleteMessage', 'メッセージを削除しました。');

  const onDeleteClick = useCallback(
    async (e: React.MouseEvent<HTMLElement, MouseEvent>, messageID: number) => {
      setShoudShowConfirmDelete(false);

      await save(e, { messageID: messageID });

      refresh();
    },
    [refresh, save, setShoudShowConfirmDelete],
  );

  return onDeleteClick;
};

const useOnOpenReportClick = (
  setMenuVisible: Dispatch<boolean>,
  showReportDialog: MouseEventHandler<HTMLElement>,
): ((e: React.MouseEvent<HTMLElement, MouseEvent>) => void) => {
  const onConfirmDeleteClick = useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      setMenuVisible(false);
      showReportDialog(e);
    },
    [setMenuVisible, showReportDialog],
  );

  return onConfirmDeleteClick;
};

const PREFIX = 'Message';
const classes = {
  article: `${PREFIX}-article`,
  sentMessage: `${PREFIX}-sentMessage`,
  marginTop: `${PREFIX}-marginTop`,
  receivedMessage: `${PREFIX}-receivedMessage`,
  messageInner: `${PREFIX}-messageInner`,
  sendDate: `${PREFIX}-sendDate`,
  read: `${PREFIX}-read`,
  menuArea: `${PREFIX}-menuArea`,
  menu: `${PREFIX}-menu`,
  sentMessageMenu: `${PREFIX}-sentMessageMenu`,
  receivedMessageMenu: `${PREFIX}-receivedMessageMenu`,
  dropdown: `${PREFIX}-dropdown`,
  menuItem: `${PREFIX}-menuItem`,
  menuItemDelete: `${PREFIX}-menuItemDelete`,
  menuItemReport: `${PREFIX}-menuItemReport`,
  menuItemLabel: `${PREFIX}-menuItemLabel`,
  messageText: `${PREFIX}-messageText`,
  messageDeleted: `${PREFIX}-messageDeleted`,
};

export const Message = styled(Component, {
  shouldForwardProp: (prop) => prop !== 'sx',
})({
  [`&.${classes.article}`]: {
    padding: '11px 15px 15px 15px',
    border: '1px solid #E3E3E3',
  },

  [`&.${classes.sentMessage}`]: {
    background: '#F2F2F2',
    borderRadius: '16px 0px 16px 16px',
  },
  [`&.${classes.marginTop}`]: {
    marginTop: 20,
  },
  [`&.${classes.receivedMessage}`]: {
    marginLeft: 12,
    borderRadius: '0px 16px 16px 16px',
    width: '100%',
  },

  [`& .${classes.messageInner}`]: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  [`& .${classes.sendDate}`]: {
    color: '#757575',
    fontSize: 12,
    lineHeight: 2,
  },
  [`& .${classes.read}`]: {
    color: '#9D9D9D',
    fontSize: 12,
  },

  [`& .${classes.menuArea}`]: {
    position: 'relative',
  },
  [`& .${classes.menu}`]: {
    width: 24,
    height: 24,
    borderRadius: '4px',
    cursor: 'pointer',
  },
  [`& .${classes.sentMessageMenu}`]: {
    [`&:hover`]: {
      background: '#DFDFDF',
    },
  },
  [`& .${classes.receivedMessageMenu}`]: {
    [`&:hover`]: {
      background: '#F2F2F2',
    },
  },
  [`& .${classes.dropdown}`]: {
    width: 'auto',
    height: 'auto',
    position: 'absolute',
    top: '30px',
    right: '10px',

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',

    padding: '4px 0',
    boxShadow: '0px 0px 2px rgba(56, 56, 56, 0.2), 0px 2px 2px rgba(56, 56, 56, 0.24)',
    borderRadius: '4px',
    backgroundColor: '#fff',
  },

  [`& .${classes.menuItem}`]: {
    width: '180px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '6px 20px',
    cursor: 'pointer',
  },
  [`& .${classes.menuItemDelete}`]: {
    color: '#FC5F5F',
    [`&:hover`]: {
      backgroundColor: '#FFEBEB',
    },
  },
  [`& .${classes.menuItemReport}`]: {
    color: '#404040',
    [`&:hover`]: {
      background: '#F2F2F2',
    },
  },
  [`& .${classes.menuItemLabel}`]: {
    marginLeft: 10,
  },

  [`& .${classes.messageText}`]: {
    lineHeight: 1.5,
    marginTop: 4,
  },
  [`& .${classes.messageDeleted}`]: {
    background: '#DFDFDF',
    borderRadius: 8,
    padding: 12,
  },
});
