import { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

export const useIDFromParam = (optional = false): number => {
  const params = useParams();
  const id = Number(params.id);

  const navigate = useNavigate();
  useEffect(() => {
    if (!optional && isNaN(id)) {
      navigate('/Error404');
    }
  }, [id, navigate, optional]);

  return id;
};
