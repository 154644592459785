import { styled } from '@mui/material/styles';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useResetRecoilState } from 'recoil';
import { logInUserAtom } from '../../../../atoms/log-in-user';
import { useOpenSnackbar } from '../../../../atoms/snackbar';
import { RButton } from '../../../base/button/button';
import { RModal } from '../../../core/modal/modal';
import { useSave } from '../../../hooks/save';
import { ComponentPropsNormarized } from '../../../types';

type Props = {
  close: () => void;
} & ComponentPropsNormarized<typeof RModal>;

function Component({ close, className }: Props): JSX.Element {
  const onDeleteAccount = useOnDeleteAccount();

  return (
    <RModal close={close} className={className}>
      <section className={classes.wrapper}>
        <h2 className={classes.title}>
          アカウントを削除すると、もとに戻せません。 <br />
          本当に削除しますか？
        </h2>

        <div className={classes.description}>
          <ul>
            <li>※自分で作成したプロジェクトなどのデータは削除されます。</li>
            <li>※他のユーザーへのコメント・評価などは削除されません。</li>
          </ul>
        </div>

        <ul className={classes.buttons}>
          <li>
            <RButton kind="WithFrame" size="InModalS" onClick={close}>
              キャンセル
            </RButton>
          </li>
          <li className={classes.deleteButton}>
            <RButton kind="Danger" size="InModalS" onClick={onDeleteAccount}>
              削除
            </RButton>
          </li>
        </ul>
      </section>
    </RModal>
  );
}

const useOnDeleteAccount = (): ((e: React.MouseEvent<HTMLElement, MouseEvent>) => Promise<void>) => {
  const [save] = useSave('/v1/MyPage/DeleteAccount', 'アカウントを削除しました。');
  const resetLogInUser = useResetRecoilState(logInUserAtom);
  const openSnackbar = useOpenSnackbar();

  const navigate = useNavigate();

  const onDeleteAccount = useCallback(
    async (e: React.MouseEvent<HTMLElement, MouseEvent>): Promise<void> => {
      await save(e, {});
      resetLogInUser();
      openSnackbar('アカウントを削除しました。');
      navigate('/');
    },
    [navigate, openSnackbar, resetLogInUser, save],
  );

  return onDeleteAccount;
};

const PREFIX = 'ConfirmDelete';
const classes = {
  wrapper: `${PREFIX}-wrapper`,
  title: `${PREFIX}-title`,
  description: `${PREFIX}-description`,
  buttons: `${PREFIX}-buttons`,
  deleteButton: `${PREFIX}-deleteButton`,
};

export const ConfirmDelete = styled(Component, {
  shouldForwardProp: (prop) => prop !== 'sx',
})({
  [`& .${classes.wrapper}`]: {
    width: 466,
    padding: 48,

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  [`& .${classes.title}`]: {
    textAlign: 'center',
    fontSize: 14,
    marginBottom: 12,
    lineHeight: '20px',
  },
  [`& .${classes.description} li`]: {
    marginTop: 4,
    fontSize: 12,
    lineHeight: '15px',
    color: '#757575',
  },
  [`& .${classes.buttons}`]: {
    display: 'flex',
    marginTop: 28,
  },
  [`& .${classes.deleteButton}`]: {
    marginLeft: 16,
  },
});
