import { styled } from '@mui/material/styles';
import { useTitle } from '../../../hooks/title';
import { ComponentPropsNormarized } from '../../../types';
import { BasicInfo } from './basic-info';
import { Header } from './header';
import { Languages } from './languages';

type Props = ComponentPropsNormarized<'div'>;

function Component({ className }: Props): JSX.Element {
  useTitle('プロフィール');

  return (
    <div className={className}>
      <Header />
      <BasicInfo />
      <Languages />
    </div>
  );
}

export const Edit = styled(Component, {
  shouldForwardProp: (prop) => prop !== 'sx',
})({
  padding: 31,
  position: 'relative',

  backgroundColor: '#fff',
  borderRadius: 8,
  border: '1px solid #E3E3E3',

  [`& h2`]: {
    marginBottom: 24,

    [`& span`]: {
      fontWeight: 'bold',
    },
  },
});
