import { styled } from '@mui/material/styles';
import { useTitle } from '../../hooks/title';
import { RRecord } from '../../core/record/record/record';
import { RContainer } from '../../layout/Container';
import { ComponentPropsNormarized } from '../../types';

type Props = ComponentPropsNormarized<typeof RContainer>;

function Component({ className }: Props): JSX.Element {
  useTitle('利用規約');

  return (
    <RContainer className={className}>
      <RRecord
        hover="None"
        sx={{
          padding: '28px 40px',
        }}>
        <h2 className={classes.title}>利用規約</h2>

        <p className={classes.description}>
          本サービスは、本利用規約に同意された方にのみ提供するサービスです。 <br />
          本サービスの利用者は、本利用規約の内容を十分理解した上でその内容を遵守することに同意して本サービスを利用するものとし、本サービスを利用した場合には、当該利用者は本利用規約を遵守することに同意したものとみなします。
        </p>

        <div className={classes.contents}>
          <h3>第1条 定義</h3>
          <ol>
            <li>
              本利用規約の中で使用される以下の各用語は、それぞれ以下各号の意味を有するものとする。
              <ol>
                <li>メンバー：本サイトで所定の手続きを行い、ログイン可能な状態になっている方を指す。</li>
                <li>ビジター：本サイトの利用者のうちメンバー以外を指す。本サイトの閲覧者を含む。</li>
                <li>ユーザー：メンバーとビジターの総称のことを指す。</li>
                <li>運営団体：当サービスの運営団体を指す。</li>
              </ol>
            </li>
          </ol>

          <h3>第2条 本サービスの利用</h3>
          <ol>
            <li>ユーザーは本利用規約に同意することによって、本サービスを利用できるものとする。</li>
          </ol>

          <h3>第3条 メンバー登録</h3>
          <ol>
            <li>
              メンバー登録手続を行う者は、登録情報の入力にあたり、入力した情報の正確性・真実性・最新性等一切について、メンバー自らが責任を負うものとする。
            </li>
            <li>メンバーが退会を希望する場合には、所定の手続を行うこととする。</li>
            <li>
              運営団体が不適切と判断した場合、あらかじめ通知することなく当該メンバーの登録を取り消し強制退会とすることができるものとする。強制退会の理由について運営団体は開示の責任を負わないものとする。
            </li>
            <li>運営団体は、強制退会によりメンバー又は第三者に損害が発生した場合であっても、一切責任を負わないものとする。</li>
            <li>運営団体はメンバーが登録した全ての情報(メンバー間でやりとりしたメッセージなど、非公開の内容を含む)を閲覧することができるものとする。</li>
            <li>メンバーは運営団体からの問い合わせに関して速やかに回答する義務があるものとする。</li>
            <li>メンバーは、自らが登録したデータの一部または全部が退会後も公開され続けることに同意するものとする。</li>
          </ol>

          <h3>第4条 契約</h3>
          <ol>
            <li>ユーザーは、本サービスを通じてユーザー間でレビュー委託契約を締結することができる。</li>
            <li>レビュー委託契約はユーザー間での二者契約とし、運営団体は契約の当事者とはならないものとする。</li>
            <li>レビュー委託契約の金額、内容、履行状況などについてはユーザー間で責任を持つものとし、運営団体は関与しないものとする。</li>
            <li>レビュー委託契約に関してユーザー間で紛争が生じた場合は当事者間で解決するものとし、運営団体は関与しないものとする。</li>
            <li>ユーザーはレビュー委託契約の締結に際し、運営団体に手数料を支払うものとする。その金額、支払い方法などは別途定めるとおりとする。</li>
            <li>レビュー委託契約の報酬は、委託者が当サービスを通じて運営団体に支払い、運営団体が受託者に支払うものとする。</li>
            <li>レビュー委託契約の報酬を受託者が受け取ることができる時期やその方法は別途定めるとおりとする。</li>
            <li>レビュー委託契約は委託者、受託者双方の合意により解除することができる。</li>
            <li>レビュー委託契約を解除した場合でも、運営団体への手数料の支払いは免れないものとする。</li>
            <li>レビュー委託契約を解除した場合でも、当サービス上では返金処理を行わないものとし、当事者間で直接対応するものとする。</li>
            <li>自らの意思による退会か強制退会かによらず、退会後も各メンバー間での契約に基づく義務は継続するものとする。</li>
            <li>
              自らの意思による退会か強制退会かによらず、レビュー契約の当事者たるメンバーが退会となった場合、現に有効である契約は当然には解除されないものとする。
            </li>
            <li>自らの意思による退会か強制退会かによらず、レビュー契約の当事者たるメンバーが退会となった場合、契約の更新は行われないものとする。</li>
            <li>
              自らの意思による退会か強制退会かによらず、メンバーが退会となった場合、出金することのできる状態にある金銭、及び今後支払われる予定であった金銭は、受け取る権利がなくなるものとする。
            </li>
          </ol>

          <h3>第5条 禁止事項</h3>
          <ol>
            <li>
              ユーザーは、本サービスの利用にあたり、以下の各号のいずれかに該当する行為をしてはならない。
              <ol>
                <li>本サービスで提供している内容およびこれと類似の内容について、本サービス以外で、本サービスのユーザーと直接契約する行為</li>
                <li>本サービスと競合するサービス等を宣伝する行為及びこれに類する行為</li>
                <li>本サービス上での契約以外の目的で他のユーザーと個人情報を交換する行為</li>
                <li>運営団体とのメールなどでのやりとりの内容を公開する行為</li>
                <li>コンピューターウィルスなどの不適切な内容を登録する行為</li>
                <li>その他、運営団体が不適切と判断する行為</li>
              </ol>
            </li>
            <li>
              ユーザーは、前項各号の禁止事項を行った場合、損害賠償または違約金として、10万円または運営団体に現実に生じた損害のうち、いずれか高い方を運営団体に支払うものとする。
            </li>
          </ol>

          <h3>第6条 損害賠償</h3>
          <ol>
            <li>
              本サービスに関して運営団体がユーザーに対する損害賠償義務を負う場合であっても、運営団体はユーザーに現実に生じた通常損害(直接損害に限る)に対して、ユーザーが過去1年間に運営団体に支払った利用代金の総額を限度額として責任を負うものとする。
            </li>
            <li>
              運営団体は運営団体の予見可能性の有無を問わず、逸失利益、間接損害や特別の事情により生じた損害については、ユーザーに対する賠償責任を負わないものとする。
            </li>
          </ol>

          <h3>第7条 サービス内容の保証および変更</h3>
          <ol>
            <li>
              運営団体は、ユーザーにあらかじめ通知することなくサービスの内容や仕様を変更したり、一部または全部の提供を停止したり終了したりすることができるものとし、これに起因する直接及び間接的な損害に関する一切の責任を負わないものとする。
            </li>
            <li>
              運営団体は、提供するサービスの内容について、バグ等の瑕疵のないことを保証するものではなく、これに起因する直接及び間接的な損害に関する一切の責任を負わないものとする。
            </li>
            <li>
              運営団体は、ユーザーにあらかじめ通知することなく、ユーザーが登録したデータを当サービス上で一時的または恒久的に非表示または削除することができるものとする。その際にユーザーや第三者に生じた損害について運営団体は責任を負わないものとする。
            </li>
          </ol>

          <h3>第8条 免責</h3>
          <ol>
            <li>
              運営団体は、以下の各号に該当する損害、ならびに以下の各号に付随する二次的なデータの漏洩、損失、損害にかかる賠償請求等の法的責任及び道義的責任を一切負わないものとする。
              <ol>
                <li>運営団体が提供していないプログラムによって生じる損害。</li>
                <li>運営団体以外の第三者による不正な行為によって生じる損害。</li>
                <li>ハッカー及びクラッカーによるサーバーへの侵入または攻撃等の行為による損害。</li>
                <li>運営団体が当サービスのために用いるサーバーにおいて生じた事情により生じる損害。</li>
                <li>当サービスのバグによって生じる損害。</li>
              </ol>
            </li>
            <li>
              運営団体は、当サービスについて、その完全性、特定目的適合性、その他一切を保証せず、当サービスの提供が中断することなく継続することを保証しない。
            </li>
            <li>運営団体は、当サービスの提供が停止または中止した場合であっても、ユーザーや第三者に生じた損害について責任を負わないものとする。</li>
            <li>
              運営団体は、当サービスにユーザーがアップロードしたデータ・情報の真偽等に関しては、一切責任を負わないものとし、当サービスにアップロードされたデータ・情報の正確性や当該情報をアップデートする責任は、全てユーザーにあるものとする。
            </li>
            <li>
              ユーザーは、当サービスで保存するデータについて、自らバックアップを行う義務を負うものであって、当サービスに保管・蓄積されたユーザーのデータが消失、毀損した場合であっても、運営団体は、ユーザーや第三者に対していかなる責任も負わない。
            </li>
          </ol>

          <h3>第9条 利用規約の変更</h3>
          <ol>
            <li>運営団体は、ユーザーにあらかじめ通知することなく、いつでも本利用規約、本サイトの仕様等を変更することができるものとする。</li>
            <li>
              前項に基づく本利用規約の変更にあたり、運営団体は、ユーザーに対して、変更後の利用規約の効力発生日及び変更内容について、事前に以下の各号の一の方法により周知するものとする。
              <ol>
                <li>本サイトへの掲載</li>
                <li>ユーザーへの電子メールの送信</li>
                <li>その他運営団体が適切と判断した方法</li>
              </ol>
            </li>
            <li>変更後の利用規約の効力発生日以降にユーザーが本サイトを利用した場合、本利用規約の変更に同意したものとみなす。</li>
            <li>規約の変更によりユーザーに損害が生じた場合であっても、運営団体は一切の責任を負わない。</li>
          </ol>

          <h3>第10条 準拠法・分離可能性・管轄裁判所</h3>
          <ol>
            <li>本利用規約は、日本法に基づき解釈されるものとする。</li>
            <li>
              本利用規約の一部について裁判所やその他正当な権限を有する機関により違法、執行不能又は無効とされた場合、その違法性、執行不能性又は無効性は、本利用規約の他の条項の適法性、執行可能性又は有効性に一切影響を与えないものとする。
            </li>
            <li>本サービスに関連して訴訟等の必要が生じた場合には、千葉地方裁判所を第一審の専属的合意管轄裁判所とする。</li>
          </ol>
        </div>

        <div className={classes.footer}>
          <p>制定日：2021年2月1日</p>
        </div>
      </RRecord>
    </RContainer>
  );
}

const PREFIX = 'LegalTermsOfUse';
const classes = {
  title: `${PREFIX}-title`,
  description: `${PREFIX}-description`,
  contents: `${PREFIX}-contents`,
  footer: `${PREFIX}-footer`,
};

export const LegalTermsOfUse = styled(Component, {
  shouldForwardProp: (prop) => prop !== 'sx',
})({
  [`& .${classes.title}`]: {
    fontSize: '2rem',
    lineHeight: '3rem',
    fontWeight: 500,
  },
  [`& .${classes.description}`]: {
    marginTop: 32,
  },

  [`& h3`]: {
    marginTop: 32,
    fontWeight: 'bold',
    fontSize: '1.6rem',
    lineHeight: '150%',
  },
  [`& ol`]: {
    marginTop: 16,
    paddingLeft: 0,
  },
  [`& ol li`]: {
    listStyleType: 'decimal',
    marginLeft: 40,
  },
  [`& ol li ol`]: {
    marginTop: 12,
  },
  [`& ol li ol li `]: {
    marginLeft: 28,
  },

  [`& .${classes.contents}`]: {
    paddingBottom: 32,
    borderBottom: 'solid 1px #E3E3E3',
  },
  [`& .${classes.footer}`]: {
    marginTop: 32,
  },
});
