import { styled } from '@mui/material/styles';
import { classNames } from '../../classNames';
import { RButton } from '../../base/button/button';
import { MouseEvent, MouseEventHandler, useCallback, useState } from 'react';
import { RValidationMessages } from '../../core/validation-message/validation-messages';
import { ComponentPropsNormarized, ValidationErrors } from '../../types';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTitle } from '../../hooks/title';
import { RPassword } from '../../base/password/password';
import { useSave } from '../../hooks/save';
import { useOpenSnackbar } from '../../../atoms/snackbar';
import { minLength } from '../../../services/max-length';
import { RInputGuide } from '../../core/input-guide/input-guide';
import { RContainer } from '../../layout/Container';

type Props = ComponentPropsNormarized<typeof RContainer>;

function Component({ className }: Props): JSX.Element {
  useTitle('パスワードリセット');

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const confirmParam = params.get('confirmParam') ?? '';
  const [password, setPassword] = useState('');

  const [validationErrors, onClick] = useOnCreateClick(confirmParam, password);

  return (
    <RContainer className={className}>
      <div className={classes.wrapper}>
        <h1 className={classes.h1}>新しいパスワードを設定</h1>

        <form method="post" action="/User/LogIn" className={classes.form}>
          <p className={classes.p}>新しいパスワードを設定してください。</p>

          <RValidationMessages messages={validationErrors['']} />
          <RValidationMessages
            messages={
              validationErrors.ConfirmParam === undefined
                ? []
                : ['URLが間違っているか、有効期限が切れています。再度パスワードリセットの手続きを行ってください。']
            }
          />

          <RPassword
            acceptEnterKey
            value={password}
            setValue={setPassword}
            sx={{
              margin: '24px auto 0 auto',
            }}
          />
          <RValidationMessages messages={validationErrors.Password} />
          <RInputGuide>※{minLength.user.password}文字以上で入力してください。</RInputGuide>

          <p className={classNames(classes.p, classes.link)}>
            <RButton
              sx={{
                width: '100%',
                height: '48px',
              }}
              onClick={onClick}>
              パスワードを設定する
            </RButton>
          </p>
        </form>
      </div>
    </RContainer>
  );
}

const useOnCreateClick = (confirmParam: string, password: string): [ValidationErrors, MouseEventHandler<HTMLElement>] => {
  const [save, validationErrors] = useSave('/v1/PasswordReset/Reset');
  const navigate = useNavigate();
  const openSnackbar = useOpenSnackbar();

  const onLogInClick = useCallback(
    async (e: MouseEvent<HTMLElement>) => {
      const response = await save(e, {
        confirmParam: confirmParam,
        password: password,
      });

      if (response.errorCode !== null) {
        return;
      }

      type Payload = {
        name: string;
      };

      const payload = response.payload as Payload;

      openSnackbar(`${payload.name}さん、おかえりなさい！`);
      navigate('/');
    },
    [confirmParam, navigate, openSnackbar, password, save],
  );

  return [validationErrors, onLogInClick];
};

const PREFIX = 'PasswordResetReset';
const classes = {
  wrapper: `${PREFIX}-wrapper`,
  h1: `${PREFIX}-h1`,
  form: `${PREFIX}-form`,
  p: `${PREFIX}-p`,
  link: `${PREFIX}-link`,
};

export const PasswordResetReset = styled(Component, {
  shouldForwardProp: (prop) => prop !== 'sx',
})({
  [`& .${classes.wrapper}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  [`& .${classes.h1}`]: {
    fontWeight: 500,
    fontSize: '28px',
  },

  [`& .${classes.form}`]: {
    width: 510,
  },
  [`& .${classes.p}`]: {
    width: '100%',
    marginTop: 24,
    lineHeight: '21px',
    textAlign: 'center',
  },
  [`& .${classes.link}`]: {
    marginTop: '30px',
  },
});
