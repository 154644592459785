import I_more10 from '../../../../Images/navigation/more10.png';

import { css, SerializedStyles } from '@emotion/react';
import { styled } from '@mui/material/styles';
import { ReactNode } from 'react';
import { ComponentPropsNormarized } from '../../../types';

type Props = {
  hover?: 'None' | 'Blue';
  unreadMessage?: boolean;
  next10?: boolean;
  children?: ReactNode;
} & ComponentPropsNormarized<'div'>;

function Component({ next10 = false, children, className, ...others }: Props): JSX.Element {
  return (
    <div className={className} {...others}>
      {next10 ? '次の10件を表示' : children}
    </div>
  );
}

const getCSS = ({ hover, unreadMessage, next10 }: Props): SerializedStyles => {
  const base: SerializedStyles = css({
    display: 'block',

    padding: '19px 19px 20px 19px',
    background: '#FFFFFF',

    borderTop: '1px solid #E3E3E3',
    borderLeft: '1px solid #E3E3E3',
    borderRight: '1px solid #E3E3E3',

    '&:first-of-type': {
      borderTopLeftRadius: '8px',
      borderTopRightRadius: '8px',
    },
    '&:last-of-type': {
      borderBottom: '1px solid #E3E3E3',
      borderBottomLeftRadius: '8px',
      borderBottomRightRadius: '8px',
    },
  });

  const withHover: SerializedStyles = css({
    '&:hover': {
      background: '#F2F2F2',
      cursor: 'pointer',
      color: '#404040',
    },
  });
  const withHoverBlue: SerializedStyles = css({
    color: '#757575',

    '&:hover': {
      background: '#F0F7FF',
      cursor: 'pointer',
      color: '#0080FF',
    },
  });

  const unreadMessageCSS: SerializedStyles = css({
    background: '#F0F7FF',
  });

  const next10CSS: SerializedStyles = css({
    height: '40px',
    padding: '7px 19px',
    color: '#8A8A8A',
    '&:hover': {
      color: '#8A8A8A',
    },
    '&::after': {
      content: '""',
      display: 'inline-block',
      width: '10px',
      height: '5px',
      background: `url(${I_more10})`,
      marginLeft: '7px',
      position: 'relative',
      top: '-3px',
    },
  });

  return css([base, hover === 'Blue' && withHoverBlue, hover === undefined && withHover, unreadMessage && unreadMessageCSS, next10 && next10CSS]);
};

export const RRecord = styled(Component, { shouldForwardProp: (prop) => prop !== 'sx' && prop !== 'hover' && prop !== 'unreadMessage' })(getCSS);
