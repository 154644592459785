import { styled } from '@mui/material/styles';
import { ComponentPropsNormarized } from '../../types';

type Props = {
  color: string;
} & ComponentPropsNormarized<'span'>;

function Component({ color, className }: Props): JSX.Element {
  return <span className={className} style={{ backgroundColor: color }} />;
}

export const Circle = styled(Component, {
  shouldForwardProp: (prop) => prop !== 'sx',
})({
  margin: '3px 8px 0 0',
  width: 16,
  height: 16,
  backgroundColor: '#fdba35',
  borderRadius: '50%',

  float: 'left',
});
