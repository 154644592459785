import { styled } from '@mui/material/styles';
import { useTitle } from '../../hooks/title';
import { RRecord } from '../../core/record/record/record';
import { RLink } from '../../base/link/link';
import { RContainer } from '../../layout/Container';
import { ComponentPropsNormarized } from '../../types';

type Props = ComponentPropsNormarized<typeof RContainer>;

function Component({ className }: Props): JSX.Element {
  useTitle('プライバシーポリシー');

  return (
    <RContainer className={className}>
      <RRecord
        hover="None"
        sx={{
          padding: '28px 40px',
        }}>
        <h2 className={classes.title}>プライバシーポリシー</h2>

        <p className={classes.description}>
          本サービスの運営団体(以下「運営団体」という)は、運営団体が取り扱う全ての個人情報の保護について、個人情報に関する法規制等を遵守します。
        </p>

        <div className={classes.inquiryWrapper}>
          <h4 className={classes.inquiryTitle}>プライバシーポリシーに関するお問い合わせ</h4>
          <p className={classes.inquiryLinkWrapper}>
            <RLink href="https://docs.google.com/forms/d/e/1FAIpQLSftphkg3eHG7fuDbxgqqyNVE5qht1ELuLzQCzSJDj2wYBTgbA/viewform" target="_blank" rel="noreferrer">
              https://docs.google.com/forms/d/e/1FAIpQLSftphkg3eHG7fuDbxgqqyNVE5qht1ELuLzQCzSJDj2wYBTgbA/viewform
            </RLink>
          </p>
        </div>

        <div className={classes.contents}>
          <h3>1. 個人情報の利用目的</h3>
          <p>運営団体は、以下の利用目的のためにユーザーの個人情報を収集します。</p>
          <ol>
            <li>ご利用登録に関する各種手続き、認証、履歴管理、その他当サービスの運営に必要な管理。</li>
            <li>緊急時その他の必要な、お客様へのご連絡。</li>
            <li>利用料金の請求や報酬金額などのお支払い等、緊急事態が発生した際のご連絡。</li>
            <li>当サービスの利用規約に違反する行為への対応、及び当該違反行為の防止。</li>
            <li>当サービスに関する規約、ポリシー等の変更等をお客様に通知。</li>
            <li>当サービスの利用に関するお知らせ。</li>
            <li>お客様からのお問い合わせ等への対応。</li>
            <li>個人を特定できない範囲での統計利用。</li>
            <li>その他、上記業務に関連又は付随する業務。</li>
          </ol>

          <h3>2.個人情報の第三者への提供</h3>
          <p>個人情報の保護に関する法律第23条第1項各号により提供する場合（法令に基づく場合など）には、第三者に提供することがあります。</p>

          <h3>3.個人情報取扱の委託</h3>
          <p>運営団体は事業運営上、前項利用目的の範囲に限って個人情報を外部に委託することがあります。</p>

          <h3>4.個人情報の開示等の請求</h3>
          <p>ご本人様は、運営団体に対してご自身の個人情報の開示等に関して、下記の窓口に要求することができます。</p>

          <h3>5.開示対象個人情報の取扱いに関する苦情の申し出先</h3>
          <p>
            <RLink href="https://docs.google.com/forms/d/e/1FAIpQLSftphkg3eHG7fuDbxgqqyNVE5qht1ELuLzQCzSJDj2wYBTgbA/viewform" target="_blank" rel="noreferrer">
              https://docs.google.com/forms/d/e/1FAIpQLSftphkg3eHG7fuDbxgqqyNVE5qht1ELuLzQCzSJDj2wYBTgbA/viewform
            </RLink>
          </p>

          <h3>6.個人情報を提供されることの任意性について</h3>
          <p>
            ご本人様が運営団体に個人情報を提供されるかどうかは任意によるものです。
            <br />
            ただし、必要な項目をいただけない場合、適切な対応ができない場合があります。
          </p>

          <h3>7.クッキー（Cookie）について</h3>
          <p>運営団体は、以下の目的でクッキーを利用いたします。</p>
          <ol>
            <li>設定内容の保存等利便性向上のため</li>
            <li>セッションの維持及び保護等セキュリティのため</li>
            <li>本サービスに対する訪問回数及び利用形態等の把握による、サービス拡充のため</li>
          </ol>
          <p>
            ※クッキーの使用可否については自由にご選択いただけますが、クッキーの保存を拒否される場合にはログインが必要なサービス等、本サービスの一部をご利用いただけない場合がございます。
          </p>
        </div>

        <div className={classes.footer}>
          <p>制定日：2021年2月1日</p>
        </div>
      </RRecord>
    </RContainer>
  );
}

const PREFIX = 'LegalPrivacyPolicy';
const classes = {
  title: `${PREFIX}-title`,
  description: `${PREFIX}-description`,
  inquiryWrapper: `${PREFIX}-inquiryWrapper`,
  inquiryTitle: `${PREFIX}-inquiryTitle`,
  inquiryLinkWrapper: `${PREFIX}-inquiryLinkWrapper`,
  contents: `${PREFIX}-contents`,
  footer: `${PREFIX}-footer`,
};

export const LegalPrivacyPolicy = styled(Component, {
  shouldForwardProp: (prop) => prop !== 'sx',
})({
  [`& .${classes.title}`]: {
    fontSize: '2rem',
    lineHeight: '3rem',
    fontWeight: 500,
  },
  [`& .${classes.description}`]: {
    marginTop: 32,
  },
  [`& .${classes.inquiryWrapper}`]: {
    marginTop: 20,
    background: '#F2F2F2',
    borderRadius: 4,
    padding: '8px 16px',
  },
  [`& .${classes.inquiryTitle}`]: {
    fontWeight: 'bold',
    fontSize: '1.4rem',
    lineHeight: '180%',
  },
  [`& .${classes.inquiryLinkWrapper}`]: {
    marginTop: 8,
  },

  [`& h3`]: {
    marginTop: 32,
    fontWeight: 'bold',
    fontSize: '1.6rem',
    lineHeight: '150%',
  },
  [`& .${classes.contents} p`]: {
    marginTop: 20,
  },
  [`& ol`]: {
    marginTop: 16,
    paddingLeft: 0,
  },
  [`& ol li`]: {
    listStyleType: 'decimal',
    marginLeft: 40,
  },
  [`& ol li ol`]: {
    marginTop: 12,
  },
  [`& ol li ol li `]: {
    marginLeft: 28,
  },

  [`& .${classes.contents}`]: {
    paddingBottom: 32,
    borderBottom: 'solid 1px #E3E3E3',
  },

  [`& .${classes.footer}`]: {
    marginTop: 32,
  },
});
