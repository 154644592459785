import 'code-prettify-google/src/prettify';

import { useCallback, useEffect, useRef } from 'react';
import { File, Review } from '../../../atoms/project-details';
import { getReviewsToShow } from './review-util';
import { useRecoilState, useRecoilValue } from 'recoil';
import { projectDetailsShowClosedReviewAtom } from '../../../atoms/project-details-show-closed-review';
import { logInUserAtom } from '../../../atoms/log-in-user';
import { projectDetailsNewReviewTargetAtom } from '../../../atoms/project-details-new-review-target';
declare const PR: { prettyPrint: () => void };

export const usePrettify = (files: File[], reviews: Review[]): void => {
  const shouldShowClosedReview = useRecoilValue(projectDetailsShowClosedReviewAtom);
  const reviewsToShow = getReviewsToShow(reviews, shouldShowClosedReview);
  const logInUser = useRecoilValue(logInUserAtom);

  const processedFileIDsRef = useRef<number[]>([]);

  useEffect(() => {
    PR.prettyPrint();

    const f = (): void => {
      // prettyprintはprettifyが付与するクラス。
      // prettyPrintが終わってから下記を実行するため、終わっていなければ待つ。
      if (!isPrettifyDone()) {
        setTimeout(() => {
          f();
        }, 50);
        return;
      }

      setWidth();

      // ファイルが追加された場合は、そのファイルだけを対象として実行する。
      // さもないと、要素が二重に作成されるなどで壊れる。
      for (const file of files) {
        if (processedFileIDsRef.current.includes(file.id)) {
          continue;
        }

        generateSpan(file.id);
        setAttribute(file.id);

        processedFileIDsRef.current.push(file.id);
      }

      hoverHighlight(logInUser.id !== null);
    };
    f();
  }, [files, logInUser.id]);

  useRefreshCommentIcon(reviewsToShow);
};

const isPrettifyDone = (): boolean => document.querySelectorAll('.prettyprinted').length >= document.querySelectorAll('.prettyprint').length;

const setAttribute = (targetFileID: number): void => {
  forEachLine((line, fileID, lineNumber) => {
    if (fileID !== targetFileID) {
      return;
    }

    line.id = getLineID(fileID, lineNumber);
    line.setAttribute('data-file-id', fileID.toString());
    line.setAttribute('data-line-number', lineNumber.toString());
  });
};

// クリック可能領域＆ハイライト領域を広げる。
const setWidth = (): void => {
  document.querySelectorAll('pre.prettyprint > ol.linenums').forEach((file) => {
    const sectionElem = file.closest('.FileContents-code');
    const width = sectionElem!.scrollWidth - 68;

    file.querySelectorAll('pre.prettyprint > ol > li').forEach((li) => {
      const elem = li as HTMLElement;
      if (elem.clientWidth < width) {
        elem.style.width = `${width}px`;
      }
    });
  });
};

const generateSpan = (targetFileID: number): void => {
  forEachLine((line, fileID, _lineNumber) => {
    if (fileID !== targetFileID) {
      return;
    }

    const span = document.createElement('span');
    span.innerHTML = line.innerHTML;
    line.innerHTML = '';
    line.appendChild(span);
    span.classList.add('generated');
    span.setAttribute('data-line', 'true');
  });
};

function useRefreshCommentIcon(reviewsToShow: Review[]): void {
  useEffect(() => {
    forEachLine((_line, fileID, lineNumber, lineNumberElem) => {
      if (lineNumberElem === null) {
        return;
      }

      const review = reviewsToShow.find((r) => r.fileID === fileID && r.line === lineNumber);
      if (review) {
        if (!lineNumberElem.getAttribute('data-review-id')) {
          lineNumberElem.setAttribute('data-review-id', review.id.toString());
        }
        lineNumberElem.classList.add('active');
      } else {
        lineNumberElem.classList.remove('active');
      }
    });
  }, [reviewsToShow]);
}

export const hoverHighlight = (on: boolean): void => {
  forEachLine((line, _fileID, _lineNumber) => {
    if (on) {
      line.classList.add('normal');
    } else {
      line.classList.remove('normal');
    }
  });
};

const forEachLine = (f: (line: Element, fileID: number, lineNumber: number, lineNumberElem: Element | null) => void): void => {
  const files = document.querySelectorAll('pre.prettyprint');

  files.forEach((file) => {
    const fileID = parseInt(file.getAttribute('data-file-id') as string);

    const lineNumberElements = file.previousElementSibling!.children;

    const lineLIs = file.querySelectorAll('ol > li');
    let i = 0;
    lineLIs.forEach((lineLI) => {
      f(lineLI, fileID, i + 1, lineNumberElements.item(i));
      i++;
    });
  });
};

export const useOnCodeClicked = (): ((event: React.MouseEvent<HTMLElement, MouseEvent>) => void) => {
  const logInUser = useRecoilValue(logInUserAtom);
  const [projectDetailsNewReviewTarget, setProjectDetailsNewReviewTarget] = useRecoilState(projectDetailsNewReviewTargetAtom);

  const onCodeClicked = useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      if (logInUser.id === null) {
        return;
      }

      if (projectDetailsNewReviewTarget.line !== null) {
        return;
      }

      const element = (event.target as HTMLElement).closest('span[data-line=true]');

      // 行番号がクリックされた。
      if (!element) {
        return;
      }

      const li = element.parentElement as HTMLElement;

      li.classList.add('clicked');

      const fileID = parseInt(li.getAttribute('data-file-id') as string);
      const lineNumber = parseInt(li.getAttribute('data-line-number') as string);

      setProjectDetailsNewReviewTarget({
        fileID: fileID,
        line: lineNumber,
        event: event.nativeEvent,
      });
      hoverHighlight(false);
    },
    [logInUser.id, projectDetailsNewReviewTarget.line, setProjectDetailsNewReviewTarget],
  );

  return onCodeClicked;
};

export const scrollToComment = (reviewID: number): void => {
  const target = document.getElementById(`comment-list-${reviewID}`) as HTMLElement;
  target.scrollIntoView();
};

export const scrollToCommentIcon = (fileID: number, lineNumber: number): void => {
  const lineID = getLineID(fileID, lineNumber);
  const target = document.getElementById(lineID) as HTMLElement;
  target.scrollIntoView();

  const section = document.getElementById(`file-section-${fileID}`) as HTMLElement;
  section.scrollLeft = 0;
};

export const clearHighlight = (): void => {
  const lis = document.querySelectorAll('pre.prettyprint ol > li');
  lis.forEach((li) => li.classList.remove('clicked'));
};

const getLineID = (fileID: number, lineNumber: number): string => `code-pane-${fileID}:${lineNumber}`;
