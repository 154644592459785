import { MouseEventHandler, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { logInSuggestionAtom } from '../../atoms/log-in-suggestion';
import { logInUserAtom } from '../../atoms/log-in-user';

/**
 * 「未ログインの場合はログインを促し、ログイン済みなら指定の処理を行う」関数を返す。
 *
 * @param description 「ログインしてXXしませんか？」のような案内文。
 * @param to ログイン済みだった場合に実行する処理または遷移先。
 * @returns 「未ログインの場合はログインを促し、ログイン済みなら指定の処理を行う」関数。
 */
export const useLogInSuggestion = (description: string, to: string | MouseEventHandler<HTMLElement>): MouseEventHandler<HTMLElement> => {
  const logInUser = useRecoilValue(logInUserAtom);
  const setLogInSuggestion = useSetRecoilState(logInSuggestionAtom);
  const navigate = useNavigate();

  const onClick: MouseEventHandler<HTMLElement> = useCallback(
    (e) => {
      if (logInUser.id === null) {
        setLogInSuggestion({
          visible: true,
          description: description,
          to: to,
        });
        return;
      }

      if (typeof to === 'string') {
        if (location.pathname !== to) {
          navigate(to);
        }
      } else {
        to(e);
      }
    },
    [logInUser.id, setLogInSuggestion, description, to, navigate],
  );

  return onClick;
};
