import { styled } from '@mui/material/styles';
import { ChangeEventHandler, Dispatch, useCallback, useEffect, useRef, useState } from 'react';
import { ComponentPropsNormarized } from '../../types';
import { ROption } from '../option/option';

export type Item = {
  label: string;
  value?: string;
};
export type ItemsInGroup = {
  group: string;
  items: Item[];
};

type Props = {
  kind?: 'Default' | 'Required' | 'Optional';
  data: (Item | ItemsInGroup)[];
  value: string | undefined;
  setValue: Dispatch<string>;
} & Omit<ComponentPropsNormarized<'select'>, 'children' | 'value'>;

function Comppnent({ kind = 'Default', value, setValue, data, className, ...others }: Props): JSX.Element {
  const ref = useRef<HTMLSelectElement>(null);
  const [first, setFirst] = useState(true);

  const onChange: ChangeEventHandler<HTMLSelectElement> = useCallback(
    (e) => {
      setValue(e.target.value);
    },
    [setValue],
  );

  const selected = kind === 'Default' || (ref.current !== null && ref.current.selectedIndex > 0);

  // 上記のスタイル制御のため、refが設定された状態でレンダリングする必要がある。
  useEffect(() => {
    if (first) {
      setFirst(false);
    }
  }, [first]);

  return (
    <select
      ref={ref}
      style={
        selected
          ? {
              color: '#404040',
            }
          : {}
      }
      value={value}
      onChange={onChange}
      className={className}
      {...others}>
      {data.map(
        // eslint-disable-next-line no-confusing-arrow
        (dataItem, index) =>
          isItemsInGroup(dataItem) ? (
            <optgroup key={dataItem.group} label={dataItem.group}>
              {dataItem.items.map((item) => (
                <ROption
                  key={item.value}
                  value={item.value}
                  sx={
                    kind === 'Required' && index === 0
                      ? {
                          display: 'none',
                        }
                      : undefined
                  }>
                  {item.label}
                </ROption>
              ))}
            </optgroup>
          ) : (
            <ROption
              key={dataItem.value}
              value={dataItem.value}
              sx={
                kind === 'Required' && index === 0
                  ? {
                      display: 'none',
                    }
                  : undefined
              }>
              {dataItem.label}
            </ROption>
          ),
      )}
    </select>
  );
}

function isItemsInGroup(dataItem: Item | ItemsInGroup): dataItem is ItemsInGroup {
  return Object.keys(dataItem).includes('group');
}

export const RSelect = styled(Comppnent)({
  height: 40,

  padding: '7px 11px',

  fontSize: 16,
  lineHeight: '23px',

  border: '1px solid #C6C6C6',
  borderRadius: 4,

  color: '#9D9D9D',
});
