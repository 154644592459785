import { styled } from '@mui/material/styles';
import { useCallback } from 'react';
import { useSetRecoilState } from 'recoil';
import { logInUserAtom } from '../../../../atoms/log-in-user';
import { maxLength } from '../../../../services/max-length';
import { RButton } from '../../../base/button/button';
import { RInput } from '../../../base/input/input';
import { RTextArea } from '../../../base/text-area/text-area';
import { RInputGuide } from '../../../core/input-guide/input-guide';
import { RInputLabel } from '../../../core/input-label/input-label';
import { RValidationMessages } from '../../../core/validation-message/validation-messages';
import { useData } from '../../../hooks/data';
import { useSave } from '../../../hooks/save';
import { ComponentPropsNormarized, ValidationErrors } from '../../../types';
import { Circle } from '../circle';

type Data = {
  name: string;
  description: string;
};
type Props = ComponentPropsNormarized<'div'>;

function Component({ className }: Props): JSX.Element {
  const [data, setData] = useData<Data>('/v1/MyPage/Profile');
  const [onSaveClick, validationErrors] = useOnSaveClick();

  if (data === undefined) {
    return <></>;
  }

  return (
    <div className={className}>
      <h2>
        <Circle color="#fdba35" />
        <span>基本情報</span>
      </h2>

      <div className={classes.inputSection}>
        <div className={classes.inputWrapper}>
          <RInputLabel
            required
            sx={{
              marginTop: '5px',
              fontSize: 14,
              lineHeight: '21px',
              color: '#757575',
            }}>
            ユーザー名
          </RInputLabel>
          <div>
            <RInput
              value={data.name}
              setValue={(v): void => {
                const newData: Data = { ...data, name: v };
                setData(newData);
              }}
              placeholder="ユーザー名を決めてください。"
              maxLength={maxLength.user.name}
              sx={{
                width: '100%',
              }}
            />
            <RValidationMessages messages={validationErrors.Name} />
            <RInputGuide>※{maxLength.user.name}文字以下で入力してください。</RInputGuide>
          </div>
        </div>
      </div>

      <div className={classes.inputSection}>
        <div className={classes.inputWrapper}>
          <RInputLabel
            sx={{
              marginTop: '5px',
              fontSize: 14,
              lineHeight: '21px',
              color: '#757575',
            }}>
            自己紹介
          </RInputLabel>
          <div>
            <RTextArea
              value={data.description}
              setValue={(v): void => {
                const newData: Data = { ...data, description: v };
                setData(newData);
              }}
              placeholder="今までにどんなコーディング経験がありますか？&#13;学習の目標はなんですか？"
              maxLength={maxLength.user.description}
              sx={{
                width: '100%',
                height: 96,
              }}
            />
            <RValidationMessages messages={validationErrors.Description} />
          </div>
        </div>
      </div>

      {/* <div className="input-section">
            <div className="input-wrapper mb0">
                <div className="input-txt">タグ</div>
                <div>
                    <select>
                        <option value="">自分の特徴や興味のあるキーワードはなんですか？</option>
                        <option value="">フロントエンジニア</option>
                        <option value="">バックエンドエンジニア</option>
                        <option value="">インフラエンジニア</option>
                    </select>
                    <p className="annotation">※@(UserTagDTO.MaxLengthProjectTag)文字以下で入力してください。</p>
                </div>
            </div>
        </div> */}

      <div className={classes.buttonWrapper}>
        <RButton
          onClick={async (e): Promise<void> => {
            await onSaveClick(e, data);
          }}
          width={130}>
          基本情報を保存
        </RButton>
      </div>
    </div>
  );
}

const useOnSaveClick = (): [(e: React.MouseEvent<HTMLButtonElement, MouseEvent>, data: Data) => Promise<void>, ValidationErrors] => {
  const [save, validationErrors] = useSave('/v1/MyPage/UpdateProfile', '保存しました。');
  const setLogInUser = useSetRecoilState(logInUserAtom);

  const onSaveClick = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, data: Data) => {
      await save(e, data);
      setLogInUser((v) => ({ ...v, name: data.name }));
    },
    [save, setLogInUser],
  );

  return [onSaveClick, validationErrors];
};

const PREFIX = 'BasicInfo';
const classes = {
  inputSection: `${PREFIX}-inputSection`,
  inputWrapper: `${PREFIX}-inputWrapper`,
  buttonWrapper: `${PREFIX}-buttonWrapper`,
};

export const BasicInfo = styled(Component, {
  shouldForwardProp: (prop) => prop !== 'sx',
})({
  marginTop: 16,

  [`& .${classes.inputSection}`]: {
    marginTop: 24,
    color: '#757575',
  },
  [`& .${classes.inputWrapper}`]: {
    display: 'grid',
    gridTemplateColumns: '112px 1fr',
  },
  [`& .${classes.buttonWrapper}`]: {
    marginTop: 8,
    display: 'flex',
    justifyContent: 'right',
  },
});
