import { styled } from '@mui/material/styles';
import { classNames } from '../../../../classNames';
import { ComponentPropsNormarized, Language } from '../../../../types';

type Props = {
  size?: 'S' | 'M';
  languages: Language[];
} & ComponentPropsNormarized<'ul'>;

function Component({ size = 'S', languages, className, ...others }: Props): JSX.Element {
  const languageClassNames = [classes.langage];
  switch (size) {
    case 'S':
      languageClassNames.push(classes.langageSizeS);
      break;
    case 'M':
      languageClassNames.push(classes.langageSizeM);
      break;
    default:
      // eslint-disable-next-line no-case-declarations
      const _: never = size;
  }

  return (
    <ul className={className} {...others}>
      {languages.map((language) => (
        <li key={language.id} className={classNames(...languageClassNames)}>
          {language.name}
        </li>
      ))}
    </ul>
  );
}

const PREFIX = 'RLanguages';
const classes = {
  langage: `${PREFIX}-langage`,
  langageSizeS: `${PREFIX}-langageSizeS`,
  langageSizeM: `${PREFIX}-langageSizeM`,
};

export const RLanguages = styled(Component, {
  shouldForwardProp: (prop) => prop !== 'sx',
})({
  display: 'flex',

  [`& .${classes.langage}`]: {
    alignItems: 'center',
    backgroundColor: '#F2F2F2',
    borderRadius: '4px',
    marginRight: '4px',
  },

  [`& .${classes.langageSizeS}`]: {
    fontSize: '1rem',
    padding: '2px 8px',
  },

  [`& .${classes.langageSizeM}`]: {
    fontSize: 12,
    lineHeight: '15px',
    padding: '4px 8px',
  },
});
