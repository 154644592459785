import { styled } from '@mui/material/styles';
import { ComponentPropsNormarized } from '../../types';

type Props = ComponentPropsNormarized<'span'>;

function Component({ children, className, ...others }: Props): JSX.Element {
  return (
    <span className={className} {...others}>
      {children}
    </span>
  );
}

export const RReviewCategory = styled(Component, {
  shouldForwardProp: (prop) => prop !== 'sx',
})({
  padding: '4px 8px',
  background: '#F0F7FF',
  borderRadius: 4,

  fontSize: 10,

  color: '#0080FF',
  opacity: 0.87,
});
