import { styled } from '@mui/material/styles';
import { ComponentPropsNormarized } from '../../types';

type Props = {
  required?: boolean;
} & ComponentPropsNormarized<'h3'>;

function Component({ required = false, children, className, ...others }: Props): JSX.Element {
  return (
    <h3 className={className} {...others}>
      {children}
      {required && <span className={classes.required}>*</span>}
    </h3>
  );
}

const PREFIX = 'RInputLabel';
const classes = {
  required: `${PREFIX}-required`,
};
export const RInputLabel = styled(Component, {
  shouldForwardProp: (prop) => prop !== 'sx',
})({
  fontSize: '12px',
  lineHeight: '15px',

  [`& .${classes.required}`]: {
    color: '#FC5F5F',
  },
});
