import { RLink } from '../../base/link/link';
import { styled } from '@mui/material/styles';
import { ComponentProps } from 'react';
import { ComponentPropsNormarized } from '../../types';

type ListItem = {
  label: string;
  kind: ComponentProps<typeof RLink>['kind'];
  href: ComponentProps<typeof RLink>['href'];
};

type Props = ComponentPropsNormarized<'ul'>;

function Component({ className }: Props): JSX.Element {
  const list: ListItem[] = [
    { label: 'ログイン', kind: 'headerRightMenuItemLogIn', href: '/User/LogIn' },
    { label: 'ユーザー登録', kind: 'headerRightMenuItemRegistration', href: '/User/Create' },
  ];

  return (
    <ul className={className}>
      {list.map((item) => (
        <RLink key={item.href} kind={item.kind} href={item.href}>
          {item.label}
        </RLink>
      ))}
    </ul>
  );
}

export const LoggedOut = styled(Component, {
  shouldForwardProp: (prop) => prop !== 'sx',
})({
  display: 'flex',
  marginLeft: 'auto',
});
