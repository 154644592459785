import I_jump from '../../../../Images/action/jump.png';

import { styled } from '@mui/material/styles';
import { RButton } from '../../../base/button/button';
import { RLink } from '../../../base/link/link';
import { RImage } from '../../../base/image/image';
import { useRecoilValue } from 'recoil';
import { logInUserAtom } from '../../../../atoms/log-in-user';
import { AvatarEditor } from './avatar-editor';
import { ComponentPropsNormarized } from '../../../types';

type Props = ComponentPropsNormarized<'div'>;

function Component({ className }: Props): JSX.Element {
  const logInUser = useRecoilValue(logInUserAtom);
  if (logInUser.id === null || logInUser.avatarUrl === null) {
    return <></>;
  }

  return (
    <div className={className}>
      <AvatarEditor />

      <RLink
        kind="GrayOnWhite"
        href={`/Profile/Main/${logInUser.id}`}
        target="_blank"
        sx={{
          position: 'absolute',
          right: 0,
        }}>
        <RButton
          kind="WithFrameGray"
          sx={{
            flexDirection: 'row',
            width: 140,
          }}>
          <RImage src={I_jump} />
          &nbsp;公開ページを見る
        </RButton>
      </RLink>
    </div>
  );
}

export const Header = styled(Component, {
  shouldForwardProp: (prop) => prop !== 'sx',
})({
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  paddingBottom: 28,
});
