import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { environment } from './environment';

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: environment.isProduction() ? 'bb3080c4-61ef-4c79-9e23-959cecd06007' : 'd8e6156a-abd7-4405-ad2f-3f11fa52e15a',
    enableAutoRouteTracking: true,
    extensions: [reactPlugin],
  },
});
appInsights.loadAppInsights();
export { reactPlugin, appInsights };
