import { styled } from '@mui/material/styles';
import { ComponentPropsNormarized } from '../../types';

type Props = ComponentPropsNormarized<'option'>;

function Component({ children, className, ...others }: Props): JSX.Element {
  return (
    <option className={className} {...others}>
      {children}
    </option>
  );
}

export const ROption = styled(Component, {
  shouldForwardProp: (prop) => prop !== 'sx',
})({
  fontSize: 16,
  lineHeight: '24px',
  color: '#404040',
});
