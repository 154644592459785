export const formatDate: (date: Date, format: string) => string = (date, format) =>
  format
    .replace(/yyyy/g, date.getFullYear().toString())
    .replace(/MM/g, `0${date.getMonth() + 1}`.slice(-2))
    .replace(/dd/g, `0${date.getDate()}`.slice(-2))
    .replace(/HH/g, `0${date.getHours()}`.slice(-2))
    .replace(/mm/g, `0${date.getMinutes()}`.slice(-2))
    .replace(/ss/g, `0${date.getSeconds()}`.slice(-2))
    .replace(/SSS/g, `00${date.getMilliseconds()}`.slice(-3));

/**
 * @deprecated APIから日付をstring以外で受け取るのは難しいので、API側でタイムゾーン変換＆フォーマットした結果のstringを返すようにする。
 *
 * ローカル時刻に変換。
 * @param date 変換元
 * @returns 変換結果
 */
export const toLocalTime = (date: Date): Date => {
  const dt = new Date(date);
  dt.setMinutes(dt.getMinutes() - dt.getTimezoneOffset());
  return dt;
};

/**
 * @deprecated APIから日付をstring以外で受け取るのは難しいので、API側でタイムゾーン変換＆フォーマットした結果のstringを返すようにする。
 *
 * ローカル時刻に変換。
 * @param date 変換元
 * @param format 変換フォーマット
 * @returns 変換結果
 */
export const formatToLocalDate = (date: Date, format: string): string => formatDate(toLocalTime(date), format);
