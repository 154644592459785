import { styled } from '@mui/material/styles';
import { ComponentProps, ReactNode } from 'react';
import { ComponentPropsNormarized } from '../types';

type Props = {
  children?: ReactNode;
  innerStyle?: ComponentProps<'div'>['style'];
} & ComponentPropsNormarized<'div'>;

function Component({ children, className, innerStyle, ...others }: Props): JSX.Element {
  return (
    <div className={className} {...others}>
      <div className={classes.inner} style={innerStyle}>
        {children}
      </div>
    </div>
  );
}

const PREFIX = 'RContainer';
const classes = {
  inner: `${PREFIX}-inner`,
};

export const RContainer = styled(Component)({
  flexGrow: 1,
  padding: '28px 24px',

  margin: '0 auto',
  width: '100%',
  maxWidth: 'calc(1360px + 24px + 24px)', // コンテンツ＋左右パディング
  minWidth: 'calc(972px + 24px + 24px)', // コンテンツ＋左右パディング

  [`& .${classes.inner}`]: {
    width: '100%',
    minWidth: '972px',
  },
});
