import 'code-prettify-google/src/prettify';
declare const PR: { prettyPrint: () => void };

import 'code-prettify-google/src/prettify.css';
import 'code-prettify-google/styles/sons-of-obsidian.css';

import I_deco from '../../../Images/icon/deco-column-title.svg';

import { styled } from '@mui/material/styles';
import { ComponentPropsNormarized } from '../../types';
import { useTitle } from '../../hooks/title';
import { RContainer } from '../../layout/Container';
import { RLink } from '../../base/link/link';
import { useEffect } from 'react';
import { classNames } from '../../classNames';

type Props = ComponentPropsNormarized<typeof RContainer>;

function Component({ className }: Props): JSX.Element {
  useTitle('コラム');

  useEffect(() => {
    PR.prettyPrint();
  }, []);

  return (
    <RContainer className={className}>
      <div className={classes.wrapper}>
        <h2 className={classes.pageTitle}>コラム</h2>
        <p>「効果的な学習のためのコードレビュー」とは、どのようなものでしょうか？</p>
        <p>一般的なコードレビューは、「製品の質の担保」を目的とする場合が多いですが、ここでは「学習のためのコードレビュー」について考えます。 </p>
        <nav className={classes.pageList}>
          <h3 className={classes.pageListTitle}>目次</h3>
          <ol>
            <li>
              <a href="#sec01">なぜコードレビューを受けるか</a>
              <ol>
                <li>
                  <a href="#sec01-1">コーディングの独学は挫折しやすい</a>
                </li>
                <li>
                  <a href="#sec01-2">より早く、より大きく上達するには適切なサポートが不可欠</a>
                </li>
                <li>
                  <a href="#sec01-3"> レビューのゴール</a>
                </li>
              </ol>
            </li>
            <li>
              <a href="#sec02">良いプログラムとは</a>
              <ol>
                <li>
                  <a href="#sec02-1">良いプログラムは理解しやすい</a>
                </li>
                <li>
                  <a href="#sec02-2">理解しやすいプログラムとは</a>
                </li>
              </ol>
            </li>
            <li>
              <a href="#sec03">良いプログラムを作れるようになるには</a>
            </li>
            <li>
              <a href="#sec04">コードレビューの心得</a>
              <ol>
                <li>
                  <a href="#sec04-1">学習者・レビュアー双方の心得</a>
                </li>
                <li>
                  <a href="#sec04-2">学習者の心得</a>
                </li>
                <li>
                  <a href="#sec04-3">レビュアーの心得</a>
                </li>
              </ol>
            </li>
          </ol>
        </nav>
        <section className={classes.sec} id="sec01">
          <h3 className={classes.secTitle}>なぜコードレビューを受けるか</h3>
          <h4 className={classes.secTitleSecondary} id="sec01-1">
            コーディングの独学は挫折しやすい
          </h4>
          <p>
            コーディングの学習者が増えてきている中、独学では挫折してしまう方も多いのが現状です。 <br />
            その理由には、どんなものがあるでしょうか？
          </p>
          <ul className={classes.listDisc}>
            <li>
              <h5 className={classes.listDiscTitle}>学習効率が悪い</h5>
              <p>習得には多くの知識や経験が必要ですが、何をどの順番で学習すべきか初学者にはわかりにくいです。</p>
            </li>
            <li>
              <h5 className={classes.listDiscTitle}>エラーが解決できない</h5>
              <p>コーディング中には「謎の」エラーが頻発します。経験者には簡単でも、初学者にはどんなに時間をかけてもわからないことがあります。</p>
            </li>
            <li>
              <h5 className={classes.listDiscTitle}>コーディングに正解はない</h5>
              <p>コーディングに正解はないため、独学では「これでよいのか」と不安になってしまいます。</p>
            </li>
            <li>
              <h5 className={classes.listDiscTitle}>成長を実感しにくい</h5>
              <p>コーディング習得までの道のりはあまりにも長く、過酷です。前に進められている実感が持てないと、続けるのが困難になります。</p>
            </li>
          </ul>
          <h4 className={classes.secTitleSecondary} id="sec01-2">
            より早く、より大きく上達するには適切なサポートが不可欠
          </h4>
          <p>上記の問題の大半は、経験者によるサポートで改善できます。</p>
          <ul className={classes.listDisc}>
            <li>
              <h5 className={classes.listDiscTitle}>学習効率が良くなる</h5>
              <p>経験者によるサポートを受けることで理解が進み、必要なものを効率的に学習することができます。</p>
            </li>
            <li>
              <h5 className={classes.listDiscTitle}>エラーが解決できる</h5>
              <p>初学者には原因のわからないエラーでも、経験者にとってはおなじみである場合があります。アドバイスをもらえば瞬時に解決できるでしょう。</p>
            </li>
            <li>
              <h5 className={classes.listDiscTitle}>より良いコードが書ける</h5>
              <p>
                コーディングに「正解」がないとは言え「良し悪し」はあります。コードレビューを受けると他者目線でコードの良し悪しがわかるため、不足点を認識したり安心を獲得したりできます。
              </p>
            </li>
            <li>
              <h5 className={classes.listDiscTitle}>成長を実感できる</h5>
              <p>レビュアーとの対話を通して一つずつ理解していくことによって、自分の成長が目に見えるようになります。</p>
            </li>
          </ul>
          <h4 className={classes.secTitleSecondary} id="sec01-3">
            レビューのゴール
          </h4>
          <p>
            学習目的のレビューでは、目の前のコードの改善よりも、「自力でより良いコードを書けるようになること」が重要です。 <br />
            今あるコードを直すことに囚われず、「なぜそうした方がよいのか」を理解して次に活かせるようにしましょう。
          </p>
        </section>
        <section className={classes.sec} id="sec02">
          <h3 className={classes.secTitle}>良いプログラムとは</h3>
          <p>では、自力でコードを改善するにあたり、何が「良いプログラム」なのでしょうか？</p>
          <h4 className={classes.secTitleSecondary} id="sec02-1">
            良いプログラムは理解しやすい
          </h4>
          <p>
            あなたのプログラムはどんな動作をするか、わかりやすく説明できますか？ <br />
            もしも説明できないのであれば、正しく動くかどうかわかっていないということになります。 <br />
            また、動作を理解できていないと、変更や修正の際にもどのようにすればよいか戸惑ってしまいます。 <br />
            (変更するのは将来の自分かもしれませんし、チームの仲間かもしれません。) <br />
            わかりやすく説明できて理解してもらえるなら、それは良いプログラムと言えるでしょう。
          </p>
          <p>※良いプログラムの条件は他にもありますが、初めは「理解しやすさ」を考えるとよいでしょう。</p>
          <h4 className={classes.secTitleSecondary} id="sec02-2">
            理解しやすいプログラムとは
          </h4>
          <p>
            それでは、理解しやすいプログラムを書くためのコツをいくつか見ていきましょう。 <br />
            これらを意識するだけで、ずっとわかりやすいコードがかけるようになるはずです。
          </p>
          <h5 className={classes.secTitleTertiary}>適切な名前</h5>
          <p>
            コードを書くとき、わかりやすいクラス名や変数名をつけていますか？ <br />
            名前は「それが何であるか」「何のためのものか」を理解する際の大切な情報源です。 <br />
            そのため、名前が不適切だと、そのプログラムを正しく理解することは難しくなります。 <br />
            以下は、初学者がつけやすい不適切な名前の例です。
          </p>
          <ul className={classes.secList}>
            <li>
              <h6 className={classes.secListTitle}>１文字</h6>
              <p>以下の2行のコードは、どちらが理解しやすいでしょうか？</p>
              {/* prettier-ignore */}
              <pre className={classNames("prettyprint", classes.pre)}>{`a = b * c;
distance = speed * time;`}</pre>
              <p>
                どちらも処理は同じですが、どんな内容かは後者の方が明らかです。 <br />
                コードの意味を明確にするために、基本的に1文字の名前は避けましょう。
              </p>
            </li>
            <li>
              <h6 className={classes.secListTitle}>連番</h6>
              <p>「先生」「生徒」を表す変数に「human1」「human2」などの連番を使っていませんか？</p>
              {/* prettier-ignore */}
              <pre className={classNames("prettyprint", classes.pre)}>{`human1.teach();
human2.study();`}</pre>
              <p>
                一見、どちらが先生でどちらが生徒なのか、わかりませんね。 <br />
                次のようにすると明確になるでしょう。
              </p>
              {/* prettier-ignore */}
              <pre className={classNames("prettyprint", classes.pre)}>{`teacher.teach();
student.study();`}</pre>
            </li>
            <li>
              <h6 className={classes.secListTitle}>省略形</h6>
              <p>この名前は、何の省略形かわかりますか？</p>
              {/* prettier-ignore */}
              <pre className={classNames("prettyprint", classes.pre)}>{`tchr.tch();
stdt.std();`}</pre>
              <p>
                自分にしか意味がわからない名前をつけると、次に見た時には自分でも意味がわからないかもしれません。 <br />
                瞬時に意味がわからない省略は、コードが読みにくくなるのでやめましょう。
              </p>
            </li>
            <li>
              <h6 className={classes.secListTitle}>無駄に長い</h6>
              <p>
                説明的な名前でなくても、文脈で理解できる場合があります。無駄に長くするのは避けましょう。 <br />
                <code>price</code>という名前で十分通じる箇所で<code>priceThatCustomersPayWhenTheyPurchaseThisItem</code>
                と名付けるのは、読むのに苦労するだけです。
              </p>
            </li>
            <li>
              <h6 className={classes.secListTitle}>表す内容が曖昧</h6>
              <p>
                名前は短ければよいわけでもありません。理解しやすい名前になっているか検討しましょう。 <br />
                「税込み」「税抜き」価格の両方を扱うプログラムなら、<code>price</code>では意味が曖昧になるので、より詳細な名前に改善する必要があります。
              </p>
            </li>
            <li>
              <h6 className={classes.secListTitle}>英語として不適切</h6>
              <p>以下の2行はどちらが適切でしょうか？</p>
              {/* prettier-ignore */}
              <pre className={classNames("prettyprint", classes.pre)}>{`if (price.isExpensive())
if (price.expensiveIs())`}</pre>
              <p>前者であれば「If price is expensive」とそのまま英語で読み下せますね。</p>
              <p>
                名詞形・動詞形の選択にも注意です。 <br />
                言うまでもなく、ものを表すのであれば名詞形、動作を表すのであれば動詞形にしましょう。 <br />
                例えば<code>you.dinner()</code>ではなく<code>you.eatDinner()</code>が適切で、<code>run.walk()</code>ではなく<code>runner.walk()</code>
                が適切です。 <br />
                何者かわからないものには適切な名前を付けることもできないので、名付け対象のクラスや変数などが何を表すものなのかをしっかりと決めることも大切です。
              </p>
            </li>
          </ul>
          <p>
            以上、不適切な名前の例を挙げました。 <br />
            名前がわかりにくい場合、そもそもプログラムでやりたいことが明確でない場合も往々にしてあります。 <br />
            プログラムを書くときは、自分が実現したいことを明確にした上で、適切な名前を考えましょう。
          </p>
          <h5 className={classes.secTitleTertiary}>整理されたロジック</h5>
          <p>
            理解しやすいコードは、ロジックも整理されています。 <br />
            例として、外出するための処理を考えてみましょう。 <br />
            以下の順序は、理解しやすいでしょうか？
          </p>
          <div className={classes.blockExample}>
            <ol className={classes.listDecimal}>
              <li>靴を選ぶ</li>
              <li>服を選ぶ</li>
              <li>顔を洗う</li>
              <li>歯を磨く</li>
              <li>服を着替える</li>
              <li>靴を履く</li>
            </ol>
          </div>
          <p>
            色々な動きが混在していて、理解が難しいですね。 <br />
            例えば顔・服・靴についての動作をまとめると、理解しやすくなるでしょう。
          </p>
          <div className={classes.blockExample}>
            <ol className={classes.listDecimal}>
              <li>歯を磨く</li>
              <li>顔を洗う</li>
              <li>服を選ぶ</li>
              <li>服を着替える</li>
              <li>靴を選ぶ</li>
              <li>靴を履く</li>
            </ol>
          </div>
          <p>同じ処理でも、順番を意識するだけで読みやすいコードになります。</p>
          <h5 className={classes.secTitleTertiary}>整理されたデータ構造</h5>
          <p>
            プログラムで必要となるデータをどのように整理して保持するかということは、そのデータをどのようなロジックで処理するかということと同等またはそれ以上に大切なことです。
          </p>
          <p>例えば以下のうち、正解はどれでしょうか？</p>
          <div className={classes.blockExample}>
            <ol className={classes.listDecimal}>
              <li>先生が、自分の生徒達が誰かを知っている(生徒の一覧を保持している)。(teacher.studentsがある。)</li>
              <li>生徒が、自分の先生が誰か知っている(先生の名前やIDを保持している)。(student.teacherがある。)</li>
              <li>先生と生徒がお互いに誰かを知っている。(上記が両方ある。)</li>
            </ol>
          </div>
          <p>
            実は、常にどれかが正解ということはありません。 <br />
            目的となる処理をうまく実現するために、どのやり方がベターなのかを常に考えて選ぶ必要があるのです。
          </p>
          <p>処理がうまく整理できない場合は、その元となるデータ構造を見直してみるとよいかもしれません。</p>
        </section>
        <section className={classes.sec} id="sec03">
          <h3 className={classes.secTitle}>良いプログラムを作れるようになるには</h3>
          <p>インプットとアウトプット、どちらも大切です。</p>
          <p>
            まずは、上記の内容を意識することから始めましょう。他にも色々な記事が出ています。 <br />
            良いコードの書き方を体系的に知るなら、以下の書籍がおすすめです。 <br />
            <RLink
              href="https://www.amazon.co.jp/%E3%83%AA%E3%83%BC%E3%83%80%E3%83%96%E3%83%AB%E3%82%B3%E3%83%BC%E3%83%89-%E2%80%95%E3%82%88%E3%82%8A%E8%89%AF%E3%81%84%E3%82%B3%E3%83%BC%E3%83%89%E3%82%92%E6%9B%B8%E3%81%8F%E3%81%9F%E3%82%81%E3%81%AE%E3%82%B7%E3%83%B3%E3%83%97%E3%83%AB%E3%81%A7%E5%AE%9F%E8%B7%B5%E7%9A%84%E3%81%AA%E3%83%86%E3%82%AF%E3%83%8B%E3%83%83%E3%82%AF-Theory-practice-Boswell/dp/4873115655"
              target="_blank">
              Dustin Boswell、Trevor Foucher『リーダブルコード――より良いコードを書くためのシンプルで実践的なテクニック』（オライリー・ジャパン、2012年）
            </RLink>
            <br />
            この本は、初学者から熟練者までを幅広く対象としている本です。わかるところから実践し、徐々に理解を進めていくとよいでしょう。
          </p>
          <p>
            そして、どんどんアウトプットしましょう。 <br />
            このような知識は、知っただけでは実現できません。コードを書きながら、自分の血肉にしていきましょう。 <br />
            「良いプログラム」であるかは自分では判断しにくいので、誰かにコードレビューを受けることが望ましいです。 <br />
            実践の過程で足りていない部分に気づいたら随時インプットする、ということを繰り返すと、徐々に良いプログラムが書けるようになっていきます。
          </p>
          <p>
            また、他の人が書いたコードを見たり、レビューのやりとりを読んだりするのも勉強になります。 <br />
            「自分だったらどうするか」を考えながらチャレンジしてみましょう。
          </p>
        </section>
        <section className={classes.sec} id="sec04">
          <h3 className={classes.secTitle}>コードレビューの心得</h3>
          <p>それではここから「学習のためのコードレビュー」で、心がけるべきポイントを考えてみましょう。</p>
          <h4 className={classes.secTitleSecondary} id="sec04-1">
            学習者・レビュアー双方の心得
          </h4>
          <p>レビューは学習者とレビュアー双方の気遣いが必要です。お互いに、以下を心がけましょう。</p>
          <ul className={classes.listDisc}>
            <li>
              <h5 className={classes.listDiscTitle}>レビューの目的やゴールを共有する</h5>
              <p>レビューに対する目線をすり合わせる、効果的なレビューの内容や伝え方を探ることが大事です。</p>
            </li>
            <li>
              <h5 className={classes.listDiscTitle}>正解はないことを前提にする</h5>
              <p>仕様の実現方法は通常複数あります。複数の観点で比較し、どれがベターか相談・検討してみましょう。</p>
            </li>
            <li>
              <h5 className={classes.listDiscTitle}>思いやりをもつ</h5>
              <p>コードレビューも、コミュニケーションです。思いやりをもってやりとりしましょう。</p>
            </li>
          </ul>
          <h4 className={classes.secTitleSecondary} id="sec04-2">
            学習者の心得
          </h4>
          <p>レビューでの指摘は、そのレビュアーからスキルを譲り受けるチャンスです。</p>
          <ul className={classes.listDisc}>
            <li>
              <h5 className={classes.listDiscTitle}>自信をなくさない</h5>
              <p>
                レビューを通してスキル不足を実感することがありますが、スキルが足りていなければ、身につければよいだけです。 <br />
                「そのためにレビューを受けている」ということを思い出しましょう。
              </p>
            </li>
            <li>
              <h5 className={classes.listDiscTitle}>指摘は否定ではない</h5>
              <p>指摘は人格やスキルに対する否定ではなく、良いコードを書くためのヒントとして受け取りましょう。</p>
            </li>
            <li>
              <h5 className={classes.listDiscTitle}>レビュアーも完璧ではない</h5>
              <p>指摘自体が妥当ではない可能性も踏まえ、指摘を鵜呑みにせず、疑問があればためらわずに質問しましょう。</p>
            </li>
            <li>
              <h5 className={classes.listDiscTitle}>意図を伝える</h5>
              <p>なぜ自分がそのようなコードを書いたのかを伝え、その目的に対して適切なやり方を学びましょう。</p>
            </li>
          </ul>
          <h4 className={classes.secTitleSecondary} id="sec04-3">
            レビュアーの心得
          </h4>
          <p>学習者の成長を第一に考えたやりとりを心がけましょう。</p>
          <ul className={classes.listDisc}>
            <li>
              <h5 className={classes.listDiscTitle}>「やってみせる」と「考えさせる」バランスを</h5>
              <p>
                改善例を見せる場合と、ヒントにとどめる場合のバランスを考えましょう。 <br />
                学習者の心理的負担にも配慮しましょう。
              </p>
            </li>
            <li>
              <h5 className={classes.listDiscTitle}>改善を強制しない</h5>
              <p>
                学習者には改善方法を理解してもらうようにし、改善の実施や方法は本人の意思を尊重しましょう。 <br />
                理解した上での決断であれば、改善・修正しないことを否定しないようにしましょう。
              </p>
            </li>
            <li>
              <h5 className={classes.listDiscTitle}>人格やスキルを否定しない</h5>
              <p>コードの内容についてのみ指摘しましょう。</p>
            </li>
            <li>
              <h5 className={classes.listDiscTitle}>伝え方を工夫する</h5>
              <p>
                相手の習熟度やコミュニケーションスタイルに応じて、理解しやすい伝え方を考えましょう。 <br />
                改善点だけでなく、改善すべき理由や背景も補足するようにしましょう。
              </p>
            </li>
            <li>
              <h5 className={classes.listDiscTitle}>間違いは素直に訂正する</h5>
              <p>間違った指摘をする可能性もあります。その場合は、わかった時点で素直に伝えましょう。</p>
            </li>
          </ul>
        </section>
      </div>
    </RContainer>
  );
}

const PREFIX = 'Column';
const classes = {
  wrapper: `${PREFIX}-wrapper`,
  pageTitle: `${PREFIX}-page-title`,
  pageList: `${PREFIX}-page-list`,
  pageListTitle: `${PREFIX}-page-list-title`,
  listDisc: `${PREFIX}-list-disc`,
  listDiscTitle: `${PREFIX}-list-disc-title`,
  listDecimal: `${PREFIX}-list-decimal`,
  sec: `${PREFIX}-sec`,
  secTitle: `${PREFIX}-sec-title`,
  secTitleSecondary: `${PREFIX}-sec-title-secondary`,
  secTitleTertiary: `${PREFIX}-sec-title-tertiary`,
  secList: `${PREFIX}-sec-list`,
  secListTitle: `${PREFIX}-sec-list-title`,
  blockExample: `${PREFIX}-block-example`,
  pre: `${PREFIX}-pre`,
};

export const Column = styled(Component, {
  shouldForwardProp: (prop) => prop !== 'sx',
})({
  [`& .${classes.wrapper}`]: {
    display: 'block',
    background: '#FFFFFF',
    border: '1px solid #E3E3E3',
    borderRadius: '8px',
    padding: '28px 40px',
  },
  [`& .${classes.pageTitle}`]: {
    fontSize: '28px',
    fontWeight: 'bold',
    textAlign: 'center',
    paddingBottom: '16px',
    marginBottom: '40px',
    backgroundImage: `url(${I_deco})`,
    backgroundPosition: 'center bottom',
    backgroundRepeat: 'no-repeat',
  },
  [`& .${classes.pageList}`]: {
    backgroundColor: '#F7F7F7',
    borderRadius: '4px',
    color: '#757575',
    marginTop: '40px',
    padding: '16px',
    [`& ol`]: {
      listStyle: 'none',
      lineHeight: '28px',
      padding: '0',
      margin: '0',
    },
    [`&>ol>li`]: {
      padding: '0 8px',
      [`&+li`]: {
        marginTop: '12px',
        paddingTop: '12px',
        borderTop: '1px solid #DFDFDF',
      },
      [`&>a`]: {
        fontWeight: 'bold',
      },
      [`&>ol`]: {
        paddingTop: '4px',
        [`& a`]: {
          paddingLeft: '20px',
        },
      },
    },
    [`& a`]: {
      display: 'block',
      color: '#757575',
      textDecoration: 'none',
      [`&:hover`]: {
        backgroundColor: '#F2F2F2',
      },
    },
  },
  [`& .${classes.pageListTitle}`]: {
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: '24px',
    marginBottom: '12px',
  },
  [`& .${classes.listDisc}`]: {
    marginTop: '12px',
    marginLeft: '24px',
    [`& li`]: {
      listStyle: 'disc',
      [`&+li`]: {
        marginTop: '12px',
      },
    },
  },
  [`& .${classes.listDiscTitle}`]: {
    fontWeight: 'bold',
  },
  [`& .${classes.listDecimal}`]: {
    paddingLeft: '1em',
  },
  [`& p+p`]: {
    marginTop: '1em',
  },
  [`& code`]: {
    display: 'inline-block',
    backgroundColor: '#F2F2F2',
    borderRadius: '4px',
    color: '#FC5F5F',
    fontFamily: '"Noto Sans Japanese"',
    margin: '0 4px',
    padding: '0 8px',
  },
  [`& .${classes.sec}`]: {
    marginTop: '40px',
  },
  [`& .${classes.secTitle}`]: {
    backgroundColor: '#66B3FF',
    borderRadius: '4px',
    color: '#FFFFFF',
    fontSize: '24px',
    fontWeight: 'bold',
    padding: '8px 12px',
    marginBottom: '32px',
  },
  [`& .${classes.secTitleSecondary}`]: {
    boxShadow: 'inset 0px -1px 0px #3399FF',
    fontSize: '20px',
    fontWeight: 'bold',
    padding: '8px 0',
    margin: '32px 0 24px',
  },
  [`& .${classes.secTitleTertiary}`]: {
    borderLeft: '4px solid #3399FF',
    fontSize: '18px',
    fontWeight: 'bold',
    lineHeight: '1',
    padding: '8px 12px',
    margin: '24px 0 16px',
  },
  [`& .${classes.secList}`]: {
    marginTop: '16px',
    [`& li+li`]: {
      marginTop: '12px',
    },
  },
  [`& .${classes.secListTitle}`]: {
    fontWeight: 'bold',
    paddingTop: '8px',
    marginBottom: '12px',
    [`&::before`]: {
      content: '"■"',
    },
  },
  [`& .${classes.blockExample}`]: {
    background: '#F2F2F2',
    borderRadius: '4px',
    lineHeight: '1.8',
    margin: '12px 0',
    padding: '8px 16px',
  },
  [`& .${classes.pre}`]: {
    padding: '8px',
    background: '#292e32',
    fontFamily: '"SF Mono","Monaco","Andale Mono","Lucida Console","Bitstream Vera Sans Mono","Courier New",Courier,monospac',
    letterSpacing: 0.5,
    fontSize: 12,
  },
});
