import { styled } from '@mui/material/styles';
import { useTitle } from '../../hooks/title';
import { useRecoilValue } from 'recoil';
import { createUserMailAddressAtom } from '../../../atoms/create-user-mail-address';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { RContainer } from '../../layout/Container';
import { ComponentPropsNormarized } from '../../types';

type Props = ComponentPropsNormarized<typeof RContainer>;

function Component({ className }: Props): JSX.Element {
  useTitle('パスワードリセット');

  const navigate = useNavigate();
  const createUserMailAddress = useRecoilValue(createUserMailAddressAtom);
  useEffect(() => {
    if (createUserMailAddress === '') {
      navigate('/');
    }
  }, [createUserMailAddress, navigate]);
  if (createUserMailAddress === '') {
    return <></>;
  }

  return (
    <RContainer className={className}>
      <div className={classes.wrapper}>
        <h1 className={classes.h1}>メールをご確認ください</h1>

        <div className={classes.form}>
          <p className={classes.p}>
            {createUserMailAddress}にパスワードリセット用のメールをお送りしました。
            <br />
            メールを確認して、パスワードリセット用のURLにアクセスしてください。
          </p>
        </div>
      </div>
    </RContainer>
  );
}

const PREFIX = 'PasswordResetRequested';
const classes = {
  wrapper: `${PREFIX}-wrapper`,
  h1: `${PREFIX}-h1`,
  form: `${PREFIX}-form`,
  p: `${PREFIX}-p`,
};

export const PasswordResetRequested = styled(Component, {
  shouldForwardProp: (prop) => prop !== 'sx',
})({
  [`& .${classes.wrapper}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  [`& .${classes.h1}`]: {
    fontWeight: 500,
    fontSize: '28px',
  },

  [`& .${classes.form}`]: {
    width: 510,
  },
  [`& .${classes.p}`]: {
    width: '100%',
    margin: '24px auto',
    lineHeight: '21px',
    textAlign: 'left',
  },
});
