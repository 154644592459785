import { styled } from '@mui/material/styles';
import { Skill } from './main';
import { RRecord } from '../../../core/record/record/record';
import { withBR } from '../../../functions/withBR';
import { RRecordGroup } from '../../../core/record/record-group/record-group';
import { RShowMore } from '../../../core/show-more/show-more';
import { Rating } from '@mui/material';
import { ComponentPropsNormarized } from '../../../types';

type Props = {
  skills: Skill[];
} & ComponentPropsNormarized<'section'>;

function Component({ skills, className }: Props): JSX.Element {
  return (
    <section className={className}>
      <h3 className={classes.sectionTitle}>言語</h3>
      {skills.length === 0 ? (
        <RRecord
          hover="None"
          sx={{
            padding: '39px 0',
            color: '#8A8A8A',
            textAlign: 'center',
          }}>
          まだ言語がありません。
        </RRecord>
      ) : (
        <RRecordGroup>
          {skills.map((skill) => (
            <RRecord key={skill.id} hover="None">
              <ul className={classes.header}>
                <li className={classes.name}>{skill.name}</li>
                <li className={classes.stars}>
                  <Rating
                    readOnly
                    sx={{
                      color: '#3399FF',
                    }}
                    size="large"
                    value={skill.level}
                  />
                </li>
              </ul>
              <RShowMore line={3}>{withBR(skill.description)}</RShowMore>
            </RRecord>
          ))}
        </RRecordGroup>
      )}
    </section>
  );
}

const PREFIX = 'Skills';
const classes = {
  sectionTitle: `${PREFIX}-sectionTitle`,
  header: `${PREFIX}-header`,
  name: `${PREFIX}-name`,
  stars: `${PREFIX}-stars`,
};

export const Skills = styled(Component)({
  marginTop: '24px',

  [`& .${classes.sectionTitle}`]: {
    fontSize: '1.6rem',
    fontWeight: 'bold',
    color: '#404040',
    marginBottom: '8px',
  },
  [`& .${classes.header}`]: {
    display: 'flex',
    marginBottom: '8px',
  },
  [`& .${classes.name}`]: {
    marginRight: '8px',
    fontWeight: 'bold',
  },
  [`& .${classes.stars}`]: {
    display: 'flex',
    alignItems: 'center',
  },
});
