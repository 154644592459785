import { styled } from '@mui/material/styles';
import { RRecordGroup } from '../../core/record/record-group/record-group';
import { RRecord } from '../../core/record/record/record';
import { SearchCategoryCheckbox } from './search-category-checkbox';
import { useCallback, useEffect, useRef, useState } from 'react';
import { RUserRecords, Users } from '../../core/record/user-records/user-records';
import { useData } from '../../hooks/data';
import { useApi } from '../../../services/api';
import { RLoadingPlaceholder } from '../../core/loading-placeholder/loading-placeholder';
import { RContainer } from '../../layout/Container';
import { ComponentPropsNormarized } from '../../types';

export const DEFAULT_COUNT = 10;

export type ResultCountByItem = {
  id: number;
  name: string;
  count: number;
};

type SearchResult = {
  items: Users;
  totalCount: number;
  resultCountsByLanguage: ResultCountByItem[];
  resultCountsByTag: ResultCountByItem[];
  resultCountsByRating: ResultCountByItem[];
};

type Props = ComponentPropsNormarized<typeof RContainer>;

function Component({ className }: Props): JSX.Element {
  const [selectedLanguages, setSelectedLanguages] = useState(new Set<number>());

  // // TODO APIから取得。
  // const tags: Item[] = [
  //   { id: 3, name: 'タグ3' },
  //   { id: 2, name: 'タグ2' },
  //   { id: 1, name: 'タグ1' },
  //   { id: 4, name: 'タグ4' },
  //   { id: 5, name: 'タグ5' },
  //   { id: 6, name: 'タグ6' },
  //   { id: 7, name: 'タグ7' },
  //   { id: 8, name: 'タグ8' },
  //   { id: 9, name: 'タグ9' },
  //   { id: 10, name: 'タグ10' },
  //   { id: 11, name: 'タグ11' },
  //   { id: 12, name: 'タグ12' },
  //   { id: 13, name: 'タグ13' },
  //   { id: 14, name: 'タグ14' },
  //   { id: 15, name: 'タグ15' },
  // ];
  // const [selectedTags, setSelectedTags] = useState(new Set<number>());

  // const [selectedRating, setSelectedRating] = useState(0);

  const [showCount, setShowCount] = useState(10);

  const [data, onMoreClick] = useUserData(showCount, selectedLanguages);
  if (data === undefined) {
    return <RLoadingPlaceholder />;
  }

  return (
    <RContainer className={className}>
      <div className={classes.headerWrapper}>
        <h2 className={classes.headerTitle}>ユーザー検索</h2>
        <p className={classes.headerDescription}>レビュアーやコーディング仲間を探しましょう！</p>
      </div>

      <div className={classes.contentWrapper}>
        <section className={classes.searchFilterWrapper}>
          <h3 className={classes.filterTitle}>絞り込み</h3>

          <RRecordGroup
            sx={{
              marginTop: '8px',
            }}>
            <RRecord hover="None" sx={searchFilterCategory}>
              <SearchCategoryCheckbox
                title="言語"
                data={data.resultCountsByLanguage}
                initialItemCountToShow={7}
                resultCountsByItem={data.resultCountsByLanguage}
                selectedItems={selectedLanguages}
                setSelectedItems={setSelectedLanguages}
                setShowCount={setShowCount}
              />
            </RRecord>

            {/* <RRecord hover="None" style={searchFilterCategory}>
                <SearchCategoryCheckbox
                  title="タグ"
                  data={tags}
                  initialItemCountToShow={7}
                  deltaItemCountToShow={7}
                  resultCountsByItem={data.resultCountsByTag}
                  selectedItems={selectedTags}
                  setSelectedItems={setSelectedTags}
                  setShowCount={setShowCount}
                />
              </RRecord> */}

            {/* <RRecord hover="None" style={searchFilterCategory}>
                <SearchCategoryRating
                  resultCountsByItem={data.resultCountsByRating}
                  selectedItem={selectedRating}
                  setSelectedItem={setSelectedRating}
                  setShowCount={setShowCount}
                />
              </RRecord> */}
          </RRecordGroup>
        </section>

        <section className={classes.searchResultWrapper}>
          <div className={classes.searchResultHeader}>
            <h3 className={classes.searchResultCount}>検索結果：{data.totalCount}件</h3>
            {/* <div>
                並び替え
                <select>
                  <option>新着順</option>
                  <option>評価高い順</option>
                </select>
              </div> */}
          </div>

          {data.totalCount > 0 ? (
            <RUserRecords
              data={data.items}
              onMoreClick={onMoreClick}
              showFollowStatus={true}
              enableFollow={false}
              sx={{
                marginTop: '8px',
              }}
            />
          ) : (
            <RRecordGroup>
              <RRecord
                hover="None"
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  marginTop: '8px',
                  height: '120px',

                  textAlign: 'center',
                  letterSpacing: '0.25px',
                  lineHeight: '21px',
                  color: '#8A8A8A',
                }}>
                <p>
                  お探しの条件で見つかりませんでした。 <br />
                  条件に合う方が登録されるまで少々お待ちください！
                </p>
              </RRecord>
            </RRecordGroup>
          )}
        </section>
      </div>
    </RContainer>
  );
}

const useUserData = (count: number, selectedLanguages: Set<number>): [SearchResult | undefined, () => Promise<void>] => {
  const api = useApi();
  const first = useRef(true);

  const [params, setParams] = useState({ resultShowCount: count, languageIDList: Array.from(selectedLanguages) });
  const [data, setData] = useData<SearchResult>('/v1/UserSearch/Search', params);

  useEffect(() => {
    const f = async (): Promise<void> => {
      // 初回レンダリング時はuseDataでデータ取得済みなので、再取得しない。
      if (first.current) {
        first.current = false;
        return;
      }

      // このstate変更により再レンダリングが発生し、useDataでデータが再取得される。
      setParams({ resultShowCount: count, languageIDList: Array.from(selectedLanguages) });

      // FIXME React 18化に伴いエラーが発生するようになったため、いったんコメントアウト。
      // useDataでデータ取得後に以下のsetDataでクリアしてしまっている模様。

      // データの再取得中は、そうと分かるような表示にする。
      // (条件を変えても検索結果が同じだと、検索が完了したかどうかが見た目で分からないため。)
      // setData((x) => ({
      //   ...x,
      //   items: {
      //     userRecords: [],
      //     hasMore: false,
      //   },
      //   totalCount: 0,
      // }));
    };
    f();
  }, [api, count, selectedLanguages, setData]);

  const onMoreClick = useCallback(async () => {
    setParams((v) => ({ ...v, resultShowCount: data!.items.userRecords.length + 10 }));
  }, [data]);

  return [data, onMoreClick];
};

const PREFIX = 'UserSearchList';
const classes = {
  headerWrapper: `${PREFIX}-headerWrapper`,
  headerTitle: `${PREFIX}-headerTitle`,
  headerDescription: `${PREFIX}-headerDescription`,

  contentWrapper: `${PREFIX}-contentWrapper`,

  searchFilterWrapper: `${PREFIX}-searchFilterWrapper`,
  filterTitle: `${PREFIX}-filterTitle`,

  searchResultWrapper: `${PREFIX}-searchResultWrapper`,
  searchResultHeader: `${PREFIX}-searchResultHeader`,
  searchResultCount: `${PREFIX}-searchResultCount`,
};

const searchFilterCategory = {
  paddingTop: '12px',
  paddingBottom: '12px',
};

export const UserSearchList = styled(Component)({
  [`& .${classes.headerWrapper}`]: {
    display: 'flex',
  },
  [`& .${classes.headerTitle}`]: {
    fontWeight: 'bold',
    fontSize: 24,
    lineHeight: '150%',
  },
  [`& .${classes.headerDescription}`]: {
    marginLeft: 16,
    fontSize: 16,
    lineHeight: '36px',
    color: '#757575',
  },

  [`& .${classes.contentWrapper}`]: {
    display: 'flex',
    marginTop: 20,
  },

  [`& .${classes.searchFilterWrapper}`]: {
    minWidth: 260,
  },
  [`& .${classes.filterTitle}`]: {
    fontWeight: 'bold',
    fontSize: 16,
    lineHeight: '24px',
    color: '#757575',
  },

  [`& .${classes.searchResultWrapper}`]: {
    maxWidth: 1072,
    width: 1072,
    minWidth: 452,
    marginLeft: 28,
  },
  [`& .${classes.searchResultHeader}`]: {
    display: 'flex',
    justifyContent: 'space-between',
    color: '#757575',
  },
  [`& .${classes.searchResultCount}`]: {
    fontWeight: 'bold',
    fontSize: 16,
    lineHeight: '24px',
  },
});
