import I_profile from '../../../Images/action/user.svg';
import I_settings from '../../../Images/action/settings.svg';
import I_follow from '../../../Images/action/follow.svg';
import I_bookmark from '../../../Images/action/bookmark.svg';
import I_message from '../../../Images/action/message.svg';
import I_contract from '../../../Images/action/contract.svg';
import I_mail from '../../../Images/action/mail.svg';
import I_logout from '../../../Images/action/logout.svg';

import { classNames } from '../../classNames';
import { RLink } from '../../base/link/link';
import { styled } from '@mui/material/styles';
import { MouseEvent, MouseEventHandler, useCallback, useEffect } from 'react';
import { useResetRecoilState, useSetRecoilState } from 'recoil';
import { logInUserAtom } from '../../../atoms/log-in-user';
import { avatarMenuStateAtom } from '../../../atoms/avatar-menu-state';
import { appInsights } from '../../../services/AppInsights';
import { useSave } from '../../hooks/save';
import { ComponentPropsNormarized } from '../../types';

type Props = ComponentPropsNormarized<'ul'>;

function Component({ className }: Props): JSX.Element {
  const onLogOutClick = useOnLogOutClick();
  useOnExternalClick();

  const list = [
    { label: 'プロフィール', href: '/MyPage/Edit', className: classes.avatarMenuItemProfile },
    { label: 'アカウント', href: '/MyPage/Account', className: classes.avatarMenuItemAccount },
    { label: 'フォロー', href: '/MyPage/Follow', className: classes.avatarMenuItemFollow },
    { label: 'ブックマーク', href: '/MyPage/Bookmark', className: classes.avatarMenuItemBookmark },
    { label: 'メッセージ', href: '/MyPage/Message', className: classes.avatarMenuItemMessage },
    // { label: '契約', href: '', className: classes.avatarMenuItemContract },
    { label: '通知メール', href: '/MyPage/NotificationMail', className: classes.avatarMenuItemMail },
    { label: 'ログアウト', href: '#', onclick: onLogOutClick, className: classes.avatarMenuItemLogout },
  ];

  return (
    <ul className={className}>
      {list.map((item) => (
        <li key={item.href} className={classNames(classes.avatarMenuItem, item.className)}>
          <RLink kind="AvatarMenuItem" href={item.href} onClick={item.onclick}>
            {item.label}
          </RLink>
        </li>
      ))}
    </ul>
  );
}

const useOnLogOutClick = (): MouseEventHandler<HTMLElement> => {
  const resetLogInUser = useResetRecoilState(logInUserAtom);
  const [save] = useSave('/v1/User/LogOut');

  const onLogOutClick = useCallback(
    async (e: MouseEvent<HTMLElement>) => {
      await save(e, {});
      resetLogInUser();

      appInsights.clearAuthenticatedUserContext();
    },
    [resetLogInUser, save],
  );

  return onLogOutClick;
};

const useOnExternalClick = (): void => {
  const setAvatarMenuState = useSetRecoilState(avatarMenuStateAtom);

  const onClick = useCallback(() => {
    setAvatarMenuState(false);
    document.body.removeEventListener('click', onClick);
  }, [setAvatarMenuState]);

  useEffect((): (() => void) => {
    document.body.addEventListener('click', onClick);

    return (): void => {
      document.body.removeEventListener('click', onClick);
    };
  }, [onClick]);
};

const PREFIX = 'AvatarMenu';
const classes = {
  avatarMenuItem: `${PREFIX}-avatarMenuItem`,
  avatarMenuItemDiv: `${PREFIX}-avatarMenuItemDiv`,
  avatarMenuItemProfile: `${PREFIX}-avatarMenuItemProfile`,
  avatarMenuItemAccount: `${PREFIX}-avatarMenuItemAccount`,
  avatarMenuItemFollow: `${PREFIX}-avatarMenuItemFollow`,
  avatarMenuItemBookmark: `${PREFIX}-avatarMenuItemBookmark`,
  avatarMenuItemMessage: `${PREFIX}-avatarMenuItemMessage`,
  avatarMenuItemContract: `${PREFIX}-avatarMenuItemContract`,
  avatarMenuItemMail: `${PREFIX}-avatarMenuItemMail`,
  avatarMenuItemLogout: `${PREFIX}-avatarMenuItemLogout`,
};

export const AvatarMenu = styled(Component, {
  shouldForwardProp: (prop) => prop !== 'sx',
})({
  padding: '4px 0',
  width: '192px',
  backgroundColor: '#fff',
  position: 'absolute',
  top: '64px',
  right: '0px',
  boxShadow: '0px 0px 2px rgba(56, 56, 56, 0.2), 0px 2px 2px rgba(56, 56, 56, 0.24)',
  borderRadius: '4px',
  zIndex: 100,

  [`& .${classes.avatarMenuItem}`]: {
    display: 'flex',
    height: 44,
    lineHeight: '16px',
    backgroundPosition: '16px center',
    backgroundRepeat: 'no-repeat',
    '&:hover': {
      backgroundColor: '#F2F2F2',
    },
    '&:last-of-type': {
      borderTop: '1px solid #C6C6C6',
    },
  },

  [`& .${classes.avatarMenuItemDiv}`]: {
    fontSize: '12px',
    lineHeight: '15px',
    color: '#757575',
  },

  [`& .${classes.avatarMenuItemProfile}`]: {
    backgroundImage: `url(${I_profile})`,
  },

  [`& .${classes.avatarMenuItemAccount}`]: {
    backgroundImage: `url(${I_settings})`,
  },

  [`& .${classes.avatarMenuItemFollow}`]: {
    backgroundImage: `url(${I_follow})`,
  },

  [`& .${classes.avatarMenuItemBookmark}`]: {
    backgroundImage: `url(${I_bookmark})`,
  },

  [`& .${classes.avatarMenuItemMessage}`]: {
    backgroundImage: `url(${I_message})`,
  },

  [`& .${classes.avatarMenuItemContract}`]: {
    backgroundImage: `url(${I_contract})`,
  },

  [`& .${classes.avatarMenuItemMail}`]: {
    backgroundImage: `url(${I_mail})`,
  },

  [`& .${classes.avatarMenuItemLogout}`]: {
    backgroundImage: `url(${I_logout})`,
  },
});
