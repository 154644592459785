import { SerializedStyles } from '@emotion/react';
import { css, styled } from '@mui/material/styles';
import { ReactNode } from 'react';
import { ComponentPropsNormarized } from '../../types';

type Props = {
  kind?: 'Primary' | 'CancelNoFrame' | 'WithFrame' | 'WithFrameGray' | 'Danger' | 'Follow' | 'Unfollow';
  size?: 'L' | 'M' | 'CancelNoFrame' | 'InModalL' | 'InModalM' | 'InModalS' | 'Follow';
  width?: number;

  children?: ReactNode;
} & ComponentPropsNormarized<'button'>;

function Component({ width, children, className, ...others }: Props): JSX.Element {
  return (
    <button style={width ? { width: width } : {}} className={className} {...others}>
      {children}
    </button>
  );
}

const getCSS = ({ kind, size }: Props): SerializedStyles => {
  const base = css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    borderWidth: 0,
    borderRadius: '4px',
    padding: 0,

    cursor: 'pointer',
  });

  const kindPrimary = css({
    background: '#3399FF',
    color: '#FFFFFF',
    '&:hover': {
      background: '#0066CC',
    },
  });
  const kindCancelNoFrame = css({
    background: 'none',
    color: '#404040',
    '&:hover': {
      background: 'rgba(51, 153, 255, 0.06)',
      color: '#3399FF',
    },
  });
  const kindWithFrame = css({
    background: '#FFFFFF',
    color: '#404040',
    border: '1px solid #575757',

    '&:hover': {
      color: '#3399FF',
      borderColor: '#3399FF',
    },
  });
  const kindWithFrameGray = css({
    background: '#FFFFFF',
    color: '#757575',
    border: '1px solid #C6C6C6',

    '&:hover': {
      backgroundColor: '#f2f2f2',
    },
  });
  const kindDanger = css({
    color: '#FFFFFF',
    background: '#FC5F5F',
    '&:hover': {
      background: '#FB4646',
    },
  });
  const kindFollow = css({
    margin: '0 auto',
    background: '#FFFFFF',
    fontSize: '14px',
    color: '#575757',
    border: '1px solid #575757',

    '&:hover': {
      border: '1px solid #FC5F72',
      color: '#FC5F72',
    },
  });
  const kindUnfollow = css({
    margin: '0 auto',
    background: '#FFFFFF',
    fontSize: '14px',
    color: '#FC5F72',
    border: '1px solid #FC5F72',

    '&:hover': {
      border: '1px solid #575757',
      color: '#575757',
    },
  });

  const sizeM = css({
    width: 60,
    height: 32,
    lineHeight: '20px',
  });
  const sizeCancelNoFrame = css({
    width: 100,
    height: 32,
    lineHeight: '20px',
  });
  const sizeL = css({
    width: 128,
    height: 48,
    lineHeight: '24px',
  });
  const sizeInModalL = css({
    width: 386,
    height: 48,
    lineHeight: '24px',
  });
  const sizeInModalM = css({
    width: 184,
    height: 48,
    lineHeight: '24px',
  });
  const sizeInModalS = css({
    width: 128,
    height: 48,
    lineHeight: '24px',
  });
  const sizeFollow = css({
    width: 232,
    height: 48,
    lineHeight: '20px',
  });

  let s: Props['size'];
  if (size === undefined) {
    if (kind === 'CancelNoFrame') {
      s = 'CancelNoFrame';
    } else {
      s = 'M';
    }
  } else {
    s = size;
  }

  return css([
    base,
    (kind === 'Primary' || kind === undefined) && kindPrimary,
    kind === 'CancelNoFrame' && kindCancelNoFrame,
    kind === 'WithFrame' && kindWithFrame,
    kind === 'WithFrameGray' && kindWithFrameGray,
    kind === 'Danger' && kindDanger,
    kind === 'Follow' && kindFollow,
    kind === 'Unfollow' && kindUnfollow,
    s === 'L' && sizeL,
    s === 'M' && sizeM,
    s === 'CancelNoFrame' && sizeCancelNoFrame,
    s === 'InModalL' && sizeInModalL,
    s === 'InModalM' && sizeInModalM,
    s === 'InModalS' && sizeInModalS,
    s === 'Follow' && sizeFollow,
  ]);
};

export const RButton = styled(Component, {
  shouldForwardProp: (prop) => prop !== 'sx' && prop !== 'kind' && prop !== 'size',
})(getCSS);
