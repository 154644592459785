import I_bookmarkOff from '../../../../Images/action/unbookmarked.svg';
import I_bookmarkOn from '../../../../Images/action/bookmarked.svg';

import { styled } from '@mui/material/styles';
import { SyntheticEvent, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RButton } from '../../../base/button/button';
import { RLink } from '../../../base/link/link';
import { RRecordGroup } from '../../../core/record/record-group/record-group';
import { RRecord } from '../../../core/record/record/record';
import { useTitle } from '../../../hooks/title';
import { RImage } from '../../../base/image/image';
import { RAvatar } from '../../../core/avatar/avatar';
import { useData } from '../../../hooks/data';
import { useSave } from '../../../hooks/save';
import { ComponentPropsNormarized } from '../../../types';

type BookmarkItem = {
  id: number;
  projectID: number;
  commentOwnerID: number;
  commentOwnerDeleted: boolean;
  projectName: string | null;
  commentID: number;
  commentOwnerAvatarImagePath: string;
  projectDeleted: boolean;
  projectOwnerDeleted: boolean;
  commentDeleted: boolean;
  categoryName: string | null;
  comment: string;
  createDate: string;
};

type Props = ComponentPropsNormarized<'div'>;

function Component({ className }: Props): JSX.Element {
  useTitle('ブックマーク');

  const [onToggle, deletedCommentIDs] = useOnToggle();

  const onRecordClick = useOnRecordClick();

  const [data] = useData<BookmarkItem[]>('/v1/MyPage/Bookmark');

  if (data === undefined) {
    return <></>;
  }

  if (data.length === 0) {
    return (
      <div className={className}>
        <RRecord
          hover="None"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '220px',
          }}>
          <h2 className={classes.boolmarkItemNoneTitle}>まだブックマークはありません</h2>
          <p className={classes.boolmarkItemNoneDescription}>参考になったレビューコメントをブックマークし、時々復習しましょう！</p>
          <RLink
            kind="GrayOnWhite"
            href="/"
            sx={{
              marginTop: '24px',
            }}>
            <RButton size="M" width={186}>
              プロジェクトを見に行く
            </RButton>
          </RLink>
        </RRecord>
      </div>
    );
  }

  return (
    <div className={className}>
      <RRecordGroup>
        {data.map((bookmark) => (
          <RRecord
            key={bookmark.id}
            hover={bookmark.projectDeleted || bookmark.projectOwnerDeleted ? 'None' : undefined}
            onClick={(_): void => onRecordClick(bookmark)}>
            <article>
              <div className={classes.bookmarkHeader}>
                {commentAlive(bookmark) ? (
                  <div>{bookmark.categoryName !== null && <p className={classes.categoryName}>{bookmark.categoryName}</p>}</div>
                ) : (
                  <p className={classes.deletedComment}>{bookmark.comment}</p>
                )}

                <div className={classes.toggleIcon}>
                  <RImage
                    src={deletedCommentIDs.includes(bookmark.commentID) ? I_bookmarkOff : I_bookmarkOn}
                    onClick={(e): void => onToggle(e, bookmark.commentID)}
                  />
                </div>
              </div>

              {commentAlive(bookmark) && <p className={classes.comment}>{bookmark.comment}</p>}

              <div className={classes.bookmarkFooter}>
                <RAvatar size="S" src={bookmark.commentOwnerAvatarImagePath} userID={bookmark.commentOwnerDeleted ? undefined : bookmark.commentOwnerID} />

                <div className={classes.projectInfo}>
                  <RLink kind="GrayOnWhite" href={`/Project/Details/${bookmark.projectID}`}>
                    {bookmark.projectName}
                  </RLink>
                  <p className={classes.commentPostDate}>{bookmark.createDate}</p>
                </div>
              </div>
            </article>
          </RRecord>
        ))}
      </RRecordGroup>
    </div>
  );
}

const commentAlive = (bookmark: BookmarkItem): boolean => !bookmark.projectDeleted && !bookmark.projectOwnerDeleted && !bookmark.commentDeleted;

const useOnRecordClick = (): ((bookmark: BookmarkItem) => void) => {
  const navigate = useNavigate();

  const onRecordClick = useCallback(
    (bookmark: BookmarkItem) => {
      if (bookmark.projectDeleted || bookmark.projectOwnerDeleted) {
        return;
      }
      navigate(`/Project/Details/${bookmark.projectID}#comment-id-${bookmark.commentID}`);
    },
    [navigate],
  );

  return onRecordClick;
};

const useOnToggle = (): [(e: SyntheticEvent, commentID: number) => void, number[]] => {
  const [deletedCommentIDs, setDeletedCommentIDs] = useState([] as number[]);
  const [save] = useSave('/v1/Project/BookmarkComment');

  const onToggle = useCallback(
    async (e: SyntheticEvent, commentID: number): Promise<void> => {
      e.stopPropagation();

      const index = deletedCommentIDs.findIndex((x) => x === commentID);
      const turnOn = index !== -1;
      await save(e, { commentID: commentID, bookmark: turnOn });

      if (turnOn) {
        const newIDs = [...deletedCommentIDs];
        newIDs.splice(index, 1);
        setDeletedCommentIDs(newIDs);
      } else {
        setDeletedCommentIDs([...deletedCommentIDs, commentID]);
      }
    },
    [deletedCommentIDs, save],
  );

  return [onToggle, deletedCommentIDs];
};

const PREFIX = 'Bookmark';
const classes = {
  boolmarkItemNoneTitle: `${PREFIX}-boolmarkItemNoneTitle`,
  boolmarkItemNoneDescription: `${PREFIX}-boolmarkItemNoneDescription`,
  bookmarkHeader: `${PREFIX}-bookmarkHeader`,
  categoryName: `${PREFIX}-categoryName`,
  deletedComment: `${PREFIX}-deletedComment`,
  toggleIcon: `${PREFIX}-toggleIcon`,
  comment: `${PREFIX}-comment`,
  bookmarkFooter: `${PREFIX}-bookmarkFooter`,
  projectInfo: `${PREFIX}-projectInfo`,
  commentPostDate: `${PREFIX}-commentPostDate`,
};

export const Bookmark = styled(Component, {
  shouldForwardProp: (prop) => prop !== 'sx',
})({
  [`& .${classes.boolmarkItemNoneTitle}`]: {
    fontWeight: 'bold',
  },
  [`& .${classes.boolmarkItemNoneDescription}`]: {
    marginTop: '12px',
    letterSpacing: '0.25px',
    lineHeight: '21px',
    color: '#757575',
  },
  [`& .${classes.bookmarkHeader}`]: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  [`& .${classes.categoryName}`]: {
    padding: '4px 8px',
    background: '#F0F7FF',
    borderRadius: '4px',
    fontSize: '10px',
    lineHeight: '10px',
    color: '#0080FF',
  },
  [`& .${classes.deletedComment}`]: {
    marginRight: '12px',
    width: '100%',
    padding: '12px',
    background: '#F2F2F2',
    borderRadius: '4px',
  },
  [`& .${classes.toggleIcon}`]: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '32px',
    height: '32px',
    borderRadius: '16px',
    cursor: 'pointer',
    [`&:hover`]: {
      background: 'rgba(51, 153, 255, 0.1)',
    },
  },
  [`& .${classes.comment}`]: {
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 3,
    overflow: 'hidden',
  },
  [`& .${classes.bookmarkFooter}`]: {
    display: 'flex',
    marginTop: '8px',
  },
  [`& .${classes.projectInfo}`]: {
    marginLeft: 8,
    color: '#757575',
    display: 'flex',
    alignItems: 'center',
  },
  [`& .${classes.commentPostDate}`]: {
    marginLeft: '8px',
  },
});
