import { styled } from '@mui/material/styles';
import { useState, Dispatch, SetStateAction, useCallback } from 'react';
import { useIDFromParam } from '../../../hooks/id-from-param';
import { useTitle } from '../../../hooks/title';
import { ComponentPropsNormarized } from '../../../types';
import { Header } from './header';
import { Messages } from './messages';

export type Params = {
  id: number;
};

type Props = ComponentPropsNormarized<'div'>;

function Component({ className }: Props): JSX.Element {
  useTitle('メッセージ');

  const id = useIDFromParam();

  const [params, setParams] = useState<Params>({ id: id });
  const refresh = useRefresh(setParams);

  if (isNaN(id)) {
    return <></>;
  }

  return (
    <div className={className}>
      <Header userID={id} refresh={refresh} />
      <Messages params={params} refresh={refresh} />
    </div>
  );
}

const useRefresh = (setParams: Dispatch<SetStateAction<Params>>): (() => void) => {
  const refresh = useCallback(() => {
    setParams((x) => ({ ...x }));
  }, [setParams]);

  return refresh;
};

export const MessageDetail = styled(Component, {
  shouldForwardProp: (prop) => prop !== 'sx',
})({});
