import { styled } from '@mui/material/styles';
import { RRecordGroup } from '../../core/record/record-group/record-group';
import { RRecord } from '../../core/record/record/record';
import { RProjectRecord } from '../../core/record/project-record/project-record';
import { useCallback, useEffect, useState } from 'react';
import { ApiResponse, useApi } from '../../../services/api';

import { ComponentPropsNormarized, Project, projectTypes } from '../../types';

type Payload = {
  projects: Project[];
  hasMore: boolean;
};

// const categoryFilterItems: Item[] = [
//   { label: '言語', value: '' },
//   { label: '言語1', value: '1' },
//   { label: '言語2', value: '2' },
// ];

type Props = ComponentPropsNormarized<'div'>;

function Component({ className }: Props): JSX.Element {
  const [
    data,
    // filterValue,
    // setFilterValue,
    // showClosed,
    // setShowClosed,
    onMoreClick,
  ] = useData();

  return (
    <div className={className}>
      <section>
        <h3 className={classes.title}>新着プロジェクト</h3>

        {/* <div className={classes.tools}>
          <RImage
            sx={{
              marginTop: '5px',
              width: '25px',
              height: '25px',
            }}
            src={I_filter}
          />
          <RSelect
            sx={{
              marginLeft: '12px',
            }}
            data={categoryFilterItems}
            value={filterValue}
            setValue={setFilterValue}
          />
          <RCheckbox
            sx={{
              marginLeft: '12px',
            }}
            checked={showClosed}
            setChecked={setShowClosed}>
            終了したプロジェクトを表示
          </RCheckbox>
        </div> */}
      </section>

      <RRecordGroup
        sx={{
          marginTop: '8px',
        }}>
        {data.projects.map((project) => (
          <RProjectRecord key={project.id} project={project} />
        ))}
        {data.hasMore && <RRecord next10 onClick={onMoreClick} />}
      </RRecordGroup>
    </div>
  );
}

const useData: () => [
  Payload,
  // string,
  // Dispatch<string>,
  // boolean,
  // Dispatch<boolean>,
  () => void,
] = () => {
  // const [filterValue, setFilterValue] = useState((categoryFilterItems[0] as Item).value);
  // const [showClosed, setShowClosed] = useState(false);

  const [data, setData] = useState({
    projects: [] as Project[],
    hasMore: false,
  });

  const api = useApi();

  useEffect(() => {
    const f = async (): Promise<void> => {
      const response = (await api.get('/v1/Project/List', {
        params: {
          projectType: projectTypes.all,
          count: 20,
        },
      })) as ApiResponse;

      setData(response.payload as Payload);
    };
    f();
  }, [api]);

  const onMoreClick = useCallback(async () => {
    const response = (await api.get('/v1/Project/List', {
      params: {
        projectType: projectTypes.all,
        count: data.projects.length + 10,
      },
    })) as ApiResponse;

    setData(response.payload as Payload);
  }, [api, data.projects.length]);

  return [
    data,
    // filterValue,
    // setFilterValue,
    // showClosed,
    // setShowClosed,
    onMoreClick,
  ];
};

const PREFIX = 'NewProjects';
const classes = {
  title: `${PREFIX}-title`,
  tools: `${PREFIX}-tools`,
  createProject: `${PREFIX}-createProject`,
};

export const NewProjects = styled(Component)({
  marginTop: '28px',

  [`& .${classes.title}`]: {
    fontSize: '1.6rem',
    marginBottom: '12px',
    color: '#757575',
    fontWeight: 'bold',
  },

  [`& .${classes.tools}`]: {
    display: 'flex',
  },
});
