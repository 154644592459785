import { styled } from '@mui/material/styles';
import { Comment } from './comment';
import { CommentResponses } from './comment-responses';
import { CommentNewResponse } from './comment-new-response';
import { Review } from '../../../atoms/project-details';
import { useRecoilValue } from 'recoil';
import { logInUserAtom } from '../../../atoms/log-in-user';
import { ComponentPropsNormarized } from '../../types';

type Props = {
  projectID: number;
  projectOwnerID: number;
  review: Review;
} & ComponentPropsNormarized<'div'>;

function Component({ projectID, projectOwnerID, review, className }: Props): JSX.Element {
  const logInUser = useRecoilValue(logInUserAtom);

  return (
    <div className={className}>
      <Comment projectID={projectID} projectOwnerID={projectOwnerID} review={review} comment={review.comments![0]!} root />
      <CommentResponses projectID={projectID} projectOwnerID={projectOwnerID} review={review} />
      {logInUser.id !== null && <CommentNewResponse projectID={projectID} review={review} />}
    </div>
  );
}

export const ReviewBox = styled(Component)({
  width: 328,
  backgroundColor: '#fff',
  borderRadius: 8,
  border: '1px solid #E3E3E3',
  marginRight: 12,
  marginBottom: 12,
  marginTop: 8,
  padding: '11px 15px',
});
