import { styled } from '@mui/material/styles';
import { Comment } from './comment';
import { Review } from '../../../atoms/project-details';
import { ComponentPropsNormarized } from '../../types';

type Props = {
  projectID: number;
  projectOwnerID: number;
  review: Review;
} & ComponentPropsNormarized<'div'>;

function Component({ projectID, projectOwnerID, review, className }: Props): JSX.Element {
  const comments = review.comments.slice(1);

  if (comments.length === 0) {
    return <></>;
  }

  return (
    <div className={className}>
      {comments.map((comment) => (
        <div key={comment.id} className={classes.comment}>
          <Comment projectID={projectID} projectOwnerID={projectOwnerID} review={review} comment={comment} />
        </div>
      ))}
    </div>
  );
}

const PREFIX = 'CommentResponses';
const classes = {
  comment: `${PREFIX}-comment`,
};

export const CommentResponses = styled(Component)({
  [`& .${classes.comment}`]: {
    marginTop: 8,
    paddingLeft: 12,
    borderLeft: '1px solid #C6C6C6',
  },
});
