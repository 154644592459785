import { ChangeEventHandler, RefObject, useCallback } from 'react';
import { useRefreshAsync } from '../../atoms/project-details';
import { useOnCloseClick } from '../screens/project-details/new-review-dialog';
import { ValidationErrors } from '../types';
import { useSave } from './save';

export const useUploadFile = (projectID: number, fileRef: RefObject<HTMLInputElement>): [ValidationErrors, ChangeEventHandler<HTMLInputElement>] => {
  const [save, validationErrors] = useSave('/v1/File/Upload');
  const refreshAsync = useRefreshAsync();

  const closeNewReviewDialog = useOnCloseClick();

  const uploadFile = useCallback(
    async (e: React.SyntheticEvent) => {
      if (fileRef.current === null) {
        return;
      }
      if (fileRef.current.files === null || fileRef.current.files[0] === undefined) {
        return;
      }

      const params = new FormData();
      params.append('projectID', projectID.toString());
      params.append('uploadFile', fileRef.current.files[0]);

      await save(e, params, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      // 同じファイルを、内容を変更して再選択された場合にonChangeが反応できるようにしておく。
      // eslint-disable-next-line require-atomic-updates
      fileRef.current.value = '';

      closeNewReviewDialog();

      await refreshAsync();
    },
    [closeNewReviewDialog, fileRef, projectID, refreshAsync, save],
  );

  return [validationErrors, uploadFile];
};
