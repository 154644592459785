import I_add from '../../../../Images/action/add.png';
import I_trash from '../../../../Images/icon/trash.png';
import I_trashHover from '../../../../Images/icon/trash-hover.png';

import { Rating } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ComponentPropsWithoutRef, useCallback, useEffect } from 'react';
import { maxLength } from '../../../../services/max-length';
import { RButton } from '../../../base/button/button';
import { RLink } from '../../../base/link/link';
import { RSelect } from '../../../base/select/select';
import { RTextArea } from '../../../base/text-area/text-area';
import { RImage } from '../../../base/image/image';
import { useRecoilValue } from 'recoil';
import { languagesAtom } from '../../../../atoms/languages';
import { useData } from '../../../hooks/data';
import { useSave } from '../../../hooks/save';
import { RValidationMessages } from '../../../core/validation-message/validation-messages';
import { Circle } from '../circle';
import { ComponentPropsNormarized } from '../../../types';

type Skill = {
  id: number;
  languageID: number;
  level: number;
  description: string;
};

type Props = ComponentPropsNormarized<'div'>;

function Component({ className }: Props): JSX.Element {
  const [data, setData] = useData<Skill[]>('/v1/MyPage/Skills');
  const addNewSkill = useAddNewSkill(data, setData);
  const [save, validationErrors] = useSave('/v1/MyPage/UpdateSkills', '保存しました。');

  const languages = useRecoilValue(languagesAtom);

  const options: ComponentPropsWithoutRef<typeof RSelect>['data'] = [{ label: '経験のある言語を教えて下さい。', value: '' }];
  options.push(...languages.map((x) => ({ label: x.name, value: x.id.toString() })));

  useEffect(() => {
    if (data !== undefined && data.length === 0) {
      addNewSkill();
    }
  }, [addNewSkill, data]);

  if (data === undefined) {
    return <></>;
  }

  return (
    <div className={className}>
      <h2>
        <Circle color="#fdba35" />
        <span>言語</span>
      </h2>

      {data.map((skill, index) => (
        <div key={index} className={classes.languageInput}>
          <div className={classes.languageInputInner}>
            <RSelect
              kind="Required"
              data={options}
              value={skill.languageID.toString()}
              setValue={(v): void => {
                const newData = [...data];
                newData[index]!.languageID = Number(v);
                setData(newData);
              }}
            />
            <p className={classes.stars}>
              <Rating
                sx={{
                  color: '#3399FF',
                }}
                size="large"
                value={skill.level}
                onChange={(_event, newValue): void => {
                  if (newValue === null) {
                    return;
                  }
                  const newData = [...data];
                  newData[index]!.level = newValue;
                  setData(newData);
                }}
              />
            </p>
            <RValidationMessages messages={validationErrors[`Index${index}.Level`]} />

            <div>
              <RTextArea
                value={skill.description}
                setValue={(v): void => {
                  const newData = [...data];
                  newData[index]!.description = v;
                  setData(newData);
                }}
                placeholder="どんな経験・スキルか教えてください。"
                maxLength={maxLength.user.skill.description}
                sx={{
                  width: '100%',
                  height: 72,
                }}
              />
              <RValidationMessages messages={validationErrors[`Index${index}.Description`]} />
            </div>

            <div className={classes.removeLanguage}>
              <RLink
                kind="Danger"
                href="#"
                onClick={(): void => {
                  const newData = [...data];
                  newData.splice(index, 1);
                  setData(newData);
                }}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}>
                <span />
                この言語を削除
              </RLink>
            </div>
          </div>
        </div>
      ))}

      {data.length < 10 && (
        <p className={classes.addLanguage}>
          <RButton
            kind="WithFrameGray"
            sx={{
              display: 'block',
              padding: '7px',
              width: 96,
              fontSize: 12,
              lineHeight: '20px',
            }}
            onClick={addNewSkill}>
            <RImage
              src={I_add}
              sx={{
                marginBottom: '-3px',
              }}
            />
            <span className={classes.addLanguageLabel}>言語を追加</span>
          </RButton>
        </p>
      )}

      <div className={classes.buttonWrapper}>
        <RButton
          onClick={(e): void => {
            save(e, { skills: data });
          }}
          sx={{
            marginTop: '16px',
            width: 102,
          }}>
          言語を保存
        </RButton>
      </div>
    </div>
  );
}

const useAddNewSkill = (data: Skill[] | undefined, setData: (value: Skill[]) => void): (() => void) => {
  const addNewSkill = useCallback(() => {
    if (data === undefined) {
      return;
    }

    const newData = [...data];
    newData.push({ id: 0, languageID: 0, level: 3, description: '' });
    setData(newData);
  }, [data, setData]);

  if (data === undefined) {
    return (): void => {
      // no-op
    };
  }

  return addNewSkill;
};

const PREFIX = 'Languages';
const classes = {
  languageInput: `${PREFIX}-languageInput`,
  languageInputInner: `${PREFIX}-languageInputInner`,
  stars: `${PREFIX}-stars`,
  removeLanguage: `${PREFIX}-removeLanguage`,
  addLanguage: `${PREFIX}-addLanguage`,
  addLanguageLabel: `${PREFIX}-addLanguageLabel`,
  buttonWrapper: `${PREFIX}-buttonWrapper`,
};

export const Languages = styled(Component, {
  shouldForwardProp: (prop) => prop !== 'sx',
})({
  marginTop: 40,

  [`& .${classes.languageInput}`]: {
    marginBottom: 24,
    borderLeft: '3px solid #f2f2f2',
  },
  [`& .${classes.languageInputInner}`]: {
    paddingLeft: 12,
  },
  [`& .${classes.stars}`]: {
    marginTop: 8,
    marginBottom: 8,
  },

  [`& .${classes.removeLanguage}`]: {
    display: 'inline-block',
    marginTop: 8,
  },
  [`& .${classes.removeLanguage} span`]: {
    display: 'block',
    marginRight: 4,
    width: 12,
    height: 14,
    backgroundImage: `url(${I_trash})`,
  },
  [`& .${classes.removeLanguage}:hover span`]: {
    backgroundImage: `url(${I_trashHover})`,
  },

  [`& .${classes.addLanguage}`]: {
    marginTop: 24,
  },
  [`& .${classes.addLanguageLabel}`]: {
    marginLeft: 4,
    fontSize: 12,
    textAlign: 'center',
  },

  [`& .${classes.buttonWrapper}`]: {
    display: 'flex',
    justifyContent: 'right',
  },
});
