import { styled } from '@mui/material/styles';
import { Outlet } from 'react-router-dom';
import { Summary } from './summary';
import { Activities } from './activities';
import { useData } from '../../hooks/data';
import { useTitle } from '../../hooks/title';
import { MenuItemDataCounts, MenuItems, RTabBar } from '../../core/tab-bar/tab-bar';
import { useIDFromParam } from '../../hooks/id-from-param';
import { RLoadingPlaceholder } from '../../core/loading-placeholder/loading-placeholder';
import { RContainer } from '../../layout/Container';
import { ComponentPropsNormarized } from '../../types';

type Data = {
  name: string;
  avatarURL: string;
  followerCount: number;
  lastAccessDate: string;
  following: boolean;
  dataCounts: MenuItemDataCounts;
};

const menuItems: MenuItems = [
  {
    key: 'profile',
    label: 'プロフィール',
    url: '/Profile/Main',
  },
  {
    key: 'project',
    label: 'プロジェクト',
    url: '/Profile/Project',
  },
];

type Props = ComponentPropsNormarized<typeof RContainer>;

function Component({ className }: Props): JSX.Element {
  const id = useIDFromParam();

  const [data] = useData<Data>(`/v1/Profile/Summary/${isNaN(id) ? 0 : id}`);
  useTitle(data?.name);

  if (data === undefined) {
    return <RLoadingPlaceholder />;
  }

  return (
    <RContainer
      className={className}
      innerStyle={{
        display: 'flex',
      }}>
      <section className={classes.left}>
        <Summary userID={id} {...data} />
        <Activities userID={id} />
      </section>

      <div className={classes.right}>
        <RTabBar menuItems={menuItems} dataCounts={data.dataCounts} />
        <Outlet />
      </div>
    </RContainer>
  );
}

const PREFIX = 'Profile';
const classes = {
  left: `${PREFIX}-left`,
  right: `${PREFIX}-right`,
  tabBar: `${PREFIX}-tabBar`,
  tab: `${PREFIX}-tab`,
  tabLink: `${PREFIX}-tabLink`,
};

export const Profile = styled(Component, {
  shouldForwardProp: (prop) => prop !== 'sx',
})({
  [`& .${classes.left}`]: {
    marginRight: '40px',
  },
  [`& .${classes.right}`]: {
    maxWidth: '1028px',
    width: '100%',
  },
});
