import { styled } from '@mui/material/styles';
import { useLocation } from 'react-router-dom';
import { RLink } from '../../base/link/link';
import { useIDFromParam } from '../../hooks/id-from-param';
import { ComponentPropsNormarized } from '../../types';

// これのindexはMenuItemsのkeyと対応。
export type MenuItemDataCounts = { [index: string]: number };

export type MenuItems = {
  key: string;
  label: string;
  url: string;
}[];

type Props = {
  menuItems: MenuItems;
  dataCounts?: MenuItemDataCounts;
  idIsOptional?: boolean;
} & ComponentPropsNormarized<'nav'>;

function Component({ menuItems, dataCounts = {}, idIsOptional = false, className }: Props): JSX.Element {
  const location = useLocation();

  const id = useIDFromParam(idIsOptional);

  return (
    <nav className={className}>
      <ul className={classes.tabBar}>
        {menuItems.map((menuItem) => (
          <li key={menuItem.label} className={classes.tab}>
            <RLink
              kind="GrayOnGray"
              href={menuItem.url + (isNaN(id) ? '' : `/${id}`)}
              sx={location.pathname.split('/', 3).join('/') === menuItem.url ? { ...TAB_LINK_CSS, ...ACTIVE_TAB_CSS } : TAB_LINK_CSS}>
              {menuItem.label}
              {dataCounts[menuItem.key] !== undefined && <> ({dataCounts[menuItem.key]})</>}
            </RLink>
          </li>
        ))}
      </ul>
    </nav>
  );
}

const PREFIX = 'RTabBar';
const classes = {
  tabBar: `${PREFIX}-tabBar`,
  tab: `${PREFIX}-tab`,
};

const TAB_LINK_CSS = {
  display: 'block',
  padding: '0 12px',
  color: '#757575',
};

const ACTIVE_TAB_CSS = {
  color: '#3399FF',
  fontWeight: 'bold',
  boxShadow: 'inset 0px -3px 0px #3399FF',
};

export const RTabBar = styled(Component, {
  shouldForwardProp: (prop) => prop !== 'sx',
})({
  [`& .${classes.tabBar}`]: {
    display: 'flex',
    borderBottom: '1px solid #C6C6C6',
  },
  [`& .${classes.tab}`]: {
    lineHeight: '40px',
    '&:hover': {
      background: '#F2F2F2',
    },
  },
});
