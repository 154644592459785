import I_filter from '../../../Images/content/filter_list_24px.png';
import I_createProject from '../../../Images/icon/add.png';

import { styled } from '@mui/material/styles';
import { useRecoilValue } from 'recoil';
import { logInUserAtom } from '../../../atoms/log-in-user';
import { RRecordGroup } from '../../core/record/record-group/record-group';
import { RRecord } from '../../core/record/record/record';
import { RButton } from '../../base/button/button';
import { RProjectRecord } from '../../core/record/project-record/project-record';
import { Item, RSelect } from '../../base/select/select';
import { Dispatch, useCallback, useEffect, useState } from 'react';
import { RImage } from '../../base/image/image';
import { ApiResponse, useApi } from '../../../services/api';
import { projectTypes, Payload, ComponentPropsNormarized } from '../../types';
import { RProjectEditor } from '../../core/project-editor/project-editor';
import { formatDate } from '../../functions/formatDate';
import { useNavigate } from 'react-router-dom';
import { maxLength } from '../../../services/max-length';

const categoryFilterItems: Item[] = [
  // eslint-disable-next-line no-bitwise
  { label: 'すべてのプロジェクト', value: (projectTypes.mine | projectTypes.commented).toString() },
  { label: '作成したプロジェクト', value: projectTypes.mine.toString() },
  { label: 'コメントしたプロジェクト', value: projectTypes.commented.toString() },
];

type Props = ComponentPropsNormarized<'div'>;

function Component({ className }: Props): JSX.Element {
  const logInUser = useRecoilValue(logInUserAtom);
  const creeateProjectName = useCreateProjectName();

  const navigate = useNavigate();

  const [
    data,
    filterValue,
    setFilterValue,
    // showClosed,
    // setShowClosed,
    onMoreClick,
  ] = useData();

  const [projectEditorVisible, showProjectEditor, closeProjectEditor] = useProjectEditor();

  if (logInUser.id === null || data === undefined) {
    return <></>;
  }

  return (
    <div className={className}>
      <section>
        <h3 className={classes.title}>作成・コメントしたプロジェクト</h3>

        <div className={classes.tools}>
          <RImage
            sx={{
              marginTop: '5px',
              width: '25px',
              height: '25px',
            }}
            src={I_filter}
          />
          <RSelect
            sx={{
              marginLeft: '12px',
            }}
            data={categoryFilterItems}
            value={filterValue}
            setValue={setFilterValue}
          />
          {/* <RCheckbox sx={styles.showClosed} checked={showClosed} setChecked={setShowClosed}>
            終了したプロジェクトを表示
          </RCheckbox> */}

          <p className={classes.createProject} onClick={showProjectEditor}>
            <RImage
              sx={{
                width: '20px',
                height: '20px',
                marginRight: '2px',
                verticalAlign: 'top',
              }}
              src={I_createProject}
            />
            プロジェクトを作成
          </p>
        </div>
      </section>

      <RRecordGroup
        sx={{
          marginTop: '8px',
        }}>
        {data.projects.map((project) => (
          <RProjectRecord key={project.id} project={project} />
        ))}
        {data.hasMore && <RRecord next10 onClick={onMoreClick} />}
      </RRecordGroup>

      {data.projects.length === 0 && (
        <div className={classes.pannel}>
          <p>プロジェクトを作るか、ほかの人のレビューをするとここに表示されます。</p>
          <div className={classes.button}>
            <RButton onClick={showProjectEditor} width={156}>
              プロジェクトを作成
            </RButton>
          </div>
        </div>
      )}

      {projectEditorVisible && (
        <RProjectEditor
          name={creeateProjectName()}
          description=""
          closeProjectEidtor={closeProjectEditor}
          postURL="/v1/Project/Create"
          onEnd={(id): void => {
            navigate(`/Project/Details/${id}`);
          }}
        />
      )}
    </div>
  );
}

const useData: () => [
  Payload | undefined,
  string | undefined,
  Dispatch<string | undefined>,
  // boolean,
  // Dispatch<boolean>,
  () => void,
] = () => {
  const logInUser = useRecoilValue(logInUserAtom);

  const [filterValue, setFilterValue] = useState((categoryFilterItems[0] as Item).value);
  // const [showClosed, setShowClosed] = useState(false);

  const [data, setData] = useState<Payload | undefined>(undefined);

  const api = useApi();

  useEffect(() => {
    if (logInUser.id === null) {
      return;
    }

    const f = async (): Promise<void> => {
      const response = (await api.get('/v1/Project/List', {
        params: {
          projectType: filterValue,
          count: 10,
        },
      })) as ApiResponse;

      setData(response.payload as Payload);
    };
    f();
  }, [api, filterValue, logInUser.id]);

  const onMoreClick = useCallback(async () => {
    if (data === undefined) {
      return;
    }

    const response = (await api.get('/v1/Project/List', {
      params: {
        projectType: filterValue,
        count: data.projects.length + 10,
      },
    })) as ApiResponse;

    setData(response.payload as Payload);
  }, [api, data, filterValue]);

  return [
    data,
    filterValue,
    setFilterValue,
    // showClosed,
    // setShowClosed,
    onMoreClick,
  ];
};

const useProjectEditor: () => [boolean, () => void, () => void] = () => {
  const [projectEditorVisible, setProjectEditorVisible] = useState(false);

  const show = useCallback(() => {
    setProjectEditorVisible(true);
  }, []);

  const close: () => void = () => {
    setProjectEditorVisible(false);
  };

  return [projectEditorVisible, show, close];
};

const useCreateProjectName = (): (() => string) => {
  const logInUser = useRecoilValue(logInUserAtom);

  const createProjectName = useCallback(() => {
    if (logInUser.name === null) {
      return '';
    }

    const suffix = `のプロジェクト_${formatDate(new Date(), 'yyyyMMdd')}`;
    const maxUserNameLength = maxLength.project.name - suffix.length;

    // ユーザー名が長すぎて、プロジェクト名のデフォルトテンプレートだとプロジェクト名の最大文字数を超えてしまう場合。
    if (logInUser.name.length > maxUserNameLength) {
      const userName = `${logInUser.name.slice(0, maxUserNameLength - '...'.length)}...`;
      return `${userName}のプロジェクト_${formatDate(new Date(), 'yyyyMMdd')}`;
    }
    return `${logInUser.name}のプロジェクト_${formatDate(new Date(), 'yyyyMMdd')}`;
  }, [logInUser.name]);

  return createProjectName;
};

const PREFIX = 'MyProjects';
const classes = {
  title: `${PREFIX}-title`,
  tools: `${PREFIX}-tools`,
  createProject: `${PREFIX}-createProject`,
  pannel: `${PREFIX}-pannel`,
  button: `${PREFIX}-button`,
};

export const MyProjects = styled(Component)({
  marginTop: '24px',

  [`& .${classes.title}`]: {
    fontSize: '1.6rem',
    marginBottom: '12px',
    color: '#757575',
    fontWeight: 'bold',
  },

  [`& .${classes.tools}`]: {
    display: 'flex',
  },

  [`& .${classes.createProject}`]: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
    color: '#404040',
    cursor: 'pointer',
  },

  [`& .${classes.pannel}`]: {
    padding: '35px',
    backgroundColor: '#FFF',
    borderRadius: '4px',
    border: '1px solid #E3E3E3',
    textAlign: 'center',
    cursor: 'auto',
  },
  [`& .${classes.button}`]: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px',
  },
});
