import { styled } from '@mui/material/styles';
import { ChangeEventHandler, Dispatch, useCallback, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { ComponentPropsNormarized } from '../../types';

type Props = {
  checked: boolean;
  setChecked: Dispatch<boolean>;
} & ComponentPropsNormarized<'input'>;

function Component({ checked, setChecked, children, className, ...others }: Props): JSX.Element {
  const onChange: ChangeEventHandler<HTMLInputElement> = useCallback((e) => setChecked(e.target.checked), [setChecked]);

  const [id] = useState(uuidv4());

  return (
    <div className={className}>
      <input id={id} type="checkbox" checked={checked} onChange={onChange} className={classes.input} {...others} />
      <label htmlFor={id} className={classes.label}>
        {children}
      </label>
    </div>
  );
}

const PREFIX = 'RCheckbox';
const classes = {
  label: `${PREFIX}-label`,
  input: `${PREFIX}-input`,
};

export const RCheckbox = styled(Component, {
  shouldForwardProp: (prop) => prop !== 'sx',
})({
  display: 'flex',
  alignItems: 'center',

  [`& .${classes.label}`]: {
    cursor: 'pointer',
  },

  [`& .${classes.input}`]: {
    width: '18px',
    height: '18px',
    margin: '3px 11px 3px 4px',
    cursor: 'pointer',
  },
});
