import I_close from '../../../Images/navigation/close.svg';

import { styled } from '@mui/material/styles';
import { ComponentPropsNormarized, ValidationErrors } from '../../types';
import { RModal } from '../modal/modal';
import { RImage } from '../../base/image/image';
import { RInputGuide } from '../input-guide/input-guide';
import { RInput } from '../../base/input/input';
import { MouseEventHandler, useCallback, useState } from 'react';
import { RTextArea } from '../../base/text-area/text-area';
import { RButton } from '../../base/button/button';
import { maxLength } from '../../../services/max-length';
import { RInputLabel } from '../input-label/input-label';
import { RValidationMessages } from '../validation-message/validation-messages';
import { useSave } from '../../hooks/save';
import { RPorjectLanguageSelector } from './project-language-selector/project-language-selector';

type Props = {
  projectID?: number;
  name: string;
  description: string;
  languages?: number[];
  closeProjectEidtor: () => void;
  postURL: string;
  onEnd: (id: number) => void;
} & Omit<ComponentPropsNormarized<typeof RModal>, 'close'>;

// const projectStatusList: Item[] = [
//   { label: '全員にレビュー依頼', value: ProjectStatus.open.toString() },
//   { label: '契約相手のみレビュー投稿可能', value: ProjectStatus.onlyPartner.toString() },
//   { label: 'レビュー終了', value: ProjectStatus.nobody.toString() },
// ];

function Component({
  projectID,
  name,
  description,
  languages: currentLanguages = [],
  closeProjectEidtor,
  postURL,
  onEnd,
  className,
  ...others
}: Props): JSX.Element {
  const [projectName, setProjectName] = useState(name);
  // const [projectStatus, setProjectStatus] = useState((projectStatusList[0] as Item).value);
  const [projectDescription, setProjectDescription] = useState(description);
  const [languages, setLanguages] = useState(currentLanguages);

  const [validationErrors, onOk] = useOnOk(
    projectID,
    projectName,
    // projectStatus,
    languages,
    projectDescription,
    postURL,
    onEnd,
  );
  const onCancel = useOnCancel(closeProjectEidtor);

  return (
    <RModal
      close={closeProjectEidtor}
      style={{
        padding: '24px',
      }}
      className={className}
      {...others}>
      <div className={classes.header}>
        <h2 className={classes.title}>プロジェクトを作成</h2>
        <p onClick={onCancel}>
          <RImage
            sx={{
              cursor: 'pointer',
            }}
            src={I_close}
          />
        </p>
      </div>
      <p className={classes.notes}>※プロジェクトの内容はすべてのユーザーに公開されます。</p>

      <form className={classes.form} action="">
        <RInputLabel
          required
          sx={{
            marginBottom: '4px',
          }}>
          プロジェクト名
        </RInputLabel>
        <RInput
          sx={{
            width: '100%',
          }}
          placeholder="プロジェクト名を入力"
          maxLength={maxLength.project.name}
          value={projectName}
          setValue={setProjectName}
        />
        <RValidationMessages messages={validationErrors.Name} />
        <RInputGuide>※{maxLength.project.name}文字以下で入力してください。</RInputGuide>

        {/* <RInputLabel>プロジェクトステータス</RInputLabel>
        <RSelect
          data={projectStatusList}
          sx={{
            width: '100%',
          }}
          value={projectStatus}
          setValue={setProjectStatus}
        /> */}

        <RInputLabel
          sx={{
            marginBottom: '4px',
          }}>
          プロジェクト説明
        </RInputLabel>
        <RTextArea
          sx={{
            width: '100%',
            height: '160px',
          }}
          placeholder="プロジェクト説明を入力"
          maxLength={maxLength.project.description}
          value={projectDescription}
          setValue={setProjectDescription}
        />
        <RValidationMessages messages={validationErrors.Description} />

        <RPorjectLanguageSelector languages={languages} setLanguages={setLanguages} />
        <RValidationMessages messages={validationErrors.Languages} />
        <RInputGuide>※指定できる言語は{maxLength.project.language}個までです。</RInputGuide>

        <ul className={classes.buttons}>
          <li>
            <RButton kind="CancelNoFrame" onClick={onCancel}>
              キャンセル
            </RButton>
          </li>
          <li>
            <RButton
              sx={{
                marginLeft: '8px',
              }}
              onClick={onOk}>
              送信
            </RButton>
          </li>
        </ul>
      </form>
    </RModal>
  );
}

const useOnOk = (
  projectID: number | undefined,
  projectName: string,
  // projectStatus: string,
  languages: number[],
  projectDescription: string,
  postURL: string,
  onEnd: (createdID: number) => void,
): [ValidationErrors, MouseEventHandler<HTMLElement>] => {
  const [save, validationErrors] = useSave(postURL, '保存しました。');

  const onOk: MouseEventHandler<HTMLElement> = useCallback(
    async (e) => {
      const response = await save(e, {
        id: projectID,
        name: projectName,
        // status: projectStatus,
        languages: languages,
        description: projectDescription,
      });

      if (response.errorCode !== null) {
        return;
      }

      type Payload = {
        id: number;
      };
      const payload = response.payload as Payload;

      onEnd(payload.id);
    },
    [languages, onEnd, projectDescription, projectID, projectName, save],
  );

  return [validationErrors, onOk];
};

const useOnCancel: (closeProjectEidtor: () => void) => MouseEventHandler<HTMLElement> = (closeProjectEidtor: () => void) => {
  const onCancel: MouseEventHandler<HTMLElement> = useCallback(
    (e) => {
      e.preventDefault();
      closeProjectEidtor();
    },
    [closeProjectEidtor],
  );

  return onCancel;
};

const PREFIX = 'RProjectEditor';
const classes = {
  header: `${PREFIX}-header`,
  title: `${PREFIX}-title`,
  notes: `${PREFIX}-notes`,
  form: `${PREFIX}-form`,
  buttons: `${PREFIX}-buttons`,
};

export const RProjectEditor = styled(Component, {
  shouldForwardProp: (prop) => prop !== 'sx',
})({
  [`& .${classes.header}`]: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  [`& .${classes.title}`]: {
    fontSize: '2rem',
    lineHeight: '1.5',
    fontWeight: 500,
    color: '#000',
    marginBottom: '4px',
    textAlign: 'left',
  },

  [`& .${classes.notes}`]: {
    lineHeight: 1.5,
  },

  [`& .${classes.form}`]: {
    marginTop: '24px',
  },

  [`& .${classes.buttons}`]: {
    marginTop: '24px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
});
