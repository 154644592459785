import { SyntheticEvent } from 'react';
import { atom, useResetRecoilState, useSetRecoilState } from 'recoil';

type SnackbarAtom = {
  open: boolean;
  message: string;
};

export const snackbarAtom = atom({
  key: 'snackbarAtom',
  default: {
    open: false,
    message: '',
  } as SnackbarAtom,
});

export const useOpenSnackbar = (): ((message: string) => void) => {
  const setSnackbar = useSetRecoilState(snackbarAtom);
  const resetSnackbar = useResetRecoilState(snackbarAtom);

  const open = (message: string): void => {
    // 既に表示中なら、まずはそれを非表示にする。
    resetSnackbar();

    // 非表示になってから表示する。
    setTimeout(() => {
      setSnackbar({
        open: true,
        message: message,
      });
    });
  };

  return open;
};

export const useCloseSnackbar = (): ((event?: Event | SyntheticEvent<Element, Event> | undefined, reason?: string | undefined) => void) => {
  const resetSnackbar = useResetRecoilState(snackbarAtom);

  const close = (event?: SyntheticEvent | Event, reason?: string): void => {
    if (reason === 'clickaway') {
      return;
    }

    resetSnackbar();
  };

  return close;
};
