import I_blue from '../../../Images/content/star_blue_24px.png';
import I_yellow from '../../../Images/content/star_yellow_24px.png';

import { styled } from '@mui/material/styles';
import { ComponentPropsNormarized } from '../../types';
import { RImage } from '../../base/image/image';

export type ImportanceIDs = 1 | 2 | 3 | 4;

type Detail = {
  name: string;
  description: string;
  icon: string;
  iconCount: number;
};

type Details = { [k in ImportanceIDs]: Detail };

const details: Details = {
  1: { name: '重要', description: '修正しましょう。', icon: I_blue, iconCount: 3 },
  2: { name: '普通', description: '特別な事情がない限り修正しましょう。', icon: I_blue, iconCount: 2 },
  3: { name: '軽微', description: '余裕があれば修正しましょう。', icon: I_blue, iconCount: 1 },
  4: { name: '提案/質問', description: '必ずしも修正の必要がない提案や質問です。', icon: I_yellow, iconCount: 1 },
} as const;

type Props = {
  importance: keyof typeof details;
} & ComponentPropsNormarized<'span'>;

function Component({ importance, ...others }: Props): JSX.Element {
  const detail = details[importance];

  return (
    <span title={detail.description} {...others}>
      {Array(detail.iconCount)
        .fill(0)
        .map((_, i) => (
          <RImage
            key={i}
            src={detail.icon}
            sx={{
              marginRight: '1px',
              verticalAlign: 'middle',
            }}
          />
        ))}
      <span className={classes.text}>{detail.name}</span>
    </span>
  );
}

const PREFIX = 'RReviewImportance';
const classes = {
  text: `${PREFIX}-text`,
};

export const RReviewImportance = styled(Component, {
  shouldForwardProp: (prop) => prop !== 'sx',
})({
  [`& .${classes.text}`]: {
    fontSize: 10,
    letterSpacing: 0.3,

    color: '#9D9D9D',
  },
});
