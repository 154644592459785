import I_close from '../../../Images/navigation/close_white.png';

import { AlertProps, IconButton, Slide, SlideProps, Snackbar, styled } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { forwardRef } from 'react';
import { useRecoilValue } from 'recoil';
import { snackbarAtom, useCloseSnackbar } from '../../../atoms/snackbar';
import { RImage } from '../../base/image/image';
import { ComponentPropsNormarized } from '../../types';

type Props = ComponentPropsNormarized<typeof Snackbar>;

function Component({ className }: Props): JSX.Element {
  const { open, message } = useRecoilValue(snackbarAtom);
  const close = useCloseSnackbar();

  return (
    <Snackbar
      className={className}
      open={open}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      TransitionComponent={SlideTransition}
      autoHideDuration={3000}
      onClose={close}>
      <Alert
        className={classes.alert}
        icon={false}
        action={
          <IconButton onClick={close}>
            <RImage src={I_close} />
          </IconButton>
        }>
        {message}
      </Alert>
    </Snackbar>
  );
}

const Alert = forwardRef<HTMLDivElement, AlertProps>((props, ref) => <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />);

function SlideTransition(props: SlideProps): JSX.Element {
  return <Slide {...props} direction="down" exit={false} />;
}

const PREFIX = 'RSnackbar';
const classes = {
  alert: `${PREFIX}-alert`,
};

export const RSnackbar = styled(Component, {
  shouldForwardProp: (prop) => prop !== 'sx',
})({
  top: 64,
  height: 45,

  [`& .${classes.alert}`]: {
    width: '100%',
    height: '100%',

    background: '#37BE91',
    display: 'flex',
    alignItems: 'center',

    fontSize: 14,
    lineHeight: '21px',
  },
});
