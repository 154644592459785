import { styled } from '@mui/material/styles';
import { classNames } from '../../../classNames';
import { RAvatar } from '../../../core/avatar/avatar';
import { RRecordGroup } from '../../../core/record/record-group/record-group';
import { RRecord } from '../../../core/record/record/record';
import { useData } from '../../../hooks/data';
import { ComponentPropsNormarized } from '../../../types';
import { Message } from './message';
import { Params } from './message-detail';

type Props = {
  params: Params;
  refresh: () => void;
} & ComponentPropsNormarized<'div'>;

export type MessageItem = {
  id: number;
  sent: boolean;
  body: string;
  opened: boolean;
  createDate: string;
  deleted: boolean;
};

type Data = {
  items: MessageItem[];
  partnerID: number;
  partnerAvatarURL: string;
  partnerDeleted: boolean;
};

function Component({ params, refresh, className }: Props): JSX.Element {
  const [data] = useData<Data>('/v1/MyPage/MessageDetail', params);
  if (data === undefined) {
    return <></>;
  }
  if (data.items.length === 0) {
    return <></>;
  }

  return (
    <div className={className}>
      <RRecordGroup>
        <RRecord hover="None">
          {data.items.map((message, i) => {
            if (message.sent) {
              return <Message key={message.id} message={message} isFirst={i === 0} refresh={refresh} />;
            }
            return (
              <div key={message.id} className={classNames(classes.receivedMessageWrapper, i > 0 ? classes.receivedMessageWrapperMarginTop : undefined)}>
                <RAvatar size="M" src={data.partnerAvatarURL} userID={data.partnerDeleted ? undefined : data.partnerID} />
                <Message message={message} isFirst={i === 0} refresh={refresh} />
              </div>
            );
          })}
        </RRecord>
      </RRecordGroup>
    </div>
  );
}

const PREFIX = 'Messages';
const classes = {
  receivedMessageWrapper: `${PREFIX}-receivedMessageWrapper`,
  receivedMessageWrapperMarginTop: `${PREFIX}-receivedMessageWrapperMarginTop`,
};

export const Messages = styled(Component, {
  shouldForwardProp: (prop) => prop !== 'sx',
})({
  marginTop: 20,

  [`& .${classes.receivedMessageWrapper}`]: {
    display: 'flex',
  },
  [`& .${classes.receivedMessageWrapperMarginTop}`]: {
    marginTop: 20,
  },
});
