import { styled } from '@mui/material/styles';
import { Autocomplete, Chip, TextField } from '@mui/material';
import { ComponentPropsNormarized } from '../../../types';
import { Dispatch, SetStateAction } from 'react';
import { useRecoilValue } from 'recoil';
import { languagesAtom } from '../../../../atoms/languages';
import { maxLength } from '../../../../services/max-length';

type Props = {
  languages: number[];
  setLanguages: Dispatch<SetStateAction<number[]>>;
} & ComponentPropsNormarized<'div'>;

function Component({ languages, setLanguages, className, ...others }: Props): JSX.Element {
  const options = useRecoilValue(languagesAtom);

  return (
    <div className={className} {...others}>
      <div>言語</div>
      <Autocomplete
        multiple
        options={options.map((option) => option.name)}
        value={languages.map((language) => options.find((option) => option.id === language)!.name)}
        renderTags={(value: readonly string[], getTagProps): React.ReactNode =>
          // eslint-disable-next-line react/jsx-key
          value.map((option: string, index: number) => <Chip variant="outlined" label={option} {...getTagProps({ index })} />)
        }
        renderInput={(params): React.ReactNode => (
          <TextField
            {...params}
            placeholder="言語を選択"
            inputProps={{
              ...params.inputProps,
              onKeyDown: (e): void => {
                if (e.key === 'Enter') {
                  // ポップアップごと閉じられてしまうのを抑制。
                  e.preventDefault();
                }
              },
            }}
          />
        )}
        onChange={(_event, values, _reason): void => {
          if (values.length > maxLength.project.language) {
            return;
          }
          const ids = values.map((x) => options.find((option) => x === option.name)!.id);
          setLanguages(ids);
        }}
        disableClearable
        sx={{
          marginTop: '4px',
        }}
      />
    </div>
  );
}

export const RPorjectLanguageSelector = styled(Component, {
  shouldForwardProp: (prop) => prop !== 'sx',
})({
  marginTop: 16,
});
