import I_search from '../../../Images/action/search.png';
import I_searchBlue from '../../../Images/action/search_blue.png';

import { styled } from '@mui/material/styles';
import { useIsMine } from './is-mine';
import { getReviewsToShow } from './review-util';
import { Review } from '../../../atoms/project-details';
import { RLink } from '../../base/link/link';
import { ComponentPropsNormarized } from '../../types';

type Props = {
  ownerID: number;
  reviews: Review[];
  shouldShowClosedReview: boolean;
} & ComponentPropsNormarized<'div'>;

function Component({ ownerID, reviews, shouldShowClosedReview, className }: Props): JSX.Element {
  const reviewsToShow = getReviewsToShow(reviews, shouldShowClosedReview);
  const isMine = useIsMine(ownerID);

  if (reviewsToShow.length > 0) {
    return <></>;
  }

  return (
    <div className={className}>
      {
        // コメントなし(プロジェクトオーナー)
        reviews.length === 0 && isMine && (
          <>
            <p>
              まだコメントがありません。 <br />
              だれかにレビューを <br />
              お願いすることもできます！
            </p>
            <div className={classes.searchUserWrapper}>
              <RLink
                kind="GrayOnWhite"
                href="/UserSearch/List"
                sx={{
                  marginTop: '24px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <div className={classes.searchUserIcon} />
                <p>探しにいく</p>
              </RLink>
            </div>
          </>
        )
      }

      {
        // コメントなし(非プロジェクトオーナー)
        reviews.length === 0 && !isMine && (
          <>
            <p>
              このプロジェクトはレビュアー募集中です。 <br />
              レビューコメントを送ってみましょう！
            </p>
          </>
        )
      }

      {
        // 解決済みコメントだけあるが、それは非表示(プロジェクトオーナー)
        reviews.length > 0 && reviewsToShow.length === 0 && !shouldShowClosedReview && isMine && (
          <>
            <p>
              コメントはすべて解決済みです。
              {/* 追加のコメントを依頼しますか？
                    </p>
                    <p>
                        プロジェクトを終了する場合は、<br />
                        ステータスをクローズにしましょう！ */}
            </p>
          </>
        )
      }

      {
        // 解決済みコメントだけあるが、それは非表示(非プロジェクトオーナー)
        reviews.length > 0 && reviewsToShow.length === 0 && !shouldShowClosedReview && !isMine && (
          <>
            <p>コメントはすべて解決済みです。</p> <br />
            <p>
              もし気づいたことがあれば、 <br />
              追加でコメントしてみましょう！
            </p>
          </>
        )
      }
    </div>
  );
}

const PREFIX = 'CommentsDescription';
const classes = {
  searchUserWrapper: `${PREFIX}-searchUserWrapper`,
  searchUserIcon: `${PREFIX}-searchUserIcon`,
  link: `${PREFIX}-link`,
};

export const CommentsDescription = styled(Component)({
  padding: '40px 0',
  color: '#757575',
  textAlign: 'center',

  [`& .${classes.searchUserWrapper} `]: {
    [`&:hover .${classes.searchUserIcon}`]: {
      backgroundImage: `url(${I_searchBlue})`,
    },
  },
  [`& .${classes.searchUserIcon}`]: {
    width: 24,
    height: 24,
    backgroundImage: `url(${I_search})`,
  },
});
