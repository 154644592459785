import I_edit from '../../../../Images/content/edit.png';

import { styled } from '@mui/material/styles';
import { Dispatch, MouseEventHandler, SetStateAction, useCallback, useEffect, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { logInUserAtom } from '../../../../atoms/log-in-user';
import { RButton } from '../../../base/button/button';
import { useSave } from '../../../hooks/save';
import { RContainer } from '../../../layout/Container';
import { ComponentPropsNormarized, ValidationErrors } from '../../../types';
import { Circle } from '../circle';
import { InputMailAddress } from './input-mail-address';
import { InputMailAddressDone } from './input-mail-address-done';
import { ResetPassword } from './reset-password';

type Props = ComponentPropsNormarized<typeof RContainer>;

function Component({ className }: Props): JSX.Element {
  const logInUser = useRecoilValue(logInUserAtom);

  const [mail, setMail] = useState('');
  const [showEditMailAddress, setShowEditMailAddress] = useState(false);
  const [showEditMailAddressDone, setShowEditMailAddressDone] = useState(false);

  const [onInputMailAddressDone, validationErrors, clearValidationErrors] = useOnInputMailAddressDone(mail, setShowEditMailAddress, setShowEditMailAddressDone);
  const showInputMailAddress = useShowInputMailAddress(setShowEditMailAddress, setMail, clearValidationErrors);

  const [showResetPassword, setShowResetPassword] = useState(false);
  const onResetPassword = useOnResetPassword(setShowResetPassword);

  return (
    <div className={className}>
      <h2>
        <Circle color="#fdba35" />
        <span>アカウント</span>
      </h2>

      <div className={classes.row}>
        <dl className={classes.info}>
          <dt className={classes.label}>メールアドレス</dt>
          <dd>{logInUser.mail}</dd>
        </dl>
        <RButton
          kind="WithFrameGray"
          sx={{
            fontSize: 12,
            backgroundImage: `url(${I_edit})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: '8px center',
            paddingLeft: '27px',
            paddingRight: '7px',
          }}
          onClick={showInputMailAddress}>
          編集
        </RButton>
      </div>
      {showEditMailAddress && (
        <InputMailAddress
          close={(): void => setShowEditMailAddress(false)}
          done={(e): void => onInputMailAddressDone(e)}
          mail={mail}
          setMail={setMail}
          validationErrors={validationErrors}
        />
      )}
      {showEditMailAddressDone && <InputMailAddressDone close={(): void => setShowEditMailAddressDone(false)} mail={mail} />}

      <div className={classes.row}>
        <dl className={classes.info}>
          <dt className={classes.label}>パスワード</dt>
          <dd>●●●●●●●●</dd>
        </dl>
        <RButton
          kind="WithFrameGray"
          sx={{
            fontSize: 12,
            backgroundImage: `url(${I_edit})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: '8px center',
            paddingLeft: '27px',
            paddingRight: '7px',
          }}
          onClick={onResetPassword}>
          編集
        </RButton>
      </div>
      {showResetPassword && <ResetPassword close={(): void => setShowResetPassword(false)} />}
    </div>
  );
}

const useShowInputMailAddress = (setShowEditMailAddress: Dispatch<boolean>, setMail: Dispatch<string>, clearValidationErrors: () => void): (() => void) => {
  const showInputMailAddress = useCallback(() => {
    setMail('');
    clearValidationErrors();
    setShowEditMailAddress(true);
  }, [clearValidationErrors, setMail, setShowEditMailAddress]);
  return showInputMailAddress;
};

const useOnInputMailAddressDone = (
  mail: string,
  setShowEditMailAddress: Dispatch<SetStateAction<boolean>>,
  setShowEditMailAddressDone: Dispatch<SetStateAction<boolean>>,
): [MouseEventHandler<HTMLElement>, ValidationErrors, () => void] => {
  const [save, validationErrors, clearValidationErrors] = useSave('/v1/MyPage/UpdateMailAddress');
  const [done, setDone] = useState(false);
  const processing = useRef(false);

  const onInputMailAddressDone = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      if (processing.current) {
        return;
      }
      processing.current = true;

      setDone(false);
      await save(e, { mailAddress: mail });
      setDone(true);

      // eslint-disable-next-line require-atomic-updates
      processing.current = false;
    },
    [mail, save],
  );

  useEffect(() => {
    if (processing.current) {
      return;
    }
    if (done && Object.keys(validationErrors).length === 0) {
      setShowEditMailAddress(false);
      setShowEditMailAddressDone(true);
    }
    setDone(false);
  }, [done, setShowEditMailAddress, setShowEditMailAddressDone, validationErrors]);

  return [onInputMailAddressDone, validationErrors, clearValidationErrors];
};

const useOnResetPassword = (setShowResetPassword: Dispatch<boolean>): ((e: React.MouseEvent<HTMLElement, globalThis.MouseEvent>) => Promise<void>) => {
  const [save] = useSave('/v1/MyPage/ResetPassword');

  const onResetPassword = useCallback(
    async (e: React.MouseEvent<HTMLElement, globalThis.MouseEvent>) => {
      await save(e, {});
      setShowResetPassword(true);
    },
    [save, setShowResetPassword],
  );

  return onResetPassword;
};

const PREFIX = 'Main';
const classes = {
  row: `${PREFIX}-row`,
  info: `${PREFIX}-info`,
  label: `${PREFIX}-label`,
};

export const Main = styled(Component, {
  shouldForwardProp: (prop) => prop !== 'sx',
})({
  [`& .${classes.row}`]: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 20,
  },
  [`& .${classes.info}`]: {
    display: 'flex',
  },
  [`& .${classes.label}`]: {
    color: '#757575',
    marginRight: 28,
    minWidth: 96,
  },
});
